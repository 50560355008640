import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import theme from 'utils/theme'
import { Box, Text, Clickable } from '../atoms'

const DropDown = ({ header, options, icon }) => {
  const [clicked, setClicked] = useState(false)
  const { t } = useTranslation(['common'])

  return (
    <Box>
      <Clickable onPress={() => setClicked(!clicked)}>
        <Box left>
          <Box p="5px">
            <Box borderRadius="3px">
              <img src={icon} alt="flag" />
              {/* header */}
            </Box>
          </Box>
        </Box>
      </Clickable>

      {clicked && (
        <Box
          absolute
          mt="30px"
          ml="-70px"
          color1={theme.colors.greySoft}
          color2={theme.colors.greySoft}
          width="115px"
          rounded
          style={{ zIndex: '1101' }}
        >
          {options?.map(item => (
            <Clickable
              onPress={() => {
                item.onPress()
                setClicked(false)
              }}
            >
              <Box flexDirection="row" bg={theme.colors.greySoft}>
                <Box width="20%" p="6px">
                  <Box borderRadius="2px">
                    <img src={item.icon} alt="flag" />
                  </Box>
                </Box>
                <Box width="80%">
                  <Text color="black">{item.text}</Text>
                </Box>
              </Box>
            </Clickable>
          ))}
        </Box>
      )}
    </Box>
  )
}

DropDown.propTypes = {
  options: PropTypes.array.isRequired,
}

export default DropDown
