import React, { useMemo } from 'react'
import DGTooltipCell from '../comps/DGTooltipCell'
import ESQVDG from './ESQVDG'

// better readability of entry titles if truncated on left side instead of right
// const RTLCell = ({ value }) => (
//   <div title={value} style={{ direction: 'rtl', overflow: 'hidden', textOverflow: 'ellipsis' }}>
//     {value}
//   </div>
// )

const BoldHeader = ({ value }) => (
  <strong>
    <span>{value}</span>
  </strong>
)

const commonColumnProps = {
  disableColumnMenu: true,
  headerAlign: 'center',
  sortable: false,
  editable: false,
  renderCell: params => <DGTooltipCell value={params.value} />,
  renderHeader: params => <BoldHeader value={params.colDef.headerName} />,
}

const columns = [
  {
    field: 'entryTitle',
    headerName: 'Entry Title',
    flex: 0.2,
    ...commonColumnProps,
  },
  {
    field: 'baseCode',
    headerName: 'Baseline Code',
    flex: 0.2,
    ...commonColumnProps,
  },
  {
    field: 'followUpCode',
    headerName: 'Follow-up Code',
    flex: 0.2,
    ...commonColumnProps,
  },
]

const getCode = (bother, sev1, sev2, sev3) =>
  `B(${bother?.selectAnswer ?? 'N/A'}) / I(F${sev1?.selectAnswer ?? '<missing>'})` +
  `${sev2?.selectAnswer !== undefined ? ` ; II(F${sev2?.selectAnswer})` : ''}` +
  `${sev3?.selectAnswer !== undefined ? ` ; III(F${sev3?.selectAnswer})` : ''}`

// ex. entries = [{ title: sharedEntryTitle, severity1: <entry_set>, severity2: <entry_set>, severity3: <entry_set>, bother: <entry_set> }, ... ]
// <entry_set> = { base: <entry>, followUp: <entry> }
const ESQVFreqSevDG = ({ entries }) => {
  const rows = useMemo(() => {
    const arr = []
    entries.forEach((cat, index) => {
      if (cat) {
        arr.push({
          id: index,
          entryTitle: cat?.title ?? '<Title missing>',
          baseCode: getCode(
            cat?.bother?.base,
            cat?.severity1?.base,
            cat?.severity2?.base,
            cat?.severity3?.base
          ),
          followUpCode: getCode(
            cat?.bother?.followUp,
            cat?.severity1?.followUp,
            cat?.severity2?.followUp,
            cat?.severity3?.followUp
          ),
        })
      }
    })
    return arr
  }, [entries])

  if (!rows || (rows && rows.length === 0)) {
    return null
  }

  return <ESQVDG rows={rows} columns={columns} />
}

export default ESQVFreqSevDG
