import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Box, FilterChip } from '../atoms'

const PARTICIPANTS_FILTERS_QUERY = gql`
  query AllParticipations {
    allParticipations {
      filterFields {
        title
        filterType
        select
      }
    }
  }
`

const Filters = ({ setFilters }) => {
  const { loading, error, data } = useQuery(PARTICIPANTS_FILTERS_QUERY)
  const [filterChips, setFilterChips] = useState([])

  useEffect(() => {
    if (data && data !== null) {
      const preparedFilters = data.allParticipations?.filterFields?.map(item => ({
        label: item.title,
        valType: { NR: 'number', SO: 'string', TF: 'string', DA: 'date' }[item.filterType],
        selection: item.select && JSON.parse(item.select),
      }))
      setFilterChips(preparedFilters)
    }
  }, [data])

  const updateFilterChipItem = item => {
    // const newFilterChips = filterChips.map(o => (o.label === item.label && item) || o)
    const newFilterChips = filterChips.map(o => (o.label === item.label ? { ...o, ...item } : o))
    setFilterChips(newFilterChips)
    setFilters(newFilterChips)
  }

  return (
    <Box row style={{ gap: '8px' }}>
      {filterChips?.map(filterItem => (
        <FilterChip
          key={`filter#${filterItem.label}`}
          label={filterItem.label}
          filter={filterItem.filter}
          updateFilterChipItem={updateFilterChipItem}
          valType={filterItem.valType}
          valLabel={filterItem.label}
          selection={filterItem.selection}
        />
      ))}
    </Box>
  )
}

export default Filters
