import React, { useRef, useState, useEffect } from 'react'
import 'react-chat-elements/dist/main.css'
import { ChatList, MessageList, Input, Button } from 'react-chat-elements'
import { gql, useMutation, useQuery, useSubscription } from '@apollo/client'
import { reverse, uniqueId } from 'lodash'
import moment from 'moment'
import colors from 'utils/colors'
import { getToken } from 'utils/helper'
import { Text, Box, Clickable } from '../atoms'

// ignore nest tenary expression in this file
/* eslint-disable no-nested-ternary */

const SET_MESSAGES_AS_READ = gql`
  mutation SetMessagesAsRead($receiverId: String) {
    setMessagesAsRead(receiverId: $receiverId) {
      successful
    }
  }
`

// const CHAT_SUBSCRIPTION = gql`
//   subscription ($token: String!, $chatroom: String) {
//     onNewChatMessage(token: $token, chatroom: $chatroom) {
//       id
//       sender
//       text
//       createdAt
//     }
//   }
// `

const CREATE_MESSAGE = gql`
  mutation ($text: String!, $receiverId: String) {
    createMessage(text: $text, receiverId: $receiverId) {
      successful
    }
  }
`

const GET_MESSAGES = gql`
  query Messages($senderId: String, $first: Int) {
    messages(senderId: $senderId, first: $first, offset: 0) {
      edges {
        node {
          id
          createdAt
          text
          senderId
          sender
          sent
        }
      }
    }
  }
`

const Conversation = ({ username, isStaff, noPaddingTop }) => {
  console.log('username', username)

  const messageListReference = useRef(null)
  const inputReference = useRef(null)
  const [offset, setOffset] = useState(20)
  const [reachedEnd, setReachedEnd] = useState(false)

  const [setMessagesAsRead] = useMutation(SET_MESSAGES_AS_READ, {
    variables: {
      receiverId: username,
    },
  })

  const [messages, setMessages] = useState([])
  const { fetchMore, refetch, startPolling, stopPolling, loading } = useQuery(GET_MESSAGES, {
    variables: {
      senderId: username,
    },
    onCompleted: data => {
      console.log('isStaff', isStaff)
      if (data?.messages) {
        const _messages = reverse(
          data.messages.edges.map(m => ({
            position: isStaff
              ? m.node.sender === username
                ? 'left'
                : 'right'
              : m.node.sender === username
              ? 'right'
              : 'left',
            type: 'text',
            text: m.node.text,
            id: m.node.id,
            date: m.node.createdAt,
          }))
        )
        setMessages(_messages)
        if (data.messages.edges.length < 20) {
          setReachedEnd(true)
        }
      }
    },
  })

  useEffect(() => {
    if (!loading) {
      startPolling(10000)
    } else {
      stopPolling()
    }
  }, [loading])

  useEffect(() => {
    setOffset(20)
    setMessagesAsRead()
    refetch()
  }, [username])

  // useSubscription(CHAT_SUBSCRIPTION, {
  //   variables: {
  //     token: getToken(),
  //     chatroom: username,
  //   },
  //   onSubscriptionData: d => {
  //     console.log('onsubscription data')
  //     console.log(d)
  //     if (d?.subscriptionData?.data?.onNewChatMessage) {
  //       const newMessage = d.subscriptionData.data.onNewChatMessage
  //       setMessages([
  //         ...messages,
  //         {
  //           position: newMessage.sender === username ? 'left' : 'right',
  //           type: 'text',
  //           text: newMessage.text,
  //           id: newMessage.id,
  //           date: newMessage.createdAt,
  //         },
  //       ])
  //       // setMessagesAsRead()
  //     }
  //   },
  //   onError: e => {
  //     console.log('got subscription error')
  //     console.log(e)
  //   },
  // })

  const [createMessage] = useMutation(CREATE_MESSAGE)

  const onSend = () => {
    createMessage({ variables: { text: inputReference.current.value, receiverId: username } })
    setMessages([
      ...messages,
      {
        position: 'right',
        type: 'text',
        text: inputReference.current.value,
        id: uniqueId(),
        date: moment().format(),
      },
    ])
    inputReference.current.value = ''
  }

  useEffect(() => {
    messageListReference.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  return (
    <Box width={noPaddingTop ? '100%' : '75%'} bg={colors.greySoft} pt="8pt">
      <Box
        cc
        height="64px"
        style={{
          borderBottomWidth: '1px',
          borderBottomColor: colors.greyMid,
          borderBottomStyle: 'solid',
        }}
      >
        {!reachedEnd && (
          <Clickable
            onPress={() => {
              // fetchMore({
              //   variables: {
              //     offset: offset + 15,
              //     senderId: username,
              //   },
              // }).then(({ data }) => {
              //   if (data?.messages) {
              //     const _messages = reverse(
              //       data.messages.edges.map(m => ({
              //         position: m.node.sender === username ? 'left' : 'right',
              //         type: 'text',
              //         text: m.node.text,
              //         id: m.node.id,
              //         date: m.node.createdAt,
              //       }))
              //     )
              //     setMessages([..._messages, ...messages])
              //     if (_messages.length < 15) {
              //       setReachedEnd(true)
              //     }
              //   }
              // })
              setOffset(offset + 20)
            }}
          >
            <Text small semibold color={colors.blue}>
              Load more
            </Text>
          </Clickable>
        )}
      </Box>
      <Box style={{ overflowY: 'scroll', height: '80vh', paddingTop: noPaddingTop ? 12 : 64 }}>
        <MessageList
          reference={messageListReference}
          className="message-list"
          lockable
          toBottomHeight="100%"
          dataSource={messages}
        />
      </Box>
      <Box ph="1rem" pb="1.5rem">
        <Input
          referance={inputReference}
          placeholder="Type here..."
          multiline
          rightButtons={
            <Button color="white" backgroundColor="black" text="Send" onClick={onSend} />
          }
        />
      </Box>
    </Box>
  )
}
export default Conversation
