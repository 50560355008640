import React from 'react'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import { Text } from 'components'

function NumberInput({ label, value, setvalue, display, marginRight }) {
  const margin = marginRight || 10

  return (
    <div style={{ marginBottom: 10, display }}>
      <div style={{ paddingTop: 5, marginRight: margin, marginBottom: 3 }}>
        <Text fontSize="17px"> {label} </Text>
      </div>
      <NumberFormat
        style={{
          width: 80,
          textAlign: 'center',
          paddingTop: 0,
          paddingBottom: 0,
          marginBottom: 0,
          borderRadius: 5,
          height: 35,
        }}
        defaultValue={value}
        onChange={o => setvalue(o)}
      />
    </div>
  )
}

NumberInput.propTypes = {
  setvalue: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  display: PropTypes.string,
  marginRight: PropTypes.number,
}

NumberInput.defaultProps = {
  label: PropTypes.label,
  display: PropTypes.display,
  marginRight: PropTypes.marginRight,
}
export default NumberInput
