import React, { useEffect } from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { gql, useLazyQuery } from '@apollo/client'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { KeyboardArrowLeft } from 'assets/icons'
import Compare from 'components/ESQ_Visualization/comps/Compare'
import moment from 'moment'
import colors from 'utils/colors'
import { Box, Text, Clickable } from '../components'
import EsqVisManager from '../components/ESQ_Visualization/EsqVisManager'
import { Loading } from '../components/ESQ_Visualization/wrappers'

// set_of_form_results = Field(List(FormResultType), form_titles = List(String()), participation_id = ID(), date = Date())
const GET_SET_OF_FORM_RESULTS = gql`
  query getSetOfFormResults($formTitles: [String!]!, $date: Date!, $participationId: ID) {
    getSetOfFormResults(formTitles: $formTitles, date: $date, participationId: $participationId) {
      id
      formTitle
      entryResults {
        entryId {
          title
          description
          entryType
          selection
        }
        numberAnswer
        textAnswer
        selectAnswer
        datefieldAnswer
        datetimefieldAnswer
        timefieldAnswer
      }
    }
  }
`

const GET_MOST_RECENT_FORM_RESULT = gql`
  query dobbyFormResults(
    $formTitlesEntryTitles: [DobbyGetFormResultsInputType!]!
    $participationId: ID
  ) {
    dobbyFormResults(
      formTitlesEntryTitles: $formTitlesEntryTitles
      participationId: $participationId
    ) {
      formTitle
      entryResults {
        title
        value
        isNumber
      }
    }
  }
`

const formTitles = [
  'ESQ General Information',
  'ESQ My Help Request',
  'ESQ My Sleep Profile',
  'ESQ Factors Affecting My Sleep',
  'ESQ My Sleep Affecting Others',
  'ESQ Nocturnal Sleep',
  'ESQ Daytime Functioning',
  'ESQ Follow-Up',
]

// const theme = () =>
//   createTheme({
//     palette: {
//       mode: 'dark',
//     },
//   })

const darkTheme = {
  palette: {
    mode: 'dark',
  },
}

// [<Entry: ESQ Metadata : base_finished_at>, <Entry: ESQ Metadata : follow_up_finished_at>]

const ESQVisualization = ({ inResponsiveDrawer }) => {
  const { state } = useLocation()
  const navigate = useNavigate()

  // Metadata usually in location.state, gql query as backup
  const [getMetaData, { data: gmdData, loading: gmdLoading, error: gmdError }] = useLazyQuery(
    GET_MOST_RECENT_FORM_RESULT
  )
  const [getEsqBase, { data: gebData, loading: gebLoading, error: gebError }] =
    useLazyQuery(GET_SET_OF_FORM_RESULTS)
  const [getEsqFollowUp, { data: gefuData, loading: gefuLoading, error: gefuError }] =
    useLazyQuery(GET_SET_OF_FORM_RESULTS)

  useEffect(() => {
    if (!state?.esqMetadata && state?.id && !gmdData && !gmdLoading) {
      getMetaData({
        notifyOnNetworkStatusChange: true,
        variables: {
          formTitlesEntryTitles: [
            {
              formTitle: 'ESQ Metadata',
              entryTitles: ['base_finished_at', 'follow_up_finished_at'],
            },
          ],
          participationId: state.id,
        },
      })
    }
  }, [state?.id, state?.esqMetadata, gmdData, gmdLoading])

  useEffect(() => {
    let date = null
    if (gmdData?.dobbyFormResults) {
      date = gmdData.dobbyFormResults[0]?.entryResults.find(
        er => er.title === 'base_finished_at'
      )?.value
    } else if (state?.esqMetadata?.base) {
      date = state.esqMetadata.base
    }
    if (date) {
      getEsqBase({
        variables: {
          formTitles,
          date: moment(date).format('YYYY-MM-DD'),
          participationId: state.id,
        },
      })
    }
  }, [gmdData, state?.esqMetadata])

  useEffect(() => {
    let date = null
    if (gmdData?.dobbyFormResults) {
      date = gmdData.dobbyFormResults[0]?.entryResults.find(
        er => er.title === 'follow_up_finished_at'
      )?.value
    } else if (state?.esqMetadata?.followUp) {
      date = state.esqMetadata.followUp
    }
    if (date) {
      getEsqFollowUp({
        variables: {
          formTitles,
          date: moment(date).format('YYYY-MM-DD'),
          participationId: state.id,
        },
      })
    }
  }, [gmdData, state?.esqMetadata])

  useEffect(() => {
    if (gmdError) {
      console.log('getMetaData error:')
      console.log(gmdError)
    }
    // if (gmdData) {
    //   console.log('getMetaData data:')
    //   console.log(gmdData)
    // }
  }, [gmdError, gmdData])

  useEffect(() => {
    if (gebError) {
      console.log('getEsqBase error:')
      console.log(gebError)
    }
    // if (gebData) {
    //   console.log('getEsqBase data:')
    //   console.log(gebData)
    // }
  }, [gebError, gebData])

  useEffect(() => {
    if (gefuError) {
      console.log('getEsqFollowUp error:')
      console.log(gefuError)
    }
    // if (gefuData) {
    //   console.log('getEsqFollowUp data:')
    //   console.log(gefuData)
    // }
  }, [gefuError, gefuData])

  if (!state?.id && !state?.personal) {
    return <Navigate to="/Home" />
  }

  if (gmdLoading || gebLoading || gefuLoading) {
    return <Loading />
  }
  // if (!(gebData && gefuData)) {
  if (!gebData) {
    return (
      <Box cc mt="80px">
        <Text bold color="black">
          Missing data
        </Text>
      </Box>
    )
  }

  return (
    <ThemeProvider
      theme={outerTheme =>
        createTheme({
          ...outerTheme,
          ...darkTheme,
        })
      }
    >
      <Box
        col
        minHeight={inResponsiveDrawer ? 'calc(100vh - 64px)' : 'calc(100vh - 104px)'}
        backgroundColor={colors.greyDark}
        ph="2rem"
        pv="3rem"
        style={{
          boxSizing: 'border-box',
        }}
      >
        <Box block mb="16px" row>
          <Clickable
            onPress={() => {
              navigate(-1)
            }}
          >
            <Box
              height="2rem"
              width="2rem"
              round
              justifyContent="center"
              cc
              bg={colors.button.right}
            >
              <KeyboardArrowLeft fill="white" style={{ marginLeft: '-2px' }} />
            </Box>
          </Clickable>
          <Box ml="1rem">
            <Text huge bold>
              ESQ Visualization
            </Text>
          </Box>
        </Box>
        <Compare>
          <EsqVisManager
            metaData={state?.esqMetadata || gmdData.dobbyFormResults[0]}
            baseData={gebData?.getSetOfFormResults}
            followUpData={gefuData?.getSetOfFormResults || []}
            sri={state?.sri}
          />
          <EsqVisManager
            metaData={state?.esqMetadata || gmdData.dobbyFormResults[0]}
            baseData={gebData?.getSetOfFormResults}
            followUpData={gefuData?.getSetOfFormResults || []}
            sri={state?.sri}
            comparison
          />
        </Compare>
      </Box>
    </ThemeProvider>
  )
}

export default ESQVisualization
