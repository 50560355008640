import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import colors from '../../../utils/colors.js'
import { Box, Text } from '../../atoms'
import { ResetPassword } from '../../molecules'

const RESET_PASSWORD = gql`
  mutation PasswordReset($token: String!, $password: String!) {
    passwordReset(token: $token, newPassword1: $password, newPassword2: $password) {
      success
      errors
    }
  }
`

const PasswordResetForm = ({ token }) => {
  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: ({ passwordReset }) => {
      setIsSuccess(passwordReset?.success || false)
    },
    onError: error => {
      setIsSuccess(false)
    },
  })
  const [isSuccess, setIsSuccess] = useState(null)

  return (
    <Box
      p="32px"
      cc
      alignItems="center"
      justifyContent="center"
      backgroundColor={colors.lightBrown}
      rounded
    >
      {isSuccess == null && <ResetPassword onResetPassword={resetPassword} token={token} />}
      {isSuccess != null && isSuccess && (
        <Text color={colors.greyDarkest}>Password successfully reset</Text>
      )}
      {isSuccess != null && !isSuccess && (
        <Text color={colors.greyDarkest}>
          Invalid token. Please request a new email to reset the password.
        </Text>
      )}
    </Box>
  )
}

export default PasswordResetForm
