import React, { Suspense, useContext } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Redirect } from 'react-router'
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'
import { ResponsiveDrawer, ChatWindow, Header, CognitiveTasks, Box, Footer } from 'components'
import Layout from '../layouts'
import { getDisabledRoutes, isLogin } from '../utils/helper'
import UserContext from '../utils/UserContext'
import {
  Error,
  Me,
  Login,
  Home,
  PasswordReset,
  Chat,
  CognitiveBattery,
  ParticipationDetails,
  ReactionTask,
  Results,
  ListLearningTask,
  LineOrientationTask,
  DigitSymbolCoding,
  AuditoryVerbalLearning,
  PASATC,
  PVSAT,
  TowerOfLondon,
  Finish,
  Esq,
  EsqTest,
  PrivacyPolicy,
  CookiesPolicy,
  TermsOfUse,
  GeneralInformation,
  MyHelpRequest,
  MySleepProfile,
  FactorsAffectingMySleep,
  MySleepAffectingOthers,
  NocturalSleep,
  DaytimeFunctioning,
  ESQVisualization,
  AccountDeletion,
} from '../views'

const Router = props => {
  const { user } = useContext(UserContext)

  console.log('user in router: ', user)

  if (user === null) {
    return null
  }

  console.log('in router')

  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={Error}>
        <Suspense fallback={<div>Loading... </div>}>
          <Routes>
            <Route path="/" element={<HomeRoute user={user} Component={Login} />} />
            <Route path="/PasswordReset" element={<PasswordReset />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/AccountDeletion" element={<AccountDeletion />} />
            <Route path="/CookiesPolicy" element={<CookiesPolicy />} />
            <Route path="/TermsOfUse" element={<TermsOfUse />} />
            {/* <Route path="/Esq" element={<EsqTest />} />
            <Route path="/EsqFollowUp" element={<EsqTest secondRound />} /> */}
            <Route path="/Esq" element={<PrivateRoute user={user} Component={EsqTest} />} />
            <Route
              path="/EsqFollowUp"
              element={<PrivateRoute user={user} Component={EsqTest} secondRound />}
            />
            {/* <Route path="/Esq/GeneralInformation" element={<GeneralInformation />} />
            <Route path="/Esq/MyHelpRequest" element={<MyHelpRequest />} />
            <Route path="/Esq/MySleepProfile" element={<MySleepProfile />} />
            <Route path="/Esq/FactorsAffectingMySleep" element={<FactorsAffectingMySleep />} />
            <Route path="/Esq/MySleepAffectingOthers" element={<MySleepAffectingOthers />} />
            <Route path="/Esq/NocturalSleep" element={<NocturalSleep />} />
            <Route path="/Esq/DaytimeFunctioning" element={<DaytimeFunctioning />} /> */}
            <Route path="/Chat" element={<ProtectedRoute user={user} Component={Chat} />} />
            <Route path="/myPage" element={<PrivateRoute user={user} Component={Me} />} />
            <Route path="/Home" element={<ProtectedRoute user={user} Component={Home} />} />
            <Route
              path="/cognitivetasks"
              element={<SecondaryPrivateRoute user={user} Component={CognitiveTasks} />}
            />
            <Route
              path="/cognitivebattery"
              element={<SecondaryPrivateRoute user={user} Component={CognitiveBattery} />}
            />
            <Route
              path="/participation"
              element={<ProtectedRoute user={user} Component={ParticipationDetails} />}
            />
            <Route
              path="/reactiontask"
              element={<SecondaryPrivateRoute user={user} Component={ReactionTask} />}
            />
            <Route
              path="/results"
              element={<SecondaryPrivateRoute user={user} Component={Results} />}
            />
            <Route
              path="/listlearningtask"
              element={<SecondaryPrivateRoute user={user} Component={ListLearningTask} />}
            />
            <Route
              path="/lineorientationtask"
              element={<SecondaryPrivateRoute user={user} Component={LineOrientationTask} />}
            />
            <Route
              path="/digitsymbolcoding"
              element={<SecondaryPrivateRoute user={user} Component={DigitSymbolCoding} />}
            />
            <Route
              path="/auditoryverballearning"
              element={<SecondaryPrivateRoute user={user} Component={AuditoryVerbalLearning} />}
            />
            <Route
              path="/pasatc"
              element={<SecondaryPrivateRoute user={user} Component={PASATC} />}
            />
            <Route
              path="/pvsat"
              element={<SecondaryPrivateRoute user={user} Component={PVSAT} />}
            />
            <Route
              path="/toweroflondon"
              element={<SecondaryPrivateRoute user={user} Component={TowerOfLondon} />}
            />
            <Route
              path="/finish"
              element={<SecondaryPrivateRoute user={user} Component={Finish} />}
            />
            <Route
              path="/esqvisualization"
              element={SplitRoute({ user, Component: ESQVisualization })}
            />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  )
}

const HomeRoute = ({ Component, user }) => {
  if (user?.role === 'staff') {
    return <Navigate to="/Home" />
  }
  if (user?.username) {
    return <Navigate to="/myPage" />
  }

  return <Component />
}

const PrivateRoute = ({ Component, user, ...params }) => {
  if (user?.role === 'staff') {
    return <Navigate to="/Home" />
  }

  if (user?.username) {
    return (
      <Suspense fallback={<Error error="Something went wrong" />}>
        <Layout>
          <Header />
          <Footer hasChatBubble>
            <Component {...params} />
          </Footer>
          <ChatWindow />
        </Layout>
      </Suspense>
    )
  }

  return <Navigate to="/" />
}

const SecondaryPrivateRoute = ({ Component, user }) => {
  // if (user?.role === 'staff') {
  //   return <Navigate to="/Home" />
  // }
  const location = useLocation()
  console.log('location', location)

  if (user?.username) {
    return (
      <Suspense fallback={<Error error="Something went wrong" />}>
        <Layout>
          <Component />
        </Layout>
      </Suspense>
    )
  }

  return <Navigate to="/" />
}

const ProtectedRoute = ({ Component, user }) => {
  if (user?.role === 'staff') {
    return (
      <Suspense fallback={<Error error="Something went wrong" />}>
        <ResponsiveDrawer>
          <Footer inResponsiveDrawer>
            <Component inResponsiveDrawer />
          </Footer>
        </ResponsiveDrawer>
      </Suspense>
    )
  }

  return <Navigate to="/" />
}

const SplitRoute = ({ Component, user }) => {
  if (user?.role === 'staff') {
    return <ProtectedRoute user={user} Component={Component} />
  }
  if (user?.username) {
    return (
      <Box mt="40px">
        <PrivateRoute user={user} Component={Component} />
      </Box>
    )
  }

  return <Navigate to="/" />
}

export default Router
