import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import numberCircle from 'assets/images/pvsat/circle.png'
import instructionsPhoto from 'assets/images/pvsat/pvsat-instructions.png'
import { Box, Instructions, Modal, Text, Button, ProgressBar } from 'components'
import Line from 'components/molecules/CognitiveTasks/Line'
import { times, random } from 'lodash'
import moment from 'moment'
import { calculatePos } from 'utils/taskHelper'
import theme from 'utils/theme'

const createBoxes = () => {
  let angle = 90
  const linesTmp = []
  while (angle <= 450) {
    linesTmp.push(calculatePos(280, angle, 600, 350))
    angle += 360 / 18.000001 // 18.000001 because of rounding in the while condition.
  }
  return linesTmp
}

const TRIALS = 60 // Approximately 3 minutes. Trials = (Minutes * 60)/3
const boxes = createBoxes()

const PVSAT = ({ onFinish }) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation(['pvsat'])

  const onNext = () => setModalIndex(modalIndex + 1)
  const onPrev = () => setModalIndex(modalIndex - 1)

  const [results, setResults] = useState([{ testName: t('header') }])
  const [correctAnswers, setCorrectAnswers] = useState([])
  const [answers, setAnswers] = useState([])
  const [trialNum, setTrialNum] = useState(0)
  const [started, setStarted] = useState(false)
  const [finished, setFinished] = useState(false)
  const [shownAt, setShownAt] = useState(null)
  const [reactedAt, setReactedAt] = useState(null)
  const [timers, setTimers] = useState([])
  const [number, setNumber] = useState(random(1, 9))
  const [lastNumber, setLastNumber] = useState(0)
  const [drawnNumber, setDrawnNumber] = useState(true)
  const [boxClicked, setBoxClicked] = useState(true)
  const [numCorrect, setNumCorrect] = useState(0)
  const [timer, setTimer] = useState(3)
  const [modalIndex, setModalIndex] = useState(0)

  const [finalInstructions, setFinalInstructions] = useState(false)
  const [practice, setPractice] = useState(false)
  const [correctPracticeAnswer, setCorrectPracticeAnswer] = useState(0)
  const [wrongPracticeAnswer, setWrongPracticeAnswer] = useState(false)
  const [practiceDone, setPracticeDone] = useState(false)

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('lang')))
  }, [])

  const clickedBox = index => {
    // User cant click on another line once it has clicked on some line.
    if (!boxClicked) {
      if (practice) {
        if (correctPracticeAnswer === index) {
          setPracticeDone(true)
          return
        }
        setWrongPracticeAnswer(true)
        setLastNumber(number)
        setBoxClicked(true)
        return
      }

      setTrialNum(trialNum + 1)
      setLastNumber(number)
      setAnswers([...answers, index])
      setBoxClicked(true)
      setReactedAt(moment())
      if (correctAnswers[correctAnswers.length - 1] === index) {
        setNumCorrect(numCorrect + 1)
      }
    }
  }

  const drawNewNumber = () => {
    setNumber(random(1, 9))
    setDrawnNumber(true)
  }

  const resetTimer = () => {
    setTimer(3)
    setBoxClicked(false)
  }

  const completePractice = () => {
    setPracticeDone(null)
    setLastNumber(0) // Reset for real test
    setPractice(false)
    setFinalInstructions(true)
    setBoxClicked(true)
    setModalIndex(modalIndex + 1)
  }

  const processResults = () => {
    let correct = 0
    if (!boxClicked) {
      timers.push(null) // Did not manage to answer last trial.
    }
    const correctTimers = []
    const incorrectTimers = []
    const allTimers = []

    for (let i = 0; i < answers.length; i += 1) {
      if (answers[i] === correctAnswers[i]) {
        correct += 1
        correctTimers.push(timers[i])
        allTimers.push(timers[i])
      } else if (timers[i] !== null) {
        incorrectTimers.push(timers[i])
        allTimers.push(timers[i])
      }
    }

    const yourAns = answers.map(elem => (elem === -1 ? '_' : elem)).join(', ')
    const corrAns = correctAnswers.join(', ')
    const percentCorr = ((correct / correctAnswers.length) * 100).toFixed(1).concat('%')

    let averageAll = (allTimers.reduce((a, b) => a + b, 0) / allTimers.length).toFixed(0)
    averageAll = Number.isNaN(Number(averageAll)) ? '-' : averageAll.concat(' ms')

    let averageCorr = (correctTimers.reduce((a, b) => a + b, 0) / correctTimers.length).toFixed(0)
    averageCorr = Number.isNaN(Number(averageCorr)) ? '-' : averageCorr.concat(' ms')

    let averageIncorr = (
      incorrectTimers.reduce((a, b) => a + b, 0) / incorrectTimers.length
    ).toFixed(0)
    averageIncorr = Number.isNaN(Number(averageIncorr)) ? '-' : averageIncorr.concat(' ms')

    setResults(
      results.push({
        yourAnswers: yourAns,
        correctAnswers: corrAns,
        numCorrect: correct,
        numIncorrect: correctAnswers.length - correct,
        percentCorrect: percentCorr,
        averageReponseTime: averageAll,
        averageCorrectReponseTime: averageCorr,
        averageIncorrectReponseTime: averageIncorr,
      })
    )

    onFinish(results)
    navigate({ pathname: '/results', state: { results } })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (practiceDone) {
        return // Do nothing when user is ready, but has not started.
      }
      // Practice
      if (timer > 1 && practice) {
        setTimer(timer - 1)
      } else if (timer === 1 && practice) {
        // Time is up.
        setLastNumber(number)
        setNumber(null) // Removes number from screen for a moment.
      }

      // Main test
      if (timer > 1 && started) {
        setTimer(timer - 1)
      } else if (timer === 1 && started) {
        // Time is up.
        if (!boxClicked) {
          // Did not manage to answer
          setTrialNum(trialNum + 1)
          setAnswers([...answers, -1])
          setTimers([...timers, null])
        }
        setLastNumber(number)
        setNumber(null) // Removes number from screen for a moment.
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [timer, practice, started, boxClicked])

  useEffect(() => {
    const interval = setInterval(() => {
      // New number is drawn after a moment, after being removed.
      if (number === null) {
        drawNewNumber()
        resetTimer()
      }
    }, 80)
    return () => clearInterval(interval)
  }, [number])

  useEffect(() => {
    if (trialNum >= TRIALS) {
      setFinished(true)
    }
  }, [trialNum])

  useEffect(() => {
    if (drawnNumber) {
      setDrawnNumber(false)
      if (lastNumber !== 0) {
        if (practice) {
          setCorrectPracticeAnswer(lastNumber + number)
        } else {
          setCorrectAnswers([...correctAnswers, lastNumber + number])
        }
      }
      setShownAt(moment())
    }
  }, [drawnNumber])

  useEffect(() => {
    if (finished) {
      processResults()
    }
  }, [finished])

  useEffect(() => {
    if (reactedAt !== null) {
      const milliseconds = reactedAt.diff(shownAt, 'milliseconds')
      setTimers([...timers, milliseconds])
    }
  }, [reactedAt])

  useEffect(() => {
    const interval = setInterval(() => {
      if (practice && wrongPracticeAnswer) {
        setWrongPracticeAnswer(false)
      } else {
        clearInterval(interval)
      }
    }, 1500)
    return () => clearInterval(interval)
  }, [practice, wrongPracticeAnswer])

  const mainScreenRender = () => (
    <Box width="100%" flexDirection="row">
      <Box width="80%" height="700px" position="relative" cc top="0px" left="0px">
        {times(boxes.length, i => (
          <Line
            height="7%"
            width="5%"
            top={boxes[i].top}
            left={boxes[i].left}
            rounded
            onPress={() => clickedBox(i)}
            hover={theme.colors.greyDarker}
            maxWidth="60px"
            minWidth="40px"
            minHeight="40px"
          >
            <Text color="black" huge center>
              {i}
            </Text>
          </Line>
        ))}
        <Box
          backgroundColor={theme.colors.grey}
          position="absolute"
          round
          cc
          top="40%"
          left="47.5%"
          minWidth="11%"
          minHeight="16%"
        >
          <Text ginormous center color={theme.colors.white}>
            {number}
          </Text>
        </Box>
        {wrongPracticeAnswer && (
          <Box position="absolute" bottom="0" left="40%" cc>
            <Text semibold center bigger color="red">
              {t('incorrectAnswer')}
            </Text>
          </Box>
        )}
        {practiceDone && (
          <Box width="100%">
            <Box position="absolute" bottom="40px" left="49%" cc>
              <Text semibold center bigger>
                {t('correctAnswer')}
              </Text>
            </Box>
            <Box width="100%" position="absolute" bottom="0" left="3%" cc>
              <Button onPress={() => completePractice()} block>
                <Text semibold center>
                  {t('continue')}
                </Text>
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Box width="20%" cc>
        <Box width="50%" backgroundColor={theme.colors.grey} round cc mt="100px">
          <Text ginormous color={theme.colors.button.left}>
            {numCorrect}
          </Text>
        </Box>
      </Box>
    </Box>
  )

  const modalList = [
    <Modal isOpen={!practice} next onNext={onNext}>
      <Text big>
        {t('header')} - {t('instructionsHeader')}
      </Text>
      <Instructions body={t('instructions1')} />
      <Box cc>
        <img src={numberCircle} alt="number circle" width="40%" />
      </Box>
      <Instructions body={t('instructions2')} />
    </Modal>,
    <Modal isOpen={!practice} previous onPrev={onPrev} next onNext={onNext}>
      <Text big>
        {t('header')} - {t('instructionsHeader')}
      </Text>
      <Box cc>
        <img src={instructionsPhoto} alt="instructions" width="100%" />
      </Box>
      <Instructions body={t('instructions3')} />
    </Modal>,
    <Modal
      isOpen={!practice}
      close
      closeText={t('startPractice')}
      onClose={() => {
        setPractice(true)
      }}
      previous
      onPrev={onPrev}
    >
      <Text big>
        {t('header')} - {t('instructionsHeader')}
      </Text>
      <Instructions body={t('instructions4')} />
      <Instructions body={t('instructions5')} />
    </Modal>,
    <Modal
      isOpen={!started}
      onClose={() => {
        setStarted(true)
        setShownAt(moment())
      }}
      close
      closeText={t('startTest')}
    >
      <Text big>
        {t('header')} - {t('instructionsHeader')}
      </Text>
      <Instructions body={t('instructions6')} />
    </Modal>,
  ]

  if (finalInstructions) {
    return (
      <Box fullscreen bg={theme.colors.greyDark}>
        {modalList[modalIndex]}
        {mainScreenRender()}
        <Box width="100%" position="fixed" bottom="0">
          <ProgressBar width="100" progressPercentage={(trialNum / TRIALS) * 100} />
        </Box>
      </Box>
    )
  }
  return (
    <Box fullscreen bg={theme.colors.greyDark}>
      {modalList[modalIndex]}
      {mainScreenRender()}
      <Box width="120px" position="fixed" right="20px" bottom="20px">
        <Button onPress={() => completePractice()} block>
          <Text semibold center>
            {t('skipPractice')}
          </Text>
        </Button>
      </Box>
    </Box>
  )
}

PVSAT.propTypes = {
  onFinish: PropTypes.func,
}

PVSAT.defaultProps = {
  onFinish: () => {},
}

export default PVSAT
