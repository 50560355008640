import colors from 'utils/colors'

export const chartSettings = {
  freqLevels: ['Never', 'Rarely', 'Sometimes', 'Often', 'Always'],
  botherLevels: ['N/A', 'Not at all', 'A little bit', 'Somewhat', 'Quite a bit', 'Very much'],
  severityColors: ['#70AD47', '#FFC000', '#ED7D31', '#D20000', '#960000'],
  gridColor: colors.greyDarker,
  textColor: colors.greySoft,
  chartHeight: 200,
  labelSpaceMulti: 6,
  tooltipBackground: colors.grey,
  strokeWidth: 2,
  strokeDasharray: [5, 5],
  radarFillIntake: colors.greyMid,
  radarFillFollowUp: colors.purple,
  radarFillOpacity: 0.2,
}
