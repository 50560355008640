export const countries = [
  'Albania',
  'Åland Islands',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan (Province of China)',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]

export const languages = [
  'Abkhazian',
  'Afar',
  'Afrikaans',
  'Akan',
  'Albanian',
  'Amharic',
  'Arabic',
  'Aragonese',
  'Armenian',
  'Assamese',
  'Avaric',
  'Avestan',
  'Aymara',
  'Azerbaijani',
  'Bambara',
  'Bashkir',
  'Basque',
  'Belarusian',
  'Bengali (Bangla)',
  'Bihari',
  'Bislama',
  'Bosnian',
  'Breton',
  'Bulgarian',
  'Burmese',
  'Catalan',
  'Chamorro',
  'Chechen',
  'Chichewa, Chewa, Nyanja',
  'Chinese',
  'Chinese (Simplified)',
  'Chinese (Traditional)',
  'Chuvash',
  'Cornish',
  'Corsican',
  'Cree',
  'Croatian',
  'Czech',
  'Danish',
  'Divehi, Dhivehi, Maldivian',
  'Dutch',
  'Dzongkha',
  'English',
  'Esperanto',
  'Estonian',
  'Ewe',
  'Faroese',
  'Fijian',
  'Finnish',
  'French',
  'Fula, Fulah, Pulaar, Pular',
  'Galician',
  'Gaelic (Scottish)',
  'Gaelic (Manx)',
  'Georgian',
  'German',
  'Greek',
  'Greenlandic',
  'Guarani',
  'Gujarati',
  'Haitian Creole',
  'Hausa',
  'Hebrew',
  'Herero',
  'Hindi',
  'Hiri Motu',
  'Hungarian',
  'Icelandic',
  'Ido',
  'Igbo',
  'Indonesian',
  'Interlingua',
  'Interlingue',
  'Inuktitut',
  'Inupiak',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Kalaallisut, Greenlandic',
  'Kannada',
  'Kanuri',
  'Kashmiri',
  'Kazakh',
  'Khmer',
  'Kikuyu',
  'Kinyarwanda (Rwanda)',
  'Kirundi',
  'Kyrgyz',
  'Komi',
  'Kongo',
  'Korean',
  'Kurdish',
  'Kwanyama',
  'Lao',
  'Latin',
  'Latvian (Lettish)',
  'Limburgish ( Limburger)',
  'Lingala',
  'Lithuanian',
  'Luga-Katanga',
  'Luganda, Ganda',
  'Luxembourgish',
  'Manx',
  'Macedonian',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maltese',
  'Maori',
  'Marathi',
  'Marshallese',
  'Moldavian',
  'Mongolian',
  'Nauru',
  'Navajo',
  'Ndonga',
  'Northern Ndebele',
  'Nepali',
  'Norwegian',
  'Norwegian bokmål',
  'Norwegian nynorsk',
  'Nuosu',
  'Occitan',
  'Ojibwe',
  'Old Church Slavonic, Old Bulgarian',
  'Oriya',
  'Oromo (Afaan Oromo)',
  'Ossetian',
  'Pāli',
  'Pashto, Pushto',
  'Persian (Farsi)',
  'Polish',
  'Portuguese',
  'Punjabi (Eastern)',
  'Quechua',
  'Romansh',
  'Romanian',
  'Russian',
  'Sami',
  'Samoan',
  'Sango',
  'Sanskrit',
  'Serbian',
  'Serbo-Croatian',
  'Sesotho',
  'Setswana',
  'Shona',
  'Sichuan Yi',
  'Sindhi',
  'Sinhalese',
  'Siswati',
  'Slovak',
  'Slovenian',
  'Somali',
  'Southern Ndebele',
  'Spanish',
  'Sundanese',
  'Swahili (Kiswahili)',
  'Swati',
  'Swedish',
  'Tagalog',
  'Tahitian',
  'Tajik',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Tibetan',
  'Tigrinya',
  'Tonga',
  'Tsonga',
  'Turkish',
  'Turkmen',
  'Twi',
  'Uyghur',
  'Ukrainian',
  'Urdu',
  'Uzbek',
  'Venda',
  'Vietnamese',
  'Volapük',
  'Wallon',
  'Welsh',
  'Wolof',
  'Western Frisian',
  'Xhosa',
  'Yiddish',
  'Yoruba',
  'Zhuang, Chuang',
  'Zulu',
]

export const genderKeys = ['Female', 'Male', 'Other']

export const ethnicityKeys = [
  'Caucasian',
  'IndigenousAustralian',
  'PacificIslander',
  'SouthEastAsian',
  'SouthAsian',
  'NorthEastAsian',
  'NorthAsian',
  'MiddleEastern',
  'SubSaharanAfrican',
  'CentralSouthAmerican',
  'Other',
  'Dontknow',
]

export const ethnicityInfoKeys = [
  'CaucasionInformation',
  'IndigenousAustralianInformation',
  'PacificIslanderInformation',
  'SouthEastAsianInformation',
  'SouthAsianInformation',
  'NorthEastAsianInformation',
  'NorthAsianInformation',
  'MiddleEasternInformation',
  'SubSaharanAfricanInformation',
  'CentralSouthAmericanInformation',
]

export const occupationKeys = [
  'Employed',
  'SelfEmployed',
  'Student',
  'Volunteering',
  'CaretakerOrHomemaker',
  'Retired',
  'OnSickLeave',
  'OnDisability',
]

export const workScheduleKeys = ['RegularHours', 'Other']

export const educationKeys = [
  'PrimaryEducation',
  'LowerSecondaryEducation',
  'UpperSecondaryEducation',
  'PostSecondaryNonTertiaryEducation',
  'ShortCycleTertiaryEducation',
  'BachelorsDegreeOrEquivalentTertiaryEducationLevel',
  'MastersDegreeOrEquivalentTertiaryEducationLevel',
  'DoctoralDegreeOrEquivalentTertiaryEducationLevel',
]

// Was supposed to be No/Yes (or checkbox)
// export const livingArrangementKeys = ['Alone', 'Together']

export const livingArrangementTogetherKeys = [
  'WithPartnerSpouse',
  'WithChildren',
  'WithOtherFamilyMembers',
  'WithOtherPeople',
  'WithPets',
]

export const sleepingArrangementKeys = [
  'TheSameRoomSameBed',
  'TheSameRoomSeparateBed',
  'AnotherRoom',
]

export const noYesKeys = ['No', 'Yes']

export const drivingCategories = ['A/B', 'C/D/E']

export const smokingHistoryKeys = ['INeverSmoke', 'IStoppedSmoking', 'IAmACurrentSmoker']

export const smokingCategoryKeys = ['Cigaretts', 'Cigars', 'Chewingtobacco', 'Pipe', 'Vape']

export const alcoholIntakeKeys = [
  'INeverDrinkAlcohol',
  'IStoppedDrinkingAlcohol',
  'ICurrentlyDrinkAlcohol',
]

export const caffeineIntakeKeys = [
  'INeverDrinkCaffeine',
  'IStoppedDrinkingCaffeine',
  'ICurrentlyDrinkCaffeine',
]

export const caffeineCategoryKeys = ['Coffee', 'Tea', 'Energydrink', 'Other']

export const substanceIntakeKeys = [
  'INeverUsedSubstance',
  'IStoppedUsingSubstances',
  'ICurrentlyUseSubstances',
]

export const dayWeekMonthKeys = ['day', 'Week', 'Month']

export const noYesDontKnowKeys = ['No', 'Yes', 'Dontknow']

export const menopausalStateKeys = ['Pre', 'Peri', 'Post', 'Dontknow']

export const selfReportedHealthKeys = ['Poor', 'Fair', 'Good', 'VeryGood', 'Excellent']

export const stressorsKeys = [
  'None',
  'Health',
  'Relationship/Family',
  'Job/School',
  'Financial',
  'Other',
]

export const sleepDaytimeProblemDurationKeys = [
  'LessThanThreeMonths',
  'MoreThanThreeMonths',
  'MoreThanAYear',
  'MoreThanFiveYears',
]

export const treatedByKeys = ['FamilyDoctor', 'Specialist', 'NonMedical']

export const sleepDurationKeys = ['Shortsleeper', 'Longsleeper', 'Neither', 'Dontknow']

export const bedtimeKeys = ['Early', 'Late', 'Neither', 'Dontknow']

export const sleepScheduleKeys = ['Regular', 'Irregular', 'Neither', 'Dontknow']

export const sleepDepthKeys = ['Deep', 'Superficial', 'Neither', 'Dontknow']

export const sleepQuantityKeys = ['Enoughsleep', 'Notenoughsleep', 'Neither', 'Dontknow']

export const sleepOffsetKeys = ['Wideawake', 'Stilltired', 'Neither', 'Dontknow']

export const activitiesBeforeSleepKeys = [
  'None',
  'Reading',
  'Listening',
  'Meditate',
  'WatchingTv',
  'Workingplaying',
  'Other',
]

export const weekOrMonthKeys = ['Week', 'Month']

export const occurrenceFrequencyKeys = ['Never', 'Rarely', 'Sometimes', 'Often', 'Always']

export const occurrenceBotherKeys = ['NotAtAll', 'ALittleBit', 'Somewhat', 'QuiteABit', 'VeryMuch']

export const whoHelpedYouKeys = [
  'helpCompletingA',
  'helpCompletingB',
  'helpCompletingC',
  'helpCompletingD',
]

export const nocturnalImprovementKeys = [
  'NoNocturnalProblems',
  'MuchImproved',
  'ModeratelyImproved',
  'MinimallyImproved',
  'NoChange',
  'MinimallyWorse',
  'ModeratelyWorse',
  'MuchWorse',
]

export const daytimeImprovementKeys = [
  'NoDaytimeProblems',
  'MuchImproved',
  'ModeratelyImproved',
  'MinimallyImproved',
  'NoChange',
  'MinimallyWorse',
  'ModeratelyWorse',
  'MuchWorse',
]

export const noYesPreferNotToAnswer = ['No', 'Yes', 'PreferNotToAnswer']

export const followedTreatmentKeys = ['CurrentlyFollowingTreatment', 'CompletedTreatment']

export const didNotFollowTreatmentKeys = [
  'NotStartedTreatment',
  'StartedTreatmentButStopped',
  'CurrentlyFollowingDifferentTreatment',
  'CompletedDifferentTreatment',
]
