import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { LanguageDropdown } from 'components/organisms'
import styled, { css } from 'styled-components'
import theme from '../../../utils/theme'
import Box from '../Base/Box'
import Button from '../Base/Button'
import Text from '../Base/Text'

const StyledModalOverlay = styled.div(
  ({ isOpen }) => css`
    display: ${isOpen ? 'block' : 'none'};
    position: fixed;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  `
)

const StyledModal = styled.div(
  () => css`
    background-color: ${theme.colors.grey};
    margin: auto;
    width: 80vw;
    max-height: 80vh;
    border-radius: 7px;
    overflow-y: scroll;
    margin-top: 10vh;
  `
)

const Modal = ({ isOpen, children, onClose, close, onNext, next, onPrev, previous, closeText }) => {
  const { t } = useTranslation(['common'])
  return (
    <StyledModalOverlay isOpen={isOpen}>
      <StyledModal>
        <Box p="21px">
          <LanguageDropdown width="60px" mb="30px" />
        </Box>
        <Box p={theme.spacing.huge} width="60%" center minHeight="300px">
          {children}
        </Box>
        <Box row spaceBetween>
          <Box width="100px" ml="25px" mt="15px" mb="25px">
            {previous && (
              <Button Button onPress={onPrev} block>
                <Text semibold center color="white">
                  {t('prevText')}
                </Text>
              </Button>
            )}
          </Box>
          <Box cc width="100%" mt="15px" mb="25px">
            {close && (
              <Button Button onPress={onClose} block>
                <Text semibold center color="white">
                  {closeText}
                </Text>
              </Button>
            )}
          </Box>
          <Box width="100px" mr="25px" mt="15px" mb="25px">
            {next && (
              <Button Button onPress={onNext} block>
                <Text semibold center color="white">
                  {t('nextText')}
                </Text>
              </Button>
            )}
          </Box>
        </Box>
      </StyledModal>
    </StyledModalOverlay>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.bool,
  next: PropTypes.bool,
  previous: PropTypes.bool,
  closeText: PropTypes.string,
}

Modal.defaultProps = {
  onClose: () => {},
  onNext: () => {},
  onPrev: () => {},
  close: false,
  next: false,
  previous: false,
  closeText: 'Close',
}

export default Modal
