const FormikInitialValues = {
  // define initial values for all fields in the form
  __isSecondRound__: false,
  country_birth: '',
  country_residence: '',
  native_lang: '',
  height: '',
  weight: '',
  gender: '',
  gender_other: '',
  ethnicity: '',
  occupation: [],
  work_schedule: '',
  work_schedule_other: '',
  education_highest: '',
  living_arrangement_alone: '',
  living_arrangement_together: [],
  sleeping_arrangement: '',
  driving_license: '',
  driving_license_category: [],
  driving_km: '',
  does_smoke: '',
  smoking_year_started: '',
  smoking_category: [],
  smoking_average_day: '',
  alcohol_intake: '',
  alcohol_intake_days: '',
  alcohol_intake_units: '',
  alcohol_sleep_aid: '',
  caffeine_intake: '',
  caffeine_category: [],
  caffeine_category_other_info: '',
  caffeine_average_units: '',
  caffeine_last_drink: '',
  substance_intake: '',
  substance_intake_info: '',
  substance_intake_days: '',
  substance_intake_units: '',
  exercise: '',
  exercise_count: '',
  exercise_count_type: '',
  exercise_hours: '',
  exercise_hours_type: '',
  leisure_recreational_activities: '',
  __female_disabled__: false,
  uses_contraception_therapy: '',
  is_pregnant: '',
  menopausal_state: '',
  uses_hormone_replacement: '',
  self_reported_health: '',
  stress: [],
  stress_other_info: '',
  stress_additional_comments: '',
  stress_additional_comments_info: '',
  sleep_daytime_problem_description: '',
  sleep_daytime_problem_duration: '',
  sleep_daytime_problem_worsen: '',
  sleep_daytime_problem_worsen_info: '',
  sleep_daytime_problem_causation: '',
  sleep_problem_affects_daytime: '',
  sleep_problem_affects_daytime_info: '',
  diagnostic_investigation_expectation: '',
  previous_sleep_problem_treatment: '',
  prescribed_treatments_for_sleep: '',
  treated_by: [],
  previous_treatment_other_sleep_problems: '',
  previous_treatment_other_sleep_problems_info: '',
  sleep_duration: '',
  bedtime: '',
  wake_up_time: '',
  sleep_schedule: '',
  sleep_depth: '',
  sleep_quantity: '',
  sleep_offset: '',
  sleep_time_preferred: '',
  out_of_bed_preferred: '',
  sleep_amount_preferred: '',
  sleep_amount_business_days: '',
  sleep_amount_free_days: '',
  sleep_into_bed_business_days: '',
  sleep_into_bed_free_days: '',
  sleep_out_of_bed_business_days: '',
  sleep_out_of_bed_free_days: '',
  activities_before_sleep: [],
  activities_before_sleep_other_info: '',
  planned_nap: '',
  nap_frequency_per_day: '',
  nap_day_count: '',
  nap_per_week_or_month: '',
  nap_average_duration: '',
  nap_feels_refreshed: '',
  pain_frequency: '',
  pain_bother: '',
  itch_frequency: '',
  itch_bother: '',
  leg_cramps_frequency: '',
  leg_cramps_bother: '',
  limbs_urge_to_move_frequency: '',
  limbs_urge_to_move_bother: '',
  bodily_movements_frequency: '',
  bodily_movements_bother: '',
  nasal_obstruction_frequency: '',
  nasal_obstruction_bother: '',
  own_snoring_frequency: '',
  own_snoring_bother: '',
  urge_to_urinate_frequency: '',
  urge_to_urinate_bother: '',
  urge_to_urinate_count: '',
  bedwetting_frequency: '',
  bedwetting_bother: '',
  urge_to_eat_frequency: '',
  urge_to_eat_bother: '',
  worrying_thoughts_frequency: '',
  worrying_thoughts_bother: '',
  nightmares_frequency: '',
  nightmares_bother: '',
  other_fa_frequency: '',
  other_fa_bother: '',
  other_fa_info: '',
  breathlessness_or_choking_frequency: '',
  breathlessness_or_chokings_bother: '',
  inability_to_move_feeling_frequency: '',
  inability_to_move_feelings_bother: '',
  panic_feeling_frequency: '',
  panic_feelings_bother: '',
  headache_pain_frequency: '',
  headache_pains_bother: '',
  dry_mouth_feeling_frequency: '',
  dry_mouth_feelings_bother: '',
  sore_throat_pain_frequency: '',
  sore_throat_pains_bother: '',
  jaw_pain_frequency: '',
  jaw_pains_bother: '',
  heartburn_feeling_frequency: '',
  heartburn_feelings_bother: '',
  palpitations_feeling_frequency: '',
  palpitations_feelings_bother: '',
  excessive_sweating_feeling_frequency: '',
  excessive_sweating_feelings_bother: '',
  hot_flushes_feeling_frequency: '',
  hot_flushes_feelings_bother: '',
  other_ds_frequency: '',
  other_ds_bother: '',
  other_ds_info: '',
  snoring_bedpartner_frequency: '',
  snoring_bedpartners_bother: '',
  movements_bedpartner_frequency: '',
  movements_bedpartners_bother: '',
  pet_activity_frequency: '',
  pet_activitys_bother: '',
  caretaking_family_frequency: '',
  caretaking_familys_bother: '',
  sleeping_apart_frequency: '',
  sleeping_aparts_bother: '',
  environmental_noise_frequency: '',
  environmental_noises_bother: '',
  smartphone_messages_frequency: '',
  smartphone_messagess_bother: '',
  bedroom_inconveniences_frequency: '',
  bedroom_inconveniencess_bother: '',
  other_o_frequency: '',
  other_o_bother: '',
  other_o_info: '',
  self_snoring_frequency: '',
  self_snoring_bother: '',
  self_breathing_stops_frequency: '',
  self_breathing_stops_bother: '',
  self_limb_movements_frequency: '',
  self_limb_movements_bother: '',
  self_tossing_turning_frequency: '',
  self_tossing_turning_bother: '',
  self_kicking_punching_frequency: '',
  self_kicking_punching_bother: '',
  self_talking_screaming_frequency: '',
  self_talking_screaming_bother: '',
  self_teeth_grinding_frequency: '',
  self_teeth_grinding_bother: '',
  self_sleepwalking_frequency: '',
  self_sleepwalking_bother: '',
  other_os_frequency: '',
  other_os_bother: '',
  other_os_info: '',
  fall_asleep_30_to_60: '',
  fall_asleep_60_to_90: '',
  fall_asleep_over_90: '',
  fall_asleep_bother: '',
  woke_up_1_to_2: '',
  woke_up_2_to_4: '',
  woke_up_over_4: '',
  woke_up_bother: '',
  fall_asleep_after_awake_30_to_60: '',
  fall_asleep_after_awake_60_to_90: '',
  fall_asleep_after_awake_over_90: '',
  fall_asleep_after_awake_bother: '',
  woke_up_early_30_to_60: '',
  woke_up_early_60_to_90: '',
  woke_up_early_over_90: '',
  woke_up_early_bother: '',
  felt_unrefreshed_30_to_60: '',
  felt_unrefreshed_1_to_4: '',
  felt_unrefreshed_over_4: '',
  felt_unrefreshed_bother: '',
  fell_unintentionally_asleep_passive: '',
  fell_unintentionally_asleep_moderate: '',
  fell_unintentionally_asleep_strong: '',
  fell_unintentionally_asleep_bother: '',
  effort_to_stay_awake: '',
  special_measures_to_stay_awake: '',
  activities_without_awareness: '',
  fight_sleepy_bother: '',
  overslept_with_alarm: '',
  overslept_with_alarm_bother: '',
  lack_of_energy_leisure_start: '',
  lack_of_energy_duty_start: '',
  lack_of_energy_any_start: '',
  lack_of_energy_bother: '',
  exhausted_activity_strong: '',
  exhausted_activity_moderate: '',
  exhausted_activity_passive: '',
  exhausted_activity_bother: '',
  exhausted_physical_activity_vigorous: '',
  exhausted_physical_activity_moderate: '',
  exhausted_physical_activity_light: '',
  exhausted_physical_activity_bother: '',
  difficulty_remembering_frequency: '',
  difficulty_remembering_bother: '',
  difficulty_concentrating_frequency: '',
  difficulty_concentrating_bother: '',
  lapses_of_attention_frequency: '',
  lapses_of_attention_bother: '',
  anxiety_frequency: '',
  anxiety_bother: '',
  depression_frequency: '',
  depression_bother: '',
  losing_temper_frequency: '',
  losing_temper_bother: '',
  problems_with_relationships_frequency: '',
  problems_with_relationships_bother: '',
  problems_with_social_interaction_frequency: '',
  problems_with_social_interaction_bother: '',
  problems_with_sexual_desire_activity_frequency: '',
  problems_with_sexual_desire_activity_bother: '',
  problems_with_daily_activities_frequency: '',
  problems_with_daily_activities_bother: '',
  sudden_muscle_weakness_frequency: '',
  sudden_muscle_weakness_bother: '',
  safety_concerns_frequency: '',
  safety_concerns_bother: '',
  concerns_with_health_frequency: '',
  concerns_with_health_bother: '',
  near_accident: '',
  receive_help: '',
  receive_help_from: '',
  nocturnal_sleep_rating: '',
  daytime_functioning_rating: '',
  final_remarks: '',
  nocturnal_sleep_change: '',
  daytime_functioning_change: '',
  which_treatment: '',
  followed_treatment: '',
  did_follow_treatment_status: '',
  did_follow_treatment_comment: '',
  did_not_follow_treatment_status: '',
  did_not_follow_treatment_comment: '',
  side_effects_extent: '',
  side_effects_description: '',
  treatment_helpful_extent: '',
  treatment_not_helpful_explanation: '',
  treatment_was_helpful_explanation: '',
}

export default FormikInitialValues
