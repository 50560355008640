/* eslint-disable camelcase */

import React, { useContext, useEffect } from 'react'
import Comment from '../../comps/Comment'
import ConnectedFields from '../../comps/ConnectedFields'
import FormikMuiInput from '../../comps/FormikMuiInput'
import FormikMuiRadio from '../../comps/FormikMuiRadio'
import FormikMuiSelect from '../../comps/FormikMuiSelect'
import Section from '../../comps/Section'
import { LangContext } from '../../const/Contexts'
import {
  noYesKeys,
  whoHelpedYouKeys,
  selfReportedHealthKeys,
  nocturnalImprovementKeys,
  daytimeImprovementKeys,
  noYesPreferNotToAnswer,
  followedTreatmentKeys,
  didNotFollowTreatmentKeys,
  occurrenceBotherKeys,
} from '../../const/ESQOptions'

export const FUFieldNames = [
  'receive_help',
  'receive_help_from',
  'nocturnal_sleep_rating',
  'daytime_functioning_rating',
  'final_remarks',
  'nocturnal_sleep_change',
  'daytime_functioning_change',
  'which_treatment',
  'followed_treatment',
  'did_follow_treatment_status',
  'did_follow_treatment_comment',
  'did_not_follow_treatment_status',
  'did_not_follow_treatment_comment',
  'side_effects_extent',
  'side_effects_description',
  'treatment_helpful_extent',
  'treatment_not_helpful_explanation',
  'treatment_was_helpful_explanation',
]

const FollowUp = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  const { values, setFieldValue } = formikProps

  const {
    __isSecondRound__,
    receive_help,
    receive_help_from,
    followed_treatment,
    did_not_follow_treatment_status,
    did_not_follow_treatment_comment,
    did_follow_treatment_status,
    did_follow_treatment_comment,
    side_effects_extent,
    side_effects_description,
    treatment_helpful_extent,
    treatment_not_helpful_explanation,
    treatment_was_helpful_explanation,
  } = values

  // Resetting 'Extra' fields when they switch back (ex. 'Other' boxes)
  useEffect(() => {
    if (receive_help !== 1 && receive_help_from !== '') {
      setFieldValue('receive_help_from', '')
    }
  }, [receive_help])

  // Second round only
  useEffect(() => {
    if (followed_treatment !== 0) {
      if (did_not_follow_treatment_status !== '') {
        setFieldValue('did_not_follow_treatment_status', '')
      }
      if (did_not_follow_treatment_comment !== '') {
        setFieldValue('did_not_follow_treatment_comment', '')
      }
    }
    if (followed_treatment !== 1) {
      if (did_follow_treatment_status !== '') {
        setFieldValue('did_follow_treatment_status', '')
      }
      if (did_follow_treatment_comment !== '') {
        setFieldValue('did_follow_treatment_comment', '')
      }
    }
  }, [followed_treatment])
  useEffect(() => {
    if (side_effects_extent === 0 && side_effects_description !== '') {
      setFieldValue('side_effects_description', '')
    }
  }, [side_effects_extent])
  useEffect(() => {
    if (treatment_helpful_extent === 0 && treatment_was_helpful_explanation !== '') {
      setFieldValue('treatment_was_helpful_explanation', '')
    }
    if (treatment_helpful_extent > 0 && treatment_not_helpful_explanation !== '') {
      setFieldValue('treatment_not_helpful_explanation', '')
    }
  }, [treatment_helpful_extent])

  return (
    <Section>
      <ConnectedFields>
        <Comment text={t('HelpCompleting')} />
        <FormikMuiSelect
          label={t('HelpCompleting')}
          name="receive_help"
          options={noYesKeys.map(val => t(val))}
        />
        {receive_help === 1 && (
          <FormikMuiSelect
            label=""
            name="receive_help_from"
            options={whoHelpedYouKeys.map(val => t(val))}
          />
        )}
      </ConnectedFields>
      {__isSecondRound__ === true && (
        <>
          <Comment center bold text={t('OverallChange')} />
          <ConnectedFields>
            <Comment text={`${t('YouWereSeekingHelpFor')}:`} />
            <FormikMuiInput
              label=""
              name="sleep_daytime_problem_description"
              type="text"
              multiline
              readOnly
            />
          </ConnectedFields>
          <Comment text={`${t('PleaseCompare')}:`} />
          <FormikMuiRadio
            label={t('DidYourSituationChange')}
            name="nocturnal_sleep_change"
            column
            options={nocturnalImprovementKeys.map(val => t(val))}
          />
          <FormikMuiRadio
            label={t('DidYourDaytimeFunctioningChange')}
            name="daytime_functioning_change"
            column
            options={daytimeImprovementKeys.map(val => t(val))}
          />
          <Comment text={`${t('RateCurrent')}:`} />
        </>
      )}
      <FormikMuiRadio
        column
        label={t('RateCurrentNocturnalSleep')}
        name="nocturnal_sleep_rating"
        options={selfReportedHealthKeys.map(val => t(val))}
      />
      <FormikMuiRadio
        column
        label={t('RateCurrentDaytimeFunctioning')}
        name="daytime_functioning_rating"
        options={selfReportedHealthKeys.map(val => t(val))}
      />
      {__isSecondRound__ === true && (
        <>
          <Comment center bold text={t('TreatmentAdherence')} />
          <ConnectedFields>
            <Comment text={`${t('WhichTreatmentDidYouReceive')}:`} />
            <FormikMuiInput
              label={t('WhichTreatmentDidYouReceive')}
              name="which_treatment"
              type="text"
              multiline
            />
          </ConnectedFields>
          <ConnectedFields>
            <Comment text={t('DidYouFollowTreatment')} />
            <FormikMuiSelect
              label={t('DidYouFollowTreatment')}
              name="followed_treatment"
              options={noYesPreferNotToAnswer.map(val => t(val))}
            />
            {followed_treatment === 1 && (
              <>
                <FormikMuiSelect
                  label=""
                  name="did_follow_treatment_status"
                  options={followedTreatmentKeys.map(val => t(val))}
                />
                <Comment text={`${t('CommentOnTreatment')}:`} />
                <FormikMuiInput
                  label={t('CommentOnTreatment')}
                  name="did_follow_treatment_comment"
                  type="text"
                  multiline
                />
              </>
            )}
            {followed_treatment === 0 && (
              <>
                <FormikMuiSelect
                  label=""
                  name="did_not_follow_treatment_status"
                  options={didNotFollowTreatmentKeys.map(val => t(val))}
                />
                <Comment text={`${t('CommentOnTreatment')}:`} />
                <FormikMuiInput
                  label={t('CommentOnTreatment')}
                  name="did_not_follow_treatment_comment"
                  type="text"
                  multiline
                />
              </>
            )}
          </ConnectedFields>
          <ConnectedFields>
            <FormikMuiRadio
              label={t('ExperienceSideEffects')}
              name="side_effects_extent"
              options={occurrenceBotherKeys.map(val => t(val))}
            />
            {side_effects_extent > 0 && (
              <>
                <Comment text={`${t('DescribeSideEffects')}:`} />
                <FormikMuiInput
                  label={t('DescribeSideEffects')}
                  name="side_effects_description"
                  type="text"
                  multiline
                />
              </>
            )}
          </ConnectedFields>
          <ConnectedFields>
            <FormikMuiRadio
              label={t('ExperienceTreatmentAsHelpful')}
              name="treatment_helpful_extent"
              options={occurrenceBotherKeys.map(val => t(val))}
            />
            {treatment_helpful_extent !== '' &&
              (treatment_helpful_extent === 0 ? (
                <>
                  <Comment text={`${t('ExplainWhyNotHelpful')}:`} />
                  <FormikMuiInput
                    label={t('ExplainWhyNotHelpful')}
                    name="treatment_not_helpful_explanation"
                    type="text"
                    multiline
                  />
                </>
              ) : (
                <>
                  <Comment text={`${t('ExplainWhyHelpful')}:`} />
                  <FormikMuiInput
                    label={t('ExplainWhyHelpful')}
                    name="treatment_was_helpful_explanation"
                    type="text"
                    multiline
                  />
                </>
              ))}
          </ConnectedFields>
        </>
      )}
      <Comment text={t('AnyOtherInformation')} />
      <FormikMuiInput label="" name="final_remarks" type="text" />
      <Comment text={t('FinalThanks')} />
    </Section>
  )
}

export default FollowUp
