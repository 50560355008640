import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Instructions, Modal, Text, Button, ProgressBar } from 'components'
import InputCounter from 'components/molecules/CognitiveTasks/InputCounter'
import InputField from 'components/molecules/CognitiveTasks/InputField'
import moment from 'moment'
import { parseInput, intersection } from 'utils/taskHelper'
import theme from 'utils/theme'

const ListLearningTask = ({ onFinish }) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation(['listLearningTask'])

  const [results, setResults] = useState([])
  const wordsList = t('words').split(',')

  const minNumOfWords = 10
  const maxNumOfWords = 15
  const [numOfWords, setNumOfWords] = useState(minNumOfWords)

  const minNumOfRounds = 1
  const maxNumOfRounds = 5
  const [numOfRounds, setNumOfRounds] = useState(minNumOfRounds)

  const [roundNumber, setRoundNumber] = useState(1)

  const [words, setWords] = useState([])

  const [wordIndex, setWordIndex] = useState(0)

  const [inputWords, setInputWords] = useState('')
  const [started, setStarted] = useState(false)
  const [finished, setFinished] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [numOfShownWords, setNumOfShownWords] = useState(0)

  const [totalCorrect, setTotalCorrect] = useState(0)

  const [resultsCollected, setResultsCollected] = useState(false)

  const [practice, setPractice] = useState(false)
  const [finalInstructions, setFinalInstructions] = useState(false)
  const [practiceWordIndex, setPracticeWordIndex] = useState(0)

  const [startedAt, setStartedAt] = useState(null)
  const [finishedAt, setFinishedAt] = useState(null)

  useEffect(() => {
    setWords(wordsList.sort(() => Math.random() - 0.5))
  }, [])

  useEffect(() => {
    if (finished) {
      onFinish(results)
      navigate({ pathname: '/results', state: { results } })
    }
  }, [resultsCollected])

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('lang')))
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (practiceWordIndex < 2 && practice) {
        setPracticeWordIndex(practiceWordIndex + 1)
      } else if (practiceWordIndex === 2 && practice) {
        setFinished(true)
      } else {
        clearInterval(interval)
      }
    }, 2000)
    return () => clearInterval(interval)
  }, [practiceWordIndex, practice])

  const skipPractice = () => {
    setFinalInstructions(true)
    setFinished(false)
    setPractice(false)
    setPracticeWordIndex(2)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!finished) {
        if (wordIndex < numOfWords - 1 && started) {
          setWordIndex(wordIndex + 1)

          setNumOfShownWords(numOfShownWords + 1)
        } else if (wordIndex >= numOfWords - 1 && started) {
          setFinished(true)
          setNumOfShownWords(numOfShownWords + 1)
          setStartedAt(moment())
        } else {
          clearInterval(interval)
        }
      }
    }, 2000)
    return () => clearInterval(interval)
  }, [wordIndex, started, numOfShownWords])

  useEffect(() => {
    if (submitted) {
      processResults()

      if (roundNumber < numOfRounds) {
        setWordIndex(0)
        setFinished(false)
        setSubmitted(false)
        setRoundNumber(roundNumber + 1)
        setInputWords('')
      } else {
        setResultsCollected(true)
      }
    }
  }, [submitted])

  const increaseNumInput = counterName => {
    if (counterName === 'words') {
      if (numOfWords < maxNumOfWords) {
        setNumOfWords(numOfWords + 1)
      }
    } else if (counterName === 'rounds') {
      if (numOfRounds < maxNumOfRounds) {
        setNumOfRounds(numOfRounds + 1)
      }
    }
  }

  const decreaseNumInput = counterName => {
    if (counterName === 'words') {
      if (numOfWords > minNumOfWords) {
        setNumOfWords(numOfWords - 1)
      }
    } else if (counterName === 'rounds') {
      if (numOfRounds > minNumOfRounds) {
        setNumOfRounds(numOfRounds - 1)
      }
    }
  }

  const getProgressPercentage = () => {
    const totalNumberOfWords = numOfRounds * numOfWords
    return (numOfShownWords / totalNumberOfWords) * 100
  }

  const processResults = () => {
    const wordList = parseInput(inputWords)
    let correct = intersection(wordList, words.slice(0, numOfWords))
    correct = correct.map(correctWord => correctWord.concat(' '))

    setTotalCorrect(totalCorrect + correct.length)

    const correctPercent = ((correct.length / numOfWords) * 100).toFixed(1) // Round with one fractional digit
    const correctPercentString = correctPercent.toString().concat('%')

    const seconds = finishedAt.diff(startedAt, 'seconds')

    if (roundNumber < numOfRounds) {
      setResults([
        ...results,
        {
          roundNumber,
          numCorrectWords: correct.length,
          numShownWords: numOfWords,
          correctRate: correctPercentString,
          correctWords: correct,
          typingTimer: seconds.toString().concat(t('seconds')),
        },
      ])
    } else {
      const totalCorrectPercent = (
        ((totalCorrect + correct.length) / (numOfWords * numOfRounds)) *
        100
      ).toFixed(1)
      const totalCorrectPercentString = totalCorrectPercent.toString().concat('%')
      setResults([
        { testName: t('header') },
        {
          numOfRounds,
          numCorrectWords: totalCorrect + correct.length,
          numShownWords: numOfWords * numOfRounds,
          totalCorrectRate: totalCorrectPercentString,
        },
        ...results,
        {
          roundNumber,
          numCorrectWords: correct.length,
          numShownWords: numOfWords,
          correctRate: correctPercentString,
          correctWords: correct,
          typingTimer: seconds.toString().concat(t('seconds')),
        },
      ])
    }
  }

  if (!finished) {
    if (finalInstructions) {
      return (
        <Box fullscreen bg={theme.colors.greyDark}>
          <Modal
            isOpen={!started}
            close
            closeText={t('startTest')}
            onClose={() => setStarted(true)}
          >
            <Text big>
              {t('header')} - {t('instructionsHeader')}
            </Text>
            <Instructions body={t('finalInstructions')} />
            <Instructions body={t('instructions')} />
            <InputCounter
              counterName={t('numOfWords')}
              defaultValue={numOfWords}
              onPressPlus={() => increaseNumInput('words')}
              onPressMinus={() => decreaseNumInput('words')}
            />
            <InputCounter
              counterName={t('numOfRounds')}
              defaultValue={numOfRounds}
              onPressPlus={() => increaseNumInput('rounds')}
              onPressMinus={() => decreaseNumInput('rounds')}
            />
          </Modal>
          <Text ginormous center lineHeight="500">
            {words[wordIndex]}
          </Text>
          <Box width="100%" bottom="0px" position="fixed">
            <ProgressBar width="100" progressPercentage={getProgressPercentage()} />
          </Box>
        </Box>
      )
    }
    return (
      <Box fullscreen bg={theme.colors.greyDark}>
        <Modal
          isOpen={!practice}
          close
          closeText={t('startPractice')}
          onClose={() => setPractice(true)}
        >
          <Text big>
            {t('header')} - {t('instructionsHeader')}
          </Text>
          <Instructions body={t('instructions')} />
          <Instructions body={t('practiceInstructions')} />
        </Modal>
        <Text ginormous center lineHeight="500">
          {wordsList[practiceWordIndex]}
        </Text>
        <Box width="120px" position="fixed" right="20px" bottom="20px">
          <Button onPress={() => skipPractice()} block>
            <Text semibold center>
              {t('skipPractice')}
            </Text>
          </Button>
        </Box>
      </Box>
    )
  }
  if (practice) {
    return (
      <Box centerContent fullscreen bg={theme.colors.greyDark}>
        <InputField
          instructions={t('inputInstructions')}
          onPress={() => {
            setFinalInstructions(true)
            setFinished(false)
            setPractice(false)
          }}
          width="320"
          rows="11"
          placeholder={t('placeholder')}
          submitText={t('submitButton')}
        />
        <Box width="120px" position="fixed" right="20px" bottom="20px">
          <Button onPress={() => skipPractice()} block>
            <Text semibold center>
              {t('skipPractice')}
            </Text>
          </Button>
        </Box>
      </Box>
    )
  }
  return (
    <Box centerContent fullscreen bg={theme.colors.greyDark}>
      <InputField
        instructions={t('inputInstructions')}
        onPress={() => {
          setSubmitted(true)
          setFinishedAt(moment())
        }}
        onChange={e => setInputWords(e.target.value)}
        width="320"
        rows="11"
        placeholder={t('placeholder')}
        submitText={t('submitButton')}
      />
      <Box width="100%" bottom="0px" position="fixed">
        <ProgressBar width="100" progressPercentage={getProgressPercentage()} />
      </Box>
    </Box>
  )
}

ListLearningTask.propTypes = {
  onFinish: PropTypes.func,
}

ListLearningTask.defaultProps = {
  onFinish: () => {},
}

export default ListLearningTask
