import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, Navigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import theme from 'utils/theme'
import { Box, Text, Button } from '../../components'

const CREATE_FORM_RESULT_ENTRY = gql`
  mutation CreateFormResultEntry($title: String!, $formTitle: String!, $results: String!) {
    createEntryResult(title: $title, formTitle: $formTitle, results: $results) {
      successful
    }
  }
`

const Results = () => {
  const {
    state: { results, battery, formTitle },
  } = useLocation()
  const { t } = useTranslation(['cognitiveTasks'])
  const [finished, setFinished] = useState(false)

  const [createFormResultEntry, { data, loading, error }] = useMutation(CREATE_FORM_RESULT_ENTRY)
  useEffect(() => {
    createFormResultEntry({
      variables: {
        formTitle: 'CognitiveBattery',
        title: formTitle,
        results: JSON.stringify(results),
      },
    })
  }, [])

  // eslint-disable-next-line prefer-destructuring
  const testName = results[0].testName
  delete results[0].testName

  const handleFinish = () => {
    setFinished(true)
  }

  if (finished) {
    if (battery && battery.length > 0) {
      const nextTask = battery.shift()
      return <Navigate to={`/${nextTask.title}`} state={{ battery, taskSetting: nextTask }} />
    }

    return <Navigate to="/myPage" />
  }

  return (
    <Box bg={theme.colors.greyDark} fullscreen>
      <Box p={theme.spacing.huge} center width="40%">
        <Text big color="white">
          {t('resultHeader')}
        </Text>
        <Box pv={theme.spacing.largest}>
          {results?.map(item => (
            <Box>
              {Object.keys(item).map(itemKey => (
                <Box row jc="space-between">
                  <Text color="white">{t(`results.${itemKey}`)}</Text>
                  <Box mh="15px" />
                  <Text color="white">{item[itemKey]}</Text>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
        <Box cc>
          <Button onPress={handleFinish} block>
            <Text semibold center color="white">
              {t('done')}
            </Text>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Results
