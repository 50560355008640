import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
// import { DatePicker, LocalizationProvider } from '@mui/lab'
import { useMediaQuery } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ClockIcon } from 'assets/icons'
import { some, inRange } from 'lodash'
import {
  Area,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ScatterChart,
  Scatter,
  ResponsiveContainer,
  Tooltip,
  Label,
  Legend,
} from 'recharts'
import theme from 'utils/theme'
import Box from './Base/Box'
import Text from './Base/Text'
import ListItem from './SRBase/ListItem'

function isTimeInIntervals(time, intervals) {
  return some(intervals, interval => {
    const [start, end] = [new Date(interval[0]), new Date(interval[1])]
    return inRange(new Date(time), start, end)
  })
}

const getSleepDataPoints = sleepData => {
  const today = new Date()
  const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate())
  const startOfYesterday = new Date(startOfToday.getTime() - 12 * 60 * 60 * 1000)

  const sleepDataPoints = []

  // Add a data point for every ten minutes in the time from 12:00am to 11:50pm
  for (let i = 0; i < 289; i += 1) {
    const time = startOfYesterday.getTime() + i * 10 * 60 * 1000

    // check if the time falls in one of the array elements that contains sleep time intervals
    const sleep = isTimeInIntervals(time, sleepData)

    sleepDataPoints.push({
      time: new Date(time),
      sleep: sleep ? 1 : 0,
    })
  }

  return sleepDataPoints
}

const CustomizedTick = props => {
  const {
    x,
    y,
    payload: { value, index },
  } = props

  const time = value.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })
  const date = value.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  })

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill="#666">
        <tspan textAnchor="middle" x="0">
          {time}
        </tspan>
        {(index === 0 || index === 72 || index === 216) && (
          <tspan textAnchor="middle" x="0" dy="20">
            {date}
          </tspan>
        )}
      </text>
    </g>
  )
}

const SleepGraph = () => {
  const sleepData = getSleepDataPoints([
    ['2023-04-25T22:01:31+00:00', '2023-04-26T07:31:31+00:00'],
    ['2023-04-26T07:50:31+00:00', '2023-04-26T08:15:31+00:00'],
  ])

  const isLargerThanMobile = useMediaQuery('(min-width: 768px)')

  const interval = isLargerThanMobile ? 23 : 71

  return (
    <ResponsiveContainer width="100%" height={200}>
      <AreaChart
        width={500}
        height={200}
        data={sleepData}
        margin={{
          top: 10,
          right: 30,
          left: -30,
          bottom: 30,
        }}
      >
        <XAxis
          dataKey="time"
          interval={interval}
          tick={<CustomizedTick />}
          tickFormatter={(time, index) => {
            const timeString = time.toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })
            if (index === 0 || index === 3) {
              const dateString = time.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
              })
              return [timeString, dateString]
            }
            return timeString
          }}
        />

        <YAxis tick={false} />
        <Tooltip
          labelFormatter={time =>
            time.toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })
          }
          formatter={(value, name) => [value ? 'Sleeping' : 'Awake']}
        />

        <Area
          type="step"
          dataKey="sleep"
          stroke={theme.colors.bluepurple}
          fill={theme.colors.bluepurple}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

const colorAry = [
  theme.colors.exercise.left,
  theme.colors.exercise.right,
  theme.colors.morningSleepDiary.left,
  theme.colors.morningSleepDiary.right,
  theme.colors.eveningSleepDiary.left,
  theme.colors.eveningSleepDiary.right,
]

const WithingsBox = ({ data }) => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const sleepData = data.entryResults.find(entry => entry.entryId.title === 'SleepTime').textAnswer

  const { t, i18n } = useTranslation(['withings'])

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  const filteredData = data.entryResults
    .filter(entry => entry.entryId.entryType === 'NR')
    .map(entry => ({
      title: entry.entryId.title,
      value: entry.numberAnswer || entry.textAnswer.join(', '),
    }))

  const isLargerThanMobile = useMediaQuery('(min-width: 768px)')

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        round
        bg={theme.colors.grey}
        pv={isLargerThanMobile ? '32px' : '16px'}
        ph={isLargerThanMobile ? '64px' : '24px'}
        mr="0px"
      >
        <Box row cc>
          <ClockIcon height={25} width={25} />
          <Box ml="8px">
            <Text bold huge>
              Withings
            </Text>
          </Box>
        </Box>
        <Box mt="8px" pt="8px">
          <DatePicker
            label="Select a date"
            value={selectedDate}
            onChange={handleDateChange}
            maxDate={new Date()}
          />
        </Box>
        <Box mv="16px" row jc="space-evenly" flexWrap style={{ gap: '8px' }}>
          {filteredData.map((item, index) => (
            <Box p="16px" flexGrow rounded bg={colorAry[index % colorAry.length]}>
              <Text semibold>{t(item.title)}</Text>
              <Box row jc="space-between" mt="8px">
                <Text>{item.value}</Text>
                <Text>{t(`${item.title}.unit`)}</Text>
              </Box>
            </Box>
          ))}
        </Box>
        <Box mb="16px" rounded bg={theme.colors.white} p="16px">
          <Box mb="8px" ml="4px">
            <Text semibold color={theme.colors.grey}>
              {t('SleepTime')}
            </Text>
          </Box>
          <SleepGraph />
        </Box>
      </Box>
    </LocalizationProvider>
  )
}

WithingsBox.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string.isRequired,
    entryResults: PropTypes.arrayOf(
      PropTypes.shape({
        entryId: PropTypes.shape({
          title: PropTypes.string.isRequired,
          entryType: PropTypes.string.isRequired,
        }).isRequired,
        numberAnswer: PropTypes.number,
        textAnswer: PropTypes.arrayOf(PropTypes.string),
        selectAnswer: PropTypes.string,
        datefieldAnswer: PropTypes.string,
        datetimefieldAnswer: PropTypes.string,
        timefieldAnswer: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
}

export default WithingsBox
