import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import theme from 'utils/theme'

const StyledBlock = styled.div(
  ({ light }) => css`
    min-height: 200px;
    min-width: 120;
    background-color: ${light ? 'white' : theme.colors.greyDarkest};
    flex: 1;
  `
)

const PatternBlock = ({ light }) => <StyledBlock light={light} />

PatternBlock.propTypes = {
  light: PropTypes.bool,
}

PatternBlock.defaultProps = {
  light: false,
}

export default PatternBlock
