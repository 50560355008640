import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Box, Switch, FormControlLabel } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DataGrid } from '@mui/x-data-grid'
import clsx from 'clsx'
import { flatten, map, merge } from 'lodash'
import theme from '../../utils/theme'

const LIST_PARTICIPATIONS_SIMPLE_QUERY = gql`
  query SimpleParticipations($first: Int, $offset: Int, $studies: [String]) {
    studyParticipationsSimple(
      orderBy: "participant_id__sri"
      first: $first
      offset: $offset
      studies: $studies
    ) {
      totalCount
      edges {
        node {
          id
          sri
          studyId {
            title
          }
          groupId {
            title
          }
          formResults {
            answeredAt
            formId {
              title
            }
            entryResults {
              id
              datefieldAnswer
              datetimefieldAnswer
              timefieldAnswer
              textAnswer
              numberAnswer
              selectAnswer
              entryId {
                title
                entryType
                selection
              }
            }
          }
        }
      }
    }
  }
`

const LIST_ALL_PARTICIPANTS_QUERY = gql`
  query AllParticipations($first: Int, $offset: Int, $formResults: [FormResultFilterType]) {
    allParticipations(first: $first, offset: $offset, formResults: $formResults) {
      totalCount
      edges {
        node {
          id
          sri
          studyId {
            title
          }
          formResults {
            formId {
              title
            }
            answeredAt
            entryResults {
              textAnswer
              numberAnswer
              selectAnswer
              datefieldAnswer
              timefieldAnswer
              entryId {
                title
                entryType
                selection
              }
            }
          }
        }
      }
    }
  }
`

const CustomSwitch = styled(Switch)(({ customColor }) => ({
  marginLeft: '1rem',
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: customColor,
    // '&:hover': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    '& + .MuiSwitch-track': {
      backgroundColor: customColor,
    },
  },
}))

const DataTable = ({ filters }) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['table'])

  const [first, setFirst] = useState(50)
  const [offset, setOffset] = useState(0)
  const [participants, setParticipants] = useState(null)
  const [newCols, setNewCols] = useState([])
  const [newRows, setNewRows] = useState([])
  const [totalRows, setTotalRows] = useState(0)

  const [hide, setHide] = useState(true)

  // Toggle the switch state when clicked
  const toggleHide = () => {
    const newHide = !hide
    setHide(newHide)
    setNewCols(newCols.map(c => (c.field === 'group' ? { ...c, hide: newHide } : c)))
  }

  const [getParticipants, { loading, error, data }] = useLazyQuery(LIST_PARTICIPATIONS_SIMPLE_QUERY)

  useEffect(() => {
    console.log('in user effect to get participants')
    console.log('filters', filters)
    console.log('offset', offset)
    // query local storage first
    getParticipants({
      variables: { first, offset, studies: filters?.[0]?.study?.title__in },
      networkPolicy: 'cache-first',
    }).then(res => {
      setTotalRows(res?.data?.studyParticipationsSimple?.totalCount)
    })
  }, [offset, filters])

  useEffect(() => {
    if (data && !loading) {
      console.log(data)
      setParticipants(data?.studyParticipationsSimple?.edges?.map(n => n.node))
    }
  }, [data, loading])

  useEffect(() => {
    if (participants) {
      const columns = []
      const rows = participants?.flatMap(p => {
        const { formResults, ...pNew } = p
        const flatEntryResults = []
        formResults?.forEach(rf => {
          const k = rf?.formId?.title
          if (k) {
            rf.entryResults.forEach(er => {
              const {
                entryId: { title },
              } = er
              if (er.entryId.entryType === 'DA') {
                const col = { field: title, headerName: t(`${k}.${title}`), width: 150 }
                if (!columns.find(c => c.field === title)) columns.push(col)
                flatEntryResults.push({ [title]: er.datefieldAnswer })
              } else {
                const col = { field: title, headerName: t(`${k}.${title}`), width: 150 }
                if (!columns.find(c => c.field === title)) columns.push(col)
                if (er.entryId.entryType === 'NR')
                  flatEntryResults.push({ [title]: er.numberAnswer })
                else if (er.entryId.entryType === 'DF')
                  flatEntryResults.push({ [title]: er.datefieldAnswer })
                else if (er.entryId.entryType === 'TF')
                  flatEntryResults.push({ [title]: er.timefieldAnswer })
                else if (er.entryId.entryType === 'SO')
                  flatEntryResults.push({
                    [title]: JSON.parse(er.entryId.selection)[er.selectAnswer],
                  })
                else if (er.entryId.entryType === 'DT')
                  flatEntryResults.push({ [title]: er.datetimefieldAnswer })
                else flatEntryResults.push({ [title]: er.textAnswer, type: 'date' })
              }
            })
          }
        })
        pNew.study = pNew.studyId.title
        pNew.group = pNew?.groupId?.title
        const mergedDict = merge(...flatEntryResults, pNew)
        const { studyId, groupId, ...filteredDict } = mergedDict
        filteredDict.esqv =
          // filteredDict.base_finished_at && filteredDict.follow_up_finished_at
          filteredDict.base_finished_at ? 'Available' : 'Not available'
        return filteredDict
      })

      // add field sri to columns
      columns.unshift({ field: 'esqv', headerName: 'ESQ Visualization', width: 140 })
      columns.unshift({ field: 'group', headerName: 'Group', width: 150, hide })
      columns.unshift({ field: 'study', headerName: 'Study', width: 150 })
      columns.unshift({ field: 'sri', headerName: 'SRI', width: 150 })

      setNewRows(flatten(rows))
      setNewCols(columns)
    }
  }, [participants])

  const handleEvent = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    navigate('/participation', {
      state: {
        id: params.row.id,
        sri: params.row.sri,
        esqMetadata: {
          base: params.row.base_finished_at,
          followUp: params.row.follow_up_finished_at,
        },
      },
    })
  }

  return (
    <Box
      sx={{
        '& .ahi.severe': {
          backgroundColor: theme.colors.red,
          color: theme.colors.white,
          fontWeight: '600',
        },
        '& .ahi.moderate': {
          backgroundColor: theme.colors.orange,
          color: theme.colors.white,
          fontWeight: '600',
        },
        '& .ahi.mild': {
          backgroundColor: theme.colors.yellow,
          color: theme.colors.white,
          fontWeight: '600',
        },
        '& .ahi.minimal': {
          backgroundColor: theme.colors.green,
          color: theme.colors.white,
          fontWeight: '600',
        },
      }}
    >
      <FormControlLabel
        control={
          <CustomSwitch
            checked={hide}
            onChange={toggleHide}
            customColor={theme.colors.bluepurple}
          />
        }
        label="Hide Group"
      />
      {newRows.length > 0 && newCols.length > 0 && (
        <DataGrid
          key="data-grid"
          rows={newRows}
          columns={newCols}
          // rowsPerPageOptions={[]}
          // rowCount={first}
          // page={offset / first + 1}
          onRowClick={handleEvent}
          onPageChange={newPage => setOffset(newPage * first)}
          pagination
          paginationMode="server"
          loading={loading}
          rowCount={totalRows}
          rowsPerPageOptions={[]}
          page={offset / first}
          pageSize={first}
          autoHeight
          density="compact"
          disableColumnFilter
        />
      )}
    </Box>
  )
}

export default DataTable
