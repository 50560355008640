import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { TitleContextProvider } from './TitleContext'

// <ContextWrapper> was around children
const MainLayout = ({ children }) => (
  <Suspense fallback={null}>
    <TitleContextProvider>{children}</TitleContextProvider>
  </Suspense>
)
MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
