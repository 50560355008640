import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import theme from 'utils/theme'

const StyledText = styled.div(
  ({
    color,
    ginormous,
    huge,
    bigger,
    big,
    medium,
    small,
    sub,
    underline,
    bold,
    semibold,
    cursive,
    uppercase,
    lineHeight,
    underlined,
    center,
    block,
    fontSize,
  }) => css`
    color: ${color};
    font-size: ${(ginormous && '2.6rem') ||
    (huge && '1.8rem') ||
    (bigger && '1.6rem') ||
    (big && '1.3rem') ||
    (medium && '0.9rem') ||
    (small && '0.7rem') ||
    (sub && '0.8rem') ||
    fontSize ||
    '1rem'};
    text-decoration: ${(underline && 'underline') || 'none'};
    text-decoration-color: ${color || 'black'};
    font-weight: ${(bold && 700) || (semibold && 500) || 300};
    font-style: ${(cursive && 'italic') || 'normal'};
    line-height: ${1.669 *
    ((ginormous && 64) ||
      (huge && 28) ||
      (bigger && 24) ||
      (big && 20) ||
      (medium && 18) ||
      (small && 12) ||
      (sub && 14) ||
      16)}px;

    ${uppercase &&
    css`
      text-transform: uppercase;
    `}

    ${lineHeight &&
    css`
      line-height: ${lineHeight}px;
    `}

    ${underlined &&
    css`
      text-decoration-line: underline;
    `}

    flex-shrink: 1;

    text-align: left;
    white-space: pre-line;

    ${center &&
    css`
      text-align: center;
    `}

    ${block &&
    css`
      width: 100%;
    `}
  `
)

const Text = ({
  children,
  color,
  ginormous,
  huge,
  bigger,
  big,
  medium,
  small,
  sub,
  underline,
  bold,
  semibold,
  cursive,
  uppercase,
  lineHeight,
  underlined,
  center,
  block,
  fontSize,
}) => (
  <StyledText
    color={color}
    ginormous={ginormous}
    huge={huge}
    bigger={bigger}
    big={big}
    medium={medium}
    small={small}
    sub={sub}
    underline={underline}
    bold={bold}
    semibold={semibold}
    cursive={cursive}
    uppercase={uppercase}
    lineHeight={lineHeight}
    underlined={underlined}
    center={center}
    block={block}
    fontSize={fontSize}
  >
    {children}
  </StyledText>
)

Text.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  ginormous: PropTypes.bool,
  huge: PropTypes.bool,
  bigger: PropTypes.bool,
  big: PropTypes.bool,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  sub: PropTypes.bool,
  underline: PropTypes.bool,
  bold: PropTypes.bool,
  semibold: PropTypes.bool,
  cursive: PropTypes.bool,
  uppercase: PropTypes.bool,
  lineHeight: PropTypes.number,
  underlined: PropTypes.bool,
  center: PropTypes.bool,
  block: PropTypes.bool,
  fontSize: PropTypes.string,
}

Text.defaultProps = {
  color: theme.colors.white,
  ginormous: false,
  huge: false,
  bigger: false,
  big: false,
  medium: false,
  small: false,
  sub: false,
  underline: false,
  bold: false,
  semibold: false,
  cursive: false,
  uppercase: false,
  lineHeight: 0,
  underlined: false,
  center: false,
  block: false,
  fontSize: '',
}

export default Text
