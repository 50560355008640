/* eslint-disable camelcase */

import React, { useContext, useEffect } from 'react'
import Info from 'components/ESQ/A_ESQ_REMAKE/comps/Info'
import Comment from '../../../comps/Comment'
import ConnectedFields from '../../../comps/ConnectedFields'
import FormikMuiInput from '../../../comps/FormikMuiInput'
import FormikMuiRadio from '../../../comps/FormikMuiRadio'
import { LangContext } from '../../../const/Contexts'
import { occurrenceFrequencyKeys, occurrenceBotherKeys, noYesKeys } from '../../../const/ESQOptions'

const DFGiantScale = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  const { values, setFieldValue } = formikProps

  const {
    difficulty_remembering_frequency,
    difficulty_remembering_bother,
    difficulty_concentrating_frequency,
    difficulty_concentrating_bother,
    lapses_of_attention_frequency,
    lapses_of_attention_bother,
    anxiety_frequency,
    anxiety_bother,
    depression_frequency,
    depression_bother,
    losing_temper_frequency,
    losing_temper_bother,
    problems_with_relationships_frequency,
    problems_with_relationships_bother,
    problems_with_social_interaction_frequency,
    problems_with_social_interaction_bother,
    problems_with_sexual_desire_activity_frequency,
    problems_with_sexual_desire_activity_bother,
    problems_with_daily_activities_frequency,
    problems_with_daily_activities_bother,
    sudden_muscle_weakness_frequency,
    sudden_muscle_weakness_bother,
    safety_concerns_frequency,
    safety_concerns_bother,
    concerns_with_health_frequency,
    concerns_with_health_bother,
    near_accident,
  } = values

  // Resetting 'Extra' fields when they switch back (ex. 'Other' boxes)
  useEffect(() => {
    if (difficulty_remembering_frequency === 0 && difficulty_remembering_bother !== '') {
      setFieldValue('difficulty_remembering_bother', '')
    }
  }, [difficulty_remembering_frequency])
  useEffect(() => {
    if (difficulty_concentrating_frequency === 0 && difficulty_concentrating_bother !== '') {
      setFieldValue('difficulty_concentrating_bother', '')
    }
  }, [difficulty_concentrating_frequency])
  useEffect(() => {
    if (lapses_of_attention_frequency === 0 && lapses_of_attention_bother !== '') {
      setFieldValue('lapses_of_attention_bother', '')
    }
  }, [lapses_of_attention_frequency])
  useEffect(() => {
    if (anxiety_frequency === 0 && anxiety_bother !== '') {
      setFieldValue('anxiety_bother', '')
    }
  }, [anxiety_frequency])
  useEffect(() => {
    if (depression_frequency === 0 && depression_bother !== '') {
      setFieldValue('depression_bother', '')
    }
  }, [depression_frequency])
  useEffect(() => {
    if (losing_temper_frequency === 0 && losing_temper_bother !== '') {
      setFieldValue('losing_temper_bother', '')
    }
  }, [losing_temper_frequency])
  useEffect(() => {
    if (problems_with_relationships_frequency === 0 && problems_with_relationships_bother !== '') {
      setFieldValue('problems_with_relationships_bother', '')
    }
  }, [problems_with_relationships_frequency])
  useEffect(() => {
    if (
      problems_with_social_interaction_frequency === 0 &&
      problems_with_social_interaction_bother !== ''
    ) {
      setFieldValue('problems_with_social_interaction_bother', '')
    }
  }, [problems_with_social_interaction_frequency])
  useEffect(() => {
    if (
      problems_with_sexual_desire_activity_frequency === 0 &&
      problems_with_sexual_desire_activity_bother !== ''
    ) {
      setFieldValue('problems_with_sexual_desire_activity_bother', '')
    }
  }, [problems_with_sexual_desire_activity_frequency])
  useEffect(() => {
    if (
      problems_with_daily_activities_frequency === 0 &&
      problems_with_daily_activities_bother !== ''
    ) {
      setFieldValue('problems_with_daily_activities_bother', '')
    }
  }, [problems_with_daily_activities_frequency])
  useEffect(() => {
    if (sudden_muscle_weakness_frequency === 0 && sudden_muscle_weakness_bother !== '') {
      setFieldValue('sudden_muscle_weakness_bother', '')
    }
  }, [sudden_muscle_weakness_frequency])
  useEffect(() => {
    if (safety_concerns_frequency === 0) {
      if (safety_concerns_bother !== '') {
        setFieldValue('safety_concerns_bother', '')
      }
      if (near_accident !== '') {
        setFieldValue('near_accident', '')
      }
    }
  }, [safety_concerns_frequency])
  useEffect(() => {
    if (concerns_with_health_frequency === 0 && concerns_with_health_bother !== '') {
      setFieldValue('concerns_with_health_bother', '')
    }
  }, [concerns_with_health_frequency])

  return (
    <>
      <ConnectedFields>
        <Comment text={t('Duringthepastmonth')} bold />
        <Comment text={t('columnName1DaytimeFunctioning8')} />
        <FormikMuiRadio
          column
          label={t('difficultyremembering')}
          name="difficulty_remembering_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('difficultyconcentrating')}
          name="difficulty_concentrating_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('lapsesattention')}
          name="lapses_of_attention_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('feltanxious')}
          name="anxiety_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('feltdepressed')}
          name="depression_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('losttemper')}
          name="losing_temper_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('problemsrelationship')}
          name="problems_with_relationships_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('problemssocial')}
          name="problems_with_social_interaction_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('problemssexual')}
          name="problems_with_sexual_desire_activity_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('problemsdailyactivities')}
          name="problems_with_daily_activities_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('muscleweakness')}
          name="sudden_muscle_weakness_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('concernssafety')}
          name="safety_concerns_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('worriesproblem')}
          name="concerns_with_health_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
      </ConnectedFields>
      {safety_concerns_frequency > 0 && (
        <FormikMuiRadio
          column
          label={t('NearAccidents')}
          name="near_accident"
          options={noYesKeys.map(val => t(val))}
        />
      )}
      <ConnectedFields>
        {(difficulty_remembering_frequency > 0 ||
          difficulty_concentrating_frequency > 0 ||
          lapses_of_attention_frequency > 0 ||
          anxiety_frequency > 0 ||
          depression_frequency > 0 ||
          losing_temper_frequency > 0 ||
          problems_with_relationships_frequency > 0 ||
          problems_with_social_interaction_frequency > 0 ||
          problems_with_sexual_desire_activity_frequency > 0 ||
          problems_with_daily_activities_frequency > 0 ||
          sudden_muscle_weakness_frequency > 0 ||
          safety_concerns_frequency > 0 ||
          concerns_with_health_frequency > 0) && (
          <Comment text={t('columnName2DaytimeFunctioning8')} />
        )}
        {difficulty_remembering_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('difficultyremembering')}
            name="difficulty_remembering_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {difficulty_concentrating_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('difficultyconcentrating')}
            name="difficulty_concentrating_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {lapses_of_attention_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('lapsesattention')}
            name="lapses_of_attention_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {anxiety_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('feltanxious')}
            name="anxiety_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {depression_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('feltdepressed')}
            name="depression_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {losing_temper_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('losttemper')}
            name="losing_temper_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {problems_with_relationships_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('problemsrelationship')}
            name="problems_with_relationships_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {problems_with_social_interaction_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('problemssocial')}
            name="problems_with_social_interaction_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {problems_with_sexual_desire_activity_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('problemssexual')}
            name="problems_with_sexual_desire_activity_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {problems_with_daily_activities_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('problemsdailyactivities')}
            name="problems_with_daily_activities_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {sudden_muscle_weakness_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('muscleweakness')}
            name="sudden_muscle_weakness_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {safety_concerns_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('concernssafety')}
            name="safety_concerns_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {concerns_with_health_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('worriesproblem')}
            name="concerns_with_health_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
      </ConnectedFields>
    </>
  )
}

export default DFGiantScale
