import React from 'react'
import PropTypes from 'prop-types'
import theme from 'utils/theme'
import { Instructions } from '..'
import { Text, Button, Box } from '../../atoms'
import TextArea from '../../atoms/CognitiveTasks/TextArea'

const InputField = ({
  header,
  onPress,
  onChange,
  instructions,
  width,
  rows,
  placeholder,
  submitText,
}) => (
  <Box onPress={onPress}>
    <Box centerContent round bg={theme.colors.grey} flex={1} pv="21px" ph="26px">
      <Text huge bold flex={1} color="white">
        {header}
      </Text>
      <Instructions body={instructions} />
      <TextArea
        fontSize="18"
        placeholder={placeholder}
        onChange={v => onChange(v)}
        width={width}
        rows={rows}
      />
      <Button onPress={onPress} block>
        <Text center color="white">
          {submitText}
        </Text>
      </Button>
    </Box>
  </Box>
)

InputField.propTypes = {
  header: PropTypes.string,
  instructions: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  width: PropTypes.string,
  rows: PropTypes.string,
  placeholder: PropTypes.string,
  submitText: PropTypes.string,
}

InputField.defaultProps = {
  header: '',
  instructions: '',
  onChange: () => {},
  width: 'auto',
  rows: 10,
  placeholder: 'Input here.',
  submitText: 'Submit',
}

export default InputField
