import React from 'react'
import PropTypes from 'prop-types'
import theme from 'utils/theme'
import { Text, Box } from '../../atoms'

const Instructions = ({ body, spacing }) => (
  <Box pv={spacing || theme.spacing.largest}>
    <Text color="white">{body}</Text>
  </Box>
)

Instructions.propTypes = {
  body: PropTypes.string.isRequired,
  spacing: PropTypes.string,
}

Instructions.defaultProps = {
  spacing: theme.spacing.largest,
}

export default Instructions
