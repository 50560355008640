import React from 'react'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { useMediaQuery, useTheme } from '@mui/material'
import { LogoutIcon } from 'assets/icons'
import SRLogo from 'assets/images/SRLogo.png'
import client from 'client'
import { clearUserData, getRefreshToken } from 'utils/helper'
import theme from 'utils/theme'
import UserContext from 'utils/UserContext'
import { LanguageDropdown } from '../../organisms'
import Box from './Box'
import Clickable from './Clickable'
import Text from './Text'

const LOGOUT_MUTATION = gql`
  mutation Logout($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      success
    }
  }
`

const Header = ({ publicRouter }) => {
  const navigate = useNavigate()
  const { setUser } = React.useContext(UserContext)
  const [logUserOut] = useMutation(LOGOUT_MUTATION)

  const muiTheme = useTheme()
  const hideLogoutText = useMediaQuery('(max-width: 312px)')

  const logout = () => {
    setUser({})
    const refreshToken = getRefreshToken()

    console.log('refersh token', refreshToken)

    logUserOut({
      variables: {
        refreshToken,
      },
      onCompleted: () => {
        clearUserData().then(() => client.clearStore().then(() => navigate('/', { replace: true })))
      },
    })
  }

  return (
    <Box
      bg={theme.colors.grey}
      height="48px"
      ph="1rem"
      style={headerStyle}
      row
      pt="12px"
      js="space-between"
    >
      <Clickable onPress={() => navigate('/')}>
        <Box row flex={1} cc mb="1rem">
          <img src={SRLogo} alt="Logo" style={{ height: '36px', marginRight: '16px' }} />
          <Text big bold>
            Sleep Revolution
          </Text>
        </Box>
      </Clickable>

      <Box row jc="flex-end" flex={1} style={{ overflow: 'visible' }} mb="12px">
        <Box col jc="center" mr="1rem">
          <LanguageDropdown />
        </Box>
        {!publicRouter && (
          <Clickable onPress={logout}>
            <Box row>
              <LogoutIcon fill="white" width="25" height="25" style={{ marginRight: 8 }} />
              {!hideLogoutText && <Text semibold>Logout</Text>}
            </Box>
          </Clickable>
        )}
      </Box>
    </Box>
  )
}

const headerStyle = {
  position: 'fixed',
  top: '0px',
  left: '0px',
  right: '0px',
  overflow: 'visible',
  zIndex: 100,
}

export default Header
