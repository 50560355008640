import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import colors from 'utils/colors'
import theme from 'utils/theme'
import { Box, Text, Button, Clickable } from '../../atoms'

const ResetPassword = ({ onResetPassword, token }) => {
  const [password, setPassword] = useState('')

  return (
    <Box ph={theme.spacing.large}>
      <Box gap={theme.spacing.large}>
        <Text medium bold color={colors.greyDarkest}>
          Set new password
        </Text>
        <TextField
          id="filledPassword"
          variant="filled"
          required
          label="Password"
          type="password"
          value={password}
          onChange={event => {
            setPassword(event.target.value)
          }}
        />
        <Box mt={theme.spacing.small}>
          <Button
            onPress={() => {
              onResetPassword({ variables: { token, password } })
            }}
          >
            <Box cc>
              <Text bold center color={theme.colors.white}>
                Reset your password
              </Text>
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

ResetPassword.propTypes = {
  onResetPassword: PropTypes.func,
}

ResetPassword.defaultProps = {
  onResetPassword: () => {},
}

export default ResetPassword
