/* eslint-disable camelcase */

import React, { useContext, useEffect } from 'react'
import Comment from '../../../comps/Comment'
import ConnectedFields from '../../../comps/ConnectedFields'
import FormikMuiInput from '../../../comps/FormikMuiInput'
import FormikMuiRadio from '../../../comps/FormikMuiRadio'
import { LangContext } from '../../../const/Contexts'
import { occurrenceFrequencyKeys, occurrenceBotherKeys } from '../../../const/ESQOptions'

const FAMSAwakenings = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  const { values, setFieldValue } = formikProps

  const {
    breathlessness_or_choking_frequency,
    breathlessness_or_chokings_bother,
    inability_to_move_feeling_frequency,
    inability_to_move_feelings_bother,
    panic_feeling_frequency,
    panic_feelings_bother,
    headache_pain_frequency,
    headache_pains_bother,
    dry_mouth_feeling_frequency,
    dry_mouth_feelings_bother,
    sore_throat_pain_frequency,
    sore_throat_pains_bother,
    jaw_pain_frequency,
    jaw_pains_bother,
    heartburn_feeling_frequency,
    heartburn_feelings_bother,
    palpitations_feeling_frequency,
    palpitations_feelings_bother,
    excessive_sweating_feeling_frequency,
    excessive_sweating_feelings_bother,
    hot_flushes_feeling_frequency,
    hot_flushes_feelings_bother,
    other_ds_frequency,
    other_ds_bother,
    other_ds_info,
  } = values

  // Resetting 'Extra' fields when they switch back (ex. 'Other' boxes)
  useEffect(() => {
    if (breathlessness_or_choking_frequency === 0 && breathlessness_or_chokings_bother !== '') {
      setFieldValue('breathlessness_or_chokings_bother', '')
    }
  }, [breathlessness_or_choking_frequency])
  useEffect(() => {
    if (inability_to_move_feeling_frequency === 0 && inability_to_move_feelings_bother !== '') {
      setFieldValue('inability_to_move_feelings_bother', '')
    }
  }, [inability_to_move_feeling_frequency])
  useEffect(() => {
    if (panic_feeling_frequency === 0 && panic_feelings_bother !== '') {
      setFieldValue('panic_feelings_bother', '')
    }
  }, [panic_feeling_frequency])
  useEffect(() => {
    if (headache_pain_frequency === 0 && headache_pains_bother !== '') {
      setFieldValue('headache_pains_bother', '')
    }
  }, [headache_pain_frequency])
  useEffect(() => {
    if (dry_mouth_feeling_frequency === 0 && dry_mouth_feelings_bother !== '') {
      setFieldValue('dry_mouth_feelings_bother', '')
    }
  }, [dry_mouth_feeling_frequency])
  useEffect(() => {
    if (sore_throat_pain_frequency === 0 && sore_throat_pains_bother !== '') {
      setFieldValue('sore_throat_pains_bother', '')
    }
  }, [sore_throat_pain_frequency])
  useEffect(() => {
    if (jaw_pain_frequency === 0 && jaw_pains_bother !== '') {
      setFieldValue('jaw_pains_bother', '')
    }
  }, [jaw_pain_frequency])
  useEffect(() => {
    if (heartburn_feeling_frequency === 0 && heartburn_feelings_bother !== '') {
      setFieldValue('heartburn_feelings_bother', '')
    }
  }, [heartburn_feeling_frequency])
  useEffect(() => {
    if (palpitations_feeling_frequency === 0 && palpitations_feelings_bother !== '') {
      setFieldValue('palpitations_feelings_bother', '')
    }
  }, [palpitations_feeling_frequency])
  useEffect(() => {
    if (excessive_sweating_feeling_frequency === 0 && excessive_sweating_feelings_bother !== '') {
      setFieldValue('excessive_sweating_feelings_bother', '')
    }
  }, [excessive_sweating_feeling_frequency])
  useEffect(() => {
    if (hot_flushes_feeling_frequency === 0 && hot_flushes_feelings_bother !== '') {
      setFieldValue('hot_flushes_feelings_bother', '')
    }
  }, [hot_flushes_feeling_frequency])
  useEffect(() => {
    if (other_ds_frequency === 0) {
      if (other_ds_bother !== '') {
        setFieldValue('other_ds_bother', '')
      }
      if (other_ds_info !== '') {
        setFieldValue('other_ds_info', '')
      }
    }
  }, [other_ds_frequency])

  return (
    <>
      <ConnectedFields>
        <Comment text={t('Duringthepastmonth')} bold />
        <Comment text={t('columnName1FactorsAffectingMySleep3')} />
        <FormikMuiRadio
          column
          label={t('breathlessness')}
          name="breathlessness_or_choking_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('paralyzed')}
          name="inability_to_move_feeling_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('panic')}
          name="panic_feeling_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('headache')}
          name="headache_pain_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('drymouth')}
          name="dry_mouth_feeling_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('sorethroat')}
          name="sore_throat_pain_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('painfuljaw')}
          name="jaw_pain_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('heartburn')}
          name="heartburn_feeling_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('palpitations')}
          name="palpitations_feeling_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('excessivesweating')}
          name="excessive_sweating_feeling_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('hotflushes')}
          name="hot_flushes_feeling_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('otherIfPresent')}
          name="other_ds_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        {other_ds_frequency > 0 && (
          <FormikMuiInput label={t('otherIfPresent')} name="other_ds_info" type="text" />
        )}
      </ConnectedFields>
      <ConnectedFields>
        {(breathlessness_or_choking_frequency > 0 ||
          inability_to_move_feeling_frequency > 0 ||
          panic_feeling_frequency > 0 ||
          headache_pain_frequency > 0 ||
          dry_mouth_feeling_frequency > 0 ||
          sore_throat_pain_frequency > 0 ||
          jaw_pain_frequency > 0 ||
          heartburn_feeling_frequency > 0 ||
          palpitations_feeling_frequency > 0 ||
          excessive_sweating_feeling_frequency > 0 ||
          hot_flushes_feeling_frequency > 0 ||
          other_ds_frequency > 0) && <Comment text={t('columnName2FactorsAffectingMySleep3')} />}
        {breathlessness_or_choking_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('breathlessness')}
            name="breathlessness_or_chokings_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {inability_to_move_feeling_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('paralyzed')}
            name="inability_to_move_feelings_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {panic_feeling_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('panic')}
            name="panic_feelings_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {headache_pain_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('headache')}
            name="headache_pains_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {dry_mouth_feeling_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('drymouth')}
            name="dry_mouth_feelings_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {sore_throat_pain_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('sorethroat')}
            name="sore_throat_pains_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {jaw_pain_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('painfuljaw')}
            name="jaw_pains_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {heartburn_feeling_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('heartburn')}
            name="heartburn_feelings_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {palpitations_feeling_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('palpitations')}
            name="palpitations_feelings_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {excessive_sweating_feeling_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('excessivesweating')}
            name="excessive_sweating_feelings_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {hot_flushes_feeling_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('hotflushes')}
            name="hot_flushes_feelings_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {other_ds_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('Other')}
            name="other_ds_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
      </ConnectedFields>
    </>
  )
}

export default FAMSAwakenings
