import React from 'react'
import PropTypes from 'prop-types'
import Box from '../Base/Box'
import Text from '../Base/Text'

const ListItem = ({ title, value }) => (
  <Box row jc="space-between" pv="4px">
    <Text semibold medium>
      {title}
    </Text>
    <Text bold medium>
      {value}
    </Text>
  </Box>
)

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default ListItem
