import React from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { Tooltip, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { tooltipClasses } from '@mui/material/Tooltip'
import { fontSizes } from 'components/ESQ/A_ESQ_REMAKE/const/Const'

const Info = styled(({ className, text, children }) => {
  const theme = useTheme()
  return (
    <div style={{ position: 'relative' }}>
      <Tooltip
        style={{
          position: 'absolute',
          left: '-35px',
          top: '0px',
          transform: 'translateY(-50%)',
        }}
        key={text}
        title={text}
        placement="left"
        classes={{ popper: className }}
        enterTouchDelay={0}
        leaveTouchDelay={3000}
      >
        <InfoIcon sx={{ color: theme.palette.primary.main, marginRight: '10px' }} />
      </Tooltip>
      {children}
    </div>
  )
})({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 'calc(90vw - 60px)',
    fontSize: fontSizes.small,
  },
})

export default Info
