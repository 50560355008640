import moment from 'moment'
import restrictions from './restrictions'

export const TOKEN_KEY = 'token'

// set and get token from apollo cache

export const setToken = token => localStorage.setItem(TOKEN_KEY, token)
export const getToken = () => localStorage.getItem(TOKEN_KEY)
export const removeToken = () => localStorage.removeItem(TOKEN_KEY)

export const REFERSH_TOKEN_KEY = 'refreshToken'
export const setRefreshToken = token => localStorage.setItem(REFERSH_TOKEN_KEY, token)
export const getRefreshToken = () => localStorage.getItem(REFERSH_TOKEN_KEY)
export const removeRefreshToken = () => localStorage.removeItem(REFERSH_TOKEN_KEY)

export const UID_KEY = 'uid'
export const setUid = uid => localStorage.setItem(UID_KEY, uid)
export const getUid = () => localStorage.getItem(UID_KEY)
export const removeUid = () => localStorage.removeItem(UID_KEY)

export const CLIENT_KEY = 'client'
export const setClient = client => localStorage.setItem(CLIENT_KEY, client)
export const getClient = () => localStorage.getItem(CLIENT_KEY)
export const removeClient = () => localStorage.removeItem(CLIENT_KEY)

export const EXPIRY_KEY = 'expiry'
export const setExpiry = expiry => localStorage.setItem(EXPIRY_KEY, expiry)
export const getExpiry = () => localStorage.getItem(EXPIRY_KEY)
export const removeExpiry = () => localStorage.removeItem(EXPIRY_KEY)

export const USER_ID_KEY = 'user.id'
export const setUserId = id => localStorage.setItem(USER_ID_KEY, id)
export const getUserId = () => localStorage.getItem(USER_ID_KEY)
export const removeUserId = id => localStorage.removeItem(USER_ID_KEY, id)

export const USER_KEY = 'user'
export const setUser = user => localStorage.setItem(USER_KEY, JSON.stringify(user))
export const getUser = () => localStorage.getItem(USER_KEY)
export const removeUser = () => localStorage.removeItem(USER_KEY)

export const TIME_KEY = 'logout.time'
export const setTimer = time => localStorage.setItem(TIME_KEY, time)
export const getTimer = time => localStorage.getItem(TIME_KEY, time)
export const removeTimer = time => localStorage.removeItem(TIME_KEY, time)

export const getDateFromString = date => {
  if (moment(date).isValid()) {
    return moment(date).toDate()
  }
  return null
}

export const moveElementInArray = (arr, from, to) => {
  arr.splice(to, 0, arr.splice(from, 1)[0])
  return arr
}

// function to remove user data from local storage asynchronously
export const clearUserData = async () => {
  removeToken()
  removeRefreshToken()
  removeUid()
  removeUserId()
  removeUser()
}

export const getNumberFromString = value =>
  !Number.isNaN(parseFloat(value)) ? Number(value) : null

export const getIntFromString = value => {
  // NOTE: ruby's max int value is 2147483647
  const MAX_INT_VALUE = 2147483647
  const number = !Number.isNaN(parseInt(value)) ? parseInt(value) : null
  if (number && number > MAX_INT_VALUE) return MAX_INT_VALUE
  return number
}

export const getUserEntryPoint = role =>
  restrictions()
    .entries.filter(item => item.roles?.includes(role))
    ?.map(item => item.name)[0] || getDefaultEntryPoint()

export const getDisabledRoutes = role =>
  restrictions()
    .routes.filter(route => route.roles?.includes(role))
    ?.map(route => route.name)

export const getDefaultEntryPoint = () => restrictions().defaultEntry

export const getDisabledElement = (role, level) => {
  const found = restrictions()?.[level]?.find(item => item?.role === role)?.visibility
  return { hidden: found === 'hidden', disabled: found === 'disabled' }
}

export const resolveNestedObject = (path, obj) =>
  path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj)
