/* eslint-disable camelcase */

import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { Button, Stepper, Step, StepLabel, AppBar, Box } from '@mui/material'
import { Text, LanguageDropdown } from 'components'
import { Form } from 'formik'
import moment from 'moment'
import DarkModeToggle from './comps/DarkModeToggle'
import Section from './comps/Section'
import { fontSizes } from './const/Const'
import { LangContext } from './const/Contexts'
import { EntryMultiChoiceNameMappings } from './const/EntryMultiChoiceNameMappings'
import formSections, { getDependentSections } from './const/FormSections'

const CREATE_ENTRY_RESULTS = gql`
  mutation dobbyCreateEntryResults(
    $chosenLanguage: String!
    $studyTitle: String
    $formsEntryResults: [DobbyFormEntryResultsInputType!]!
    $resetIntervalDays: Int
  ) {
    dobbyCreateEntryResults(
      chosenLanguage: $chosenLanguage
      studyTitle: $studyTitle
      formsEntryResults: $formsEntryResults
      resetIntervalDays: $resetIntervalDays
    ) {
      successful
    }
  }
`

const ESQManager = ({ formikProps, ESQResetIntervalDays }) => {
  const { t, i18n } = useContext(LangContext)
  const [usedSections, setUsedSections] = useState(formSections)
  // Make sure next line is useState(0) when done.
  const [currentSection, setCurrentSection] = useState(0)
  const [dobbyCreateEntryResults] = useMutation(CREATE_ENTRY_RESULTS)
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false)
  const { __isSecondRound__, living_arrangement_together } = formikProps.values
  const navigate = useNavigate()

  useEffect(() => {
    const s = []
    const toSkip = []
    if (__isSecondRound__) {
      toSkip.push('MHR0')
    }
    if (!living_arrangement_together.includes(0)) {
      toSkip.push('MSAO0')
    }
    formSections.forEach(fs => {
      if (!toSkip.includes(fs.key)) {
        s.push(fs)
      }
    })
    setUsedSections(s)
  }, [living_arrangement_together])

  const smoothScrollToTop = () => {
    const scrollDurationMs = 50
    const scrollMsPerFrame = 2
    const scrollStep = -window.scrollY / (scrollDurationMs / scrollMsPerFrame)
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep)
      } else {
        clearInterval(scrollInterval)
      }
    }, scrollMsPerFrame)
  }

  const instantScrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const handleChangeSection = sectionIdx => {
    saveProgress([usedSections[currentSection]])
    setCurrentSection(sectionIdx)
    // instantScrollToTop()
    smoothScrollToTop()
  }

  const handleSubmit = async () => {
    const res = await saveProgress(usedSections)
    if (!res.hadErrors && res.successful) {
      setSubmittedSuccessfully(true)
    }
    formikProps.handleSubmit()
  }

  useEffect(() => {
    if (submittedSuccessfully) {
      const formsEntryResultsList = [
        {
          formTitle: 'ESQ Metadata',
          entryResults: [
            {
              title: __isSecondRound__ ? 'follow_up_finished_at' : 'base_finished_at',
              result: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
            },
          ],
        },
      ]
      // if is not second round, append entryresult for days until second round
      if (!__isSecondRound__) {
        formsEntryResultsList[0].entryResults.push({
          title: 'days_until_follow_up',
          result: 90,
        })
      }

      // sent submitted
      dobbyCreateEntryResults({
        variables: {
          chosenLanguage: i18n.language,
          formsEntryResults: formsEntryResultsList,
          resetIntervalDays: 9999,
        },
      }).then(res => {
        navigate('/myPage', { replace: true })
      })
    }
  }, [submittedSuccessfully])

  const saveProgress = async sections => {
    const formsEntryResultsList = []
    const ret = {
      hadErrors: false,
      successful: false,
    }
    // ex. Living Arrangements affects fields in My Sleep Affecting Others
    const sectionsAndDependentSections = getDependentSections(sections)
    // console.log(sectionsAndDependentSections)
    sectionsAndDependentSections.forEach(({ formTitle, fieldNames }) => {
      const formEntryResults = { formTitle, entryResults: [] }
      fieldNames.forEach(fn => {
        // check if field is required
        if (formikProps.errors[fn]) {
          ret.hadErrors = true
          // console.log(`${fn}: ${formikProps.errors[fn]}`)
        } else {
          const val = formikProps.values[fn]

          /* result is received as string in resolver, an empty string
          deletes or skips the entry result, everything else creates or
          updates the entry result */

          // for converting multiple choice questions to database format
          if (Array.isArray(val) && EntryMultiChoiceNameMappings[fn]) {
            EntryMultiChoiceNameMappings[fn].forEach((title, idx) => {
              /* all multichoice questions require 1+ choices, so an empty
              array means the question was skipped, so we dont want to
              skip/delete the entry result */
              if (val.length === 0) {
                formEntryResults.entryResults.push({ title, result: '' })
              } else {
                formEntryResults.entryResults.push({
                  title,
                  result: val.includes(idx) ? 1 : 0,
                })
              }
            })
          } else if (fn === 'caffeine_intake' && val === 1) {
            /* No translations for caffeine_intake so displaying it as 'No'/'Yes',
            but transforming it to 'I never drink'/'I currently drink' */
            formEntryResults.entryResults.push({ title: fn, result: 2 })
          } else {
            formEntryResults.entryResults.push({ title: fn, result: val })
          }
        }
      })
      formsEntryResultsList.push(formEntryResults)
    })
    // pushing all formTitles so answered_at gets updated for every formresult
    // regardless of whether something has changed or not
    formSections.forEach(fs => {
      if (!formsEntryResultsList.some(item => item.formTitle === fs.formTitle)) {
        formsEntryResultsList.push({ formTitle: fs.formTitle, entryResults: [] })
      }
    })

    if (formsEntryResultsList.length > 0) {
      try {
        const response = await dobbyCreateEntryResults({
          variables: {
            chosenLanguage: i18n.language,
            studyTitle: 'ESADA Study',
            formsEntryResults: formsEntryResultsList,
            resetIntervalDays: ESQResetIntervalDays,
          },
        })
        ret.successful = response?.data?.dobbyCreateEntryResults?.successful
      } catch (err) {
        console.error('Error executing mutation:', err)
      }
    }
    return ret
  }

  return (
    <div style={{ width: '100%', margin: '48px 10px 0 10px' }}>
      <AppBar
        enableColorOnDark
        sx={{
          overflowX: 'visible',
          width: '100%',
          '@media screen and (min-width: 350px)': {
            alignItems: 'center',
          },
          top: 'auto',
          zIndex: 99,
        }}
      >
        <Box maxWidth="900px" minWidth="350px" py="10px" alignItems="center" width="100%">
          <div
            style={{
              minWidth: '350px',
              margin: '0 10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <Text center semibold fontSize={fontSizes.medium}>
              ESQ
            </Text>
            <Text center semibold fontSize={fontSizes.medium}>
              {t(
                typeof usedSections[currentSection].translationKey === 'function'
                  ? usedSections[currentSection].translationKey(formikProps)
                  : usedSections[currentSection].translationKey
              )}
            </Text>
            <DarkModeToggle />
          </div>
          <Stepper activeStep={currentSection}>
            {usedSections.map(({ key, fieldNames }, idx) => (
              <Step
                key={key}
                completed={!fieldNames.some(fn => formikProps.errors[fn])}
                sx={{ px: '0', paddingLeft: '8px' }}
              >
                <StepLabel
                  onClick={() => handleChangeSection(idx)}
                  style={{ cursor: 'pointer' }}
                  error={fieldNames.some(fn => formikProps.touched[fn] && formikProps.errors[fn])}
                />
              </Step>
            ))}
          </Stepper>
        </Box>
      </AppBar>
      <div style={{ minWidth: '350px', maxWidth: '900px', margin: '0 auto' }}>
        <div style={{ margin: '110px 10px 0 10px' }}>
          <Section>
            <Form>{usedSections[currentSection].component(formikProps)}</Form>
            {!formikProps.isSubmitting && (
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                {currentSection !== 0 && (
                  <Button
                    type="button"
                    onClick={() => handleChangeSection(prevSection => prevSection - 1)}
                  >
                    {t('Back')}
                  </Button>
                )}
                <Button type="button" onClick={() => saveProgress(usedSections)}>
                  {t('SaveProgress')}
                </Button>
                {currentSection === usedSections.length - 1 ? (
                  <Button
                    type="submit"
                    disabled={submittedSuccessfully}
                    onClick={() => handleSubmit()}
                  >
                    {t('Submit')}
                  </Button>
                ) : (
                  <Button
                    type="button"
                    onClick={() => handleChangeSection(prevSection => prevSection + 1)}
                  >
                    {t('Next')}
                  </Button>
                )}
              </div>
            )}
          </Section>
        </div>
      </div>
    </div>
  )
}

export default ESQManager
