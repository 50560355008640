import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Text } from '../../atoms'
import { Instructions } from '../../molecules'

const RecallInstructions = () => {
  const { t } = useTranslation(['auditoryVerbalLearning'])

  return (
    <>
      <Text big color="white">
        {t('header')} - {t('instructionsHeader')}
        <Instructions body={t('recallInstructions')} />
      </Text>
    </>
  )
}

export default RecallInstructions
