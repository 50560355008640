import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PlusIcon } from '../../../assets/icons'
import colors from '../../../utils/colors'
import { Box } from '../../atoms'
import { ActionButton } from '../../molecules'

const PASATC = () => {
  const { t } = useTranslation(['pasatC'])
  const navigate = useNavigate()

  return (
    <Box width="380px">
      <ActionButton
        Icon={PlusIcon}
        header={t('header')}
        description={t('description')}
        actionText={t('actionText')}
        color1={colors.pasatC.left}
        color2={colors.pasatC.right}
        onPress={() => navigate('/pasatc')}
      />
    </Box>
  )
}

export default PASATC
