import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'
import TextField from '@mui/material/TextField'
import colors from 'utils/colors'
import theme from 'utils/theme'
import { Box, Text, Button, Clickable } from '../../atoms'

const SEND_RESET_PASSWORD_MAIL = gql`
  mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`

const ForgotPassword = ({ setIsLogin }) => {
  const [email, setEmail] = useState('')
  const [isSuccess, setIsSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [sendPasswordResetEmail] = useMutation(SEND_RESET_PASSWORD_MAIL, {
    onCompleted: ({ sendPasswordResetEmail: { success, errors } }) => {
      setIsSuccess(success)
      if (errors && Object.keys(errors)?.length > 0) {
        setError(errors[Object.keys(errors)[0]][0]?.message)
      }
    },
  })

  return (
    <Box ph={theme.spacing.large}>
      <Box gap={theme.spacing.large}>
        {!isSuccess && (
          <Box>
            <Text medium bold color={colors.greyDarkest}>
              Forgot your password?
            </Text>
            <TextField
              id="filled"
              variant="filled"
              required
              label="Email"
              type="email"
              value={email}
              onChange={event => {
                setEmail(event.target.value)
              }}
            />

            {error && <Text color={colors.red}>{error}</Text>}

            <Box mt={theme.spacing.small}>
              <Button
                onPress={() => {
                  setError(null)
                  sendPasswordResetEmail({ variables: { email } })
                }}
              >
                <Box cc>
                  <Text bold center color={theme.colors.white}>
                    Reset your password
                  </Text>
                </Box>
              </Button>
            </Box>
          </Box>
        )}
        {isSuccess && (
          <Text color={colors.greyDarkest}>
            Reset email was sent. Please check your mail box for reset instructions.
          </Text>
        )}
        <Box mt={theme.spacing.small} cc>
          <Clickable onPress={() => setIsLogin(true)}>
            <Text sub bold color={colors.greyDarkest}>
              Back to login
            </Text>
          </Clickable>
        </Box>
      </Box>
    </Box>
  )
}

ForgotPassword.propTypes = {
  setIsLogin: PropTypes.func,
}

ForgotPassword.defaultProps = {
  setIsLogin: () => {},
}

export default ForgotPassword
