import React from 'react'
import PropTypes from 'prop-types'
import theme from 'utils/theme'
import { Text, Box } from '../../atoms'

const DigitSymbol = ({ children, image, color }) => (
  <Box mb="4px" ml="8px">
    <Box>
      <img src={image} alt="symbol" height="30px" />
    </Box>
    <Box outline height="35px" bg={color}>
      <Text center bigger color="white">
        {children}
      </Text>
    </Box>
  </Box>
)

DigitSymbol.propTypes = {
  children: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  color: PropTypes.string,
}

DigitSymbol.defaultProps = {
  color: theme.colors.greyDark,
}

export default DigitSymbol
