import React from 'react'
import { Box, Text, Footer, Header } from 'components'

const TermsOfUse = () => (
  <Footer>
    <Box minHeight="100vh">
      <Header publicRouter />
      <Box flexGrow pv="2rem" ph="4rem" mb="140px" mt="80px">
        <Box mv="4px">
          <Text huge color="black">
            Terms of Use
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            By accessing this website, you are agreeing to be bound by these web site Terms and
            Conditions of Use, all applicable laws and regulations, and agree that you are
            responsible for compliance with any applicable local laws. Except where expressly
            indicated all content and information on this web site – including, for example, text,
            images, graphics, video and audio files- are protected in accordance with current
            legislation on copyright. The site contents protected by copyright can not be, in whole
            or in part, copied, modified, reproduced, republished, uploaded, transcribed,
            transmitted, or distributed in any way or form, without a prior authorization from of
            rights holders. The contents are made available and accessible for personal use,
            research or teaching.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            The reproduction will be possible only with the prior consent of rights holders. In case
            of reproduction of the contents you need to cite the source. The personal data included
            in the site may be used only for purposes related to the institutional activities,
            teaching and research. It’s banned the use of personal data for trade, marketing or
            advertising purposes in the absence of prior consent by the individual . By registering
            to the Forum Facility or the Networking Facility of the Knowledge Exchange Platform, you
            agree that we are sending you emails about the forum activity. Any violations will be
            reported to the competent authorities.
          </Text>
        </Box>
      </Box>
      {/* <Box style={{ bottom: 0, position: 'relative' }}>
      <Footer />
    </Box> */}
    </Box>
  </Footer>
)

export default TermsOfUse
