import colors from './colors'

const fontFamily = 'Ubuntu'

const spacing = {
  default: '12px',
  small: '8px',
  smaller: '4px',
  smallest: '2px',
  large: '16px',
  larger: '24px',
  largest: '32px',
  huge: '64px',
}

const theme = {
  colors,
  spacing,
  fontFamily,
}

export default theme
