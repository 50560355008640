import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { Text } from 'components'

function DropDown({ label, options, setvalue, display, marginRight, width, info }) {
  const margin = marginRight || 10
  const pickedWidth = width || 271
  return (
    <div style={{ marginBottom: 15, display }}>
      <div style={{ paddingTop: 5, marginRight: margin, marginBottom: 3 }}>
        <Text fontSize="17px"> {label}</Text>
        {info && <Text fontSize="15px">{info}</Text>}
      </div>
      <div style={{ height: 40, width: pickedWidth }}>
        <Select isClearable clearValue={null} options={options} onChange={o => setvalue(o)} />
      </div>
    </div>
  )
}

DropDown.propTypes = {
  setvalue: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  display: PropTypes.string,
  marginRight: PropTypes.number,
  width: PropTypes.number,
}

DropDown.defaultProps = {
  display: PropTypes.display,
  marginRight: PropTypes.marginRight,
  width: PropTypes.width,
  label: PropTypes.label,
}
export default DropDown
