/* eslint-disable camelcase */

import React, { useContext, useEffect } from 'react'
import Comment from '../../comps/Comment'
import ConnectedFields from '../../comps/ConnectedFields'
import FormikMuiRadio from '../../comps/FormikMuiRadio'
import Info from '../../comps/Info'
import Section from '../../comps/Section'
import { LangContext } from '../../const/Contexts'
import { occurrenceFrequencyKeys, occurrenceBotherKeys } from '../../const/ESQOptions'

export const NSFieldNames = [
  'fall_asleep_30_to_60',
  'fall_asleep_60_to_90',
  'fall_asleep_over_90',
  'fall_asleep_bother',
  'woke_up_1_to_2',
  'woke_up_2_to_4',
  'woke_up_over_4',
  'woke_up_bother',
  'fall_asleep_after_awake_30_to_60',
  'fall_asleep_after_awake_60_to_90',
  'fall_asleep_after_awake_over_90',
  'fall_asleep_after_awake_bother',
  'woke_up_early_30_to_60',
  'woke_up_early_60_to_90',
  'woke_up_early_over_90',
  'woke_up_early_bother',
  'felt_unrefreshed_30_to_60',
  'felt_unrefreshed_1_to_4',
  'felt_unrefreshed_over_4',
  'felt_unrefreshed_bother',
]

const NocturnalSleep = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  const { values, setFieldValue } = formikProps

  const {
    fall_asleep_30_to_60,
    fall_asleep_60_to_90,
    fall_asleep_over_90,
    fall_asleep_bother,
    woke_up_1_to_2,
    woke_up_2_to_4,
    woke_up_over_4,
    woke_up_bother,
    fall_asleep_after_awake_30_to_60,
    fall_asleep_after_awake_60_to_90,
    fall_asleep_after_awake_over_90,
    fall_asleep_after_awake_bother,
    woke_up_early_30_to_60,
    woke_up_early_60_to_90,
    woke_up_early_over_90,
    woke_up_early_bother,
    felt_unrefreshed_30_to_60,
    felt_unrefreshed_1_to_4,
    felt_unrefreshed_over_4,
    felt_unrefreshed_bother,
  } = values

  // Resetting 'Extra' fields when they switch back (ex. 'Other' boxes)
  useEffect(() => {
    if (
      (!fall_asleep_30_to_60 || fall_asleep_30_to_60 === 0) &&
      (!fall_asleep_60_to_90 || fall_asleep_60_to_90 === 0) &&
      (!fall_asleep_over_90 || fall_asleep_over_90 === 0)
    ) {
      if (fall_asleep_bother !== '') {
        setFieldValue('fall_asleep_bother', '')
      }
    }
  }, [fall_asleep_30_to_60, fall_asleep_60_to_90, fall_asleep_over_90])
  useEffect(() => {
    if (
      (!woke_up_1_to_2 || woke_up_1_to_2 === 0) &&
      (!woke_up_2_to_4 || woke_up_2_to_4 === 0) &&
      (!woke_up_over_4 || woke_up_over_4 === 0)
    ) {
      if (woke_up_bother !== '') {
        setFieldValue('woke_up_bother', '')
      }
      if (fall_asleep_after_awake_30_to_60 !== '') {
        setFieldValue('fall_asleep_after_awake_30_to_60', '')
      }
      if (fall_asleep_after_awake_60_to_90 !== '') {
        setFieldValue('fall_asleep_after_awake_60_to_90', '')
      }
      if (fall_asleep_after_awake_over_90 !== '') {
        setFieldValue('fall_asleep_after_awake_over_90', '')
      }
    }
  }, [woke_up_1_to_2, woke_up_2_to_4, woke_up_over_4])
  useEffect(() => {
    if (
      (!fall_asleep_after_awake_30_to_60 || fall_asleep_after_awake_30_to_60 === 0) &&
      (!fall_asleep_after_awake_60_to_90 || fall_asleep_after_awake_60_to_90 === 0) &&
      (!fall_asleep_after_awake_over_90 || fall_asleep_after_awake_over_90 === 0)
    ) {
      if (fall_asleep_after_awake_bother !== '') {
        setFieldValue('fall_asleep_after_awake_bother', '')
      }
    }
  }, [
    fall_asleep_after_awake_30_to_60,
    fall_asleep_after_awake_60_to_90,
    fall_asleep_after_awake_over_90,
  ])
  useEffect(() => {
    if (
      (!woke_up_early_30_to_60 || woke_up_early_30_to_60 === 0) &&
      (!woke_up_early_60_to_90 || woke_up_early_60_to_90 === 0) &&
      (!woke_up_early_over_90 || woke_up_early_over_90 === 0)
    ) {
      if (woke_up_early_bother !== '') {
        setFieldValue('woke_up_early_bother', '')
      }
    }
  }, [woke_up_early_30_to_60, woke_up_early_60_to_90, woke_up_early_over_90])
  useEffect(() => {
    if (
      (!felt_unrefreshed_30_to_60 || felt_unrefreshed_30_to_60 === 0) &&
      (!felt_unrefreshed_1_to_4 || felt_unrefreshed_1_to_4 === 0) &&
      (!felt_unrefreshed_over_4 || felt_unrefreshed_over_4 === 0)
    ) {
      if (felt_unrefreshed_bother !== '') {
        setFieldValue('felt_unrefreshed_bother', '')
      }
    }
  }, [felt_unrefreshed_30_to_60, felt_unrefreshed_1_to_4, felt_unrefreshed_over_4])

  return (
    <Section>
      <Info text={t('NocturalSleepinfo')}>
        <Comment text={t('NocturalSleepInfo')} />
      </Info>
      {/* Long time to fall asleep */}
      <ConnectedFields>
        <Comment text={t('columnName1NocturalSleep2')} />
        <FormikMuiRadio
          column
          label={t('NocturalSleep2statement1')}
          name="fall_asleep_30_to_60"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('NocturalSleep2statement2')}
          name="fall_asleep_60_to_90"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('NocturalSleep2statement3')}
          name="fall_asleep_over_90"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
      </ConnectedFields>
      {(fall_asleep_30_to_60 > 0 || fall_asleep_60_to_90 > 0 || fall_asleep_over_90 > 0) && (
        <ConnectedFields>
          <FormikMuiRadio
            column
            label={t('columnName2NocturalSleep2')}
            name="fall_asleep_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        </ConnectedFields>
      )}
      {/* Woke up at night */}
      <ConnectedFields>
        <Comment text={t('columnName1NocturalSleep3')} />
        <FormikMuiRadio
          column
          label={t('NocturalSleep3statement1')}
          name="woke_up_1_to_2"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('NocturalSleep3statement2')}
          name="woke_up_2_to_4"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('NocturalSleep3statement3')}
          name="woke_up_over_4"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
      </ConnectedFields>
      {(woke_up_1_to_2 > 0 || woke_up_2_to_4 > 0 || woke_up_over_4 > 0) && (
        <ConnectedFields>
          <FormikMuiRadio
            column
            label={t('columnName2NocturalSleep3')}
            name="woke_up_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        </ConnectedFields>
      )}
      {/* Long time to fall asleep after waking up at night */}
      {(woke_up_1_to_2 > 0 || woke_up_2_to_4 > 0 || woke_up_over_4 > 0) && (
        <>
          <ConnectedFields>
            <Comment text={t('columnName1NocturalSleep4')} />
            <FormikMuiRadio
              column
              label={t('NocturalSleep4statement1')}
              name="fall_asleep_after_awake_30_to_60"
              options={occurrenceFrequencyKeys.map(val => t(val))}
            />
            <FormikMuiRadio
              column
              label={t('NocturalSleep4statement2')}
              name="fall_asleep_after_awake_60_to_90"
              options={occurrenceFrequencyKeys.map(val => t(val))}
            />
            <FormikMuiRadio
              column
              label={t('NocturalSleep4statement3')}
              name="fall_asleep_after_awake_over_90"
              options={occurrenceFrequencyKeys.map(val => t(val))}
            />
          </ConnectedFields>
          {(fall_asleep_after_awake_30_to_60 > 0 ||
            fall_asleep_after_awake_60_to_90 > 0 ||
            fall_asleep_after_awake_over_90 > 0) && (
            <ConnectedFields>
              <FormikMuiRadio
                column
                label={t('columnName2NocturalSleep4')}
                name="fall_asleep_after_awake_bother"
                options={occurrenceBotherKeys.map(val => t(val))}
              />
            </ConnectedFields>
          )}
        </>
      )}
      {/* Woke up earlier than planned */}
      <ConnectedFields>
        <Comment text={t('columnName1NocturalSleep5')} />
        <FormikMuiRadio
          column
          label={t('NocturalSleep5statement1')}
          name="woke_up_early_30_to_60"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('NocturalSleep5statement2')}
          name="woke_up_early_60_to_90"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('NocturalSleep5statement3')}
          name="woke_up_early_over_90"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
      </ConnectedFields>
      {(woke_up_early_30_to_60 > 0 || woke_up_early_60_to_90 > 0 || woke_up_early_over_90 > 0) && (
        <ConnectedFields>
          <FormikMuiRadio
            column
            label={t('columnName2NocturalSleep5')}
            name="woke_up_early_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        </ConnectedFields>
      )}
      {/* Felt unrefreshed after getting up */}
      <ConnectedFields>
        <Comment text={t('columnName1NocturalSleep6')} />
        <FormikMuiRadio
          column
          label={t('NocturalSleep6statement1')}
          name="felt_unrefreshed_30_to_60"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('NocturalSleep6statement2')}
          name="felt_unrefreshed_1_to_4"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('NocturalSleep6statement3')}
          name="felt_unrefreshed_over_4"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
      </ConnectedFields>
      {(felt_unrefreshed_30_to_60 > 0 ||
        felt_unrefreshed_1_to_4 > 0 ||
        felt_unrefreshed_over_4 > 0) && (
        <ConnectedFields>
          <FormikMuiRadio
            column
            label={t('columnName2NocturalSleep6')}
            name="felt_unrefreshed_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        </ConnectedFields>
      )}
    </Section>
  )
}

export default NocturnalSleep
