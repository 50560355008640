import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TowerOfLondonIcon } from '../../../assets/icons'
import colors from '../../../utils/colors'
import { Box } from '../../atoms'
import { ActionButton } from '../../molecules'

const TowerOfLondon = () => {
  const { t } = useTranslation(['towerOfLondon'])
  const navigate = useNavigate()

  return (
    <Box width="380px">
      <ActionButton
        Icon={TowerOfLondonIcon}
        header={t('header')}
        description={t('description')}
        actionText={t('actionText')}
        color1={colors.towerOfLondon.left}
        color2={colors.towerOfLondon.right}
        onPress={() => navigate('/toweroflondon')}
      />
    </Box>
  )
}

export default TowerOfLondon
