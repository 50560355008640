import React, { useContext, useMemo } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Tooltip,
  Checkbox,
  ListItemText,
  Typography,
} from '@mui/material'
import { FastField, useField } from 'formik'
import { LangContext } from '../const/Contexts'
import { shouldUpdateFastField } from './FastFieldShouldUpdateOverride'

const FormikMuiSelect = ({ name, label, options, tooltips, multiple, ...props }) => {
  const { i18n } = useContext(LangContext)
  const [field, meta, helpers] = useField(name)
  const { setValue } = helpers
  const { error, touched } = meta

  const handleChange = event => {
    setValue(event.target.value)
  }

  const optionsValues = useMemo(
    () => options.map((o, idx) => ({ label: o, value: idx })),
    [options]
  )

  return (
    <FastField
      name={name}
      meta={{ lang: i18n.language, disabled: props.disabled || props.readOnly }}
      shouldUpdate={shouldUpdateFastField}
    >
      {() => (
        <FormControl fullWidth variant="filled" error={touched && !!error}>
          <InputLabel htmlFor={name}>
            <Typography>{label}</Typography>
          </InputLabel>
          <Select
            {...props}
            {...field}
            label={<Typography>{label}</Typography>}
            onChange={handleChange}
            inputProps={{
              id: name,
            }}
            MenuProps={{ disableScrollLock: true }}
            {...(multiple
              ? {
                  multiple,
                  value: field.value,
                  renderValue: selected => selected.map(i => optionsValues.at(i)?.label).join(', '),
                }
              : {})}
          >
            {optionsValues.map((option, idx) =>
              tooltips && idx < tooltips.length ? (
                <MenuItem key={option.value} value={option.value}>
                  <Tooltip key={option.value} title={tooltips[idx]} arrow>
                    <div style={{ width: '100%' }}>
                      {multiple ? (
                        <>
                          <Checkbox checked={field.value.includes(idx)} />
                          <ListItemText primary={option.label} />
                        </>
                      ) : (
                        option.label
                      )}
                    </div>
                  </Tooltip>
                </MenuItem>
              ) : (
                <MenuItem key={option.value} value={option.value}>
                  {multiple ? (
                    <>
                      <Checkbox checked={field.value.includes(idx)} />
                      <ListItemText primary={option.label} />
                    </>
                  ) : (
                    option.label
                  )}
                </MenuItem>
              )
            )}
          </Select>
          {touched && !!error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )}
    </FastField>
  )
}

export default FormikMuiSelect
