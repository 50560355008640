import React, { useState, useEffect } from 'react'
import { useMediaQuery } from '@mui/material'
import { Button, Text, Box } from 'components/atoms'
import colors from 'utils/colors'

const tinyScreenWidth = 1460

const Compare = ({ children }) => {
  const [comparing, setComparing] = useState(false)
  const tinyScreen = useMediaQuery(`(max-width: ${tinyScreenWidth}px)`)

  useEffect(() => {
    if (tinyScreen) {
      setComparing(false)
    }
  }, [tinyScreen])

  if (children?.length !== 2) return null
  return (
    <Box block cc>
      {!tinyScreen && (
        <Box width="160px" cc mb="20px">
          <Button
            width="100%"
            onPress={() => setComparing(!comparing)}
            color1={colors.greyDarker}
            color2={colors.greyDarker}
            pressed={comparing}
          >
            <Text center>{comparing ? 'Stop comparing' : 'Compare'}</Text>
          </Button>
        </Box>
      )}
      <div style={{ display: 'flex', width: '100%' }}>
        <div
          style={
            comparing
              ? {
                  flex: 1,
                  height: 'calc(100vh - 223px)',
                  overflowY: 'auto',
                  paddingRight: '8px',
                  width: '100%',
                }
              : { width: '100%' }
          }
        >
          {children[0]}
        </div>
        <div
          style={{
            display: comparing ? 'block' : 'none',
            flex: 1,
            height: 'calc(100vh - 223px)',
            overflowY: 'auto',
            paddingLeft: '8px',
            width: '100%',
          }}
        >
          {children[1]}
        </div>
      </div>
    </Box>
  )
}

export default Compare
