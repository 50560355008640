import { ApolloClient, gql, split, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
// import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import appConfig from 'config'
import moment from 'moment'
// import { SubscriptionClient } from 'subscriptions-transport-ws'
// eslint-disable-next-line import/no-extraneous-dependencies
import { setToken, setExpiry, getToken, getRefreshToken, setRefreshToken } from './utils/helper'

const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      success
      refreshToken
    }
  }
`

const cache = new InMemoryCache()

const refreshTokens = (uri, options) => {
  const refreshToken = localStorage.getItem('refreshToken')

  return client
    .mutate({ mutation: REFRESH_TOKEN, variables: { refreshToken } })
    .then(({ data }) => {
      if (data?.refreshToken?.success) {
        const newRefreshToken = data.refreshToken.refreshToken
        const newToken = data.refreshToken.token

        setRefreshToken(newRefreshToken)
        setToken(newToken)
        setExpiry(moment().add(7, 'days').subtract(5, 'minutes'))
        /* eslint-enable no-param-reassign */

        return fetch(uri, {
          ...options,
          refreshingPromise: true,
          headers: { ...options.headers, authorization: `JWT ${newToken}` },
        })
      }
      return {}
    })
}

const customFetch = (uri, options) => {
  // Create initial fetch, this is what would normally be executed in the link without the override
  const initialRequest = fetch(uri, options)

  // The apolloHttpLink expects that whatever fetch function is used, it returns a promise.
  // Here we return the initialRequest promise
  return initialRequest
    .then(response => response.json())
    .then(json => {
      if (
        (json?.errors?.[0]?.message === 'You do not have permission to perform this action' ||
          json?.errors?.[0]?.message === 'Signature has expired' ||
          json?.errors?.[0]?.message === 'Error decoding signature') &&
        !options.refreshingPromise
      ) {
        return refreshTokens(uri, options)
      }
      const result = {}
      result.ok = true
      result.json = () =>
        new Promise(resolve => {
          resolve(json)
        })
      result.text = () =>
        new Promise(resolve => {
          resolve(JSON.stringify(json))
        })
      return result
    })
}

const httpLink = createHttpLink({
  uri: appConfig.graphqlConfig.url,
  credentials: 'include',
  fetch: customFetch,
})

// const wsLink = new WebSocketLink(new SubscriptionClient(appConfig.graphqlConfig.ws))

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  }
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only', // 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query)
//     return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
//   },
//   wsLink,
//   authLink.concat(httpLink)
// )

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  defaultOptions,
})

export { cache }
export default client
