/* eslint-disable camelcase */

import React, { useContext, useEffect } from 'react'
import Comment from '../../../comps/Comment'
import ConnectedFields from '../../../comps/ConnectedFields'
import FormikMuiRadio from '../../../comps/FormikMuiRadio'
import { LangContext } from '../../../const/Contexts'
import { occurrenceFrequencyKeys, occurrenceBotherKeys } from '../../../const/ESQOptions'

const DFSmallScales = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  const { values, setFieldValue } = formikProps

  const {
    fell_unintentionally_asleep_passive,
    fell_unintentionally_asleep_moderate,
    fell_unintentionally_asleep_strong,
    fell_unintentionally_asleep_bother,
    effort_to_stay_awake,
    special_measures_to_stay_awake,
    activities_without_awareness,
    fight_sleepy_bother,
    overslept_with_alarm,
    overslept_with_alarm_bother,
    lack_of_energy_leisure_start,
    lack_of_energy_duty_start,
    lack_of_energy_any_start,
    lack_of_energy_bother,
    exhausted_activity_strong,
    exhausted_activity_moderate,
    exhausted_activity_passive,
    exhausted_activity_bother,
    exhausted_physical_activity_vigorous,
    exhausted_physical_activity_moderate,
    exhausted_physical_activity_light,
    exhausted_physical_activity_bother,
  } = values

  // Resetting 'Extra' fields when they switch back (ex. 'Other' boxes)
  useEffect(() => {
    if (
      (!fell_unintentionally_asleep_passive || fell_unintentionally_asleep_passive === 0) &&
      (!fell_unintentionally_asleep_moderate || fell_unintentionally_asleep_moderate === 0) &&
      (!fell_unintentionally_asleep_strong || fell_unintentionally_asleep_strong === 0)
    ) {
      if (fell_unintentionally_asleep_bother !== '') {
        setFieldValue('fell_unintentionally_asleep_bother', '')
      }
    }
  }, [
    fell_unintentionally_asleep_passive,
    fell_unintentionally_asleep_moderate,
    fell_unintentionally_asleep_strong,
  ])
  useEffect(() => {
    if (
      (!effort_to_stay_awake || effort_to_stay_awake === 0) &&
      (!special_measures_to_stay_awake || special_measures_to_stay_awake === 0) &&
      (!activities_without_awareness || activities_without_awareness === 0)
    ) {
      if (fight_sleepy_bother !== '') {
        setFieldValue('fight_sleepy_bother', '')
      }
    }
  }, [effort_to_stay_awake, special_measures_to_stay_awake, activities_without_awareness])
  useEffect(() => {
    if (!overslept_with_alarm || overslept_with_alarm === 0) {
      if (overslept_with_alarm_bother !== '') {
        setFieldValue('overslept_with_alarm_bother', '')
      }
    }
  }, [overslept_with_alarm])
  useEffect(() => {
    if (
      (!lack_of_energy_leisure_start || lack_of_energy_leisure_start === 0) &&
      (!lack_of_energy_duty_start || lack_of_energy_duty_start === 0) &&
      (!lack_of_energy_any_start || lack_of_energy_any_start === 0)
    ) {
      if (lack_of_energy_bother !== '') {
        setFieldValue('lack_of_energy_bother', '')
      }
    }
  }, [lack_of_energy_leisure_start, lack_of_energy_duty_start, lack_of_energy_any_start])
  useEffect(() => {
    if (
      (!exhausted_activity_strong || exhausted_activity_strong === 0) &&
      (!exhausted_activity_moderate || exhausted_activity_moderate === 0) &&
      (!exhausted_activity_passive || exhausted_activity_passive === 0)
    ) {
      if (exhausted_activity_bother !== '') {
        setFieldValue('exhausted_activity_bother', '')
      }
    }
  }, [exhausted_activity_strong, exhausted_activity_moderate, exhausted_activity_passive])
  useEffect(() => {
    if (
      (!exhausted_physical_activity_vigorous || exhausted_physical_activity_vigorous === 0) &&
      (!exhausted_physical_activity_moderate || exhausted_physical_activity_moderate === 0) &&
      (!exhausted_physical_activity_light || exhausted_physical_activity_light === 0)
    ) {
      if (exhausted_physical_activity_bother !== '') {
        setFieldValue('exhausted_physical_activity_bother', '')
      }
    }
  }, [
    exhausted_physical_activity_vigorous,
    exhausted_physical_activity_moderate,
    exhausted_physical_activity_light,
  ])

  return (
    <>
      {/* Fell asleep during activities */}
      <ConnectedFields>
        <Comment text={t('columnName1DaytimeFunctioning2')} />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning2statement1')}
          name="fell_unintentionally_asleep_passive"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning2statement2')}
          name="fell_unintentionally_asleep_moderate"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning2statement3')}
          name="fell_unintentionally_asleep_strong"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
      </ConnectedFields>
      {(fell_unintentionally_asleep_passive > 0 ||
        fell_unintentionally_asleep_moderate > 0 ||
        fell_unintentionally_asleep_strong > 0) && (
        <ConnectedFields>
          <FormikMuiRadio
            column
            label={t('columnName2DaytimeFunctioning2')}
            name="fell_unintentionally_asleep_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        </ConnectedFields>
      )}
      {/* Felt sleepy had to fight sleep */}
      <ConnectedFields>
        <Comment text={t('columnName1DaytimeFunctioning3')} />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning3statement1')}
          name="effort_to_stay_awake"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning3statement2')}
          name="special_measures_to_stay_awake"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning3statement3')}
          name="activities_without_awareness"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
      </ConnectedFields>
      {(effort_to_stay_awake > 0 ||
        special_measures_to_stay_awake > 0 ||
        activities_without_awareness > 0) && (
        <ConnectedFields>
          <FormikMuiRadio
            column
            label={t('columnName2DaytimeFunctioning3')}
            name="fight_sleepy_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        </ConnectedFields>
      )}
      {/* Overslept */}
      <ConnectedFields>
        <Comment text={t('columnName1DaytimeFunctioning4')} />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning4statement1')}
          name="overslept_with_alarm"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
      </ConnectedFields>
      {overslept_with_alarm > 0 && (
        <ConnectedFields>
          <FormikMuiRadio
            column
            label={t('columnName2DaytimeFunctioning4')}
            name="overslept_with_alarm_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        </ConnectedFields>
      )}
      {/* Lack of energy to start activities */}
      <ConnectedFields>
        <Comment text={t('columnName1DaytimeFunctioning5')} />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning5statement1')}
          name="lack_of_energy_leisure_start"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning5statement2')}
          name="lack_of_energy_duty_start"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning5statement3')}
          name="lack_of_energy_any_start"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
      </ConnectedFields>
      {(lack_of_energy_leisure_start > 0 ||
        lack_of_energy_duty_start > 0 ||
        lack_of_energy_any_start > 0) && (
        <ConnectedFields>
          <FormikMuiRadio
            column
            label={t('columnName2DaytimeFunctioning5')}
            name="lack_of_energy_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        </ConnectedFields>
      )}
      {/* Exhausted after activities */}
      <ConnectedFields>
        <Comment text={t('columnName1DaytimeFunctioning6')} />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning6statement1')}
          name="exhausted_activity_strong"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning6statement2')}
          name="exhausted_activity_moderate"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning6statement3')}
          name="exhausted_activity_passive"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
      </ConnectedFields>
      {(exhausted_activity_strong > 0 ||
        exhausted_activity_moderate > 0 ||
        exhausted_activity_passive > 0) && (
        <ConnectedFields>
          <FormikMuiRadio
            column
            label={t('columnName2DaytimeFunctioning6')}
            name="exhausted_activity_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        </ConnectedFields>
      )}
      {/* Exhausted after physical activities */}
      <ConnectedFields>
        <Comment text={t('columnName1DaytimeFunctioning7')} />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning7statement1')}
          name="exhausted_physical_activity_vigorous"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning7statement2')}
          name="exhausted_physical_activity_moderate"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('DaytimeFunctioning7statement3')}
          name="exhausted_physical_activity_light"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
      </ConnectedFields>
      {(exhausted_physical_activity_vigorous > 0 ||
        exhausted_physical_activity_moderate > 0 ||
        exhausted_physical_activity_light > 0) && (
        <ConnectedFields>
          <FormikMuiRadio
            column
            label={t('columnName2DaytimeFunctioning7')}
            name="exhausted_physical_activity_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        </ConnectedFields>
      )}
    </>
  )
}

export default DFSmallScales
