import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useTimer } from 'react-timer-hook'
import PropTypes from 'prop-types'
import { Box, Instructions, Modal, ReactionTaskPattern, Text } from 'components'
import { random } from 'lodash'
import moment from 'moment'
import { useWindowDimensions } from 'utils'
import theme from 'utils/theme'

const ReactionTask = ({ timeInMinutes, onFinish }) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation(['reactionTask'])

  const [results, setResults] = useState([{ testName: 'Reaction Task' }])

  const { height, width } = useWindowDimensions()

  const [started, setStarted] = useState(false)
  const [shownAt, setShownAt] = useState(null)
  const [reactedAt, setReactedAt] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const [finished, setFinished] = useState(false)

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('lang')))
  }, [started])

  const taskTimer = useTimer({
    expiryTimestamp: moment().add(1, 'minutes').toDate(),
    autoStart: false,
    onExpire: () => setFinished(true),
  })

  const patternTimer = useTimer({
    expiryTimestamp: moment().add(random(3, 10), 'seconds').toDate(),
    autoStart: false,
    onExpire: () => {
      setIsVisible(true)
      setShownAt(moment().toLocaleString())
    },
  })

  useEffect(() => {
    if (started) {
      taskTimer.start()
      patternTimer.start()
    }
  }, [started])

  useEffect(() => {
    if (reactedAt !== null) {
      setResults([...results, { shownAt, reactedAt }])
      patternTimer.restart(moment().add(random(3, 10), 'seconds').toDate())
    }
  }, [reactedAt])

  useEffect(() => {
    if (finished) {
      onFinish(results)
      navigate({ pathname: '/results', state: { results } })
    }
  }, [finished])

  return (
    <Box fullscreen bg={theme.colors.greyDark}>
      <Modal isOpen={!started} onClose={() => setStarted(true)} close closeText="Start Test">
        <Text big>Reaction Task - Instructions</Text>
        <Instructions body={t('instructions')} />
      </Modal>
      <ReactionTaskPattern
        width={width}
        height={height}
        isVisible={isVisible}
        timeInMinutes={timeInMinutes}
        onPress={() => {
          setReactedAt(moment().toLocaleString())
          setIsVisible(false)
        }}
      />
    </Box>
  )
}

ReactionTask.propTypes = {
  timeInMinutes: PropTypes.number,
  onFinish: PropTypes.func,
}

ReactionTask.defaultProps = {
  timeInMinutes: 1,
  onFinish: () => {},
}

export default ReactionTask
