import { Tooltip } from 'recharts'
import { chartSettings } from '../const/chartSettings'

const getVal = (bother, value) => {
  if (bother) {
    return chartSettings.botherLevels[value + 1]
  }
  return chartSettings.freqLevels.at(value) || 'N/A'
}

const RCTooltip = (params = {}) => {
  const { bother, position } = params
  return (
    <Tooltip
      {...(position !== undefined ? { position } : {})}
      contentStyle={{ backgroundColor: chartSettings.tooltipBackground }}
      labelStyle={{ textDecorationLine: 'underline', color: chartSettings.textColor }}
      isAnimationActive={false}
      formatter={(value, name) => [getVal(bother, value), name === 'base' ? 'Ba' : 'Fo']}
      labelFormatter={value => {
        if (typeof value === 'string' && value.length >= 8) {
          return `${value.slice(0, 8)}... ${bother ? '(B)' : '(F)'}`
        }
        return value
      }}
    />
  )
}

export default RCTooltip
