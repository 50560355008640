import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import theme from 'utils/theme'

const StyledButton = styled.button(
  ({ color1, color2, block, circle, width, center, maxWidth, padding }) => css`
    background-color: transparent;
    text-decoration: none;
    border: none;
    padding: 5px;
    border-radius: 7px;
    cursor: pointer;
    max-width: 320px;
    align-self: ${center ? 'center' : 'left'};

    ${width &&
    css`
      width: ${width};
    `}

    ${block &&
    css`
      width: 100%;
    `}

    background: linear-gradient(to bottom right, ${color1}, ${color2 || theme.colors.button.right});

    ${circle &&
    css`
      height: 50px;
      width: 50px;
      border-radius: 25px;
    `}

    ${maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

    ${padding &&
    css`
      padding: ${padding};
    `}
  `
)

const Button = ({
  children,
  color1,
  color2,
  block,
  circle,
  onPress,
  width,
  center,
  maxWidth,
  padding,
}) => (
  <StyledButton
    onClick={onPress}
    color1={color1}
    color2={color2}
    block={block}
    circle={circle}
    width={width}
    center={center}
    maxWidth={maxWidth}
    padding={padding}
  >
    {children}
  </StyledButton>
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color1: PropTypes.string,
  color2: PropTypes.string,
  block: PropTypes.bool,
  circle: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  padding: PropTypes.string,
}

Button.defaultProps = {
  color1: theme.colors.button.left,
  color2: theme.colors.button.right,
  block: false,
  circle: false,
  width: 'auto',
  maxWidth: '320px',
}

export default Button
