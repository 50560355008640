import React from 'react'
import { Box, Text } from 'components/atoms'
import { chartSettings } from '../const/chartSettings'

const ColoredScale = ({ bother, frequency }) => {
  if (!bother && !frequency) {
    return null
  }
  return (
    <Box>
      {bother &&
        chartSettings.botherLevels
          .map((bl, index) => (
            <Box key={bl} row>
              <Box
                round
                width="16px"
                height="16px"
                backgroundColor={chartSettings.severityColors[index - 1] ?? chartSettings.textColor}
                mt="4px"
                mr="5px"
              />
              <Text>{bl}</Text>
            </Box>
          ))
          .reverse()}
      {frequency &&
        chartSettings.freqLevels
          .map((fl, index) => (
            <Box key={fl} row>
              <Box
                round
                width="16px"
                height="16px"
                backgroundColor={chartSettings.severityColors[index] ?? chartSettings.textColor}
                mt="4px"
                mr="5px"
              />
              <Text>{fl}</Text>
            </Box>
          ))
          .reverse()}
    </Box>
  )
}

const LinesLegend = () => (
  <Box col mb="5px">
    <Box row cc justifyContent="start">
      <div
        style={{
          width: '50px',
          height: '0px',
          borderBottom: `2px solid ${chartSettings.textColor}`,
          marginRight: '12px',
          flexShrink: 0,
        }}
      />
      <Text center color={chartSettings.textColor}>
        Baseline (Ba)
      </Text>
    </Box>
    <Box row cc justifyContent="start">
      <div
        style={{
          width: '50px',
          height: '0px',
          borderBottom: `2px dashed ${chartSettings.textColor}`,
          marginRight: '12px',
          flexShrink: 0,
        }}
      />
      <Text center color={chartSettings.textColor}>
        Follow-up (Fo)
      </Text>
    </Box>
  </Box>
)

const ESQVLegend = ({ bother, frequency }) => {
  if (!bother && !frequency) {
    return null
  }
  return (
    <Box jc="center" style={{ overflowX: 'auto' }}>
      <Box col ml="20px" mr="10px" width="13rem">
        {bother && (
          <Box>
            <Text big>Bothersomeness (B)</Text>
            <LinesLegend />
            <ColoredScale bother />
          </Box>
        )}
        {bother && frequency && <Box mb="20px" />}
        {frequency && (
          <Box>
            <Text big>Frequency (F)</Text>
            <LinesLegend />
            <ColoredScale frequency />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ESQVLegend
