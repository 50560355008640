// for converting multiple choice questions to database format
// make sure to change both this object and the reverse one below
export const EntryMultiChoiceNameMappings = {
  occupation: [
    'occupation_employed',
    'occupation_self_employed',
    'occupation_student',
    'occupation_volunteering',
    'occupation_caretaker',
    'occupation_retired',
    'occupation_sick_leave',
    'occupation_disability',
  ],
  living_arrangement_together: [
    'living_arrangement_with_partner',
    'living_arrangement_with_children',
    'living_arrangement_with_family',
    'living_arrangement_with_others',
    'living_arrangement_with_pet',
  ],
  driving_license_category: ['driving_license_category_1', 'driving_license_category_2'],
  smoking_category: [
    'smoking_category_cigarettes',
    'smoking_category_cigars',
    'smoking_category_chewing_tobacco',
    'smoking_category_pipe',
    'smoking_category_vape',
  ],
  caffeine_category: [
    'caffeine_category_coffee',
    'caffeine_category_tea',
    'caffeine_category_energy_drinks',
    'caffeine_category_other',
  ],
  stress: [
    'stress_none',
    'stress_health',
    'stress_relationship_family',
    'stress_job_school',
    'stress_financial',
    'stress_other',
  ],
  treated_by: [
    'treated_by_family_doctor',
    'treated_by_specialist_medical_doctor',
    'treated_by_non_medical_practitioner',
  ],
  activities_before_sleep: [
    'activities_before_sleep_none',
    'activities_before_sleep_reading',
    'activities_before_sleep_listening',
    'activities_before_sleep_meditating',
    'activities_before_sleep_watching_tv',
    'activities_before_sleep_using_device',
    'activities_before_sleep_other',
  ],
}

export const ReverseEntryMultiChoiceNameMappings = {
  occupation_employed: { field: 'occupation', idx: 0 },
  occupation_self_employed: { field: 'occupation', idx: 1 },
  occupation_student: { field: 'occupation', idx: 2 },
  occupation_volunteering: { field: 'occupation', idx: 3 },
  occupation_caretaker: { field: 'occupation', idx: 4 },
  occupation_retired: { field: 'occupation', idx: 5 },
  occupation_sick_leave: { field: 'occupation', idx: 6 },
  occupation_disability: { field: 'occupation', idx: 7 },

  living_arrangement_with_partner: { field: 'living_arrangement_together', idx: 0 },
  living_arrangement_with_children: { field: 'living_arrangement_together', idx: 1 },
  living_arrangement_with_family: { field: 'living_arrangement_together', idx: 2 },
  living_arrangement_with_others: { field: 'living_arrangement_together', idx: 3 },
  living_arrangement_with_pet: { field: 'living_arrangement_together', idx: 4 },

  driving_license_category_1: { field: 'driving_license_category', idx: 0 },
  driving_license_category_2: { field: 'driving_license_category', idx: 1 },

  smoking_category_cigarettes: { field: 'smoking_category', idx: 0 },
  smoking_category_cigars: { field: 'smoking_category', idx: 1 },
  smoking_category_chewing_tobacco: { field: 'smoking_category', idx: 2 },
  smoking_category_pipe: { field: 'smoking_category', idx: 3 },
  smoking_category_vape: { field: 'smoking_category', idx: 4 },

  caffeine_category_coffee: { field: 'caffeine_category', idx: 0 },
  caffeine_category_tea: { field: 'caffeine_category', idx: 1 },
  caffeine_category_energy_drinks: { field: 'caffeine_category', idx: 2 },
  caffeine_category_other: { field: 'caffeine_category', idx: 3 },

  stress_none: { field: 'stress', idx: 0 },
  stress_health: { field: 'stress', idx: 1 },
  stress_relationship_family: { field: 'stress', idx: 2 },
  stress_job_school: { field: 'stress', idx: 3 },
  stress_financial: { field: 'stress', idx: 4 },
  stress_other: { field: 'stress', idx: 5 },

  treated_by_family_doctor: { field: 'treated_by', idx: 0 },
  treated_by_specialist_medical_doctor: { field: 'treated_by', idx: 1 },
  treated_by_non_medical_practitioner: { field: 'treated_by', idx: 2 },

  activities_before_sleep_none: { field: 'activities_before_sleep', idx: 0 },
  activities_before_sleep_reading: { field: 'activities_before_sleep', idx: 1 },
  activities_before_sleep_listening: { field: 'activities_before_sleep', idx: 2 },
  activities_before_sleep_meditating: { field: 'activities_before_sleep', idx: 3 },
  activities_before_sleep_watching_tv: { field: 'activities_before_sleep', idx: 4 },
  activities_before_sleep_using_device: { field: 'activities_before_sleep', idx: 5 },
  activities_before_sleep_other: { field: 'activities_before_sleep', idx: 6 },
}

/*
export const ReverseEntryMultiChoiceNameMappings = () => {
  const reversed = {}
  Object.keys(EntryMultiChoiceNameMappings).forEach(key => {
    const value = EntryMultiChoiceNameMappings[key]
    value.forEach((field, idx) => {
      reversed[field] = { field: key, idx }
    })
  })
  return reversed
}
*/
