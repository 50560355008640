import React, { useState, useMemo, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ScopedCssBaseline, useMediaQuery } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { ColorModeContext } from 'components/ESQ/A_ESQ_REMAKE/const/Contexts'
import colors from 'utils/colors'
import { Box } from '../../components'
import ESQuestionnaire from '../../components/ESQ/A_ESQ_REMAKE/ESQuestionnaire'

const baseTheme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primaryColor, // Change this to the desired background color
        },
      },
    },
  },
})

const EsqTest = ({ secondRound }) => {
  const { state } = useLocation()
  const [darkMode, setDarkMode] = useState(true)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const navigate = useNavigate()

  useEffect(() => {
    setDarkMode(prefersDarkMode)
  }, [setDarkMode, prefersDarkMode])

  const theme = useMemo(
    () =>
      createTheme(baseTheme, {
        palette: {
          mode: darkMode ? 'dark' : 'light',
          text: {
            primary: darkMode ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)',
            secondary: darkMode ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)',
          },
          background: {
            default: darkMode ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)',
            paper: darkMode ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)',
          },
          action: {
            active: darkMode ? 'rgba(255, 255, 255,0.54)' : 'rgba(0,0,0,0.54)',
            disabled: darkMode ? 'rgba(255, 255, 255,0.26)' : 'rgba(0,0,0,0.26)',
          },
        },
      }),
    [darkMode]
  )

  // prevent users from going directly to /esq or /esqFollowup urls when they
  // have already completed them
  if (!state?.clickedButton) {
    navigate('/myPage', { replace: true })
  }

  return (
    <ColorModeContext.Provider value={() => setDarkMode(!darkMode)}>
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <Box bg={darkMode ? colors.greyDark : colors.light} fullscreen cc p="10px" pb="100px">
            <ESQuestionnaire secondRound={secondRound} esqBaseDoneAt={state?.esqBaseDoneAt} />
          </Box>
        </ScopedCssBaseline>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default EsqTest
