const ENTRY_TYPE_MAP = {
  TX: 'textAnswer',
  NR: 'numberAnswer',
  DF: 'datefieldAnswer',
  TF: 'timefieldAnswer',
  DT: 'datetimefieldAnswer',
  SO: 'textAnswer',
}

export const processEntryResults = entryResults =>
  entryResults.map(entryResult => ({
    title: entryResult?.entryId?.title,
    value: entryResult?.[ENTRY_TYPE_MAP[entryResult?.entryId?.entryType]],
  }))
