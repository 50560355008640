import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { gql, useLazyQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { Formik } from 'formik'
import moment from 'moment'
import { LangContext } from './const/Contexts'
import {
  EntryMultiChoiceNameMappings,
  ReverseEntryMultiChoiceNameMappings,
} from './const/EntryMultiChoiceNameMappings'
import FormikInitialValues from './const/FormikInitialValues'
import FormikValidationSchema from './const/FormikValidationSchema'
import formSections from './const/FormSections'
import ESQManager from './ESQManager'

// How old the most recent formresult is allowed to be
// so that we update it rather than create a new one.
// Also applies to fetching data to fill saved form fields.
const ESQResetIntervalDays = 89 // 90 - 1 because else follow up might overwrite base

const GET_FORM_RESULTS = gql`
  query dobbyFormResults(
    $studyTitle: String!
    $formTitlesEntryTitles: [DobbyGetFormResultsInputType!]!
    $maxAgeDays: Int!
  ) {
    dobbyFormResults(
      studyTitle: $studyTitle
      formTitlesEntryTitles: $formTitlesEntryTitles
      maxAgeDays: $maxAgeDays
    ) {
      formTitle
      entryResults {
        title
        value
        isNumber
      }
    }
  }
`

const GET_SET_OF_FORM_RESULTS = gql`
  query getSetOfFormResults($formTitles: [String!]!, $date: Date!, $participationId: ID) {
    getSetOfFormResults(formTitles: $formTitles, date: $date, participationId: $participationId) {
      id
      formTitle
      entryResults {
        entryId {
          title
          description
          entryType
          selection
        }
        numberAnswer
        textAnswer
        selectAnswer
        datefieldAnswer
        datetimefieldAnswer
        timefieldAnswer
      }
    }
  }
`

const ESQuestionnaire = ({ secondRound, esqBaseDoneAt }) => {
  const { t, i18n } = useTranslation(['esq'])
  const [getFormResults, { data, loading, error }] = useLazyQuery(GET_FORM_RESULTS)
  const [getHelpRequestER, { data: HRData, loading: HRLoading, error: HRError }] =
    useLazyQuery(GET_SET_OF_FORM_RESULTS)
  const [initValues, setInitValues] = useState(null)
  // const [FRDone, setFRDone] = useState(false)
  // const [HRDone, setHRDone] = useState(!!!secondRound)
  const [followUpProblemDesc, setFollowUpProblemDesc] = useState(null)

  useEffect(() => {
    console.log('ESQ fetching saved form data')
    const formTitlesEntryTitles = []
    formSections.forEach(({ formTitle, fieldNames }) => {
      if (formTitle) {
        const currentFTET = { formTitle, entryTitles: [] }
        fieldNames.forEach(fn => {
          const mappedFns = EntryMultiChoiceNameMappings[fn]
          if (mappedFns) {
            mappedFns.forEach(mappedFn => {
              currentFTET.entryTitles.push(mappedFn)
            })
          } else {
            currentFTET.entryTitles.push(fn)
          }
        })
        formTitlesEntryTitles.push(currentFTET)
      }
    })
    getFormResults({
      variables: {
        studyTitle: 'ESADA Study',
        formTitlesEntryTitles,
        maxAgeDays: ESQResetIntervalDays,
      },
    })
  }, [getFormResults])

  useEffect(() => {
    if (secondRound && esqBaseDoneAt) {
      getHelpRequestER({
        variables: {
          formTitles: ['ESQ My Help Request'],
          date: moment(esqBaseDoneAt).format('YYYY-MM-DD'),
        },
      })
    }
  }, [secondRound, esqBaseDoneAt])

  useEffect(() => {
    if (HRData?.getSetOfFormResults) {
      const val = HRData.getSetOfFormResults[0]?.entryResults?.find(
        er => er.entryId.title === 'sleep_daytime_problem_description'
      )?.textAnswer
      if (val) {
        setFollowUpProblemDesc(val)
      }
    }
  }, [HRData, setFollowUpProblemDesc])

  // useEffect(() => {
  //   if (secondRound) {
  //     getHelpRequestER({
  //       variables: {
  //         studyTitle: 'ESADA Study',
  //         formTitlesEntryTitles: [
  //           {
  //             formTitle: 'ESQ My Help Request',
  //             entryTitles: ['sleep_daytime_problem_description'],
  //           },
  //         ],
  //         maxAgeDays: 9999,
  //       },
  //     })
  //   }
  // }, [getHelpRequestER])

  useEffect(() => {
    if (data && !loading && !error) {
      const fetchedValues = {}
      data.dobbyFormResults?.forEach(({ formTitle, entryResults }) => {
        if (formTitle === 'ESQ General Information') {
          fetchedValues.__female_disabled__ = true
        }
        entryResults?.forEach(({ title, value, isNumber }) => {
          if (title === 'is_pregnant') {
            fetchedValues.__female_disabled__ = false
          }
          const val = isNumber ? Number(value) : value
          const mappedFn = ReverseEntryMultiChoiceNameMappings[title]
          if (mappedFn) {
            if (!fetchedValues[mappedFn.field]) {
              fetchedValues[mappedFn.field] = []
            }
            if (val === 1) {
              fetchedValues[mappedFn.field].push(mappedFn.idx)
            }
          } else if (title === 'caffeine_intake' && val === 2) {
            /* No translations for caffeine_intake so displaying it as 'No'/'Yes',
            but transforming it to 'I never drink'/'I currently drink' */
            fetchedValues[title] = 1
          } else {
            fetchedValues[title] = val
          }
        })
      })
      setInitValues({
        ...FormikInitialValues,
        ...fetchedValues,
        __isSecondRound__: !!secondRound,
      })
      // setFRDone(true)
    }
    if (error) {
      console.log(error)
    }
  }, [data, loading, error])

  useEffect(() => {
    // if (HRData && !HRLoading && !HRError) {
    //   setHRDone(true)
    // }
    if (HRError) {
      console.log(HRError)
    }
  }, [HRData, HRLoading, HRError])

  if (secondRound && !HRLoading && !HRError && HRData) {
    // if (!(HRData.getSetOfFormResults?.length > 0)) {
    if (!followUpProblemDesc) {
      return (
        <div style={{ position: 'fixed', top: '50%' }}>
          <h3>No base ESQ data found for this user.</h3>
        </div>
      )
    }
  }

  if (!initValues || loading || (secondRound && (HRLoading || !HRData))) {
    return (
      <div style={{ position: 'fixed', top: '50%' }}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <LangContext.Provider value={{ t, i18n }}>
      <Formik
        initialValues={
          secondRound
            ? {
                ...initValues,
                sleep_daytime_problem_description: followUpProblemDesc,
              }
            : initValues
        }
        validationSchema={FormikValidationSchema}
        onSubmit={async values => {
          console.log(values)
        }}
        validateOnMount
      >
        {formikProps => (
          <ESQManager formikProps={formikProps} ESQResetIntervalDays={ESQResetIntervalDays} />
        )}
      </Formik>
    </LangContext.Provider>
  )
}

export default ESQuestionnaire
