import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Clickable, Text } from '..'
import colors from '../../../utils/colors'

const inactiveColor = colors.greyMid
const badgeSize = '1.4rem'

const Badge = ({ color, isActive, bigLetter, label, onPress }) => {
  const hasLabel = useMemo(() => label !== '', [label])

  return (
    // container
    <Clickable onPress={onPress} disabled={!hasLabel}>
      <Box flexDirection="row" cc>
        {/* badge */}
        <Box
          round
          bg={isActive ? color : colors.white}
          mr={hasLabel ? '4px' : '0'}
          height={badgeSize}
          width={badgeSize}
          justifyContent="center"
          cc
          style={{
            border: isActive ? 'none' : `1px solid ${inactiveColor}`,
            boxSizing: 'border-box',
          }}
        >
          <Text center fontSize="0.7rem" bold color={isActive ? colors.white : inactiveColor}>
            {bigLetter}
          </Text>
        </Box>

        {/* badge label */}
        {hasLabel ? (
          <Box>
            <Text fontSize="1rem" bold color={isActive ? color : inactiveColor} lineHeight="1rem">
              {label}
            </Text>
          </Box>
        ) : null}
      </Box>
    </Clickable>
  )
}

Badge.propTypes = {
  color: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  bigLetter: PropTypes.string.isRequired,
  label: PropTypes.string,
  onPress: PropTypes.func,
}

Badge.defaultProps = {
  label: '',
  onPress: () => {},
}

export default Badge
