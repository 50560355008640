import React from 'react'
import PropTypes from 'prop-types'
import theme from 'utils/theme'
import { Button, Box, Text } from '../../atoms'

const InputCounter = ({ onPressPlus, onPressMinus, counterName, defaultValue }) => (
  <Box centerContent round bg={theme.colors.grey} flexWrap row spaceBetween pv="21px" ph="26px">
    <Box>
      <Text>{counterName}</Text>
    </Box>
    <Box row>
      <Button onPress={onPressMinus} width="30">
        <Text center>-</Text>
      </Button>
      <Box jc rounded bg={theme.colors.greyDark} width="50px" m="4px">
        <Text block center>
          {defaultValue}
        </Text>
      </Box>
      <Button onPress={onPressPlus} width="30">
        <Text center>+</Text>
      </Button>
    </Box>
  </Box>
)

InputCounter.propTypes = {
  onPressPlus: PropTypes.func.isRequired,
  onPressMinus: PropTypes.func.isRequired,
  counterName: PropTypes.string,
  defaultValue: PropTypes.number.isRequired,
}

InputCounter.defaultProps = {
  counterName: 'Counter',
}

export default InputCounter
