import React from 'react'
import { CircularProgress } from '@mui/material'
import { Box } from '../..'

const Loading = () => (
  <Box cc mt="80px">
    <CircularProgress />
  </Box>
)

export default Loading
