import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Text } from '../../atoms'
import { Instructions } from '../../molecules'

const TaskInstructions = () => {
  const { t } = useTranslation(['auditoryVerbalLearning'])

  return (
    <Box>
      <Text big color="white">
        {t('header')} - {t('instructionsHeader')}
      </Text>
      <Instructions body={t('finalInstructions')} />
      <Instructions body={t('instructions')} />
    </Box>
  )
}

export default TaskInstructions
