import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import allLines from 'assets/images/lot/allLines.png'
import line from 'assets/images/lot/line.png'
import { Box, Instructions, Modal, Text, Button, ProgressBar } from 'components'
import Line from 'components/molecules/CognitiveTasks/Line'
import { times } from 'lodash'
import moment from 'moment'
import { calculatePos } from 'utils/taskHelper'
import theme from 'utils/theme'

const createLines = () => {
  let angle = 0
  const linesTmp = []
  while (angle <= 180) {
    linesTmp.push(calculatePos(200, angle, -100, 300, true))
    angle += 180 / 14.000001 // 14.000001 because of rounding in the while condition. Need 15 lines.
  }
  return linesTmp
}

const createLineAns = () => {
  const lineAns = []
  while (lineAns.length <= 20) {
    const randInt = Math.floor(Math.random() * 15)
    if (lineAns[lineAns.length - 1] !== randInt) {
      lineAns.push(randInt)
    }
  }
  return lineAns
}

const lines = createLines()
const TRIALS = 19
const linesAns = createLineAns()
const practiceAns = [2, 8]

const LineOrientationTask = ({ onFinish }) => {
  const location = useLocation()
  const { t, i18n } = useTranslation(['lineOrientationTask'])

  const trials = location?.state?.taskSetting?.rounds || TRIALS
  const [results, setResults] = useState([{ testName: t('header') }])
  const [answers, setAnswers] = useState([])
  const [reactSeconds, setReactSeconds] = useState([])
  const [finalInstructions, setFinalInstructions] = useState(false)
  const [practiceFinished, setPracticeFinished] = useState(false)
  const [started, setStarted] = useState(false)
  const [finished, setFinished] = useState(false)
  const [practice, setPractice] = useState(false)
  const [practiceLineClicked, setPracticeLineClicked] = useState(false)
  const [shownAt, setShownAt] = useState(null)
  const [reactedAt, setReactedAt] = useState(null)
  const [lineIndex, setlineIndex] = useState(0)
  const [timer, setTimer] = useState(10)
  const [lineClicked, setlineClicked] = useState(false)
  const [nextClicked, setNextClicked] = useState(false)
  const [practiceMessage, setPracticeMessage] = useState()

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('lang')))
  }, [])

  useEffect(() => {
    if (finished) {
      processResults()
    }
  }, [finished])

  const processResults = () => {
    let correct = 0
    let incorrect = 0
    let timedOut = 0
    const allTimers = []
    const correctTimers = []
    const incorrectTimers = []
    for (let i = 0; i < answers.length; i += 1) {
      if (answers[i] === linesAns[i]) {
        correct += 1
        correctTimers.push(reactSeconds[i])
        allTimers.push(reactSeconds[i])
      } else if (answers[i] === -1) {
        timedOut += 1
      } else {
        incorrect += 1
        incorrectTimers.push(reactSeconds[i])
        allTimers.push(reactSeconds[i])
      }
    }

    let averageAll = (allTimers.reduce((a, b) => a + b, 0) / allTimers.length).toFixed(2)
    averageAll = Number.isNaN(Number(averageAll)) ? '-' : averageAll.concat(t('seconds'))

    let averageCorr = (correctTimers.reduce((a, b) => a + b, 0) / correctTimers.length).toFixed(2)
    averageCorr = Number.isNaN(Number(averageCorr)) ? '-' : averageCorr.concat(t('seconds'))

    let averageIncorr = (
      incorrectTimers.reduce((a, b) => a + b, 0) / incorrectTimers.length
    ).toFixed(2)
    averageIncorr = Number.isNaN(Number(averageIncorr)) ? '-' : averageIncorr.concat(t('seconds'))

    setResults([
      ...results,
      {
        numCorrect: correct,
        numIncorrect: incorrect,
        numTimedOut: timedOut,
        correctRate: `${((correct / (TRIALS + 1)) * 100).toFixed(1)}%`,
        averageReponseTime: averageAll,
        averageCorrectReponseTime: averageCorr,
        averageIncorrectReponseTime: averageIncorr,
      },
    ])
  }

  const clickedLinePractice = index => {
    if (index === practiceAns[lineIndex]) {
      setPracticeLineClicked(true)
      setPracticeMessage(t('correct'))
      if (lineIndex === 1) {
        setPracticeFinished(true)
      }
    } else {
      setPracticeMessage(t('incorrect'))
    }
  }

  const clickedNextPractice = () => {
    if (lineIndex === 1) {
      setlineIndex(0)
      setFinalInstructions(true)
    } else {
      setlineIndex(lineIndex + 1)
      setPracticeMessage(t('message'))
    }
    setPracticeLineClicked(false)
  }

  const clickedLine = index => {
    // Cannot click on another line in the same round
    if (!lineClicked) {
      setAnswers([...answers, index])
      setlineClicked(true)
      setReactedAt(moment())
    }
  }

  const skipPractice = () => {
    setlineIndex(0)
    setFinalInstructions(true)
    setPracticeLineClicked(false)
  }

  useEffect(() => {
    if (reactedAt !== null) {
      const seconds = reactedAt.diff(shownAt, 'seconds')
      setReactSeconds([...reactSeconds, seconds])
    }
  }, [reactedAt])

  useEffect(() => {
    const interval = setInterval(() => {
      if (started && !lineClicked) {
        if (timer > 1) {
          // User has not yet clicked. Timer is decreased.
          setTimer(timer - 1)
        } else if (timer === 1) {
          // User did not manage to answer. Time's up.
          setTimer(timer - 1)
          setlineClicked(true)
          setReactedAt(moment())
          setAnswers([...answers, -1])
        }
      } else {
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [timer, started, lineClicked])

  useEffect(() => {
    if (nextClicked && started) {
      setTimer(10)
      setNextClicked(false)
      setlineClicked(false)
      setlineIndex(lineIndex + 1)
      setShownAt(moment())
    }
  }, [nextClicked])

  const renderSingleLine = (top, left, rotate) => (
    <Line
      top={top}
      left={left}
      height="10px"
      width="200px"
      rotate={rotate}
      disabled
      position="absolute"
    />
  )

  const renderLines = isPractice => (
    <Box>
      {times(lines.length, i => (
        <Line
          top={`${lines[i].top}px`}
          left={lines[i].left}
          height="10px"
          width="200px"
          rotate={lines[i].rotate}
          onPress={isPractice ? () => clickedLinePractice(i) : () => clickedLine(i)}
          hover={practiceLineClicked || lineClicked ? 'white' : theme.colors.greyDarker}
          disabled={(practiceLineClicked || lineClicked) && 'disabled'}
          position="absolute"
        />
      ))}
    </Box>
  )

  const practiceRender = () => (
    <Box>
      <Box height="350px" position="relative" style={{ left: '50%', overflow: 'visible' }}>
        {renderSingleLine(
          `${String(parseInt(lines[practiceAns[lineIndex]].top) + 20)}px`,
          lines[practiceAns[lineIndex]].left,
          lines[practiceAns[lineIndex]].rotate
        )}
      </Box>
      <Box height="350px" position="relative" style={{ left: '50%', overflow: 'visible' }}>
        {renderLines(true)}
      </Box>
      <Box cc>
        <Text>{practiceMessage} </Text>
        {practiceLineClicked && (
          <Box width="100%" mt="20px" cc>
            <Button onPress={() => clickedNextPractice()} block>
              <Text semibold center>
                {practiceFinished ? t('continue') : t('next')}
              </Text>
            </Button>
          </Box>
        )}
      </Box>
      <Box width="120px" position="fixed" right="20px" bottom="20px">
        <Button block onPress={() => skipPractice()}>
          <Text semibold center>
            {t('skipPractice')}
          </Text>
        </Button>
      </Box>
    </Box>
  )

  const mainScreenRender = () => (
    <Box pv="128px">
      <Box mb="-200px" jc="flex-end" row ph="64px">
        <Box width="200px" backgroundColor={theme.colors.grey} round cc>
          <Text ginormous color={theme.colors.button.left}>
            {timer}
          </Text>
        </Box>
      </Box>
      <Box height="350px" position="relative" style={{ left: '50%', overflow: 'visible' }}>
        {renderSingleLine(
          `${String(parseInt(lines[linesAns[lineIndex]].top) + 20)}px`,
          lines[linesAns[lineIndex]].left,
          lines[linesAns[lineIndex]].rotate
        )}
      </Box>
      <Box>
        <Box height="350px" position="relative" style={{ left: '50%', overflow: 'visible' }}>
          {renderLines(false)}
        </Box>
      </Box>

      {lineClicked && lineIndex < trials && (
        <Box width="100%" cc>
          <Button onPress={() => setNextClicked(true)} block>
            <Text semibold center>
              {t('next')}
            </Text>
          </Button>
        </Box>
      )}
      {lineClicked && lineIndex === trials && (
        <Box width="100%" cc>
          <Button onPress={() => setFinished(true)} block>
            <Text semibold center>
              {t('seeResults')}
            </Text>
          </Button>
        </Box>
      )}
      {/* <Box width="100%" position="fixed" bottom="0">
        <ProgressBar width="100" progressPercentage={(lineIndex / 20) * 100} />
      </Box> */}
    </Box>
  )

  if (results.length > 1) {
    return (
      <Navigate
        to="/results"
        state={{
          results,
          battery: location?.state?.battery,
          formTitle: location?.state?.taskSetting?.title || 'lineorientationtask',
        }}
      />
    )
  }

  if (finalInstructions) {
    return (
      <Box fullscreen bg={theme.colors.greyDark}>
        <Modal
          isOpen={!started}
          close
          onClose={() => {
            setStarted(true)
            setShownAt(moment())
          }}
          closeText={t('startTest')}
        >
          <Text big>{t('instructionHeader')}</Text>
          <Instructions body={t('instructions4')} />
        </Modal>
        {mainScreenRender()}
      </Box>
    )
  }
  return (
    <Box fullscreen bg={theme.colors.greyDark}>
      <Modal
        isOpen={!practice}
        onClose={() => {
          setPractice(true)
          setPracticeMessage(t('message'))
        }}
        close
        closeText={t('startPractice')}
      >
        <Text big>{t('instructionHeader')}</Text>
        <Instructions body={t('instructions')} />
        <Box cc>
          <img src={line} alt="symbol key" width="12%" />
        </Box>
        <Instructions body={t('instructions2')} />
        <Box cc>
          <img src={allLines} alt="symbol key" width="40%" />
        </Box>
        <Instructions body={t('instructions3')} />
      </Modal>
      {practiceRender()}
    </Box>
  )
}

LineOrientationTask.propTypes = {
  onFinish: PropTypes.func,
}

LineOrientationTask.defaultProps = {
  onFinish: () => {},
}

export default LineOrientationTask
