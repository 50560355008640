import React from 'react'
import { Box, Text, Footer, Header } from 'components'

const PrivacyPolicy = () => (
  <Footer>
    <Box minHeight="100vh">
      <Header publicRouter />
      <Box flexGrow pv="2rem" ph="4rem" mb="140px" mt="80px">
        <Box mv="4px">
          <Text huge color="black">
            Privacy Policy
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            Reykjavik University LC, id. 510105-4190, Menntavegi 1, 102 Reykjavik („RU“) has
            developed a mobile app with regards to the research project Sleep Revolution („Sleep
            Revolution“) for which RU is responsible.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            The mobile app is available through the App Store and Play Store and allows users to
            record various information about the user‘s sleep and health status. The purpose of the
            mobile app is mainly to make it easier for participants in the Sleep Revolution to
            record information, answer questionnaires and take part in cognitive tests. However, the
            mobile app is also accessible to users who are not participants in the Sleep Revolution.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            Information and data from participants in the Sleep Revolution will be part of a
            so-called research database that will be used during the research, but information from
            other users will not be part of such a database.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            The use of the mobile app involves the processing of personal information about users
            according to Act no. 90/2018 on personal protection and processing of personal data (
            „Personal Protection Act“). This privacy policy describes how RU processes such data,
            both with regard to participants in the Sleep Revolution as well as other users of the
            mobile app.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black" medium bold>
            1. Personal data processed, purpose and legality of processing
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            During the creation of an account in the mobile app, the user must register their name,
            age, and email address. Those who are participants in the Sleep Revolution must
            additionally enter their research number and with the use of a QR code they are
            identified as participants in the project.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            In addition to the above-mentioned information, RU processes the personal information
            that users register within the mobile app which may be related to:
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">- sleep duration and users‘ quality of sleep</Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            - caffeine consumption, napping, menstrual cycle, exercise and alcohol consumption of
            users,
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">- users‘ use of sleep medication, and/or results from</Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            - users‘ cognitive tests that measure alertness, attention and cognitive ability of
            users and its relationship with sleep
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            The purpose of the data processing is to authenticate users. Users are then given the
            opportunity to keep track of their sleep and sleep patterns using the mobile app.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            As for the participants in the Sleep Revolution, the purpose of the processing is also
            to obtain additional research data. More specifically, the purpose of processing data
            related to sleep, exercise, health and medication use is to see how users experience
            their sleep compared to the results of sleep measurements that participants in the Sleep
            Revolution undergo. The purpose of processing data from cognitive tests is to assess the
            cognitive ability of users and its relationship with sleep. The purpose of processing
            the aforementioned information is also to examine the users‘ use of the mobile app and
            the effect on sleep quality and sleep patterns, i.e. whether users are more diligent in
            taking care of their sleep if they are aware of recording information.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            The above information is processed on the basis of user consent, cf. item 1 of Article
            9(1) and item 1 of Article 11(1) of the Personal Protection Act.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            RU will then use the users‘ contact information to be able to contact users by sending
            them notifications regarding the use of the mobile app. Such processing is carried out
            on the basis of RU‘s legitimate interests, cf. item 6 of Article 9(1) of the Personal
            Protection Act.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black" medium bold>
            2. Source of data and retention period
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            The personal data processed in the mobile app comes directly from the users.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            As far as the participants in the Sleep Revolution are concerned, the retention period
            of the personal information collected through the use of the mobile app goes hand in
            hand with the study itself. It is planned to erase all personally identifiable
            information about participants at the end of 2025, with the proviso that an application
            for an extension will be submitted to the Scientific Ethics Committee.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            Personal information about users who are not participating in the Sleep Revolution is
            preserved while the person is still considered a user of the mobile app. If a user
            deletes their account, the personal information entered into the mobile app and
            associated with that account will be deleted. If the user has not used the applet for
            six months, RU reserves the right to delete information from the mobile app. Users can
            at any time delete the information they have entered into the mobile app.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black" medium bold>
            3. Disclosure to third parties
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            The personal information of the users of the mobile app, who are not participants in the
            Sleep Revolution, is stored in electronic form in the mobile app and in Sleep
            Revolution‘s high performance cluster, and such information is not disclosed to third
            parties.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            Personal information about participants in the Sleep Revolution may be shared with RU‘s
            partners in the research project. However, such information is only shared when it has
            become part of the data of the research project and has become non-personally
            identifiable. Disclosure to partners is always done in accordance with the consent given
            by users in connection with the research.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            In other respects, RU does not share users‘ personal information with third parties
            except to the extent that this is permitted or required on the basis of relevant laws or
            regulations or in response to legal actions such as house searches, subpoenas or court
            orders.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black" medium bold>
            4. Security of personal information
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            RU ensures the utmost security in the handling of personal information and RU has taken
            appropriate technical and organizational measures to ensure the security of the
            information processed in the mobile app. Examples of such security measures are data
            encryption, access controls to systems and data, and the use of firewalls. These
            measures are intended to protect personal information against accidental loss or
            alteration and against unauthroized access, copying, use or disclosure.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black" medium bold>
            Users‘ rights
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            The user has the right to receive access and, in certain cases, a copy of processed
            personal data about them as well as information regarding the data processing itself.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            Under certain circumstances, the user may also be authorized to request that personal
            information about them be deleted or that its processing be restricted. Additionally,
            the user has the right to have their personal data corrected in case it is incorrect or
            unreliable.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            In addition, the user has the right to a copy of the information they have provided to
            RU in digital form, or that RU sends it directly to a third party.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            When RU processes a user‘s personal data on the basis of legitimate interests, the user
            can object to that processing. Furthermore, the user always has the right to withdraw
            the consent they have given on which the processing of personal data is based.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            However, the above rights of the user are not unconditional. Thus, laws or regulations
            may authorize or oblige RU to reject a user‘s request to exercise said rights.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black" medium bold>
            6. Inquiries and complaints to Persónuvernd
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            If the user has questions about the handling of personal data by RU and/or if the user
            wants to exercise the rights described above, they can contact RU at the email address
            sleeprevolution@ru.is or personuvernd@ru.is. RU will try to respond to the user‘s
            requests as soon as possible and guide the user about the rights under this privacy
            policy.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            If a user is not satisfied with RU‘s handling of personal information, it is always
            possible to send a message to Persónuvernd (www.personuvernd.is)
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black" medium bold>
            7. Revision
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            RU may from time to time change this prvacy policy in accordance with changes in
            relevant laws or regulations or due to changes in the way RU processes personal
            information. If changes are made to this privacy policy, the updated version will be
            notified to the user in the same way that this policy was announced.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            Any changes that may be made to the policy will take effect after the updated version
            has been published.
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">This privacy policy was published on 06.08.2022</Text>
        </Box>
      </Box>
      {/* <Box style={{ bottom: 0, position: 'relative' }}>
      <Footer />
    </Box> */}
    </Box>
  </Footer>
)

export default PrivacyPolicy
