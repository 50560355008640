import React, { useContext, useMemo } from 'react'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
  Radio,
  useTheme,
  useMediaQuery,
  Typography,
} from '@mui/material'
import { FastField, useField } from 'formik'
import { LangContext } from '../const/Contexts'
import { shouldUpdateFastField } from './FastFieldShouldUpdateOverride'

/* eslint-disable no-cond-assign */
/* eslint-disable react/no-array-index-key */

const parseText = text => {
  const regex = /<b>(.*?)<\/b>|<u>(.*?)<\/u>|<i>(.*?)<\/i>/g
  let match
  let lastIndex = 0
  const parts = []

  while ((match = regex.exec(text)) !== null) {
    if (lastIndex !== match.index) {
      parts.push({
        bold: false,
        underline: false,
        italic: false,
        content: text.substring(lastIndex, match.index),
      })
    }
    parts.push({
      bold: match[1] !== undefined,
      underline: match[2] !== undefined,
      italic: match[3] !== undefined,
      content: match[1] || match[2] || match[3],
    })
    lastIndex = match.index + match[0].length
  }

  if (lastIndex !== text.length) {
    parts.push({ bold: false, underline: false, italic: false, content: text.substring(lastIndex) })
  }
  console.log('parsedTextParts', parts)

  return parts
}

const StyledText = ({ text }) => {
  const parsedTextParts = parseText(text) // Use the parseText function we defined earlier.

  return (
    <>
      {parsedTextParts.map((part, index) => {
        const style = {
          fontWeight: part.bold ? 'bold' : 'normal',
          textDecoration: part.underline ? 'underline' : 'none',
          fontStyle: part.italic ? 'italic' : 'normal',
        }
        return (
          <span key={index} style={style}>
            {part.content}
          </span>
        )
      })}
    </>
  )
}

const FormikMuiRadio = ({ name, label, options, column, ...props }) => {
  const { i18n } = useContext(LangContext)
  const [field, meta, helpers] = useField(name)
  const { setValue } = helpers
  const { error, touched } = meta
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('630'))

  const handleChange = event => {
    setValue(Number(event.target.value))
  }

  const optionsValues = useMemo(
    () => options.map((o, idx) => ({ label: o, value: idx })),
    [options]
  )

  return (
    <FastField
      name={name}
      meta={{ lang: i18n.language, disabled: props.disabled, isMobile }}
      shouldUpdate={shouldUpdateFastField}
    >
      {() => (
        <FormControl fullWidth error={touched && !!error}>
          <FormLabel htmlFor={name}>
            <StyledText text={label} />
          </FormLabel>
          <RadioGroup
            sx={{ alignContent: 'center', marginBottom: '2rem' }}
            row={!column && !isMobile}
            {...props}
            {...field}
            onChange={handleChange}
          >
            {optionsValues.map(option => (
              <FormControlLabel
                value={option.value}
                control={<Radio sx={{ ...(touched && !!error && { color: 'rgb(211,47,47)' }) }} />}
                label={
                  <Typography {...(touched && !!error && { color: 'rgb(211,47,47)' })}>
                    {option.label}
                  </Typography>
                }
                labelPlacement="end"
              />
            ))}
          </RadioGroup>
          {touched && !!error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )}
    </FastField>
  )
}

export default FormikMuiRadio
