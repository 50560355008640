import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import InfoText from './InfoText'

function RabioTable({
  columnName1,
  columnName2,
  columninfo,
  items,
  extent,
  updateOften,
  updateExtent,
}) {
  const { t, i18n } = useTranslation(['esq'])

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('lang')))
  }, [])

  const textcolor = 'white'
  const backgroundColor = '#333236'

  const showBothersome = items.some(e => !(e.often === 0))

  return (
    <div>
      <TableContainer component={Paper} style={{ backgroundColor }}>
        <Table sx={{ minWidth: 650, paddingLeft: 40 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: textcolor }} align="left">
                {columnName1}
              </TableCell>
              <TableCell sx={{ color: textcolor }}>
                <div style={{ display: 'flex', gap: 30, alignSelf: 'end' }}>
                  <div style={{ width: 40, fontSize: 14, alignSelf: 'end' }}>{t('Never')}</div>
                  <div style={{ width: 74, fontSize: 14, alignSelf: 'end' }}>{t('VeryRarely')}</div>
                  <div style={{ width: 40, fontSize: 14, alignSelf: 'end' }}>{t('Rarely')}</div>
                  <div style={{ width: 73, fontSize: 14, alignSelf: 'end' }}>
                    {t('Occasionally')}
                  </div>
                  <div style={{ width: 40, fontSize: 14, alignSelf: 'end' }}>{t('Often')}</div>
                  <div style={{ width: 71, fontSize: 14, alignSelf: 'end' }}>{t('Veryoften')}</div>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {items.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell sx={{ color: textcolor }}>{item.statement}</TableCell>
                <TableCell>
                  <div
                    style={{ display: 'flex', gap: 40, marginLeft: 10 }}
                    onChange={updateOften(index)}
                  >
                    <input
                      type="radio"
                      value="0"
                      name={item.statement}
                      style={{ width: 20, height: 20, accentColor: 'purple' }}
                    />
                    <input
                      // disabled
                      type="radio"
                      value="1"
                      name={item.statement}
                      style={{ width: 42, height: 20, accentColor: 'purple' }}
                    />
                    <input
                      type="radio"
                      value="2"
                      name={item.statement}
                      style={{ width: 45, height: 20, accentColor: 'purple' }}
                    />
                    <input
                      type="radio"
                      value="3"
                      name={item.statement}
                      style={{ width: 37, height: 20, accentColor: 'purple' }}
                    />
                    <input
                      type="radio"
                      value="4"
                      name={item.statement}
                      style={{ width: 40, height: 20, accentColor: 'purple' }}
                    />
                    <input
                      type="radio"
                      value="5"
                      name={item.statement}
                      style={{ width: 20, height: 20, accentColor: 'purple' }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {showBothersome ? (
          <Table sx={{ minWidth: 650, paddingLeft: 40 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columninfo ? (
                  <TableCell sx={{ color: textcolor }} style={{ display: 'flex' }}>
                    {columnName2} <InfoText style={{ color: textcolor }} infoText={columninfo} />
                  </TableCell>
                ) : (
                  <TableCell sx={{ color: textcolor }} align="left">
                    {columnName2}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {extent.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <div>
                      <div
                        style={{ display: 'flex', gap: 8, marginLeft: 260, color: textcolor }}
                        onChange={updateExtent(index)}
                      >
                        <div>
                          <div style={{ marginLeft: 10 }}>0</div>
                          <input
                            type="radio"
                            value="0"
                            name={columnName1}
                            style={{ width: 20, height: 20, accentColor: 'purple' }}
                          />
                        </div>
                        <div>
                          <div style={{ marginLeft: 10 }}>1</div>
                          <input
                            type="radio"
                            value="1"
                            name={columnName1}
                            style={{ width: 20, height: 20, accentColor: 'purple' }}
                          />
                        </div>
                        <div>
                          <div style={{ marginLeft: 10 }}>2</div>
                          <input
                            type="radio"
                            value="2"
                            name={columnName1}
                            style={{ width: 20, height: 20, accentColor: 'purple' }}
                          />
                        </div>
                        <div>
                          <div style={{ marginLeft: 10 }}>3</div>
                          <input
                            type="radio"
                            value="3"
                            name={columnName1}
                            style={{ width: 20, height: 20, accentColor: 'purple' }}
                          />
                        </div>
                        <div>
                          <div style={{ marginLeft: 10 }}>4</div>
                          <input
                            type="radio"
                            value="4"
                            name={columnName1}
                            style={{ width: 20, height: 20, accentColor: 'purple' }}
                          />
                        </div>
                        <div>
                          <div style={{ marginLeft: 10 }}>5</div>
                          <input
                            type="radio"
                            value="5"
                            name={columnName1}
                            style={{ width: 20, height: 20, accentColor: 'purple' }}
                          />
                        </div>
                        <div>
                          <div style={{ marginLeft: 10 }}>6</div>
                          <input
                            type="radio"
                            value="6"
                            name={columnName1}
                            style={{ width: 20, height: 20, accentColor: 'purple' }}
                          />
                        </div>
                        <div>
                          <div style={{ marginLeft: 10 }}>7</div>
                          <input
                            type="radio"
                            value="7"
                            name={columnName1}
                            style={{ width: 20, height: 20, accentColor: 'purple' }}
                          />
                        </div>
                        <div>
                          <div style={{ marginLeft: 10 }}>8</div>
                          <input
                            type="radio"
                            value="8"
                            name={columnName1}
                            style={{ width: 20, height: 20, accentColor: 'purple' }}
                          />
                        </div>
                        <div>
                          <div style={{ marginLeft: 10 }}>9</div>
                          <input
                            type="radio"
                            value="9"
                            name={columnName1}
                            style={{ width: 20, height: 20, accentColor: 'purple' }}
                          />
                        </div>
                        <div>
                          <div style={{ marginLeft: 6 }}>10</div>
                          <input
                            type="radio"
                            value="10"
                            name={columnName1}
                            style={{ width: 20, height: 20, accentColor: 'purple' }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: 65,
                          paddingLeft: 265,
                          fontSize: 15,
                          marginTop: 5,
                          color: textcolor,
                        }}
                      >
                        <div>{t('Notbothersome')} </div>
                        <div>{t('Extremelybothersome')}</div>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
      </TableContainer>
    </div>
  )
}

RabioTable.propTypes = {
  columnName1: PropTypes.string.isRequired,
  columnName2: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  extent: PropTypes.array.isRequired,
  updateOften: PropTypes.func.isRequired,
  updateExtent: PropTypes.func.isRequired,
  columninfo: PropTypes.string,
}

RabioTable.defaultProps = {
  columninfo: PropTypes.columninfo,
}
export default RabioTable
