import React, { useContext } from 'react'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { fontSizes } from '../const/Const'
import { ColorModeContext, LangContext } from '../const/Contexts'

const DarkModeToggle = () => {
  const theme = useTheme()
  const toggleColorMode = useContext(ColorModeContext)
  const { t } = useContext(LangContext)
  return (
    <Box
      onClick={toggleColorMode}
      sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
    >
      {/* <Typography>{theme.palette.mode === 'dark' ? t('Dark') : t('Light')}</Typography> */}
      <IconButton color="inherit">
        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Box>
  )
}

export default DarkModeToggle
