/* eslint-disable camelcase */

import React from 'react'
import Section from '../../comps/Section'
import GeneralHealth, { GHFieldNames } from './Subsections/GeneralHealth'
import PersonalInformation, { PIFieldNames } from './Subsections/PersonalInformation'
import PersonalProfile, { PPFieldNames } from './Subsections/PersonalProfile'

export const GIFieldNames = [...PIFieldNames, ...PPFieldNames, ...GHFieldNames]

const GeneralInformation = ({ formikProps }) => (
  <Section>
    <PersonalInformation formikProps={formikProps} />
    <PersonalProfile formikProps={formikProps} />
    <GeneralHealth formikProps={formikProps} />
  </Section>
)

export default GeneralInformation
