import level0 from 'assets/images/tol/level0.png'
import level1 from 'assets/images/tol/level1.png'
import level10 from 'assets/images/tol/level10.png'
import level11 from 'assets/images/tol/level11.png'
import level12 from 'assets/images/tol/level12.png'
import level2 from 'assets/images/tol/level2.png'
import level3 from 'assets/images/tol/level3.png'
import level4 from 'assets/images/tol/level4.png'
import level5 from 'assets/images/tol/level5.png'
import level6 from 'assets/images/tol/level6.png'
import level7 from 'assets/images/tol/level7.png'
import level8 from 'assets/images/tol/level8.png'
import level9 from 'assets/images/tol/level9.png'

export const levelImages = [
  level0,
  level1,
  level2,
  level3,
  level4,
  level5,
  level6,
  level7,
  level8,
  level9,
  level10,
  level11,
  level12,
]

export const levels = [
  {
    // Practice
    finalPos: [1, 0, 5], // Blue, green, red
    moves: 2,
  },
  {
    // Lvl 1
    finalPos: [3, 4, 5],
    moves: 2,
  },
  {
    // Lvl 2
    finalPos: [5, 0, 3],
    moves: 2,
  },
  {
    // Lvl 3
    finalPos: [1, 0, 3],
    moves: 3,
  },
  {
    // Lvl 4
    finalPos: [4, 0, 3],
    moves: 3,
  },
  {
    // Lvl 5
    finalPos: [3, 1, 0],
    moves: 4,
  },
  {
    // Lvl 6
    finalPos: [0, 4, 3],
    moves: 4,
  },
  {
    // Lvl 7
    finalPos: [1, 5, 0],
    moves: 4,
  },
  {
    // Lvl 8
    finalPos: [4, 5, 3],
    moves: 4,
  },
  {
    // Lvl 9
    finalPos: [2, 1, 0],
    moves: 5,
  },
  {
    // Lvl 10
    finalPos: [1, 2, 0],
    moves: 5,
  },
  {
    // Lvl 11
    finalPos: [0, 1, 3],
    moves: 5,
  },
  {
    // Lvl 12
    finalPos: [0, 5, 3],
    moves: 5,
  },
]

export const startingBalls = [
  {
    id: '1',
    color: 'blue',
    pos: 3,
    letter: 'B',
  },
  {
    id: '2',
    color: 'green',
    pos: 0,
    letter: 'G',
  },
  {
    id: '3',
    color: 'red',
    pos: 1,
    letter: 'R',
  },
]
