import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'
import { useMediaQuery } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import client from 'client'
import { Text } from 'components'
import { clearUserData, setRefreshToken } from 'utils/helper'
import UserContext from 'utils/UserContext'
import { ReactComponent as DashboardIcon } from '../../../assets/icons/chart-area-solid.svg'
import { ReactComponent as ChatIcon } from '../../../assets/icons/comment-solid.svg'
import { ReactComponent as LogoutIcon } from '../../../assets/icons/right-from-bracket-solid.svg'
import { ReactComponent as DataGroupIcon } from '../../../assets/icons/table-list-solid.svg'
import SRLogo from '../../../assets/images/SRLogo.png'
import theme from '../../../utils/theme'

export const drawerWidth = 240

const LOGOUT_MUTATION = gql`
  mutation Logout($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      success
    }
  }
`

// const lightTheme = createTheme({
//   palette: {
//     mode: 'light',
//   },
// })

const lightTheme = {
  palette: {
    mode: 'light',
  },
}

function ResponsiveDrawer({ window, children }) {
  const navigate = useNavigate()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [activeItemIndex, setActiveItemIndex] = React.useState(0)
  const [logUserOut] = useMutation(LOGOUT_MUTATION)

  const muiTheme = useTheme()
  const smallScreen = useMediaQuery(muiTheme.breakpoints.down('sm'))

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const { setUser } = React.useContext(UserContext)

  const logout = () => {
    setUser({})
    const refreshToken = localStorage.getItem('refreshToken')
    setRefreshToken(null)

    logUserOut({
      variables: {
        refreshToken,
      },
      onCompleted: () => {
        clearUserData().then(() => client.clearStore().then(() => navigate('/', { replace: true })))
      },
    })
  }

  const drawer = (
    <Box
      style={{
        backgroundColor: theme.colors.greyDark,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        {/* <List>
          <ListItem>
            <ListItemIcon>
              <Avatar />
            </ListItemIcon>
            <ListItemText primary="Lisa Schmitz" style={{ color: theme.colors.white }} />
          </ListItem>
        </List>
        <Divider style={{ background: theme.colors.greyDarker }} /> */}
        <List>
          {[
            ['Dashboard', <DashboardIcon height="25px" width="25px" fill="white" />, '/Home'],
            // ['My data groups', <DataGroupIcon height="25px" width="25px" fill="white" />, '/Home'],
            ['Chat', <ChatIcon height="25px" width="25px" fill="white" />, '/Chat'],
          ].map((item, index) => (
            <ListItemButton
              key={`itembutton#${item}`}
              onClick={() => {
                setActiveItemIndex(index)
                navigate(item[2])
              }}
              style={{
                backgroundColor:
                  activeItemIndex === index ? theme.colors.bluepurple : 'transparent',
              }}
            >
              <ListItemIcon>{item[1]}</ListItemIcon>
              <ListItemText primary={item[0]} style={{ color: theme.colors.white }} />
            </ListItemButton>
          ))}
        </List>
      </Box>
      <Box>
        <Divider style={{ background: theme.colors.greyDarker }} />
        <List>
          {['Logout'].map((text, index) => (
            <ListItemButton key={text} onClick={logout}>
              <ListItemIcon>
                <LogoutIcon height="25px" width="25px" fill="white" />
              </ListItemIcon>
              <ListItemText primary={text} style={{ color: theme.colors.white }} />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Box>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <ThemeProvider
      theme={outerTheme =>
        createTheme({
          ...outerTheme,
          ...lightTheme,
        })
      }
    >
      <Box sx={{ display: 'flex' }}>
        {/* <CssBaseline /> */}
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar
            style={{
              background: theme.colors.greyDark,
              paddingTop: '1rem',
              paddingBottom: '1rem',
              marginRight: '-20px',
              boxSizing: 'border-box',
              minHeight: '5rem',
            }}
          >
            {smallScreen ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: '1rem', display: { sm: 'none' } }}
              >
                <img src={SRLogo} alt="Logo" style={{ height: '3rem' }} />
              </IconButton>
            ) : (
              <img src={SRLogo} alt="Logo" style={{ height: '3rem', marginRight: '1rem' }} />
            )}

            <Text huge bold color={theme.colors.white}>
              Sleep Revolution
            </Text>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box component="main" sx={{ width: { xs: '100%', sm: `calc(100% - ${drawerWidth}px)` } }}>
          <Toolbar />
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

export default ResponsiveDrawer
