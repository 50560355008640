import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '../../atoms'
import { Instructions } from '../../molecules'

const PracticeInstructions = () => {
  const { t } = useTranslation(['auditoryVerbalLearning'])

  return (
    <>
      <Text big color="white">
        {t('header')} - {t('instructionsHeader')}
      </Text>
      <Instructions body={t('instructions')} />
      <Instructions body={t('practiceInstructions')} />
    </>
  )
}

export default PracticeInstructions
