import React, { useState } from 'react'
import PropTypes from 'prop-types'

const initialValues = {
  title: '',
  subTitle: '',
  extra: null,
}
const context = {
  values: initialValues,
  setValues: () => {},
}

const TitleContext = React.createContext(context)

export const TitleContextProvider = ({ children }) => {
  const [values, setValues] = useState(initialValues)
  return <TitleContext.Provider value={{ values, setValues }}>{children}</TitleContext.Provider>
}
TitleContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TitleContext
