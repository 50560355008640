import { getIn } from 'formik'

export const shouldUpdateFastField = (nextProps, currentProps) =>
  nextProps.meta?.lang !== currentProps.meta?.lang ||
  nextProps.meta?.disabled !== currentProps.meta?.disabled ||
  nextProps.meta?.isMobile !== currentProps.meta?.isMobile ||
  /* default below */
  nextProps.name !== currentProps.name ||
  nextProps.required !== currentProps.required ||
  nextProps.disabled !== currentProps.disabled ||
  nextProps.readOnly !== currentProps.readOnly ||
  nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting ||
  Object.keys(nextProps).length !== Object.keys(currentProps).length ||
  getIn(nextProps.formik.values, currentProps.name) !==
    getIn(currentProps.formik.values, currentProps.name) ||
  getIn(nextProps.formik.errors, currentProps.name) !==
    getIn(currentProps.formik.errors, currentProps.name) ||
  getIn(nextProps.formik.touched, currentProps.name) !==
    getIn(currentProps.formik.touched, currentProps.name)
