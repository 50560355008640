import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSound from 'use-sound'
import number1 from '../../../assets/audio/pasatC/1.wav'
import number2 from '../../../assets/audio/pasatC/2.wav'
import number3 from '../../../assets/audio/pasatC/3.wav'
import number4 from '../../../assets/audio/pasatC/4.wav'
import number5 from '../../../assets/audio/pasatC/5.wav'
import number6 from '../../../assets/audio/pasatC/6.wav'
import number7 from '../../../assets/audio/pasatC/7.wav'
import number8 from '../../../assets/audio/pasatC/8.wav'
import number9 from '../../../assets/audio/pasatC/9.wav'
import { Box } from '../../atoms'

const AudioPlayer = ({ onFinish, numbers, onPlay, intervalTimeInMs }) => {
  const { t } = useTranslation(['auditoryVerbalLearning'])
  console.log(numbers)

  const [soundProgress, setSoundProgress] = useState(0)

  const [play1] = useSound(number1)
  const [play2] = useSound(number2)
  const [play3] = useSound(number3)
  const [play4] = useSound(number4)
  const [play5] = useSound(number5)
  const [play6] = useSound(number6)
  const [play7] = useSound(number7)
  const [play8] = useSound(number8)
  const [play9] = useSound(number9)

  const playSounds = [play1, play2, play3, play4, play5, play6, play7, play8, play9]

  useEffect(() => {
    if (soundProgress > 0) {
      if (soundProgress <= numbers.length) {
        playSounds[numbers[soundProgress - 1] - 1]()
        onPlay(soundProgress - 1)
      } else {
        onFinish()
      }
    }

    const interval = setInterval(() => {
      setSoundProgress(soundProgress + 1)
    }, intervalTimeInMs)
    return () => clearInterval(interval)
  }, [soundProgress])

  return <Box />
}

export default AudioPlayer
