import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import TextField from '@mui/material/TextField'
import moment from 'moment'
import { setRefreshToken, setToken, setExpiry } from 'utils/helper'
import theme from 'utils/theme'
import { Box, Text, Button, Clickable } from '../../atoms'

const REGISTER_MUTATION = gql`
  mutation Register($email: String!, $password: String!, $firstName: String!, $lastName: String!) {
    register(
      email: $email
      username: $email
      password1: $password
      password2: $password
      firstName: $firstName
      lastName: $lastName
    ) {
      token
      refreshToken
      success
      errors
    }
  }
`

const Register = ({ setIsLogin, setIsRegister }) => {
  const navigate = useNavigate()

  const [register, { data, loading, error }] = useMutation(REGISTER_MUTATION)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')

  useEffect(() => {
    if (data?.register?.success) {
      setToken(data.register.token).then(() => {
        setRefreshToken(data.register.refreshToken).then(() => {
          setExpiry(moment().add(7, 'days').subtract(5, 'minutes')).then(() => {
            navigate('/Home')
          })
        })
      })
    }
  }, data)

  return (
    <Box ph={theme.spacing.large}>
      <Box gap={theme.spacing.large}>
        <Text medium bold>
          Sign up
        </Text>
        <TextField
          id="filled"
          variant="filled"
          required
          label="Email"
          type="email"
          value={email}
          onChange={event => {
            setEmail(event.target.value)
          }}
        />
        <TextField
          id="filled"
          variant="filled"
          required
          label="Password"
          type="password"
          value={password}
          onChange={event => {
            setPassword(event.target.value)
          }}
        />
        <TextField
          id="filled"
          variant="filled"
          required
          label="Firstname"
          value={firstname}
          onChange={event => {
            setFirstname(event.target.value)
          }}
        />
        <TextField
          id="filled"
          variant="filled"
          required
          label="Lastname"
          value={lastname}
          onChange={event => {
            setLastname(event.target.value)
          }}
        />
        <Box mt={theme.spacing.small}>
          <Button
            right="0"
            onPress={() =>
              register({ variables: { email, password, firstName: firstname, lastName: lastname } })
            }
          >
            <Box cc>
              <Text bold center color={theme.colors.white}>
                Sign up
              </Text>
            </Box>
          </Button>
        </Box>
        <Box mt={theme.spacing.small} cc>
          <Clickable
            onPress={() => {
              setIsLogin(true)
              setIsRegister(false)
            }}
          >
            <Text sub bold>
              I already have an account
            </Text>
          </Clickable>
        </Box>
      </Box>
    </Box>
  )
}

Register.propTypes = {
  setIsLogin: PropTypes.func,
  setIsRegister: PropTypes.func,
}

Register.defaultProps = {
  setIsLogin: () => {},
  setIsRegister: () => {},
}

export default Register
