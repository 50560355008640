import React from 'react'

const Section = ({ children }) => (
  <div
    style={{
      width: 'auto',
      margin: '0 15px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    }}
  >
    {children}
  </div>
)

export default Section
