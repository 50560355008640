import React from 'react'
import { Box, Text } from 'components/atoms'
import DGTooltipCell from '../comps/DGTooltipCell'
import ESQVDG from './ESQVDG'

const BoldHeader = ({ value }) => (
  <strong>
    <span>{value}</span>
  </strong>
)

const commonColumnProps = {
  disableColumnMenu: true,
  headerAlign: 'center',
  editable: false,
  sortable: false,
  renderCell: params => <DGTooltipCell value={params.value} />,
  renderHeader: params => <BoldHeader value={params.colDef.headerName} />,
}

const columns = [
  {
    field: 'botherCode',
    headerName: 'Bothersomeness code',
    flex: 0.2,
    ...commonColumnProps,
  },
  {
    field: 'botherMeaning',
    headerName: 'Meaning',
    flex: 0.2,
    ...commonColumnProps,
  },
  {
    field: 'freqCode',
    headerName: 'Frequency code',
    flex: 0.2,
    ...commonColumnProps,
  },
  {
    field: 'freqMeaning',
    headerName: 'Meaning',
    flex: 0.2,
    ...commonColumnProps,
  },
]

const rows = [
  {
    id: 0,
    botherCode: 'B(N/A)',
    botherMeaning: 'This symptom is not present',
    freqCode: '',
    freqMeaning: '',
  },
  {
    id: 1,
    botherCode: 'B(0)',
    botherMeaning: 'Not at all',
    freqCode: 'F(0)',
    freqMeaning: 'Never',
  },
  {
    id: 2,
    botherCode: 'B(1)',
    botherMeaning: 'A little bit',
    freqCode: 'F(1)',
    freqMeaning: 'Rarely',
  },
  {
    id: 3,
    botherCode: 'B(2)',
    botherMeaning: 'Somewhat',
    freqCode: 'F(2)',
    freqMeaning: 'Sometimes',
  },
  {
    id: 4,
    botherCode: 'B(3)',
    botherMeaning: 'Quite a bit',
    freqCode: 'F(3)',
    freqMeaning: 'Often',
  },
  {
    id: 5,
    botherCode: 'B(4)',
    botherMeaning: 'Very much',
    freqCode: 'F(4)',
    freqMeaning: 'Always',
  },
]

const ESQVCodeLegend = () => (
  <Box>
    <ESQVDG rows={rows} columns={columns} />
    <Box mt="40px">
      <Text>'I', 'II' and 'III' represent severity levels.</Text>
    </Box>
  </Box>
)

export default ESQVCodeLegend
