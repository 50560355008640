import React from 'react'
import PropTypes from 'prop-types'
import theme from 'utils/theme'
import { Box, Text } from '../../atoms'
import { PieChart } from '../../molecules'

const odata = [
  { name: 'minimal', value: 1 },
  { name: 'mild', value: 1 },
  // { name: 'moderate', value: 0 },
  { name: 'severe', value: 1 },
]

const colors = [theme.colors.green, theme.colors.yellow, theme.colors.orange, theme.colors.red] // theme.colors.orange,

const ApneaDistribution = ({ data }) => {
  const preparedData = Object.keys(data)
    .filter(key => key !== '__typename')
    .map(key => ({ name: key, value: data[key] }))

  return (
    <Box outline color1={theme.colors.bluepurplerLighter} rounded pv="16px" ph="32px" width="500px">
      <Text big bold>
        Apnea Distribution
      </Text>
      <PieChart data={preparedData} colors={colors} />
    </Box>
  )
}

export default ApneaDistribution
