import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import client from './client'
import reportWebVitals from './reportWebVitals'
import Router from './routes/Router'
import GlobalStyle from './utils/GlobalStyle'
import theme from './utils/theme'
import './index.css'
import './Localization'
import { UserContextProvider } from './utils/UserContext'

const muiTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Ubuntu',
      textTransform: 'none',
      fontSize: '1rem',
    },
  },
  palette: {
    mode: 'dark',
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Ubuntu',
          fontSize: '1rem',
        },
      },
    },
  },
})

const Index = () => (
  <ApolloProvider client={client}>
    <UserContextProvider>
      <ThemeProvider theme={muiTheme}>
        <GlobalStyle theme={theme} />
        <Router />
        {/* <Chat isStaff={true} /> */}
      </ThemeProvider>
    </UserContextProvider>
  </ApolloProvider>
)

ReactDOM.render(<Index />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

// import { createMuiTheme } from '@material-ui/core/styles'
