import React from 'react'
import PropTypes from 'prop-types'
import { times } from 'lodash'
import { PatternBlock, Box, Clickable } from '../../atoms'

const ReactionTaskPattern = ({ width, height, isVisible, onPress }) => {
  const blockWidth = 120.0
  const blockHeight = 200.0

  const colCount = width / blockWidth
  const rowCount = height / blockHeight + 1

  return (
    <Clickable onPress={onPress} disabled={!isVisible}>
      <Box
        bg="red"
        width={`${width}px`}
        height={`${height}px`}
        invisible={!isVisible}
        style={{ position: 'fixed', zIndex: 1, left: 0, top: 0 }}
      >
        {times(Math.floor(rowCount), j => (
          <Box row>
            {times(Math.floor(colCount), i => (
              <PatternBlock key={`PatternBlock${j}${i}`} light={(i % 2) + (j % 2) === 1} />
            ))}
          </Box>
        ))}
      </Box>
    </Clickable>
  )
}

ReactionTaskPattern.propTypes = {
  onPress: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isVisible: PropTypes.number,
}

ReactionTaskPattern.defaultProps = {
  isVisible: true,
}

export default ReactionTaskPattern
