import React from 'react'

const ConnectedFields = ({ children }) => (
  <div>
    {/* Array.isArray(children) ? children.map(child => child && <div>{child}</div>) : children */}
    {children}
  </div>
)

export default ConnectedFields
