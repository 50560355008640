import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box } from '../..'

const ESQVDG = ({ rows, columns, showToolbar }) => (
  <Box width="100%">
    <DataGrid
      autoHeight
      rows={rows}
      columns={columns}
      hideFooter
      showColumnRightBorder
      showCellRightBorder
      disableSelectionOnClick
      components={showToolbar ? { Toolbar: GridToolbar } : {}}
      componentsProps={
        showToolbar
          ? {
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
              },
            }
          : {}
      }
      density="compact"
    />
  </Box>
)

export default ESQVDG
