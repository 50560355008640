import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import numberCircle from 'assets/images/pasatc/circle.png'
import instructionsPhoto from 'assets/images/pasatc/pasat-instructions.png'
import {
  Box,
  PCNumberInput,
  Instructions,
  Modal,
  Text,
  ProgressBar,
  PCAudioPlayer,
} from 'components'
import { times, random, result } from 'lodash'
import moment from 'moment'
import theme from 'utils/theme'

const TRIALS = 60 // Approximately 3 minutes. Trials = (Minutes * 60)/3
const numbersPerRound = [60, 150, 400]
// const numbersPerRound = [2, 2, 10]
const intervalTimesInMs = [3000, 2000, 1500]
const practiceNumbers = times(100, i => random(1, 9))

const PASATC = ({ onFinish }) => {
  const location = useLocation()

  const trials = location?.state?.taskSetting?.numbersPerRound?.[0] || 2

  const { t, i18n } = useTranslation(['pasatC'])

  const onNext = () => setModalIndex(modalIndex + 1)
  const onPrev = () => setModalIndex(modalIndex - 1)

  const [results, setResults] = useState([{ testName: t('header') }])
  const [correctAnswers, setCorrectAnswers] = useState([])
  const [answers, setAnswers] = useState([])
  const [trialNum, setTrialNum] = useState(0)

  const [numbers, setNumbers] = useState(
    times(numbersPerRound[0] + numbersPerRound[1], i => random(1, 9))
  )
  const [finalNumbers, setFinalNumbers] = useState(times(numbersPerRound[2], i => random(1, 9)))

  const [finalRound, setFinalRound] = useState(false)
  const [started, setStarted] = useState(false)
  const [finished, setFinished] = useState(false)

  const [shownAt, setShownAt] = useState(null)
  const [reactedAt, setReactedAt] = useState(null)
  const [timers, setTimers] = useState([])

  const [numberIndex, setNumberIndex] = useState(0)
  const [playNumbers, setPlayNumbers] = useState(false)
  const [playFinalNumbers, setPlayFinalNumbers] = useState(false)
  const [playPracticeNumbers, setPlayPracticeNumbers] = useState(false)

  const [boxClicked, setBoxClicked] = useState(true)
  const [numCorrect, setNumCorrect] = useState(0)

  const [modalIndex, setModalIndex] = useState(0)

  const [finalInstructions, setFinalInstructions] = useState(false)
  const [practice, setPractice] = useState(false)
  const [practiceDone, setPracticeDone] = useState(false)
  const [wrongPracticeAnswer, setWrongPracticeAnswer] = useState(false)
  const [correctPracticeAnswer, setCorrectPracticeAnswer] = useState(false)

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('lang')))
  }, [])

  const clickedBox = index => {
    // User cant click on another line once it has clicked on some line.
    if (!boxClicked && numberIndex) {
      setBoxClicked(true)
      if (practice) {
        if (practiceNumbers[numberIndex] + practiceNumbers[numberIndex - 1] === index) {
          setCorrectPracticeAnswer(true)
          setPracticeDone(true)
          return
        }
        setWrongPracticeAnswer(true)
        return
      }

      setTrialNum(trialNum + 1)
      setAnswers([...answers, index])
      setReactedAt(moment())
      if (correctAnswers[correctAnswers.length - 1] === index) {
        setNumCorrect(numCorrect + 1)
      }
    }
  }

  const completePractice = () => {
    setNumberIndex(0)
    setPracticeDone(false)
    setWrongPracticeAnswer(false)
    setCorrectPracticeAnswer(false)
    setPlayPracticeNumbers(false)
    setPractice(false)
    setFinalInstructions(true)
    setBoxClicked(true)
    setModalIndex(modalIndex + 1)
  }

  const processResults = () => {
    let correct = 0
    const correctTimers = []
    const incorrectTimers = []
    const allTimers = []

    for (let i = 0; i < answers.length; i += 1) {
      if (answers[i] === correctAnswers[i]) {
        correct += 1
        correctTimers.push(timers[i])
        allTimers.push(timers[i])
      } else if (timers[i] !== null) {
        incorrectTimers.push(timers[i])
        allTimers.push(timers[i])
      }
    }

    const yourAns = answers.map(elem => (elem === -1 ? '_' : elem)).join(' , ')
    const corrAns = correctAnswers.join(', ')
    const percentCorr = ((correct / correctAnswers.length) * 100).toFixed(1).concat('%')

    let averageAll = (allTimers.reduce((a, b) => a + b, 0) / allTimers.length).toFixed(0)
    averageAll = Number.isNaN(Number(averageAll)) ? '-' : averageAll.concat(' ms')

    let averageCorr = (correctTimers.reduce((a, b) => a + b, 0) / correctTimers.length).toFixed(0)
    averageCorr = Number.isNaN(Number(averageCorr)) ? '-' : averageCorr.concat(' ms')

    let averageIncorr = (
      incorrectTimers.reduce((a, b) => a + b, 0) / incorrectTimers.length
    ).toFixed(0)
    averageIncorr = Number.isNaN(Number(averageIncorr)) ? '-' : averageIncorr.concat(' ms')

    setResults([
      ...results,
      {
        yourAnswers: yourAns,
        correctAnswers: corrAns,
        numCorrect: correct,
        numIncorrect: correctAnswers.length - correct,
        percentCorrect: percentCorr,
        averageReponseTime: averageAll,
        averageCorrectReponseTime: averageCorr,
        averageIncorrectReponseTime: averageIncorr,
      },
    ])
  }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (practiceDone) {
  //       return // Do nothing when user is ready, but has not started.
  //     }
  //     // Practice
  //     if (timer > 1 && practice) {
  //       setTimer(timer - 1)
  //     } else if (timer === 1 && practice) {
  //       // Time is up.
  //       drawNewNumber()
  //       setPlayNumbers(true)
  //       resetTimer()
  //     }

  //     // Main test
  //     if (timer > 1 && started) {
  //       setTimer(timer - 1)
  //     } else if (timer === 1 && started) {
  //       // Time is up.
  //       if (!boxClicked) {
  //         // Did not manage to answer
  //         setTrialNum(trialNum + 1)
  //         setAnswers([...answers, -1])
  //         setTimers([...timers, null])
  //       }
  //       drawNewNumber()
  //       setPlayNumbers(true)
  //       resetTimer()
  //     }
  //   }, 1000)
  //   return () => clearInterval(interval)
  // }, [timer, practice, started, boxClicked])

  useEffect(() => {
    if (finished) {
      processResults()
    }
  }, [finished])

  useEffect(() => {
    if (reactedAt !== null) {
      const milliseconds = reactedAt.diff(shownAt, 'milliseconds')
      setTimers([...timers, milliseconds])
    }
  }, [reactedAt])

  const handlePlayNumbers = newIndex => {
    setShownAt(moment())
    if (practice) {
      setCorrectPracticeAnswer(false)
      setWrongPracticeAnswer(false)
    }

    if (playNumbers && newIndex > 0) {
      setCorrectAnswers([...correctAnswers, numbers[newIndex - 1] + numbers[newIndex]])
      if (!boxClicked && numberIndex > 0) {
        setAnswers([...answers, '_'])
        setTimers([...timers, null])
      }
    }

    if (playFinalNumbers && newIndex > 0) {
      setCorrectAnswers([...correctAnswers, finalNumbers[newIndex - 1] + finalNumbers[newIndex]])
      if (!boxClicked && numberIndex > 0) {
        setAnswers([...answers, '_'])
        setTimers([...timers, null])
      }
    }

    setNumberIndex(newIndex)
    setBoxClicked(false)
  }

  const modalList = [
    <Modal isOpen={!practice} next onNext={onNext}>
      <Text big>
        {t('header')} - {t('instructionsHeader')}
      </Text>
      <Instructions body={t('instructions1')} />
      <Box cc>
        <img src={numberCircle} alt="number circle" width="40%" />
      </Box>
      <Instructions body={t('instructions2')} />
    </Modal>,
    <Modal isOpen={!practice} previous onPrev={onPrev} next onNext={onNext}>
      <Text big>
        {t('header')} - {t('instructionsHeader')}
      </Text>
      <Box cc>
        <img src={instructionsPhoto} alt="number circle" width="100%" />
      </Box>
      <Instructions body={t('instructions3')} />
    </Modal>,
    <Modal
      isOpen={!practice}
      close
      closeText={t('startPractice')}
      onClose={() => {
        setPractice(true)
        setPlayPracticeNumbers(true)
      }}
      previous
      onPrev={onPrev}
    >
      <Text big>
        {t('header')} - {t('instructionsHeader')}
      </Text>
      <Instructions body={t('instructions4')} />
      <Instructions body={t('instructions5')} />
    </Modal>,
    <Modal
      isOpen={!started}
      onClose={() => {
        setBoxClicked(true)
        setStarted(true)
        setPlayNumbers(true)
      }}
      close
      closeText={t('startTest')}
    >
      <Text big>
        {t('header')} - {t('instructionsHeader')}
      </Text>
      <Instructions body={t('instructions6')} />
    </Modal>,
    <Modal
      isOpen={finalRound}
      onClose={() => {
        setFinalRound(false)
        setBoxClicked(true)
        setPlayFinalNumbers(true)
      }}
      close
      closeText={t('startTest')}
    >
      <Text big>
        {t('header')} - {t('instructionsHeader')}
      </Text>
      <Instructions body={t('instructions7')} />
      <Instructions body={t('instructions8')} />
      <Instructions body={t('instructions9')} />
    </Modal>,
  ]

  if (results.length > 1) {
    return (
      <Navigate
        to="/results"
        state={{
          results,
          battery: location?.state?.battery,
          formTitle: location?.state?.taskSetting?.title || 'toweroflondon',
        }}
      />
    )
  }

  return (
    <Box fullscreen cc bg={theme.colors.greyDark}>
      {modalList[modalIndex]}
      <PCNumberInput
        completePractice={completePractice}
        completeTask={() => {
          setAnswers([...answers, '_'])
          setTimers([...timers, null])
          setFinished(true)
        }}
        practiceDone={practiceDone}
        wrongPracticeAnswer={wrongPracticeAnswer}
        correctPracticeAnswer={correctPracticeAnswer}
        clickedBox={clickedBox}
        finalRound={playFinalNumbers}
        numCorrect={
          numberIndex > 0 && practice
            ? practiceNumbers[numberIndex] + practiceNumbers[numberIndex - 1]
            : ''
        }
      />
      {playPracticeNumbers && (
        <PCAudioPlayer
          onFinish={() => {
            setPlayPracticeNumbers(false)
          }}
          onPlay={handlePlayNumbers}
          numbers={practiceNumbers}
          intervalTimeInMs={intervalTimesInMs[0]}
        />
      )}
      {playNumbers && (
        <PCAudioPlayer
          onFinish={() => {
            if (!boxClicked) {
              setAnswers([...answers, '_'])
              setTimers([...timers, null])
            }
            setModalIndex(modalIndex + 1)
            setPlayNumbers(false)
            setNumberIndex(0)
            setFinalRound(true)
            setBoxClicked(true)
          }}
          onPlay={handlePlayNumbers}
          numbers={numbers}
          intervalTimeInMs={
            numberIndex >= numbersPerRound[0] ? intervalTimesInMs[1] : intervalTimesInMs[0]
          }
        />
      )}
      {playFinalNumbers && (
        <PCAudioPlayer
          onFinish={() => {
            if (!boxClicked) {
              setAnswers([...answers, '_'])
              setTimers([...timers, null])
            }
            setPlayFinalNumbers(false)
            setFinished(true)
          }}
          onPlay={handlePlayNumbers}
          numbers={finalNumbers}
          intervalTimeInMs={intervalTimesInMs[2]}
        />
      )}
    </Box>
  )

  // if (finalInstructions) {
  //   return (
  //     <Box fullscreen bg={theme.colors.greyDark}>
  //       {modalList[modalIndex]}
  //       {mainScreenRender()}
  //       {/* <Box width="100%" position="fixed" bottom="0">
  //         <ProgressBar width="100" progressPercentage={(trialNum / TRIALS) * 100} />
  //       </Box> */}
  //     </Box>
  //   )
  // }
  // return (
  //   <Box fullscreen bg={theme.colors.greyDark}>
  //     {modalList[modalIndex]}
  //     {mainScreenRender()}
  //     <Box width="120px" position="fixed" right="20px" bottom="20px">
  //       <Button onPress={() => completePractice()} block>
  //         <Text semibold center>
  //           {t('skipPractice')}
  //         </Text>
  //       </Button>
  //     </Box>
  //   </Box>
  // )
}

PASATC.propTypes = {
  onFinish: PropTypes.func,
}

PASATC.defaultProps = {
  onFinish: () => {},
}

export default PASATC
