import React from 'react'
import PropTypes from 'prop-types'
import theme from 'utils/theme'
import Box from './Box'

const ProgressBar = ({ progressPercentage, width, color1, color2 }) => (
  <Box bg="darkgrey" width={`${width}px`} height="10px">
    <Box
      width={`${(width * progressPercentage) / 100.0}px`}
      height="10px"
      color1={color1}
      color2={color2}
      style={{ borderTopRightRadius: '50px', borderBottomRightRadius: '50px' }}
    />
  </Box>
)

ProgressBar.propTypes = {
  progressPercentage: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  color1: PropTypes.string,
  color2: PropTypes.string,
}

ProgressBar.defaultProps = {
  color1: theme.colors.button.left,
  color2: theme.colors.button.right,
}

export default ProgressBar
