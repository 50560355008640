import React from 'react'
import { Tooltip } from '@mui/material'

const DGTooltipCell = ({ value, format }) => {
  const formattedVal =
    format && typeof value === 'string'
      ? (value?.charAt(0)?.toUpperCase() + value?.slice(1))?.replaceAll('_', ' ')
      : value
  return (
    <Tooltip title={formattedVal} placement="top" arrow enterTouchDelay={0} leaveTouchDelay={3000}>
      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{formattedVal}</span>
    </Tooltip>
  )
}

export default DGTooltipCell
