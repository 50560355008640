/* eslint-disable camelcase */

import React, { useContext, useEffect } from 'react'
import Info from 'components/ESQ/A_ESQ_REMAKE/comps/Info'
import Comment from '../../../comps/Comment'
import ConnectedFields from '../../../comps/ConnectedFields'
import FormikMuiInput from '../../../comps/FormikMuiInput'
import FormikMuiRadio from '../../../comps/FormikMuiRadio'
import { LangContext } from '../../../const/Contexts'
import { occurrenceFrequencyKeys, occurrenceBotherKeys } from '../../../const/ESQOptions'

const FAMSSleep = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  const { values, setFieldValue } = formikProps

  const {
    snoring_bedpartner_frequency,
    snoring_bedpartners_bother,
    movements_bedpartner_frequency,
    movements_bedpartners_bother,
    pet_activity_frequency,
    pet_activitys_bother,
    caretaking_family_frequency,
    caretaking_familys_bother,
    sleeping_apart_frequency,
    sleeping_aparts_bother,
    environmental_noise_frequency,
    environmental_noises_bother,
    smartphone_messages_frequency,
    smartphone_messagess_bother,
    bedroom_inconveniences_frequency,
    bedroom_inconveniencess_bother,
    other_o_frequency,
    other_o_bother,
    other_o_info,
  } = values

  // Resetting 'Extra' fields when they switch back (ex. 'Other' boxes)
  useEffect(() => {
    if (snoring_bedpartner_frequency === 0 && snoring_bedpartners_bother !== '') {
      setFieldValue('snoring_bedpartners_bother', '')
    }
  }, [snoring_bedpartner_frequency])
  useEffect(() => {
    if (movements_bedpartner_frequency === 0 && movements_bedpartners_bother !== '') {
      setFieldValue('movements_bedpartners_bother', '')
    }
  }, [movements_bedpartner_frequency])
  useEffect(() => {
    if (pet_activity_frequency === 0 && pet_activitys_bother !== '') {
      setFieldValue('pet_activitys_bother', '')
    }
  }, [pet_activity_frequency])
  useEffect(() => {
    if (caretaking_family_frequency === 0 && caretaking_familys_bother !== '') {
      setFieldValue('caretaking_familys_bother', '')
    }
  }, [caretaking_family_frequency])
  useEffect(() => {
    if (sleeping_apart_frequency === 0 && sleeping_aparts_bother !== '') {
      setFieldValue('sleeping_aparts_bother', '')
    }
  }, [sleeping_apart_frequency])
  useEffect(() => {
    if (environmental_noise_frequency === 0 && environmental_noises_bother !== '') {
      setFieldValue('environmental_noises_bother', '')
    }
  }, [environmental_noise_frequency])
  useEffect(() => {
    if (smartphone_messages_frequency === 0 && smartphone_messagess_bother !== '') {
      setFieldValue('smartphone_messagess_bother', '')
    }
  }, [smartphone_messages_frequency])
  useEffect(() => {
    if (bedroom_inconveniences_frequency === 0 && bedroom_inconveniencess_bother !== '') {
      setFieldValue('bedroom_inconveniencess_bother', '')
    }
  }, [bedroom_inconveniences_frequency])
  useEffect(() => {
    if (other_o_frequency === 0) {
      if (other_o_bother !== '') {
        setFieldValue('other_o_bother', '')
      }
      if (other_o_info !== '') {
        setFieldValue('other_o_info', '')
      }
    }
  }, [other_o_frequency])

  return (
    <>
      <ConnectedFields>
        <Comment text={t('Duringthepastmonth')} bold />
        <Comment text={t('columnName1FactorsAffectingMySleep4')} />
        <FormikMuiRadio
          column
          label={t('snoringbedpartner')}
          name="snoring_bedpartner_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('movementsbedpartner')}
          name="movements_bedpartner_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('activitypets')}
          name="pet_activity_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('familyCare')}
          name="caretaking_family_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <Info text={t('sleepapartInfo')}>
          <FormikMuiRadio
            column
            label={t('sleepapart')}
            name="sleeping_apart_frequency"
            options={occurrenceFrequencyKeys.map(val => t(val))}
          />
        </Info>
        <FormikMuiRadio
          column
          label={t('environmentalnoise')}
          name="environmental_noise_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('smartphone')}
          name="smartphone_messages_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('inconveniencesbedroom')}
          name="bedroom_inconveniences_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('otherIfPresent')}
          name="other_o_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        {other_o_frequency > 0 && (
          <FormikMuiInput label={t('otherIfPresent')} name="other_o_info" type="text" />
        )}
      </ConnectedFields>
      <ConnectedFields>
        {(snoring_bedpartner_frequency > 0 ||
          movements_bedpartner_frequency > 0 ||
          pet_activity_frequency > 0 ||
          caretaking_family_frequency > 0 ||
          sleeping_apart_frequency > 0 ||
          environmental_noise_frequency > 0 ||
          smartphone_messages_frequency > 0 ||
          bedroom_inconveniences_frequency > 0 ||
          other_o_frequency > 0) && <Comment text={t('columnName2FactorsAffectingMySleep4')} />}
        {snoring_bedpartner_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('snoringbedpartner')}
            name="snoring_bedpartners_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {movements_bedpartner_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('movementsbedpartner')}
            name="movements_bedpartners_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {pet_activity_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('activitypets')}
            name="pet_activitys_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {caretaking_family_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('familyCare')}
            name="caretaking_familys_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {sleeping_apart_frequency > 0 && (
          <Info text={t('sleepapartInfo')}>
            <FormikMuiRadio
              column
              label={t('sleepapart')}
              name="sleeping_aparts_bother"
              options={occurrenceBotherKeys.map(val => t(val))}
            />
          </Info>
        )}
        {environmental_noise_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('environmentalnoise')}
            name="environmental_noises_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {smartphone_messages_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('smartphone')}
            name="smartphone_messagess_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {bedroom_inconveniences_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('inconveniencesbedroom')}
            name="bedroom_inconveniencess_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {other_o_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('Other')}
            name="other_o_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
      </ConnectedFields>
    </>
  )
}

export default FAMSSleep
