import { css, createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle(
  props => css`
    html {
      // overflow: auto;

      @media only screen and (max-width: 576px) {
          font-size: 10px;
      }
      
      /* Small devices (tablets) */
      @media only screen and (min-width: 576px) and (max-width: 768px) {
          font-size: 12px;
      }
      
      /* Medium devices (laptops, desktops) */
      @media only screen and (min-width: 768px) and (max-width: 992px) {
          font-size: 14px;
      }
      
      /* Large devices (large desktops) */
      @media only screen and (min-width: 992px) {
          font-size: 16px;
      }

    }
    body {
      font-family: ${props.theme.fontFamily}, 'Ubuntu-Regular, sans-serif';
      
      margin: 0;
      },
      margin: 0;
      font-weight: 300;
      color: ${props.theme.colors.black};

      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: thin;
    }
  `
)

export default GlobalStyle
