import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { times, random } from 'lodash'
import { calculatePos } from 'utils/taskHelper'
import theme from 'utils/theme'
import { Box, Text, Button } from '../../atoms'
import Line from '../../molecules/CognitiveTasks/Line'

const createBoxes = () => {
  let angle = 90
  const linesTmp = []
  while (angle <= 450) {
    linesTmp.push(calculatePos(280, angle, 600, 350))
    angle += 360 / 18.000001 // 18.000001 because of rounding in the while condition. Need 15 lines.
  }
  return linesTmp
}

const NumberInput = ({
  completePractice,
  completeTask,
  practiceDone,
  finalRound,
  wrongPracticeAnswer,
  correctPracticeAnswer,
  clickedBox,
  numCorrect,
}) => {
  const { t } = useTranslation(['pasatC'])
  const boxes = createBoxes()

  return (
    <Box width="100%" flexDirection="row" jc="center">
      <Box width="1100px" height="700px" position="relative" cc>
        {times(boxes.length, i => (
          <Line
            top={boxes[i].top}
            left={boxes[i].left}
            height="7%"
            width="5%"
            rounded
            onPress={() => clickedBox(i)}
            hover={theme.colors.greyDarker}
            maxWidth="60px"
            minWidth="40px"
            minHeight="40px"
          >
            <Text color="black" huge center>
              {i}
            </Text>
          </Line>
        ))}
        <Box width="100%">
          <Box position="absolute" bottom="40px" left="49%" cc>
            {wrongPracticeAnswer && (
              <Text semibold center bigger color="red">
                {t('incorrectAnswer')}
              </Text>
            )}
            {correctPracticeAnswer && (
              <Text semibold center bigger>
                {t('correctAnswer')}
              </Text>
            )}
          </Box>

          {practiceDone && (
            <Box width="100%" position="absolute" bottom="0" left="3%" cc>
              <Button onPress={() => completePractice()} block>
                <Text semibold center>
                  {t('continue')}
                </Text>
              </Button>
            </Box>
          )}
          {finalRound && (
            <Box width="100%" position="absolute" bottom="0" left="3%" cc>
              <Button onPress={() => completeTask()} block>
                <Text semibold center>
                  {t('skipRound')}
                </Text>
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box width="20%" cc>
        <Box width="50%" backgroundColor={theme.colors.grey} round cc mt="100px">
          <Text ginormous color={theme.colors.button.left}>
            {numCorrect}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default NumberInput
