import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../../utils/colors'
import Badge from './Badge'

const EveningDiaryBadge = ({ isActive, hasLabel, onPress }) => (
  <Badge
    bigLetter="E"
    color={colors.eveningSleepDiary.primary}
    isActive={isActive}
    label={hasLabel ? 'Evening diary' : ''}
    onPress={onPress}
  />
)

EveningDiaryBadge.propTypes = {
  isActive: PropTypes.bool.isRequired,
  hasLabel: PropTypes.bool.isRequired,
  onPress: PropTypes.func,
}

EveningDiaryBadge.defaultProps = {
  onPress: () => {},
}

export default EveningDiaryBadge
