import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'components/atoms'
import theme from 'utils/theme'

const EsqProgressBar = ({ progressPercentage, color1, color2 }) => (
  <Box bg="darkgrey" width="92%" height="10px">
    <Box width={progressPercentage} height="10px" color1={color1} color2={color2} />
  </Box>
)

EsqProgressBar.propTypes = {
  progressPercentage: PropTypes.string.isRequired,
  color1: PropTypes.string,
  color2: PropTypes.string,
}

EsqProgressBar.defaultProps = {
  color1: theme.colors.button.left,
  color2: theme.colors.button.right,
}

export default EsqProgressBar
