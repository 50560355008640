import React from 'react'
import { Box, Text, Footer, Header } from 'components'

const CookiesPolicy = () => (
  <Footer>
    <Box minHeight="100vh">
      <Header publicRouter />
      <Box flexGrow pv="2rem" ph="4rem" mb="140px" mt="80px">
        <Box mv="4px">
          <Text huge color="black">
            Cookies Policy
          </Text>
        </Box>
        <Box mv="4px">
          <Text color="black">
            Cookies are small text files stored on users’ computer by their browser. They have many
            applications, such as: tracking users as they navigate around a website; remembering
            user preferences; auto-logins for visitors coming back to a site; and website security.
            Website cookies policy was automatically implemented to the Sleep Revolution website.
          </Text>
        </Box>
      </Box>
      {/* <Box style={{ bottom: 0, position: 'relative' }}>
      <Footer />
    </Box> */}
    </Box>
  </Footer>
)

export default CookiesPolicy
