import React, { useMemo } from 'react'
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarRadiusAxis,
  PolarAngleAxis,
  ResponsiveContainer,
} from 'recharts'
import colors from 'utils/colors'
import { Box, Text } from '../..'
import RCSeverityDot from '../comps/RCSeverityDot'
import { chartSettings } from '../const/chartSettings'
import { RCTooltip, ESQVLegend } from '.'

// botherEntries = [bother_entry_result1, bother_entry_result2, ...]
const ESQVRC = ({ botherEntries }) => {
  const seriesData = useMemo(() => {
    const arr = []
    if (botherEntries && Array.isArray(botherEntries)) {
      botherEntries.forEach(er => {
        let entryName =
          er?.base?.entryId?.title?.replace('_bother', '') ??
          er?.followUp?.entryId?.title?.replace('_bother', '')
        entryName = entryName?.charAt(0)?.toUpperCase() + entryName?.slice(1)?.replace(/_/g, ' ')

        arr.push({
          name: entryName ?? '<Missing data>',
          base: er?.base?.selectAnswer ?? -1, // for N/A bother level
          followUp: er?.followUp?.selectAnswer ?? -1,
        })
      })
    }
    return arr
  }, [botherEntries])

  return (
    <Box jc="center" row flexWrap style={{ gap: '10px' }}>
      <Box style={{ overflowX: 'auto', width: '100%', maxWidth: '45rem' }}>
        <Box width="100%" mt="40px" style={{ minWidth: '35rem' }}>
          <ResponsiveContainer aspect={1.5}>
            <RadarChart
              data={seriesData}
              cx="50%"
              cy="50%"
              innerRadius="8%"
              outerRadius="70%"
              margin={{ top: 30, right: 40, bottom: 5, left: 40 }}
            >
              <PolarGrid stroke={colors.greyDarker} />
              <PolarAngleAxis
                dataKey="name"
                stroke={chartSettings.gridColor}
                strokeDasharray={[2, 10]}
                tickLine={false}
                tick={{ fill: chartSettings.textColor, width: 80 }}
                // tick={<ESQVTickChart />}
                radius={100}
              />
              <PolarRadiusAxis
                ticks={chartSettings.botherLevels.map((_, index) => index - 1)}
                tickCount={chartSettings.botherLevels.length + 1}
                axisLine={false}
                tick={false}
              />
              {RCTooltip({ bother: true, position: { x: 0, y: 0 } })}
              <Radar
                dataKey="base"
                stroke={chartSettings.textColor}
                strokeWidth={chartSettings.strokeWidth}
                fill={chartSettings.radarFillIntake}
                fillOpacity={chartSettings.radarFillOpacity}
                dot={<RCSeverityDot />}
                isAnimationActive={false}
              />
              <Radar
                dataKey="followUp"
                stroke={chartSettings.textColor}
                strokeWidth={chartSettings.strokeWidth}
                strokeDasharray={chartSettings.strokeDasharray}
                fill={chartSettings.radarFillFollowUp}
                fillOpacity={chartSettings.radarFillOpacity}
                dot={<RCSeverityDot />}
                isAnimationActive={false}
              />
            </RadarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
      <ESQVLegend bother />
    </Box>
  )
}

export default ESQVRC
