import * as React from 'react'
import PropTypes from 'prop-types'
import InfoIcon from '@mui/icons-material/Info'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

function InfoText({ infoText }) {
  const [anchorEl, setAnchorEl] = React.useState(false)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  return (
    <div
      style={{
        paddingTop: 3,
      }}
    >
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        color="white"
      >
        <InfoIcon sx={{ fontSize: 'medium', marginLeft: 1 }} />
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}> {infoText}</Typography>
      </Popover>
    </div>
  )
}

InfoText.propTypes = {
  infoText: PropTypes.string.isRequired,
}

export default InfoText
