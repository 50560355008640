import React from 'react'
import momentPropTypes from 'react-moment-proptypes'
import PropTypes from 'prop-types'
import colors from '../../utils/colors'
import CognitiveBatteryBadge from './Badges/CognitiveBatteryBadge'
import ESQFollowUpBadge from './Badges/ESQFollowUpBadge'
import ESQIntakeBadge from './Badges/ESQIntakeBadge'
import EveningDiaryBadge from './Badges/EveningDiaryBadge'
import EveningMyoBadge from './Badges/EveningMyoBadge'
import FlexibilityGameBadge from './Badges/FlexibilityGameBadge'
import MemoryGameBadge from './Badges/MemoryGameBadge'
import MiddayMyoBadge from './Badges/MiddayMyoBadge'
import MorningDiaryBadge from './Badges/MorningDiaryBadge'
import MorningMyoBadge from './Badges/MorningMyoBadge'
import PerceptionGameBadge from './Badges/PerceptionGameBadge'
import ReactionGameBadge from './Badges/ReactionGameBadge'
import Box from './Base/Box'
import Text from './Base/Text'

const CARD_HEIGHT = '8.5rem'
const CARD_WIDTH = '7.8rem'

const BubbleDate = ({
  date,
  isOut,
  isFuture,
  activeBadges,
  hasMorningDiary,
  hasEveningDiary,
  hasFlexibilityGame,
  hasReactionGame,
  hasMemoryGame,
  hasPerceptionGame,
  hasCognitiveBattery,
  hasMyoMorning,
  hasMyoMidday,
  hasMyoEvening,
  hasEsqIntake,
  hasEsqFollowUp,
}) => (
  // card container
  <Box
    width={CARD_WIDTH}
    height={CARD_HEIGHT}
    col
    bg={isFuture ? colors.greySoft : colors.white}
    style={{
      borderRadius: '8px',
      boxShadow: isFuture ? 'none' : '0 4px 18px 0 rgba(0, 0, 0, 0.12)',
      opacity: isOut ? '0' : '1',
    }}
  >
    {/* Container for the card's contents */}
    <Box flex={1} col style={{ opacity: isFuture ? '0' : '1' }} jc="space-around" pb="0.2rem">
      {/* day of month text */}
      <Box ml="7px" mt="1px">
        <Text bold fontSize="18px" color={colors.greyDarker}>
          {date.date().toString().padStart(2, '0')}
        </Text>
      </Box>

      {/* Show Badges that are in activeBadges dict */}
      <Box mb="4px" spaceBetween>
        {(activeBadges?.morning ||
          activeBadges?.evening ||
          activeBadges?.cognitive ||
          activeBadges?.flexibility) && (
          <Box flexDirection="row" spaceEvenly pl="7px" pr="7px" mb="3px">
            {activeBadges?.morning && (
              <MorningDiaryBadge isActive={hasMorningDiary} hasLabel={false} />
            )}
            {activeBadges?.evening && (
              <EveningDiaryBadge isActive={hasEveningDiary} hasLabel={false} />
            )}
            {activeBadges?.cognitive && (
              <CognitiveBatteryBadge isActive={hasCognitiveBattery} hasLabel={false} />
            )}
            {activeBadges?.flexibility && (
              <FlexibilityGameBadge isActive={hasFlexibilityGame} hasLabel={false} />
            )}
          </Box>
        )}
        {(activeBadges?.memory ||
          activeBadges?.perception ||
          activeBadges?.reaction ||
          activeBadges?.morningMyo) && (
          <Box flexDirection="row" spaceEvenly pl="7px" pr="7px" mb="3px">
            {activeBadges?.reaction && (
              <ReactionGameBadge isActive={hasReactionGame} hasLabel={false} />
            )}
            {activeBadges?.memory && <MemoryGameBadge isActive={hasMemoryGame} hasLabel={false} />}
            {activeBadges?.perception && (
              <PerceptionGameBadge isActive={hasPerceptionGame} hasLabel={false} />
            )}
            {activeBadges?.morningMyo && (
              <MorningMyoBadge isActive={hasMyoMorning} hasLabel={false} />
            )}
          </Box>
        )}
        {(activeBadges?.middayMyo ||
          activeBadges?.eveningMyo ||
          activeBadges.esqIntake ||
          activeBadges.esqFollowUp) && (
          <Box flexDirection="row" spaceEvenly pl="7px" pr="7px" mb="3px">
            {activeBadges?.middayMyo && <MiddayMyoBadge isActive={hasMyoMidday} hasLabel={false} />}
            {activeBadges?.eveningMyo && (
              <EveningMyoBadge isActive={hasMyoEvening} hasLabel={false} />
            )}
            {activeBadges?.esqIntake && <ESQIntakeBadge isActive={hasEsqIntake} hasLabel={false} />}
            {activeBadges?.esqFollowUp && (
              <ESQFollowUpBadge isActive={hasEsqFollowUp} hasLabel={false} />
            )}
          </Box>
        )}
      </Box>
    </Box>
  </Box>
)

BubbleDate.propTypes = {
  date: momentPropTypes.momentObj.isRequired,
  isOut: PropTypes.bool.isRequired,
  isFuture: PropTypes.bool.isRequired,
  hasMorningDiary: PropTypes.bool,
  hasEveningDiary: PropTypes.bool,
  hasFlexibilityGame: PropTypes.bool,
  hasMemoryGame: PropTypes.bool,
  hasPerceptionGame: PropTypes.bool,
  hasReactionGame: PropTypes.bool,
  activeBadges: PropTypes.object,
}

BubbleDate.defaultProps = {
  hasMorningDiary: false,
  hasEveningDiary: false,
  hasFlexibilityGame: false,
  hasMemoryGame: false,
  hasPerceptionGame: false,
  hasReactionGame: false,
  activeBadges: {
    morning: true,
    evening: true,
    cognitive: true,
    memory: true,
    perception: true,
    flexibility: true,
    reaction: true,
    esqIntake: true,
    esqFollowUp: true,
  },
}

export default BubbleDate
