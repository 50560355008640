import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../../utils/colors'
import Badge from './Badge'

const MorningDiaryBadge = ({ isActive, hasLabel, onPress }) => (
  <Badge
    bigLetter="M"
    color={colors.morningSleepDiary.primary}
    isActive={isActive}
    onPress={onPress}
    label={hasLabel ? 'Morning diary' : ''}
  />
)

MorningDiaryBadge.propTypes = {
  isActive: PropTypes.bool.isRequired,
  hasLabel: PropTypes.bool.isRequired,
  onPress: PropTypes.func,
}

MorningDiaryBadge.defaultProps = {
  onPress: () => {},
}

export default MorningDiaryBadge
