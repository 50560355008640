import React from 'react'
import PropTypes from 'prop-types'
import Box from '../Base/Box'
import Text from '../Base/Text'
import ListItem from './ListItem'

const ListBox = ({ title, items, color }) => (
  <Box bg={color} round p="32px" style={{ display: 'inline' }}>
    <Box pb="4px">
      <Text bigger bold center>
        {title}
      </Text>
    </Box>
    <Box>
      {items.map(item => (
        <ListItem key={`listItem#${item.title}`} title={item.title} value={item.value} />
      ))}
    </Box>
  </Box>
)

ListBox.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
}

export default ListBox
