/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import InfoIcon from '@mui/icons-material/Info'
import moment from 'moment'
import colors from 'utils/colors'
import UserContext from 'utils/UserContext'
import BubbleCalendarView from 'components/organisms/BubbleCalendarView'
import {
  WithingsPresenter,
  Box,
  ActionButton,
  BodyMeasurementPresenter,
  Footer,
  Button,
  Text,
} from '../../components'

const GET_FORM_RESULTS = gql`
  query dobbyFormResults(
    $studyTitle: String
    $formTitlesEntryTitles: [DobbyGetFormResultsInputType!]!
    $maxAgeDays: Int
  ) {
    dobbyFormResults(
      studyTitle: $studyTitle
      formTitlesEntryTitles: $formTitlesEntryTitles
      maxAgeDays: $maxAgeDays
    ) {
      formTitle
      entryResults {
        title
        value
        isNumber
      }
    }
  }
`

const calendarWidth = '65rem'

const Me = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation(['esq'])
  const { user } = useContext(UserContext)
  // const [getFormResults, { data, loading, error }] = useLazyQuery(GET_FORM_RESULTS)
  const [esqBaseDone, setESQBaseDone] = useState(false)
  const [esqBaseDoneAt, setESQBaseDoneAt] = useState(null)
  const [esqFollowUpDone, setESQFollowUpDone] = useState(false)
  const [esqFollowUpDoneAt, setESQFollowUpDoneAt] = useState(null)
  const [esqFUText, setEsqFUText] = useState(null)
  const [daysUntilFollowUp, setDaysUntilFollowUp] = useState(90)

  const { data, loading, error } = useQuery(GET_FORM_RESULTS, {
    fetchPolicy: 'network-only',
    variables: {
      formTitlesEntryTitles: [
        {
          formTitle: 'ESQ Metadata',
          entryTitles: ['base_finished_at', 'follow_up_finished_at', 'days_until_follow_up'],
        },
      ],
    },
  })

  useEffect(() => {
    if (!loading && !error && data) {
      data?.dobbyFormResults?.[0]?.entryResults?.forEach(entry => {
        if (entry.title === 'base_finished_at' && entry.value) {
          setESQBaseDone(true)
          setESQBaseDoneAt(entry.value)
        }
        if (entry.title === 'follow_up_finished_at' && entry.value) {
          setESQFollowUpDone(true)
          setESQFollowUpDoneAt(entry.value)
        }
        if (entry.title === 'days_until_follow_up') {
          const value = Number(entry.value)
          if (!Number.isNaN(value) && value >= 0) {
            setDaysUntilFollowUp(value)
          }
        }
      })
    }
  }, [data, loading, error])

  // ------ this was not updating after submitting esq and returning to this page -------
  // useEffect(() => {
  //   console.log('in hook')
  //   getFormResults({
  //     fetchPolicy: 'network-only',
  //     variables: {
  //       formTitlesEntryTitles: [
  //         { formTitle: 'ESQ Metadata', entryTitles: ['base_finished_at', 'follow_up_finished_at'] },
  //       ],
  //     },
  //     onCompleted: data => {
  //       data?.dobbyFormResults?.[0]?.entryResults?.forEach(entry => {
  //         if (entry.title === 'base_finished_at' && entry.value) {
  //           setESQBaseDone(true)
  //           setESQBaseDoneAt(entry.value)
  //         }
  //         if (entry.title === 'follow_up_finished_at' && entry.value) {
  //           setESQFollowUpDone(true)
  //         }
  //       })
  //     },
  //   })
  // }, [getFormResults])

  useEffect(() => {
    if (
      !esqFollowUpDone &&
      esqBaseDone &&
      moment(esqBaseDoneAt).add(daysUntilFollowUp, 'days') > moment()
    ) {
      setEsqFUText([
        t('AvailableIn', {
          days: moment(esqBaseDoneAt)
            .add(daysUntilFollowUp + 1, 'days')
            .diff(moment(), 'days'),
        }),
      ])
    } else if (esqFollowUpDone) {
      setEsqFUText([])
    } else {
      setEsqFUText(null)
    }
  }, [esqBaseDone, esqFollowUpDone, esqBaseDoneAt, daysUntilFollowUp])

  return (
    <Box>
      <Box pt="calc(60px + 2rem)" ph="1rem" bg={colors.greyDark} style={{ minHeight: '100vh' }}>
        <Box pb="4rem">
          <Box
            outline
            round
            bg={colors.grey}
            pv="21px"
            ph="26px"
            mb="2rem"
            row
            style={{ boxSizing: 'border-box' }}
          >
            <InfoIcon sx={{ fill: colors.alertnessTask.primary, marginRight: '10px' }} />
            <div style={{ color: colors.white, textAlign: 'justify', paddingRight: '40px' }}>
              We are proud to introduce the European Sleep Questionnaire (ESQ), which is a
              comprehensive questionnaire translated into 15 languages to date. Although the
              questions and the content of the ESQ have been translated, we have not translated the
              buttons in the SleepWell platform,{' '}
              <span style={{ fontWeight: 'bold' }}>
                so some of the words that appear on the buttons in the SleepWell platform may be in
                English
              </span>
              . We thank you for your understanding.
            </div>
          </Box>
          <Box jc="center" row flexWrap style={{ gap: '1rem' }}>
            <Box
              round
              bg={colors.white}
              mb="1rem"
              style={{ overflowX: 'auto', maxWidth: calendarWidth }}
            >
              <BubbleCalendarView esqBaseDate={esqBaseDoneAt} esqFollowUpDate={esqFollowUpDoneAt} />
            </Box>
            {/* <WithingsPresenter /> */}

            <Box col flexGrow maxWidth={calendarWidth}>
              {/* {esqBaseDone && esqFollowUpDone && ( */}
              {esqBaseDone && (
                <Box round mb="2rem">
                  <Button
                    center
                    block
                    maxWidth="100%"
                    onPress={() =>
                      navigate('/esqvisualization', {
                        state: {
                          sri: user?.firstname,
                          personal: true,
                          esqMetadata: {
                            base: esqBaseDoneAt,
                            followUp: esqFollowUpDoneAt,
                          },
                        },
                      })
                    }
                  >
                    <Text bigger center bold>
                      ESQ Visualization
                    </Text>
                  </Button>
                </Box>
              )}
              <ActionButton
                // Icon={ReactionIcon}
                header={t('ESQ')}
                description={
                  esqBaseDone
                    ? ''
                    : [
                        <div>
                          {t('ESQintro')}
                          <br />
                          <br />
                        </div>,
                        <div>{t('ESQ0')}</div>,
                        <div>{t('ESQ1')}</div>,
                        <div>{t('ESQ2')}</div>,
                        <div>{t('ESQ3')}</div>,
                        <div>{t('ESQ4')}</div>,
                        <div>{t('ESQ5')}</div>,
                        <div>
                          {t('ESQ6')}
                          <br />
                          <br />
                        </div>,
                        <div>
                          {t('ESQintro2')}
                          <br />
                          <br />
                        </div>,
                        <div>{t('ESQthanks')}</div>,
                      ]
                }
                actionText={
                  esqBaseDone ? 'Thank you for successfully completing the ESQ' : t('ESQstart')
                }
                color1={esqBaseDone ? colors.grey : colors.alertnessTask.left}
                color2={esqBaseDone ? colors.grey : colors.alertnessTask.right}
                onPress={() =>
                  navigate('/Esq', {
                    state: {
                      clickedButton: true,
                    },
                  })
                }
                checked={esqBaseDone}
                disabled={!!!data} // so it is disabled while fetching metadata
              />
              <ActionButton
                // Icon={ReactionIcon}
                header="ESQ - Follow Up"
                description={
                  esqFUText || [
                    <div>
                      {t('ESQintro')}
                      <br />
                      <br />
                    </div>,
                    <div>{t('ESQ0')}</div>,
                    <div>{t('ESQ1')}</div>,
                    <div>{t('ESQ2')}</div>,
                    <div>{t('ESQ3')}</div>,
                    <div>{t('ESQ4')}</div>,
                    <div>{t('ESQ5')}</div>,
                    <div>
                      {t('ESQ6')}
                      <br />
                      <br />
                    </div>,
                    <div>
                      {t('ESQintro2')}
                      <br />
                      <br />
                    </div>,
                    <div>{t('ESQthanks')}</div>,
                  ]
                }
                actionText={
                  esqFollowUpDone
                    ? 'Thank you for successfully completing the ESQ Follow-up'
                    : t('ESQstart')
                }
                color1={esqFollowUpDone ? colors.grey : colors.pvsat.left}
                color2={esqFollowUpDone ? colors.grey : colors.pvsat.right}
                onPress={() =>
                  navigate('/EsqFollowUp', {
                    state: {
                      clickedButton: true,
                      esqBaseDoneAt: esqBaseDoneAt,
                    },
                  })
                }
                checked={esqFollowUpDone}
                disabled={
                  !esqBaseDone ||
                  moment(esqBaseDoneAt).add(daysUntilFollowUp, 'days') > moment() ||
                  esqFollowUpDone
                }
              />
              <ActionButton
                // Icon={ReactionIcon}
                header="Cognitive Tasks"
                description={[
                  <div>Here, you can find additional neurocognitive games.</div>,
                  <div>These are optional, but give a better insight into your sleep health.</div>,
                ]}
                actionText="Start"
                color1={colors.reactionTask.left}
                color2={colors.reactionTask.right}
                onPress={() => navigate('/cognitivebattery')}
              />
              {/* <BodyMeasurementPresenter /> */}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Box style={{ bottom: 0, position: 'relative' }}>
        <Footer />
      </Box> */}
    </Box>
  )
}
export default Me
