import React from 'react'
import PropTypes from 'prop-types'
import Box from './Base/Box'
import Text from './Base/Text'

const ActionBox = ({ children, color1, color2 }) => (
  <Box color1={color1} color2={color2} flex rounded p="5px">
    <Text semibold center>
      {children}
    </Text>
  </Box>
)

ActionBox.propTypes = {
  children: PropTypes.node.isRequired,
  color1: PropTypes.string.isRequired,
  color2: PropTypes.string.isRequired,
}

export default ActionBox
