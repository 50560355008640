const colors = {
  primaryColor: '#1f2937',
  secondaryColor: '#e5e7eb',
  white: '#ffffff',
  light: '#FFFFFC',
  greySoft: '#f3f4f6',
  grey: '#323034',
  greyDark: '#272529',
  greyMid: '#d1d5db',
  greyDarker: '#6b7280',
  greyDarkest: '#4b5563',
  greyDarkestTransparent: 'rgba(75, 85, 99, 0.16)',
  black: '#1f2937',
  green: '#52CC47',
  yellow: '#FFBF3B',
  orange: '#F59429',
  red: '#F04024',
  pink: '#DF57C1',
  purpleLight: '#C266FF',
  purple: '#870DFF',
  bluepurple: '#7166D9',
  bluepurplerLight: '#a9a3e8',
  bluepurplerLighter: '#e2e0f7',
  bluepurplerLightest: '#F0EFFB',
  greyblue: '#476CCC',
  blue: '#47A4CC',
  button: {
    left: '#B4419B',
    right: '#870DFF',
  },
  eveningSleepDiary: {
    primary: '#36BB9B',
    left: '#478CCC',
    right: '#36BB9B',
  },
  morningSleepDiary: {
    primary: '#C266FF',
    left: '#B4419B',
    right: '#870DFF',
  },
  alertnessTask: {
    primary: '#47A4CC',
    left: '#476CCC',
    right: '#47A4CC',
  },
  reactionTask: {
    primary: '#FFBF3B',
    left: '#FFBF3B',
    right: '#DF57C1',
  },
  listLearningTask: {
    primary: '#989CFF',
    left: '#5E6CEA',
    right: '#7166D9',
  },
  auditoryVerbalLearningTask: {
    primary: '#FFCC00',
    left: '#FFCC00',
    right: '#FFFF66',
  },
  pasatC: {
    primary: '#C70039',
    left: '#900C3F',
    right: '#FF5733',
  },
  pvsat: {
    primary: '#47C4CC',
    left: '#47C4CC',
    right: '#52CC47',
  },
  digitSymbolTask: {
    primary: '#f59428',
    left: '#ef4024',
    right: '#f59429',
  },
  towerOfLondon: {
    primary: '#C266FF',
    left: '#B4419B',
    right: '#870DFF',
  },
  corsiBlockTask: {
    blockBlue: '#0066FF',
    blockYellow: '#FFFF00',
    primary: '#989CFF',
    left: '#5E6CEA',
    right: '#7167DA',
  },
  inspectionTimeTask: {
    primary: '#F59429',
    left: '#F04024',
    right: '#F59429',
  },
  caffeine: {
    primary: '#9F58E5',
    left: '#9F58E5',
    right: '#3682DB',
  },
  naps: {
    primary: '#47C4CC',
    left: '#47C4CC',
    right: '#52CC47',
  },
  exercise: {
    primary: '#57A6DF',
    left: '#57A6DF',
    right: '#3B7FE6',
  },
  myofunctional: {
    primary: '#B4419B',
    left: '#794AC5',
    right: '#B4419B',
  },
  mood: {
    left: '#F59429',
    right: '#F04024',
  },
  medicine: {
    primary: '#57A6DF',
    left: '#57A6DF',
    right: '#3B7FE6',
  },
  alcohol: {
    primary: '#DCC500',
    left: '#DF57C1',
    right: '#DCC500',
  },
  lightBrown: '#ECE8E2',
}
export default colors
