import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const StyledClickable = styled.button(
  ({ disabled }) => css`
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    cursor: ${disabled ? 'default' : 'pointer'};

    ${disabled &&
    css`
      pointer-events: none;
    `}

    background: transparent;
  `
)

const Clickable = ({ children, onPress, disabled }) => (
  <StyledClickable onClick={onPress} disabled={disabled}>
    {children}
  </StyledClickable>
)

Clickable.propTypes = {
  children: PropTypes.node.isRequired,
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

Clickable.defaultProps = {
  disabled: false,
}

export default Clickable
