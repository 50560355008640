import { useState, useEffect, useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import { getUid, getToken } from './helper'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

// export const useFetchExport = ({ headers }) => {
//   const [data, setData] = useState(null)
//   const [error, setError] = useState(false)
//   const [loading, setLoading] = useState(false)
//   const [url, setUrl] = useState(undefined)
//   useEffect(() => {
//     const fetchData = async () => {
//       setError(false)
//       setLoading(true)
//       try {
//         let filename
//         const result = await fetch(url, {
//           method: 'GET',
//           headers: {
//             ...headers,
//             'access-token': getToken(),
//             uid: getUid(),
//             client: useApolloClient(),
//           },
//         })
//           .then(res => {
//             // eslint-disable-next-line prefer-destructuring
//             filename = res.headers.get('content-disposition').split('"')[1]
//             return res.blob()
//           })
//           .then(blob => {
//             const downloadUrl = window.URL.createObjectURL(new Blob([blob]))
//             const link = document.createElement('a')
//             link.href = downloadUrl
//             link.setAttribute('download', filename)
//             link.click()
//             link.parentNode.removeChild(link)
//             return blob
//           })
//           .finally(() => setUrl(undefined))
//         setData(result)
//       } catch (err) {
//         setError(err)
//       }
//       setLoading(false)
//     }

//     if (url) fetchData()
//   }, [url, headers])
//   return [{ data, loading, error }, setUrl]
// }

export const useLocalStorageJSON = (key, initialState) => {
  const serializedInitialState = JSON.stringify(initialState)
  let storageValue = initialState
  try {
    storageValue = JSON.parse(localStorage.getItem(key)) ?? initialState
  } catch {
    localStorage.setItem(key, serializedInitialState)
  }
  const [value, setValue] = useState(storageValue)
  const updatedSetValue = useCallback(
    newValue => {
      const serializedNewValue = JSON.stringify(newValue)
      if (serializedNewValue === serializedInitialState || typeof newValue === 'undefined') {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, serializedNewValue)
      }
      setValue(newValue ?? initialState)
    },
    [initialState, serializedInitialState, key]
  )
  return [value, updatedSetValue]
}

export const useLocalStorage = (key, initialState) => {
  const [value, setValue] = useState(localStorage.getItem(key) ?? initialState)
  const updatedSetValue = useCallback(
    newValue => {
      if (newValue === initialState || typeof newValue === 'undefined') {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, newValue)
      }
      setValue(newValue ?? initialState)
    },
    [initialState, key]
  )
  return [value, updatedSetValue]
}
