import React from 'react'
import PropTypes from 'prop-types'

const isDev = process.env.REACT_APP_ENV === 'development'

const Error = ({ error }) => (
  <div
    style={{
      paddingTop: 50,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1080,
      margin: 'auto',
    }}
  >
    <div style={{ backgroundColor: 'lightgray' }}>{isDev && error.message}</div>
  </div>
)

Error.propTypes = {
  error: PropTypes.node,
}

Error.defaultProps = {
  error: { message: 'Error' },
}

export default Error
