import React, { useEffect, useState, useCallback } from 'react'
import 'react-chat-elements/dist/main.css'
import { ChatList, MessageList, Input, Button } from 'react-chat-elements'
import { gql, useMutation, useQuery, useSubscription } from '@apollo/client'
import { FormControlLabel, Switch } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Conversation, Filters } from 'components'
import ChatComponent from 'components/organisms/Chat'
import colors from 'utils/colors'
import theme from 'utils/theme'
import { Text, Box } from '../../components/atoms'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box mt="32px">{children}</Box>}
    </div>
  )
}

const PARTICIPANTS_FILTERS_QUERY = gql`
  query AllParticipations {
    allParticipations {
      filterFields {
        title
        filterType
        select
      }
    }
  }
`

const CustomSwitch = styled(Switch)(({ customColor }) => ({
  marginLeft: '1rem',
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: customColor,
    // '&:hover': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    '& + .MuiSwitch-track': {
      backgroundColor: customColor,
    },
  },
}))

const Chat = () => {
  const [tab, setTab] = useState(0)
  const { loading, error, data } = useQuery(PARTICIPANTS_FILTERS_QUERY)
  const [studies, setStudies] = useState([])
  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const [hide, setHide] = useState(true)

  // Toggle the switch state when clicked
  const toggleHide = () => {
    const newHide = !hide
    setHide(newHide)
  }

  useEffect(() => {
    if (data && data !== null) {
      const preparedFilters = data.allParticipations?.filterFields?.map(item => ({
        label: item.title,
        valType: { NR: 'number', SO: 'string', TF: 'string', DA: 'date' }[item.filterType],
        selection: item.select && JSON.parse(item.select),
      }))
      setStudies(preparedFilters?.[0]?.selection)
    }
  }, data)

  return (
    <Box ph={theme.spacing.huge} pv={theme.spacing.largest}>
      <FormControlLabel
        control={
          <CustomSwitch
            checked={hide}
            onChange={toggleHide}
            customColor={theme.colors.bluepurple}
          />
        }
        label="Hide Group"
      />
      <Box row jc="space-between" style={{ borderBottom: '1px solid rgb(212, 212, 212)' }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: colors.bluepurple,
            },
          }}
        >
          {studies.map((study, index) => (
            <Tab
              label={study}
              style={{ color: tab === index ? colors.bluepurple : colors.black }}
            />
          ))}
        </Tabs>
      </Box>
      {studies.map((study, index) => (
        <TabPanel value={tab} index={index}>
          <ChatComponent studyTitle={study} hideGroups={hide} />
        </TabPanel>
      ))}
    </Box>
  )
}
export default Chat
