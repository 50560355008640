export const getAnswerReadable = er => {
  const entryType = er?.entryId?.entryType
  if (entryType === 'SO') {
    const selection = er?.entryId?.selection?.replaceAll('�', '?')
    try {
      const arr = JSON.parse(selection)
      return arr[er.selectAnswer]
    } catch (error) {
      // try cleaning common issue
      try {
        const selectionCleaned = selection.replaceAll('\\', '')
        const arr = JSON.parse(selectionCleaned)
        return arr[er.selectAnswer]
      } catch (error2) {
        console.error('Error parsing JSON after cleaning attempt:', error2.message)
      }
      console.error('Error parsing JSON:', error.message)
    }
  } else if (entryType === 'TX') {
    return er.textAnswer ?? ''
  } else if (entryType === 'NR') {
    return er.numberAnswer ?? ''
  } else if (entryType === 'DF') {
    return er.datefieldAnswer ?? ''
  } else if (entryType === 'TF') {
    return er.timefieldAnswer ?? ''
  } else if (entryType === 'DT') {
    return er.datetimefieldAnswer ?? ''
  }
  return ''
}
