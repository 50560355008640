import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { processEntryResults } from 'utils/formResultHelper'
import theme from 'utils/theme'
import { ListBox } from '../atoms'

const GET_BODY_MEASUREMENT_RESULTS = gql`
  query GetFormResults($participationId: ID) {
    formResult(formType: "BM", participationId: $participationId) {
      id
      entryResults {
        entryId {
          title
          entryType
        }
        numberAnswer
        textAnswer
        selectAnswer
        datefieldAnswer
        datetimefieldAnswer
        timefieldAnswer
      }
    }
  }
`

const BodyMeasurementPresenter = ({ participationId }) => {
  const [entryResults, setEntryResults] = useState([])
  const latestBodyMeasurement = useQuery(GET_BODY_MEASUREMENT_RESULTS, {
    variables: { formType: 'BM', participationId },
    onCompleted: ({ formResult }) => {
      if (formResult?.entryResults) {
        setEntryResults(processEntryResults(formResult.entryResults))
      }
    },
  })

  if (entryResults?.length > 0) {
    return <ListBox title="Body Measurements" items={entryResults} color={theme.colors.grey} />
  }

  return null
}

export default BodyMeasurementPresenter
