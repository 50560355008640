import React, { useContext } from 'react'
import { TextField, Typography, InputAdornment } from '@mui/material'
import { FastField, useField } from 'formik'
import { LangContext } from '../const/Contexts'
import { shouldUpdateFastField } from './FastFieldShouldUpdateOverride'

const FormikMuiInput = ({ name, label, unit, ...props }) => {
  const { i18n } = useContext(LangContext)
  const [field, meta, helpers] = useField(name)
  const { setValue, setTouched } = helpers
  const { error, touched } = meta

  const handleChange = event => {
    setValue(event.target.value)
    // causes required error to trigger even when valid
    // if (!touched) {
    //   setTouched(true)
    // }
  }

  return (
    <FastField
      name={name}
      meta={{ lang: i18n.language, disabled: props.disabled || props.readOnly }}
      shouldUpdate={shouldUpdateFastField}
    >
      {() => (
        <TextField
          {...props}
          {...field}
          InputProps={{
            readOnly: props.readOnly,
            startAdornment: unit ? (
              <InputAdornment position="start">
                <Typography>{unit}</Typography>
              </InputAdornment>
            ) : undefined,
          }}
          label={<Typography>{label}</Typography>}
          fullWidth
          variant="filled"
          onChange={handleChange}
          error={touched && !!error}
          helperText={touched && error}
        />
      )}
    </FastField>
  )
}

export default FormikMuiInput
