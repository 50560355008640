import React from 'react'
import PropTypes from 'prop-types'
import { CheckCircleIcon } from 'assets/icons'
import theme from 'utils/theme'
import { Box, Text, ActionBox, Clickable } from '../atoms'

const ActionButton = ({
  header,
  description,
  actionText,
  color1,
  color2,
  Icon,
  onPress,
  checked,
  disabled,
}) => (
  <Clickable onPress={onPress} disabled={disabled || checked}>
    <Box
      round
      bg={theme.colors.grey}
      flex={1}
      pv="21px"
      ph="21px"
      mb="2rem"
      style={{ opacity: checked || disabled ? 0.5 : 1 }}
    >
      <Box row jc="space-between">
        <Box row>
          {/* <Icon width={25} height={25} /> */}
          <Box>
            {/*   pl="12px"> */}
            <Text bigger bold color="white">
              {header}
            </Text>
          </Box>
        </Box>
        <Box col jc="center">
          <CheckCircleIcon
            fill={checked ? theme.colors.green : theme.colors.greyDarker}
            height="1.5rem"
            width="1.5rem"
          />
        </Box>
      </Box>
      <Box pv="8px">
        <Text color="white">{description}</Text>
      </Box>
      <ActionBox color1={color1} color2={color2} center>
        <Text bold center color="white">
          {actionText}
        </Text>
      </ActionBox>
    </Box>
  </Clickable>
)

ActionButton.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  color1: PropTypes.string,
  color2: PropTypes.string,
  Icon: PropTypes.func.isRequired,
  onPress: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}

ActionButton.defaultProps = {
  color1: theme.colors.button.left,
  color2: theme.colors.button.right,
  checked: false,
  disabled: false,
}

export default ActionButton
