import React, { useEffect, useContext } from 'react'
import { Conversation } from 'components/organisms'
import UserContext from 'utils/UserContext'
import colors from '../../../utils/colors'
import Box from '../Base/Box'

const CHAT_WINDOW_WIDTH = '317px'
const CHAT_WINDOW_HEIGHT = '400px'

const ChatWindowBox = () => {
  const { user } = useContext(UserContext)

  return (
    <Box
      style={chatWindowBoxStyle}
      height={CHAT_WINDOW_HEIGHT}
      width={CHAT_WINDOW_WIDTH}
      bg={colors.white}
      rounded
    >
      <Conversation noPaddingTop username={user?.username} isStaff={user?.role === 'staff'} />
    </Box>
  )
}

const chatWindowBoxStyle = {
  position: 'fixed',
  bottom: '80px',
  right: '20px',
}

export default ChatWindowBox
