/* eslint-disable camelcase */

import React, { useContext } from 'react'
import Comment from '../../comps/Comment'
import Info from '../../comps/Info'
import Section from '../../comps/Section'
import { LangContext } from '../../const/Contexts'
import DFGiantScale from './Subsections/DFGiantScale'
import DFSmallScales from './Subsections/DFSmallScales'

export const DFFieldNames = [
  'fell_unintentionally_asleep_passive',
  'fell_unintentionally_asleep_moderate',
  'fell_unintentionally_asleep_strong',
  'fell_unintentionally_asleep_bother',
  'effort_to_stay_awake',
  'special_measures_to_stay_awake',
  'activities_without_awareness',
  'fight_sleepy_bother',
  'overslept_with_alarm',
  'overslept_with_alarm_bother',
  'lack_of_energy_leisure_start',
  'lack_of_energy_duty_start',
  'lack_of_energy_any_start',
  'lack_of_energy_bother',
  'exhausted_activity_strong',
  'exhausted_activity_moderate',
  'exhausted_activity_passive',
  'exhausted_activity_bother',
  'exhausted_physical_activity_vigorous',
  'exhausted_physical_activity_moderate',
  'exhausted_physical_activity_light',
  'exhausted_physical_activity_bother',
  'difficulty_remembering_frequency',
  'difficulty_remembering_bother',
  'difficulty_concentrating_frequency',
  'difficulty_concentrating_bother',
  'lapses_of_attention_frequency',
  'lapses_of_attention_bother',
  'anxiety_frequency',
  'anxiety_bother',
  'depression_frequency',
  'depression_bother',
  'losing_temper_frequency',
  'losing_temper_bother',
  'problems_with_relationships_frequency',
  'problems_with_relationships_bother',
  'problems_with_social_interaction_frequency',
  'problems_with_social_interaction_bother',
  'problems_with_sexual_desire_activity_frequency',
  'problems_with_sexual_desire_activity_bother',
  'problems_with_daily_activities_frequency',
  'problems_with_daily_activities_bother',
  'sudden_muscle_weakness_frequency',
  'sudden_muscle_weakness_bother',
  'safety_concerns_frequency',
  'safety_concerns_bother',
  'concerns_with_health_frequency',
  'concerns_with_health_bother',
  'near_accident',
]

const DaytimeFunctioning = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  return (
    <Section>
      <Info text={t('DaytimeFunctioninginfo')}>
        <Comment text={t('DaytimeFunctioningInfo')} />
      </Info>
      <DFSmallScales formikProps={formikProps} />
      <DFGiantScale formikProps={formikProps} />
    </Section>
  )
}

export default DaytimeFunctioning
