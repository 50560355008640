import React from 'react'
import { useSearchParams } from 'react-router-dom'
import backgroundImage from '../assets/images/login_background_image.jpg'
import { Text, PasswordResetForm, Box } from '../components'

const PasswordReset = () => {
  const [searchParams] = useSearchParams()
  const resetToken = searchParams.get('token')

  return (
    <Box
      flex={1}
      cc
      justifyContent="center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <PasswordResetForm token={resetToken} />
    </Box>
  )
}
export default PasswordReset
