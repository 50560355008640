import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { flatten } from 'lodash'
import moment from 'moment'
import colors from 'utils/colors'
import { Box, Text } from '../atoms'

/**
 * A component that generates that handles the infrastructure around creating a
 * calendar. You supply your own function (`createDate`), that tells this
 * component how to render each date in the calendar, given some information
 * about each date that is supplied to you by this component.
 */

// helper functions
const getKey = d => d.toISOString()
const getIsFuture = (d, other) => d.isAfter(other.add(7, 'days'))

const CustomWeeklyCalendar = ({ startDate, createDate, hSpacing, vSpacing, weekdays }) => {
  const start = useMemo(() => moment(startDate), [startDate])
  const currDate = useMemo(() => start.clone(), [start])

  // collect all of the dates that should appear in the calendar
  const dates = useMemo(() => {
    const arr = []
    const finalDate = start.clone().add(6, 'days')
    while (currDate.isSameOrBefore(finalDate)) {
      arr.push(currDate.clone())
      currDate.add(1, 'day')
    }
    return arr
  }, [start, currDate])

  // return each week as a calendar row
  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          padding: '0 3rem 0 3rem',
          marginBottom: '0.5rem',
        }}
      >
        {weekdays &&
          weekdays.map(wd => (
            <div key={wd} style={{ width: '2rem', textAlign: 'center' }}>
              <Text small semibold center color={colors.greyDarker}>
                {wd}
              </Text>
            </div>
          ))}
      </div>
      <div
        key="cal-row"
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: vSpacing,
          width: '100%',
          justifyContent: 'space-around',
        }}
      >
        {dates?.flatMap((date, dateIndex) => {
          const isFinalDate = dateIndex === dates.length - 1

          return [
            createDate(date, false, getIsFuture(date, start), getKey(date)),
            isFinalDate ? null : (
              <Box key={`divider-${getKey(date)}`} height="1px" width={hSpacing} />
            ),
          ]
        })}
      </div>
    </div>
  )
}

CustomWeeklyCalendar.propTypes = {
  hSpacing: PropTypes.string,
  vSpacing: PropTypes.string,
  startDate: PropTypes.string,
}

CustomWeeklyCalendar.defaultProps = {
  hSpacing: '1em',
  vSpacing: '0em',
  startDate: moment().format(),
}

export default CustomWeeklyCalendar
