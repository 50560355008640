import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '../../atoms'
import InputField from '../../molecules/CognitiveTasks/InputField'

const WordListForm = ({ processResults }) => {
  const { t } = useTranslation(['auditoryVerbalLearning'])

  const [inputWords, setInputWords] = useState('')

  const handleSubmit = () => {
    processResults(inputWords)
    setInputWords('')
  }

  return (
    <Box cc>
      <InputField
        instructions={t('inputInstructions')}
        onPress={handleSubmit}
        onChange={value => setInputWords(value)}
        width="320"
        rows="11"
        placeholder={t('placeholder')}
        submitText={t('submitButton')}
      />
    </Box>
  )
}

export default WordListForm
