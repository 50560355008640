import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { TablePagination, useMediaQuery } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from 'assets/icons'
import CognitiveBatteryBadge from 'components/atoms/Badges/CognitiveBatteryBadge'
import ESQFollowUpBadge from 'components/atoms/Badges/ESQFollowUpBadge'
import ESQIntakeBadge from 'components/atoms/Badges/ESQIntakeBadge'
import EveningDiaryBadge from 'components/atoms/Badges/EveningDiaryBadge'
import EveningMyoBadge from 'components/atoms/Badges/EveningMyoBadge'
import FlexibilityGameBadge from 'components/atoms/Badges/FlexibilityGameBadge'
import MemoryGameBadge from 'components/atoms/Badges/MemoryGameBadge'
import MiddayMyoBadge from 'components/atoms/Badges/MiddayMyoBadge'
import MorningDiaryBadge from 'components/atoms/Badges/MorningDiaryBadge'
import MorningMyoBadge from 'components/atoms/Badges/MorningMyoBadge'
import PerceptionGameBadge from 'components/atoms/Badges/PerceptionGameBadge'
import ReactionGameBadge from 'components/atoms/Badges/ReactionGameBadge'
import { range } from 'lodash'
import moment from 'moment'
import Cookies from 'universal-cookie'
import colors from '../../utils/colors'
import UserContext from '../../utils/UserContext'
import { Box, Text, Clickable } from '../atoms'
import BubbleCalendar from '../molecules/BubbleCalendar'

const GET_PARTICIPANTS_DATA = gql`
  query GetAllParticipationsResults(
    $startDate: Date!
    $endDate: Date!
    $offset: Int
    $studies: [String]
  ) {
    allParticipationsFormResults(first: 25, offset: $offset, studies: $studies) {
      totalCount
      edges {
        node {
          sri
          morningDiaryResults(startDate: $startDate, endDate: $endDate) {
            answeredAt
          }
          eveningDiaryResults(startDate: $startDate, endDate: $endDate) {
            answeredAt
          }
          memoryGameResults(startDate: $startDate, endDate: $endDate) {
            answeredAt
          }
          flexibilityGameResults(startDate: $startDate, endDate: $endDate) {
            answeredAt
          }
          perceptionGameResults(startDate: $startDate, endDate: $endDate) {
            answeredAt
          }
          reactionGameResults(startDate: $startDate, endDate: $endDate) {
            answeredAt
          }
          cognitiveBatteryResults(startDate: $startDate, endDate: $endDate) {
            answeredAt
          }
          morningMyoResults(startDate: $startDate, endDate: $endDate) {
            answeredAt
          }
          middayMyoResults(startDate: $startDate, endDate: $endDate) {
            answeredAt
          }
          eveningMyoResults(startDate: $startDate, endDate: $endDate) {
            answeredAt
          }
          esqMetadata(startDate: $startDate, endDate: $endDate) {
            entryResults {
              entryId {
                title
              }
              datetimefieldAnswer
            }
          }
        }
      }
    }
  }
`

// TODO: add myofunctional badges
// {
//   allParticipationsFormResults(first: 25, offset: 0) {
//     totalCount
//     edges {
//       node {
//         sri
//         myofunctionalResults(startDate: "2023-04-04", endDate: "2023-04-04") {
//           createdAt
//             entryResults {
//               entryId {
//                 title
//               }
//               selectAnswer
//             }
//         }
//       }
//     }
//   }
// }

const getLegendBadges = ({ onPress, activeBadges }) => {
  // collect all of the different badges to use
  const badges = [
    <MorningDiaryBadge
      key="morning"
      hasLabel
      isActive={activeBadges.morning}
      onPress={() => {
        onPress('morning')
      }}
    />,
    <EveningDiaryBadge
      key="evening"
      hasLabel
      isActive={activeBadges.evening}
      onPress={() => {
        onPress('evening')
      }}
    />,
    <CognitiveBatteryBadge
      key="cognitive"
      hasLabel
      isActive={activeBadges.cognitive}
      onPress={() => {
        onPress('cognitive')
      }}
    />,
    <FlexibilityGameBadge
      key="flexibility"
      hasLabel
      isActive={activeBadges.flexibility}
      onPress={() => {
        onPress('flexibility')
      }}
    />,
    <ReactionGameBadge
      key="reaction"
      hasLabel
      isActive={activeBadges.reaction}
      onPress={() => {
        onPress('reaction')
      }}
    />,
    <MemoryGameBadge
      key="memory"
      hasLabel
      isActive={activeBadges.memory}
      onPress={() => {
        onPress('memory')
      }}
    />,
    <PerceptionGameBadge
      key="perception"
      hasLabel
      isActive={activeBadges.perception}
      onPress={() => {
        onPress('perception')
      }}
    />,
    <MorningMyoBadge
      key="morningMyo"
      hasLabel
      isActive={activeBadges.morningMyo}
      onPress={() => {
        onPress('morningMyo')
      }}
    />,
    <MiddayMyoBadge
      key="middayMyo"
      hasLabel
      isActive={activeBadges.middayMyo}
      onPress={() => {
        onPress('middayMyo')
      }}
    />,
    <EveningMyoBadge
      key="eveningMyo"
      hasLabel
      isActive={activeBadges.eveningMyo}
      onPress={() => {
        onPress('eveningMyo')
      }}
    />,
    <ESQIntakeBadge
      key="esqIntake"
      hasLabel
      isActive={activeBadges.esqIntake}
      onPress={() => {
        onPress('esqIntake')
      }}
    />,
    <ESQFollowUpBadge
      key="esqFollowUp"
      hasLabel
      isActive={activeBadges.esqFollowUp}
      onPress={() => {
        onPress('esqFollowUp')
      }}
    />,
  ]

  // separate them using boxes
  const elements = [badges[0]]
  for (let i = 1; i < badges.length; i += 1) {
    elements.push(<Box key={i.toString()} height="1px" width="20px" />)
    elements.push(badges[i])
  }

  return elements
}

const ROWS_PER_PAGE = 25

const cookies = new Cookies()

const BubbleCalendarRow = ({ filters }) => {
  const [currDate, setCurrDate] = useState(moment().subtract(6, 'days'))
  const weekdays = useMemo(
    () => range(7).map(d => currDate.clone().add(d, 'days').format('ddd')),
    [currDate]
  )
  const nextWeekAllowed = useMemo(
    () => !currDate.clone().add(7, 'days').isAfter(moment()),
    [currDate]
  )
  const nextMonthAllowed = useMemo(
    () => !currDate.clone().add(4, 'weeks').isAfter(moment()),
    [currDate]
  )
  const [participants, setParticipants] = useState([])
  const [currPage, setCurrPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const { user } = useContext(UserContext)

  const [getParticipants, { loading, error, data, refetch }] = useLazyQuery(GET_PARTICIPANTS_DATA)

  const defaultActiveBadges = useMemo(
    () => ({
      morning: true,
      evening: true,
      flexibility: true,
      reaction: true,
      memory: true,
      perception: true,
      cognitive: true,
      morningMyo: user.role === 'staff',
      middayMyo: user.role === 'staff',
      eveningMyo: user.role === 'staff',
      esqIntake: true,
      esqFollowUp: true,
    }),
    [user]
  )

  const [activeBadges, setActiveBadges] = useState(defaultActiveBadges)
  const cookieActiveBadges = useMemo(() => cookies.get('activeBadges'), [])

  useEffect(() => {
    const relevantBadges = {}
    if (cookieActiveBadges) {
      // if there is a cookie, use it
      const cb =
        typeof cookieActiveBadges === 'string' || cookieActiveBadges instanceof String
          ? JSON.parse(cookieActiveBadges)
          : cookieActiveBadges
      // only get badges that are actually used in this component
      Object.keys(cb).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(defaultActiveBadges, key)) {
          relevantBadges[key] = cb[key]
        }
      })
    }

    if (Object.keys(relevantBadges).length !== 0) {
      setActiveBadges({ ...defaultActiveBadges, ...relevantBadges })
    } else {
      // if there is no cookie, use default
      setActiveBadges(defaultActiveBadges)
    }
  }, [])

  const onPressBadge = badge => {
    setActiveBadges(b => ({ ...b, [badge]: !b[badge] }))
  }

  useEffect(() => {
    // save active badges settings in cookies without deleting unrelevant badges
    const obj = { ...cookieActiveBadges, ...activeBadges }
    cookies.set('activeBadges', JSON.stringify(obj))
  }, [activeBadges])

  useEffect(() => {
    // console.log('usereffect')
    getParticipants({
      fetchPolicy: 'network-only',
      variables: {
        startDate: currDate.clone().format('y-MM-DD'),
        endDate: currDate.clone().add(6, 'days').format('y-MM-DD'),
        offset: currPage * ROWS_PER_PAGE,
        studies: filters?.[0]?.study?.title__in,
      },
    })
  }, [currPage, currDate, filters])

  useEffect(() => {
    if (!loading && data) {
      // console.log('res', data?.allParticipationsFormResults?.edges)
      setParticipants(data?.allParticipationsFormResults?.edges || [])
      setTotalCount(data?.allParticipationsFormResults?.totalCount)
    }
  }, [data, loading])

  return (
    // overarching container
    <Box width="70rem">
      <Box flexDirection="column" cc style={{ width: '100%', minWidth: '70rem' }}>
        {/* date & controls */}
        <Box flexDirection="row" spaceBetween style={{ margin: '0 0 5px 0' }} maxWidth="40rem">
          {/* temp prev month button */}
          <Clickable
            onPress={() => {
              const newDate = currDate.clone().subtract(4, 'weeks')
              setCurrDate(newDate)
            }}
          >
            <Box
              height="2.6rem"
              width="2.6rem"
              round
              justifyContent="center"
              cc
              style={{ border: '2px solid #000000' }}
            >
              <Box flexDirection="row" cc style={{ marginLeft: '-2px' }}>
                <KeyboardArrowLeft style={{ fill: '#000000' }} />
                <KeyboardArrowLeft style={{ fill: '#000000' }} />
              </Box>
            </Box>
          </Clickable>
          <Box width="10px" />

          {/* "previous week" button */}
          <Clickable
            onPress={() => {
              const newDate = currDate.clone().subtract(7, 'days')
              setCurrDate(newDate)
            }}
          >
            <Box
              height="2.6rem"
              width="2.6rem"
              round
              justifyContent="center"
              cc
              style={{ border: '2px solid #000000' }}
            >
              <KeyboardArrowLeft style={{ marginLeft: '-2px' }} />
            </Box>
          </Clickable>

          {/* date */}
          <Box width="401px" flexDirection="row" justifyContent="center">
            <Text huge bold color={colors.greyDarkest}>
              {currDate.format('DD-MM-y')} - {currDate.clone().add(6, 'days').format('DD-MM-y')}
            </Text>
          </Box>

          {/* "next week" button */}
          <Clickable
            onPress={() => {
              const newDate = currDate.clone().add(7, 'days')
              setCurrDate(newDate)
            }}
            disabled={!nextWeekAllowed}
          >
            <Box
              height="2.6rem"
              width="2.6rem"
              round
              justifyContent="center"
              cc
              style={{ border: '2px solid #000000', opacity: `${Number(nextWeekAllowed)}` }}
            >
              <KeyboardArrowRight style={{ marginLeft: '2px' }} />
            </Box>
          </Clickable>

          {/* temp next month button */}
          <Box width="10px" />
          <Clickable
            onPress={() => {
              const newDate = currDate.clone().add(4, 'weeks')
              setCurrDate(newDate)
            }}
            disabled={!nextMonthAllowed}
          >
            <Box
              height="2.6rem"
              width="2.6rem"
              round
              justifyContent="center"
              cc
              style={{
                border: '2px solid #000000',
                opacity: `${Number(nextMonthAllowed)}`,
              }}
            >
              <Box flexDirection="row" spaceBetween style={{ marginLeft: '2px' }}>
                <KeyboardArrowRight style={{ fill: '#000000' }} />
                <KeyboardArrowRight style={{ fill: '#000000' }} />
              </Box>
            </Box>
          </Clickable>
        </Box>

        {/* legend */}
        <Box flex={1} width="80%" flexDirection="row" justifyContent="center" mb="25px" flexWrap>
          {getLegendBadges({ onPress: onPressBadge, activeBadges })}
        </Box>

        {/* weekday headings */}
        {/* <Box flexDirection="row" width="100%" jc="flex-end" mb="2rem">
          {weekdays.map(wd => (
            <Box cc key={wd} width="30px" mh="58px">
              <Text small semibold center color={colors.greyDarker}>
                {wd}
              </Text>
            </Box>
          ))}
        </Box> */}

        {participants?.map(item => (
          <>
            <div
              key={item.node.sri}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                padding: '10px',
                width: '100%',
              }}
            >
              <Box mb="4px" mr="32px" jc="center" style={{ minWidth: '6rem' }}>
                <Text medium semibold color={colors.greyDarker} center>
                  {item.node.sri}
                </Text>
              </Box>
              <BubbleCalendar
                itemData={item.node}
                year={currDate.year()}
                month={currDate.month() + 1}
                startDate={currDate.format()}
                participationId={9}
                activeBadges={activeBadges}
                weekly
                weekdays={weekdays}
              />
            </div>
            <Box width="100%" style={{ borderBottom: '1px solid rgb(240, 240, 240)' }} mv="1rem" />
          </>
        ))}

        <TablePagination
          component="div"
          count={totalCount}
          page={currPage}
          onPageChange={(event, page) => {
            if (page !== null) {
              setCurrPage(page)
            }
          }}
          rowsPerPage={ROWS_PER_PAGE}
          rowsPerPageOptions={[]}
          onRowsPerPageChange={null}
        />
      </Box>
    </Box>
  )
}

// BubbleCalendarRow.propTypes = {
//   morningDiaryDates: PropTypes.arrayOf(momentPropTypes.momentObj),
//   eveningDiaryDates: PropTypes.arrayOf(momentPropTypes.momentObj),
//   flexibilityGameDates: PropTypes.arrayOf(momentPropTypes.momentObj),
//   memoryGameDates: PropTypes.arrayOf(momentPropTypes.momentObj),
//   perceptionGameDates: PropTypes.arrayOf(momentPropTypes.momentObj),
//   reactionGameDates: PropTypes.arrayOf(momentPropTypes.momentObj),
// }

// BubbleCalendarRow.defaultProps = {
//   morningDiaryDates: [],
//   eveningDiaryDates: [],
//   flexibilityGameDates: [],
//   memoryGameDates: [],
//   perceptionGameDates: [],
//   reactionGameDates: [],
// }

export default BubbleCalendarRow
