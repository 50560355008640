import React from 'react'
import styled, { css } from 'styled-components'

const StyledBox = styled.div(
  ({
    backgroundColor,
    bg,
    color1,
    color2,
    p,
    ph,
    pv,
    pr,
    pl,
    pt,
    pb,
    m,
    mb,
    mt,
    mr,
    ml,
    mh,
    mv,
    block,
    outline,
    flexWrap,
    flexGrow,
    width,
    height,
    fullscreen,
    flex,
    shadow,
    flexDirection,
    fd,
    row,
    column,
    center,
    c,
    centerContent,
    cc,
    invisible,
    absolute,
    justifyContent,
    jc,
    alignContent,
    ac,
    flexStart,
    spaceBetween,
    spaceEvenly,
    rounded,
    round,
    hover,
    top,
    left,
    right,
    bottom,
    end,
    rotate,
    position,
    maxWidth,
    minWidth,
    minHeight,
  }) => css`
    display: flex;
    background-color: ${backgroundColor || bg || 'transparent'};
    overflow: hidden;

    padding-horizontal: ${p || ph || 0};
    padding-vertical: ${p || pv || 0};
    padding-top: ${pt || pv || p || 0};
    padding-bottom: ${pb || pv || p || 0};
    padding-left: ${pl || ph || p || 0};
    padding-right: ${pr || ph || p || 0};
    margin-bottom: ${mb || mv || m || 0};
    margin-top: ${mt || mv || m || 0};
    margin-right: ${mr || mh || m || 0};
    margin-left: ${ml || mh || m || 0};

    ${block &&
    css`
      width: 100%;
    `}

    ${color2 &&
    css`
      background: linear-gradient(to bottom right, ${color1 || bg}, ${color2});
    `}

    ${outline &&
    css`
      border-style: solid;
      border-width: 2px;
      border-color: grey;
    `}

    ${flexWrap &&
    css`
      flex-wrap: wrap;
    `}

    ${flexGrow &&
    css`
      flex-grow: ${flexGrow && flexGrow > 1 ? flexGrow : 1};
    `}

    ${width &&
    css`
      width: ${width};
    `}

    ${minWidth &&
    css`
      min-width: ${minWidth};
    `}
  
  
    ${maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  
  
    ${minHeight &&
    css`
      min-height: ${minHeight};
    `}

    ${height &&
    css`
      height: ${height};
    `}

    ${flex &&
    css`
      flex: ${typeof flex === 'boolean' ? 1 : flex};
    `}

    ${fullscreen &&
    css`
      flex-direction: column;
      min-height: 100vh;
    `}

    ${shadow &&
    css`
      shadow-opacity: 0.75;
      shadow-radius: 4px;
      shadow-color: rgba(0, 0, 0, 0.2);
      shadow-offset: 0px 0px;
      elevation: 5;
    `}

    ${rounded &&
    css`
      border-radius: 7px;
    `}
  
      ${round &&
    css`
      border-radius: 32px;
    `}

    flex-direction: ${flexDirection || fd || (row && 'row') || (column && 'column') || 'column'};

    align-content: ${alignContent || ac || 'flex-start'};

    justify-content: ${justifyContent ||
    jc ||
    (flexStart && 'flex-start') ||
    (spaceBetween && 'space-between') ||
    (spaceEvenly && 'space-evenly') ||
    'flex-start'};

    ${(center || c) &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}

    ${(centerContent || cc) &&
    css`
      align-items: center;
    `}

    ${end &&
    css`
      align-items: end;
    `}

    ${invisible &&
    css`
      opacity: 0;
    `}

    ${absolute &&
    css`
      position: absolute;
    `}

    ${hover &&
    css`
      &:hover {
        background-color: ${hover};
      }
    `}

    ${top &&
    css`
      top: ${top};
    `}
    
    ${left &&
    css`
      left: ${left};
    `}

    ${bottom &&
    css`
      bottom: ${bottom};
    `}

    ${right &&
    css`
      right: ${right};
    `}

    ${rotate &&
    css`
      transform: rotate(${rotate});
    `}

    ${position &&
    css`
      position: ${position};
    `}
  `
)
// eslint-disable-next-line react/destructuring-assignment
const Box = props => <StyledBox {...props} ref={props.innerRef} />

export default Box
