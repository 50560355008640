import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components'

function TimeInput({ label, value, setvalue, marginRight, display }) {
  const margin = marginRight || 10
  return (
    <div style={{ marginBottom: 15, display }}>
      <div style={{ paddingTop: 5, marginRight: margin }}>
        <Text fontSize="17px">{label}</Text>
      </div>
      <input
        type="time"
        id="appt"
        name="appt"
        style={{ backgroundColor: 'white', borderRadius: 5, width: 70, height: 30 }}
        onChange={o => setvalue(o)}
        value={value}
      />
    </div>
  )
}

TimeInput.propTypes = {
  setvalue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  display: PropTypes.string,
  marginRight: PropTypes.number,
}

TimeInput.defaultProps = {
  display: PropTypes.display,
  marginRight: PropTypes.marginRight,
}
export default TimeInput
