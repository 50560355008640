import React from 'react'
import Typography from '@mui/material/Typography' // adjust the import to your actual Typography path

/* eslint-disable no-cond-assign */
/* eslint-disable react/no-array-index-key */

const parseText = text => {
  const regex = /<b>(.*?)<\/b>|<u>(.*?)<\/u>|<i>(.*?)<\/i>/g
  let match
  let lastIndex = 0
  const parts = []

  while ((match = regex.exec(text)) !== null) {
    if (lastIndex !== match.index) {
      parts.push({
        bold: false,
        underline: false,
        italic: false,
        content: text.substring(lastIndex, match.index),
      })
    }
    parts.push({
      bold: match[1] !== undefined,
      underline: match[2] !== undefined,
      italic: match[3] !== undefined,
      content: match[1] || match[2] || match[3],
    })
    lastIndex = match.index + match[0].length
  }

  if (lastIndex !== text.length) {
    parts.push({ bold: false, underline: false, italic: false, content: text.substring(lastIndex) })
  }

  return parts
}

const Comment = ({ text, center, bold: externalBold }) => {
  const parsedTextParts = parseText(text)

  return (
    <div style={{ textAlign: center ? 'center' : 'left', marginBottom: '10px', marginTop: '10px' }}>
      <Typography fontWeight={externalBold ? 'bold' : 'regular'}>
        {parsedTextParts.map((part, index) => {
          const style = {
            fontWeight: externalBold || part.bold ? 'bold' : 'normal',
            textDecoration: part.underline ? 'underline' : 'none',
            fontStyle: part.italic ? 'italic' : 'normal',
          }
          return (
            <span key={index} style={style}>
              {part.content}
            </span>
          )
        })}
      </Typography>
    </div>
  )
}

export default Comment
