import stringSimilarity from 'string-similarity'

export const parseInput = inputWords => {
  let wordList = inputWords.split('\n')
  wordList = wordList.map(e => e.trim().toLowerCase())
  return wordList
}

export const intersection = (words, theWords) => {
  const correctWords = []
  const threshold = 0.6
  for (let i = 0; i < words.length; i += 1) {
    for (let j = 0; j < theWords.length; j += 1) {
      const similarity = stringSimilarity.compareTwoStrings(words[i], theWords[j])
      if (similarity > threshold) {
        if (!correctWords.includes(theWords[j])) {
          correctWords.push(theWords[j])
        }
      }
    }
  }
  return correctWords
}

export const calculatePos = (lineLength, angle, centerX, centerY, isLOT) => {
  const angleRadians = (180 - angle) * (Math.PI / 180)
  const x = centerX - (lineLength / 2) * Math.sin(angleRadians - Math.PI / 2)
  const y = centerY - (lineLength / 2) * Math.cos(angleRadians - Math.PI / 2)
  let l = x + (lineLength / 2) * Math.cos(angleRadians)
  let t = y - (lineLength / 2) * Math.sin(angleRadians)
  if (isLOT) {
    return { top: `${t}`, left: `${l}px`, rotate: `${angle}deg` }
  }
  t /= 8
  l /= 12
  return { top: `${t}%`, left: `${l}%`, rotate: `${angle}deg` }
}
