import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../../utils/colors'
import Badge from './Badge'

const PerceptionGameBadge = ({ isActive, hasLabel, onPress }) => (
  <Badge
    bigLetter="p"
    color={colors.inspectionTimeTask.primary}
    isActive={isActive}
    onPress={onPress}
    label={hasLabel ? 'Perception game' : ''}
  />
)

PerceptionGameBadge.propTypes = {
  isActive: PropTypes.bool.isRequired,
  hasLabel: PropTypes.bool.isRequired,
  onPress: PropTypes.func,
}

PerceptionGameBadge.defaultProps = {
  onPress: () => {},
}

export default PerceptionGameBadge
