import React from 'react'
import { Box } from 'components'
import DaytimeFunctioning1 from 'components/ESQ/DaytimeFunctioning/DaytimeFunctioning1'
import EsqProgressBar from 'components/ESQ/EsqProgressBar'
import ProgressBarText from 'components/ESQ/ProgressBarText'
import colors from 'utils/colors'
import theme from 'utils/theme'

function DaytimeFunctioning() {
  return (
    <Box bg={colors.greyDark} fullscreen cc p={theme.spacing.huge}>
      <ProgressBarText />
      <EsqProgressBar progressPercentage="95%" />
      <DaytimeFunctioning1 />
    </Box>
  )
}

export default DaytimeFunctioning
