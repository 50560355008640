import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSound from 'use-sound'
import animal from '../../../assets/audio/auditoryVerbalLearning/animal.mp3'
import bottle from '../../../assets/audio/auditoryVerbalLearning/bottle.mp3'
import rabbit from '../../../assets/audio/auditoryVerbalLearning/rabbit.mp3'
import { Box } from '../../atoms'

const AudioPlayer = ({ onFinish, practice, sounds }) => {
  const { t } = useTranslation(['auditoryVerbalLearning'])

  const [soundProgress, setSoundProgress] = useState(0)

  const [playPractice0] = useSound(rabbit)
  const [playPractice1] = useSound(animal)
  const [playPractice2] = useSound(bottle)
  const playPracticeSounds = [playPractice0, playPractice1, playPractice2]

  const [play0] = useSound(sounds[0].sound)
  const [play1] = useSound(sounds[1].sound)
  const [play2] = useSound(sounds[2].sound)
  const [play3] = useSound(sounds[3].sound)
  const [play4] = useSound(sounds[4].sound)
  const [play5] = useSound(sounds[5].sound)
  const [play6] = useSound(sounds[6].sound)
  const [play7] = useSound(sounds[7].sound)
  const [play8] = useSound(sounds[8].sound)
  const [play9] = useSound(sounds[9].sound)
  const [play10] = useSound(sounds[10].sound)
  const [play11] = useSound(sounds[11].sound)
  const [play12] = useSound(sounds[12].sound)
  const [play13] = useSound(sounds[13].sound)
  const [play14] = useSound(sounds[14].sound)

  const playSounds = [
    play0,
    play1,
    play2,
    play3,
    play4,
    play5,
    play6,
    play7,
    play8,
    play9,
    play10,
    play11,
    play12,
    play13,
    play14,
  ]

  useEffect(() => {
    if (soundProgress > 0) {
      if (!practice && soundProgress <= playSounds.length) {
        playSounds[soundProgress - 1]()
      } else if (practice && soundProgress <= playPracticeSounds.length) {
        playPracticeSounds[soundProgress - 1]()
      } else {
        onFinish()
      }
    }

    const interval = setInterval(() => {
      setSoundProgress(soundProgress + 1)
    }, 2000)
    return () => clearInterval(interval)
  }, [soundProgress])

  return <Box />
}

export default AudioPlayer
