import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    backend: {
      addPath: '/public/locales/{{lng}}/{{ns}}.json?v=1.1',
    },
    lng: 'en',
    fallbackLng: ['en'],
    debug: true,
    defaultNS: 'common',
    expirationTime: 8 * 60 * 60 * 1000, // 8 hours
    namespaces: ['auth', 'common', 'reactionTask', 'cognitiveTasks'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
