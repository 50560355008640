import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import TextField from '@mui/material/TextField'
import colors from 'utils/colors'
import {
  setRefreshToken,
  setToken,
  setUser,
  clearUserData,
  removeToken,
  removeRefreshToken,
  getToken,
} from 'utils/helper'
import theme from 'utils/theme'
import UserContext from 'utils/UserContext'
import { Box, Text, Button, Clickable } from '../../atoms'

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      refreshToken
      success
      errors
    }
  }
`
const ME_QUERY = gql`
  query {
    me {
      username
      firstName
      lastName
    }
    myAppPermissions {
      isAllowedToUseChat
      isAllowedToDoBrainGames
      isAllowedToDoMyofunctionalExercises
      isStaff
    }
  }
`

const Login = ({ setIsLogin, setIsRegister }) => {
  const navigate = useNavigate()
  const { setUser: setContextUser } = React.useContext(UserContext)
  const [error, setError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [getMe] = useLazyQuery(ME_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: meData => {
      console.log('meData', meData)
      if (meData?.me) {
        setUser({
          username: meData.me.username,
          firstname: meData.me.firstName,
          lastname: meData.me.lastName,
          role: meData?.myAppPermissions?.isStaff ? 'staff' : null,
        })
        setContextUser({
          username: meData.me.username,
          firstname: meData.me.firstName,
          lastname: meData.me.lastName,
          role: meData?.myAppPermissions?.isStaff ? 'staff' : null,
        })
        if (meData?.myAppPermissions?.isStaff) {
          navigate('/Home', { replace: true })
        } else {
          navigate('/myPage', { replace: true })
        }
      } else {
        setIsSubmitting(false)
        navigate('/')
      }
    },
    onError: e => {
      console.log('error', e)
      setIsSubmitting(false)
      navigate('/')
    },
  })

  // use login mutation and then get me
  const [login] = useMutation(LOGIN_MUTATION, {
    onCompleted: async data => {
      if (data?.tokenAuth?.success) {
        // set token in local storage and wait for completion, then call get me
        setToken(data.tokenAuth.token)
        setRefreshToken(data.tokenAuth.refreshToken)
        getMe()
      } else {
        setError(true)
        setIsSubmitting(false)
      }
    },
    onError: e => {
      setError(true)
      setIsSubmitting(false)
    },
  })

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = () => {
    if (!isSubmitting) {
      setIsSubmitting(true)
      clearUserData()
      setError(false)
      login({ variables: { email, password } })
    }
  }

  return (
    <Box>
      <Box gap={theme.spacing.large}>
        <Text medium bold color={colors.greyDarkest}>
          Login
        </Text>
        <TextField
          id="filledEmail"
          variant="filled"
          required
          label="Email"
          type="email"
          value={email}
          onChange={event => {
            setError(false)
            setEmail(event.target.value)
          }}
        />
        <TextField
          id="filledPassword"
          variant="filled"
          required
          label="Password"
          type="password"
          value={password}
          onChange={event => {
            setError(false)
            setPassword(event.target.value)
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              handleSubmit()
            }
          }}
        />
        <Box>
          {error && (
            <Text sub color={colors.red}>
              Invalid email or password
            </Text>
          )}
        </Box>

        <Clickable onPress={() => setIsLogin(false)}>
          <Text sub bold color={colors.greyDarkest}>
            Forgot your password?
          </Text>
        </Clickable>
        <Box mt={theme.spacing.largest}>
          <Button onPress={handleSubmit}>
            <Box cc>
              <Text bold center color="white">
                Login
              </Text>
            </Box>
          </Button>
        </Box>
        {/* <Box mt={theme.spacing.small} cc>
          <Clickable
            onPress={() => {
              setIsRegister(true)
              setIsLogin(false)
            }}
          >
            <Text sub bold>
              No account yet?
            </Text>
          </Clickable>
        </Box> */}
      </Box>
    </Box>
  )
}

Login.propTypes = {
  setIsLogin: PropTypes.func,
  setIsRegister: PropTypes.func,
}

Login.defaultProps = {
  setIsLogin: () => {},
  setIsRegister: () => {},
}

export default Login
