/* eslint-disable camelcase */

import React, { useContext, useEffect } from 'react'
import { Text } from 'components'
import Comment from '../../../comps/Comment'
import ConnectedFields from '../../../comps/ConnectedFields'
import FormikMuiInput from '../../../comps/FormikMuiInput'
import FormikMuiSelect from '../../../comps/FormikMuiSelect'
import Info from '../../../comps/Info'
import { fontSizes } from '../../../const/Const'
import { LangContext } from '../../../const/Contexts'
import {
  countries,
  languages,
  genderKeys,
  ethnicityKeys,
  ethnicityInfoKeys,
  occupationKeys,
  workScheduleKeys,
  educationKeys,
  // livingArrangementKeys, (Was supposed to be No/Yes (or checkbox))
  livingArrangementTogetherKeys,
  sleepingArrangementKeys,
  noYesKeys,
  drivingCategories,
} from '../../../const/ESQOptions'

export const PIFieldNames = [
  'country_birth',
  'country_residence',
  'native_lang',
  'height',
  'weight',
  'gender',
  'gender_other',
  'ethnicity',
  'occupation',
  'work_schedule',
  'work_schedule_other',
  'education_highest',
  'living_arrangement_alone',
  'living_arrangement_together',
  'sleeping_arrangement',
  'driving_license',
  'driving_license_category',
  'driving_km',
]

const PersonalInformation = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  const { values, setFieldValue, setValues, setFieldTouched } = formikProps

  const {
    gender,
    gender_other,
    work_schedule,
    work_schedule_other,
    living_arrangement_alone,
    living_arrangement_together,
    // sleeping_arrangement,
    driving_license,
    driving_license_category,
    driving_km,
    __isSecondRound__,
  } = values

  // Resetting 'Extra' fields when they switch back (ex. 'Other' boxes)
  useEffect(() => {
    if (gender !== 2 && gender_other !== '') {
      setFieldValue('gender_other', '')
    }
  }, [gender])
  useEffect(() => {
    if (work_schedule !== 1 && work_schedule_other !== '') {
      setFieldValue('work_schedule_other', '')
    }
  }, [work_schedule])
  useEffect(() => {
    if (living_arrangement_alone !== 0 && living_arrangement_together.length > 0) {
      setFieldValue('living_arrangement_together', [])
    }
  }, [living_arrangement_alone])
  useEffect(() => {
    if (!living_arrangement_together.includes(0)) {
      // Footnote in 'My sleep affecting others'
      setValues({
        ...values,
        // sleeping_arrangement is local
        sleeping_arrangement: '',
        // rest is from 'My sleep affecting others'
        self_snoring_frequency: '',
        self_snoring_bother: '',
        self_breathing_stops_frequency: '',
        self_breathing_stops_bother: '',
        self_limb_movements_frequency: '',
        self_limb_movements_bother: '',
        self_tossing_turning_frequency: '',
        self_tossing_turning_bother: '',
        self_kicking_punching_frequency: '',
        self_kicking_punching_bother: '',
        self_talking_screaming_frequency: '',
        self_talking_screaming_bother: '',
        self_teeth_grinding_frequency: '',
        self_teeth_grinding_bother: '',
        self_sleepwalking_frequency: '',
        self_sleepwalking_bother: '',
        other_os_frequency: '',
        other_os_bother: '',
        other_os_info: '',
      })
    }
  }, [living_arrangement_together])
  useEffect(() => {
    if (driving_license !== 1) {
      if (driving_license_category.length > 0) {
        setFieldValue('driving_license_category', [])
      }
      if (driving_km !== '') {
        setFieldValue('driving_km', '')
        // setFieldTouched('driving_km', false)
      }
    }
  }, [driving_license])

  return (
    <>
      <Comment center bold text={t('PersonalInformation')} />
      {__isSecondRound__ === false && (
        <>
          <FormikMuiSelect label={t('CountryOfBirth')} name="country_birth" options={countries} />
          <FormikMuiSelect
            label={t('CountryOfResidence')}
            name="country_residence"
            options={countries}
          />
          <Info text={t('LanguageInfo')}>
            <FormikMuiSelect label={t('Language')} name="native_lang" options={languages} />
          </Info>
          <FormikMuiInput label={t('Height')} type="number" name="height" unit={t('Centimeters')} />
        </>
      )}
      <FormikMuiInput label={t('Weight')} type="number" name="weight" unit={t('Kilograms')} />
      {__isSecondRound__ === false && (
        <>
          <ConnectedFields>
            <FormikMuiSelect
              label={t('Gender')}
              name="gender"
              options={genderKeys.map(val => t(val))}
            />
            {gender === 2 && (
              <FormikMuiInput
                label={`${t('Gender')} - ${t('Other')}`}
                type="text"
                name="gender_other"
              />
            )}
          </ConnectedFields>
          <Info text={t('EthnicityInfo')}>
            <ConnectedFields>
              <Comment text={`${t('EthnicityInstructions')}:`} />
              <FormikMuiSelect
                label={t('Ethnicity')}
                name="ethnicity"
                options={ethnicityKeys.map(val => t(val))}
                tooltips={ethnicityInfoKeys.map(val => t(val))}
              />
            </ConnectedFields>
          </Info>
        </>
      )}
      <FormikMuiSelect
        label={t('Occupation')}
        name="occupation"
        options={occupationKeys.map(val => t(val))}
        multiple
      />
      <ConnectedFields>
        <FormikMuiSelect
          label={t('WorkSchedule')}
          name="work_schedule"
          options={workScheduleKeys.map(val => t(val))}
        />
        {work_schedule === 1 && (
          <FormikMuiInput
            label={`${t('WorkSchedule')} - ${t('Other')}`}
            type="text"
            name="work_schedule_other"
          />
        )}
      </ConnectedFields>
      {__isSecondRound__ === false && (
        <>
          <FormikMuiSelect
            label={t('Education')}
            name="education_highest"
            options={educationKeys.map(val => t(val))}
          />
        </>
      )}
      <ConnectedFields>
        <Comment text={t('InMyResidenceILive')} />
        <FormikMuiSelect
          label={t('Alone')}
          name="living_arrangement_alone"
          options={noYesKeys.map(val => t(val))}
        />
        {living_arrangement_alone === 0 && (
          <FormikMuiSelect
            label={`${t('Living')} - ${t('Together')}`}
            name="living_arrangement_together"
            options={livingArrangementTogetherKeys.map(val => t(val))}
            multiple
          />
        )}
        {living_arrangement_alone === 0 && living_arrangement_together.includes(0) && (
          <>
            <Comment text={`${t('TheSleepingConditionIsIn')}:`} />
            <FormikMuiSelect
              label={t('TheSleepingConditionIsIn')}
              name="sleeping_arrangement"
              options={sleepingArrangementKeys.map(val => t(val))}
            />
          </>
        )}
      </ConnectedFields>
      {__isSecondRound__ === false && (
        <>
          <ConnectedFields>
            <Comment text={`${t('IHaveADriversLicense')}:`} />
            <FormikMuiSelect
              label={t('Driving')}
              name="driving_license"
              options={noYesKeys.map(val => t(val))}
            />
            {driving_license === 1 && (
              <FormikMuiSelect
                label={t('WhatCategory')}
                name="driving_license_category"
                options={drivingCategories}
                multiple
              />
            )}
            {driving_license === 1 && (
              <FormikMuiInput label={t('km/year')} type="number" name="driving_km" />
            )}
          </ConnectedFields>
        </>
      )}
    </>
  )
}

export default PersonalInformation
