import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components'

function LargeInput({ label, info, value, setvalue, placeholder, width }) {
  const pickedWidth = width || 400
  return (
    <div style={{ marginBottom: 15 }}>
      <div style={{ paddingTop: 5, marginRight: 40 }}>
        {label && <Text fontSize="20px">{label}</Text>}
      </div>
      <div style={{ paddingTop: 5, marginRight: 40 }}>
        {info && <Text fontSize="17px">{info}</Text>}
      </div>
      <textarea
        style={{ backgroundColor: 'white', borderRadius: 5, width: pickedWidth }}
        id="review-text"
        onChange={o => setvalue(o)}
        placeholder={placeholder}
        rows={4}
        value={value}
      />
    </div>
  )
}

LargeInput.propTypes = {
  setvalue: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.number,
}

LargeInput.defaultProps = {
  label: PropTypes.label,
  info: PropTypes.info,
  placeholder: PropTypes.placeholder,
  width: PropTypes.width,
}
export default LargeInput
