import React from 'react'
import { Box } from 'components'
import GeneralInformation1 from 'components/ESQ/GeneralInformation/GeneralInformation1'
import GeneralInformation2 from 'components/ESQ/GeneralInformation/GeneralInformation2'
import GeneralInformation3 from 'components/ESQ/GeneralInformation/GeneralInformation3'
import GeneralInformation4 from 'components/ESQ/GeneralInformation/GeneralInformation4'
import colors from 'utils/colors'
import theme from 'utils/theme'

function GeneralInformation() {
  return (
    <Box bg={colors.greyDark} fullscreen cc p={theme.spacing.huge}>
      <GeneralInformation1 />
      <GeneralInformation2 />
      <GeneralInformation3 />
      <GeneralInformation4 />
    </Box>
  )
}

export default GeneralInformation
