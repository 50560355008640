import React from 'react'
import PropTypes from 'prop-types'
import theme from 'utils/theme'
import { Box, Text } from '../../atoms'
import { PieChart } from '../../molecules'

const colors = [theme.colors.blue, theme.colors.pink, theme.colors.greyblue]

const GenderDistribution = ({ data }) => {
  const preparedData = Object.keys(data)
    .filter(key => key !== '__typename')
    .map(key => ({ name: key, value: data[key] }))

  return (
    <Box
      color1={theme.colors.bluepurplerLightest}
      color2={theme.colors.bluepurplerLightest}
      rounded
      pv="16px"
      ph="32px"
      width="500px"
    >
      <Text big bold>
        Gender Distribution
      </Text>
      <PieChart data={preparedData} colors={colors} />
    </Box>
  )
}

export default GenderDistribution
