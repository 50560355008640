import React, { useMemo } from 'react'
import { useMediaQuery } from '@mui/material'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts'
import { Box, Text } from '../..'
import RCSeverityDot from '../comps/RCSeverityDot'
import { chartSettings } from '../const/chartSettings'
import { RCTooltip } from '.'

// Checking that the entries belong together (ex. pain_frequency + pain_bother)
const checkValidTuple = tuple => {
  if (Array.isArray(tuple) && tuple.length === 2) {
    const [freq, bother] = tuple
    const freqTitle = freq?.base?.entryId?.title ?? freq?.followUp?.entryId?.title
    const botherTitle = bother?.base?.entryId?.title ?? bother?.followUp?.entryId?.title
    if (freqTitle && typeof freqTitle === 'string' && freqTitle.endsWith('_frequency')) {
      if (!botherTitle) {
        return true
      }
      if (
        botherTitle &&
        typeof botherTitle === 'string' &&
        botherTitle.endsWith('_bother') // &&
        // freqTitle.replace('_frequency', '') === botherTitle.replace('_bother', '')
        // some entry titles dont match perfectly so doesn't work
      ) {
        return true
      }
    }
  }
  console.error('Invalid tuple in freqBotherEntries in ESQVLC')
  console.error(tuple)
  return false
}

// Not interested in showing 'Never' frequencies and
// 'Not at all' & 'A little bit' bothersomeness
const shouldIncludeTuple = (tuple, shouldFilterInteresting) => {
  const [freq, bother] = tuple
  if (
    !checkValidTuple(tuple) ||
    (shouldFilterInteresting &&
      (!(freq?.base?.selectAnswer > 0 || freq?.followUp?.selectAnswer > 0) ||
        !(bother?.base?.selectAnswer > 1 || bother?.followUp?.selectAnswer > 1)))
  ) {
    return false
  }
  return true
}

const CustomYAxisTick = ({ x, y, payload, bother, frequency }) => {
  const tickText = bother
    ? chartSettings.botherLevels[payload.value + 1] // +1 for N/A bother
    : chartSettings.freqLevels[payload.value]
  if ((!bother && !frequency) || !tickText) {
    return null
  }
  return (
    <text
      x={x}
      y={y}
      dx={15}
      dy={5}
      textAnchor="start"
      fill={chartSettings.severityColors[payload.value] ?? chartSettings.textColor}
    >
      {tickText}
    </text>
  )
}

const CustomXAxisTick = ({ x, y, payload, angle, fill }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} textAnchor="start" fill={fill} transform={`rotate(${angle})`}>
      {payload.value}
    </text>
  </g>
)

// freqBotherEntries = [[freq_entry_result1, bother_entry_result1], [freq_entry_result2, bother_entry_result2], ...]
const ESQVLC = ({ freqBotherEntries, shouldFilterInteresting, comparison }) => {
  // console.log(freqBotherEntries)
  // const tinyScreen = useMediaQuery('(max-width: 500px)')

  const someSyncId = useMemo(() => {
    if (freqBotherEntries && Array.isArray(freqBotherEntries)) {
      for (let i = 0; i < freqBotherEntries.length; i += 1) {
        const erTuple = freqBotherEntries[i]
        if (erTuple && Array.isArray(erTuple) && erTuple.length === 2) {
          return `${erTuple[0]?.base?.entryId?.title}${comparison ? '-compare' : ''}`
        }
      }
    }
    return undefined
  }, [freqBotherEntries])

  const seriesData = useMemo(() => {
    if (freqBotherEntries && Array.isArray(freqBotherEntries)) {
      let longestTitleLength = 10
      const obj = { labelSpace: 100, frequency: [], bother: [] }
      freqBotherEntries.forEach(erTuple => {
        if (shouldIncludeTuple(erTuple, shouldFilterInteresting)) {
          const [f, b] = erTuple
          let entryName = f?.base?.entryId?.title?.replace('_frequency', '')
          if (entryName?.length > longestTitleLength) {
            longestTitleLength = entryName.length
          }
          entryName = entryName?.charAt(0)?.toUpperCase() + entryName?.slice(1)?.replace(/_/g, ' ')

          obj.frequency.push({
            name: entryName ?? '<Missing data>',
            base: f?.base?.selectAnswer,
            followUp: f?.followUp?.selectAnswer,
          })
          obj.bother.push({
            name: entryName ?? '<Missing data>',
            base: b?.base?.selectAnswer ?? -1, // -1 for N/A bother
            followUp: b?.followUp?.selectAnswer ?? -1,
          })
        }
      })
      obj.labelSpace = longestTitleLength * chartSettings.labelSpaceMulti
      return obj
    }
    console.error('Invalid freqBotherEntries in ESQVLC')
    console.error(freqBotherEntries)
    return null
  }, [freqBotherEntries, shouldFilterInteresting])

  return (
    <Box
      mb="40px"
      c
      width="100%"
      maxWidth={`${Math.max(seriesData.frequency.length * 4, 22)}rem`}
      style={{ overflowX: 'auto' }}
    >
      <Box style={{ minWidth: `${Math.max(seriesData.frequency.length * 4, 22)}rem` }}>
        <ResponsiveContainer
          height={chartSettings.chartHeight + seriesData.labelSpace}
          width="100%"
        >
          <LineChart
            data={seriesData.bother}
            syncId={someSyncId}
            margin={{
              top: seriesData.labelSpace,
              // right: tinyScreen ? 25 : Math.max(50, seriesData.labelSpace / 2),
              right: Math.max(50, seriesData.labelSpace / 2),
              bottom: 20,
              left: 15,
            }}
          >
            <CartesianGrid
              stroke={chartSettings.gridColor}
              strokeWidth={2}
              strokeOpacity={0.4}
              strokeDasharray={[2, 5]}
            />
            <XAxis
              stroke={chartSettings.textColor}
              orientation="top"
              axisLine={false}
              // tickLine={false}
              // angle={tinyScreen ? 270 : 320}
              angle={320}
              // tick={{
              //   textAnchor: 'start',
              //   style: { wordBreak: 'normal' },
              // }}
              tick={<CustomXAxisTick />}
              dx={4}
              tickMargin={20}
              interval={0}
              dataKey="name"
            />
            <YAxis
              orientation="right"
              ticks={chartSettings.botherLevels.map((_, index) => index - 1)}
              tickCount={chartSettings.botherLevels.length}
              tick={<CustomYAxisTick bother />}
              axisLine={false}
              tickLine={false}
              interval={0}
            />
            {RCTooltip({ bother: true })}
            <Line
              type="monotone"
              dataKey="base"
              stroke={chartSettings.textColor}
              strokeWidth={chartSettings.strokeWidth}
              dot={<RCSeverityDot />}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="followUp"
              stroke={chartSettings.textColor}
              strokeWidth={chartSettings.strokeWidth}
              strokeDasharray={chartSettings.strokeDasharray}
              dot={<RCSeverityDot />}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
        <Box width="100%" row cc justifyContent="space-around">
          <Box col mr="5px">
            <Box row cc justifyContent="start">
              <div
                style={{
                  width: '40px',
                  height: '0px',
                  borderBottom: `2px solid ${chartSettings.textColor}`,
                  marginRight: '12px',
                }}
              />
              <Text center color={chartSettings.textColor}>
                Baseline (Ba)
              </Text>
            </Box>
            <Box row cc justifyContent="start">
              <div
                style={{
                  width: '40px',
                  height: '0px',
                  borderBottom: `2px dashed ${chartSettings.textColor}`,
                  marginRight: '12px',
                }}
              />
              <Text center color={chartSettings.textColor}>
                Follow-up (Fo)
              </Text>
            </Box>
          </Box>
          <Box col flexGrow={0.7}>
            <Text bold center color={chartSettings.textColor}>
              Bothersomeness (B)
            </Text>
            <div
              style={{
                width: '90%',
                height: '0px',
                borderBottom: `2px solid ${chartSettings.gridColor}`,
                margin: '0 auto',
              }}
            />
            <Text bold center color={chartSettings.textColor}>
              Frequency (F)
            </Text>
          </Box>
        </Box>
        <ResponsiveContainer height={chartSettings.chartHeight} width="100%">
          <LineChart
            data={seriesData.frequency}
            syncId={someSyncId}
            margin={{
              top: 20,
              // right: tinyScreen ? 25 : Math.max(50, seriesData.labelSpace / 2),
              right: Math.max(50, seriesData.labelSpace / 2),
              bottom: 20,
              left: 15,
            }}
          >
            <CartesianGrid
              stroke={chartSettings.gridColor}
              strokeWidth={2}
              strokeOpacity={0.4}
              strokeDasharray={[2, 5]}
            />
            <XAxis hide interval={0} dataKey="name" />
            <YAxis
              orientation="right"
              ticks={chartSettings.freqLevels.map((_, index) => index)}
              tickCount={chartSettings.freqLevels.length}
              tick={<CustomYAxisTick frequency />}
              axisLine={false}
              tickLine={false}
              interval={0}
            />
            {RCTooltip()}
            <Line
              type="monotone"
              dataKey="base"
              stroke={chartSettings.textColor}
              strokeWidth={chartSettings.strokeWidth}
              dot={<RCSeverityDot />}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="followUp"
              stroke={chartSettings.textColor}
              strokeWidth={chartSettings.strokeWidth}
              strokeDasharray={chartSettings.strokeDasharray}
              dot={<RCSeverityDot hideNull />}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default ESQVLC
