/* eslint-disable camelcase */

import React, { useContext, useEffect } from 'react'
import { Trans } from 'react-i18next'
import Comment from '../../comps/Comment'
import ConnectedFields from '../../comps/ConnectedFields'
import FormikMuiInput from '../../comps/FormikMuiInput'
import FormikMuiSelect from '../../comps/FormikMuiSelect'
import Section from '../../comps/Section'
import { LangContext } from '../../const/Contexts'
import { noYesKeys, sleepDaytimeProblemDurationKeys, treatedByKeys } from '../../const/ESQOptions'

export const MHRFieldNames = [
  'sleep_daytime_problem_description',
  'sleep_daytime_problem_duration',
  'sleep_daytime_problem_worsen',
  'sleep_daytime_problem_worsen_info',
  'sleep_daytime_problem_causation',
  'sleep_problem_affects_daytime',
  'sleep_problem_affects_daytime_info',
  'diagnostic_investigation_expectation',
  'previous_sleep_problem_treatment',
  'prescribed_treatments_for_sleep',
  'treated_by',
  'previous_treatment_other_sleep_problems',
  'previous_treatment_other_sleep_problems_info',
]

const MyHelpRequest = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  const { values, setFieldValue } = formikProps
  const {
    sleep_daytime_problem_worsen,
    sleep_daytime_problem_worsen_info,
    sleep_problem_affects_daytime,
    sleep_problem_affects_daytime_info,
    previous_sleep_problem_treatment,
    prescribed_treatments_for_sleep,
    treated_by,
    previous_treatment_other_sleep_problems,
    previous_treatment_other_sleep_problems_info,
  } = values

  // Resetting 'Extra' fields when they switch back (ex. 'Other' boxes)
  useEffect(() => {
    if (sleep_daytime_problem_worsen !== 1 && sleep_daytime_problem_worsen_info !== '') {
      setFieldValue('sleep_daytime_problem_worsen_info', '')
    }
  }, [sleep_daytime_problem_worsen])
  useEffect(() => {
    if (sleep_problem_affects_daytime !== 1 && sleep_problem_affects_daytime_info !== '') {
      setFieldValue('sleep_problem_affects_daytime_info', '')
    }
  }, [sleep_problem_affects_daytime])
  useEffect(() => {
    if (previous_sleep_problem_treatment !== 1) {
      if (prescribed_treatments_for_sleep !== '') {
        setFieldValue('prescribed_treatments_for_sleep', '')
      }
      if (treated_by.length !== 0) {
        setFieldValue('treated_by', [])
      }
    }
  }, [previous_sleep_problem_treatment])
  useEffect(() => {
    if (
      previous_treatment_other_sleep_problems !== 1 &&
      previous_treatment_other_sleep_problems_info !== ''
    ) {
      setFieldValue('previous_treatment_other_sleep_problems_info', '')
    }
  }, [previous_treatment_other_sleep_problems])

  return (
    <Section>
      <ConnectedFields>
        <Comment text={t('Problems')} />
        <FormikMuiInput label="" name="sleep_daytime_problem_description" type="text" multiline />
      </ConnectedFields>
      <FormikMuiSelect
        label={t('ProblemsSince')}
        name="sleep_daytime_problem_duration"
        options={sleepDaytimeProblemDurationKeys.map(val => t(val))}
      />
      <ConnectedFields>
        <FormikMuiSelect
          label={t('ProblemsWorse')}
          name="sleep_daytime_problem_worsen"
          options={noYesKeys.map(val => t(val))}
        />
        {sleep_daytime_problem_worsen === 1 && (
          <FormikMuiInput
            label={t('ProblemsWorseInput')}
            name="sleep_daytime_problem_worsen_info"
            type="text"
            multiline
          />
        )}
      </ConnectedFields>
      <ConnectedFields>
        <Comment text={t('ProblemCauses')} />
        <FormikMuiInput label="" name="sleep_daytime_problem_causation" type="text" multiline />
      </ConnectedFields>
      <ConnectedFields>
        <Comment text={t('ProblemsNegative')} />
        <FormikMuiSelect
          label=""
          name="sleep_problem_affects_daytime"
          options={noYesKeys.map(val => t(val))}
        />
        {sleep_problem_affects_daytime === 1 && (
          <FormikMuiInput
            label={t('NegaviteInput')}
            name="sleep_problem_affects_daytime_info"
            type="text"
            multiline
          />
        )}
      </ConnectedFields>
      <ConnectedFields>
        <Comment text={t('ExpectTreatment')} />
        <FormikMuiInput
          label=""
          name="diagnostic_investigation_expectation"
          type="text"
          multiline
        />
      </ConnectedFields>
      <ConnectedFields>
        <Comment text={t('TreatedForCurrentSleepProblemsPreviously')} />
        <FormikMuiSelect
          label=""
          name="previous_sleep_problem_treatment"
          options={noYesKeys.map(val => t(val))}
        />
        {previous_sleep_problem_treatment === 1 && (
          <FormikMuiInput
            label={t('WhatTreatments')}
            name="prescribed_treatments_for_sleep"
            type="text"
            multiline
          />
        )}
        {previous_sleep_problem_treatment === 1 && (
          <>
            <Comment text={t('WhoTreated')} />
            <FormikMuiSelect
              label=""
              name="treated_by"
              options={treatedByKeys.map(val => t(val))}
              multiple
            />
          </>
        )}
      </ConnectedFields>
      <ConnectedFields>
        <Comment text={t('TreatedInPast')} />
        <FormikMuiSelect
          label=""
          name="previous_treatment_other_sleep_problems"
          options={noYesKeys.map(val => t(val))}
        />
        {previous_treatment_other_sleep_problems === 1 && (
          <FormikMuiInput
            label=""
            name="previous_treatment_other_sleep_problems_info"
            type="text"
            multiline
          />
        )}
      </ConnectedFields>
    </Section>
  )
}

export default MyHelpRequest
