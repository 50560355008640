import React, { useState } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import SRLogo from 'assets/images/SRLogo.png'
import colors from '../../../utils/colors.js'
import { Box, Text } from '../../atoms'
import { Login, Register, ForgotPassword } from '../../molecules'

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
})

const LoginForm = () => {
  const [isLogin, setIsLogin] = useState(true)
  const [isRegister, setIsRegister] = useState(false)

  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        p="32px"
        cc
        alignItems="center"
        justifyContent="center"
        backgroundColor={colors.lightBrown}
        rounded
      >
        <Box row mb="2rem">
          <img src={SRLogo} alt="Logo" style={{ height: '36px', marginRight: '16px' }} />
          <Text big bold color={colors.black}>
            Sleep Revolution
          </Text>
        </Box>
        {isLogin && <Login setIsLogin={setIsLogin} setIsRegister={setIsRegister} />}
        {/* {isRegister && <Register setIsLogin={setIsLogin} setIsRegister={setIsRegister} />} */}
        {!isLogin && !isRegister && <ForgotPassword setIsLogin={setIsLogin} />}
      </Box>
    </ThemeProvider>
  )
}

export default LoginForm
