import React, { useEffect } from 'react'
import colors from 'utils/colors'
import theme from 'utils/theme'
import { Box, Text } from '../components/atoms'

const Finish = () => {
  useEffect(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
  }, [])

  return (
    <Box bg={colors.greyDark} fullscreen cc p={theme.spacing.huge}>
      <Text>Thank you for participating!</Text>
    </Box>
  )
}
export default Finish
