import * as Yup from 'yup'

const currentYear = new Date().getFullYear()
const reqErr = 'Required'
const oneOrMoreErr = 'Choose at least one option'
const maxChars = 2000
const tooLongErr = 'Too long'
const minNum = 0
const maxNum = 999999
const numErr = 'Invalid number'

const FormikValidationSchema = Yup.object({
  // define validation schema for all fields in the form
  country_birth: Yup.number().when('__isSecondRound__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),

  country_residence: Yup.number().when('__isSecondRound__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),

  native_lang: Yup.number().when('__isSecondRound__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),

  height: Yup.number().when('__isSecondRound__', {
    is: false,
    then: Yup.number()
      .integer()
      .min(50, 'Invalid height')
      .max(300, 'Invalid height')
      .required(reqErr),
  }),

  weight: Yup.number()
    .integer()
    .min(20, 'Invalid weight')
    .max(700, 'Invalid weight')
    .required(reqErr),

  gender: Yup.number().when('__isSecondRound__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  gender_other: Yup.string().when(['__isSecondRound__', 'gender'], {
    is: (a, b) => a === false && b === 2,
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),

  ethnicity: Yup.number().when('__isSecondRound__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),

  occupation: Yup.array().of(Yup.number()).min(1, oneOrMoreErr).required(reqErr),

  work_schedule: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  work_schedule_other: Yup.string().when('work_schedule', {
    is: 1,
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),

  education_highest: Yup.number().when('__isSecondRound__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),

  living_arrangement_alone: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  living_arrangement_together: Yup.array().when('living_arrangement_alone', {
    is: 0,
    then: Yup.array().of(Yup.number()).min(1, oneOrMoreErr).required(reqErr),
  }),
  sleeping_arrangement: Yup.number().when('living_arrangement_together', {
    is: arr => arr.includes(0),
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),

  driving_license: Yup.number().when('__isSecondRound__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  driving_license_category: Yup.array().when(['__isSecondRound__', 'driving_license'], {
    is: (a, b) => a === false && b === 1,
    then: Yup.array().of(Yup.number()).min(1, oneOrMoreErr).required(reqErr),
  }),
  driving_km: Yup.number().when(['__isSecondRound__', 'driving_license'], {
    is: (a, b) => a === false && b === 1,
    then: Yup.number()
      .integer()
      .min(0, 'Invalid distance')
      .max(999999, 'Invalid distance')
      .required(reqErr),
  }),

  does_smoke: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  smoking_year_started: Yup.number().when('does_smoke', {
    is: 2,
    then: Yup.number()
      .integer()
      .min(1900, 'Invalid year')
      .max(currentYear, 'Invalid year')
      .required(reqErr),
  }),
  smoking_category: Yup.array().when('does_smoke', {
    is: 2,
    then: Yup.array().of(Yup.number()).min(1, oneOrMoreErr).required(reqErr),
  }),
  smoking_average_day: Yup.number().when('does_smoke', {
    is: 2,
    then: Yup.number().min(0, 'Invalid units/day').max(maxNum, numErr).required(reqErr),
  }),

  alcohol_intake: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  alcohol_intake_days: Yup.number().when('alcohol_intake', {
    is: 2,
    then: Yup.number().min(0, 'Invalid days/week').max(7, numErr).required(reqErr),
  }),
  alcohol_intake_units: Yup.number().when('alcohol_intake', {
    is: 2,
    then: Yup.number().min(0, 'Invalid units/week').max(maxNum, numErr).required(reqErr),
  }),
  alcohol_sleep_aid: Yup.number().when('alcohol_intake', {
    is: 2,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),

  caffeine_intake: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  caffeine_category: Yup.array().when('caffeine_intake', {
    is: 1,
    then: Yup.array().of(Yup.number()).min(1, oneOrMoreErr).required(reqErr),
  }),
  caffeine_category_other_info: Yup.string().when(['caffeine_category'], {
    is: cc => cc.includes(3),
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),
  caffeine_average_units: Yup.number().when('caffeine_intake', {
    is: 1,
    then: Yup.number().min(0, 'Invalid units/day').max(maxNum, numErr).required(reqErr),
  }),
  caffeine_last_drink: Yup.string().when('caffeine_intake', {
    is: 1,
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),

  substance_intake: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  substance_intake_info: Yup.string().when('substance_intake', {
    is: 2,
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),
  substance_intake_days: Yup.number().when('substance_intake', {
    is: 2,
    then: Yup.number().min(0, 'Invalid days/week').max(7, numErr).required(reqErr),
  }),
  substance_intake_units: Yup.number().when('substance_intake', {
    is: 2,
    then: Yup.number().min(0, 'Invalid units/week').max(maxNum, numErr).required(reqErr),
  }),

  exercise: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  exercise_count: Yup.number().when('exercise', {
    is: 1,
    then: Yup.number().min(0, 'Invalid').max(maxNum, numErr).required(reqErr),
  }),
  exercise_count_type: Yup.number().when('exercise', {
    is: 1,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  exercise_hours: Yup.number().when('exercise', {
    is: 1,
    then: Yup.number().min(0, 'Invalid').max(maxNum, numErr).required(reqErr),
  }),
  exercise_hours_type: Yup.number().when('exercise', {
    is: 1,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),

  leisure_recreational_activities: Yup.string().max(maxChars, tooLongErr),

  uses_contraception_therapy: Yup.number().when('__female_disabled__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  is_pregnant: Yup.number().when('__female_disabled__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  menopausal_state: Yup.number().when('__female_disabled__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  uses_hormone_replacement: Yup.number().when('__female_disabled__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),

  self_reported_health: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),

  stress: Yup.array().of(Yup.number()).min(1, oneOrMoreErr).required(reqErr),
  stress_other_info: Yup.string().when('stress', {
    is: arr => arr.includes(5),
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),
  stress_additional_comments: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  stress_additional_comments_info: Yup.string().when('stress_additional_comments', {
    is: 1,
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),

  sleep_daytime_problem_description: Yup.string().when('__isSecondRound__', {
    is: false,
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),
  sleep_daytime_problem_duration: Yup.number().when('__isSecondRound__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  sleep_daytime_problem_worsen: Yup.number().when('__isSecondRound__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  sleep_daytime_problem_worsen_info: Yup.string().when(
    ['__isSecondRound__', 'sleep_daytime_problem_worsen'],
    {
      is: (a, b) => a === false && b === 1,
      then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
    }
  ),

  sleep_daytime_problem_causation: Yup.string().when('__isSecondRound__', {
    is: false,
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),

  sleep_problem_affects_daytime: Yup.number().when('__isSecondRound__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  sleep_problem_affects_daytime_info: Yup.string().when(
    ['__isSecondRound__', 'sleep_problem_affects_daytime'],
    {
      is: (a, b) => a === false && b === 1,
      then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
    }
  ),

  diagnostic_investigation_expectation: Yup.string().when('__isSecondRound__', {
    is: false,
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),

  previous_sleep_problem_treatment: Yup.number().when('__isSecondRound__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  prescribed_treatments_for_sleep: Yup.string().when(
    ['__isSecondRound__', 'previous_sleep_problem_treatment'],
    {
      is: (a, b) => a === false && b === 1,
      then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
    }
  ),
  treated_by: Yup.array().when(['__isSecondRound__', 'previous_sleep_problem_treatment'], {
    is: (a, b) => a === false && b === 1,
    then: Yup.array().of(Yup.number()).min(1, oneOrMoreErr).required(reqErr),
  }),

  previous_treatment_other_sleep_problems: Yup.number().when('__isSecondRound__', {
    is: false,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  previous_treatment_other_sleep_problems_info: Yup.string().when(
    ['__isSecondRound__', 'previous_treatment_other_sleep_problems'],
    {
      is: (a, b) => a === false && b === 1,
      then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
    }
  ),

  sleep_duration: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  bedtime: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  wake_up_time: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  sleep_schedule: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  sleep_depth: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  sleep_quantity: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  sleep_offset: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),

  sleep_time_preferred: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  out_of_bed_preferred: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  sleep_amount_preferred: Yup.string().max(maxChars, tooLongErr).required(reqErr),

  sleep_amount_business_days: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  sleep_amount_free_days: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  sleep_into_bed_business_days: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  sleep_into_bed_free_days: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  sleep_out_of_bed_business_days: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  sleep_out_of_bed_free_days: Yup.string().max(maxChars, tooLongErr).required(reqErr),

  activities_before_sleep: Yup.array().of(Yup.number()).min(1, oneOrMoreErr).required(reqErr),
  activities_before_sleep_other_info: Yup.string().when('activities_before_sleep', {
    is: arr => arr.includes(6),
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),

  planned_nap: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  nap_frequency_per_day: Yup.number().when('planned_nap', {
    is: 1,
    then: Yup.number().min(0, 'Invalid').max(maxNum, numErr).required(reqErr),
  }),
  nap_day_count: Yup.number().when('planned_nap', {
    is: 1,
    then: Yup.number().min(0, 'Invalid').max(maxNum, numErr).required(reqErr),
  }),
  nap_per_week_or_month: Yup.number().when('planned_nap', {
    is: 1,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  nap_average_duration: Yup.number().when('planned_nap', {
    is: 1,
    then: Yup.number().min(0, 'Invalid').max(maxNum, numErr).required(reqErr),
  }),
  nap_feels_refreshed: Yup.number().when('planned_nap', {
    is: 1,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),

  pain_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  pain_bother: Yup.number().when('pain_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  itch_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  itch_bother: Yup.number().when('itch_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  leg_cramps_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  leg_cramps_bother: Yup.number().when('leg_cramps_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  limbs_urge_to_move_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  limbs_urge_to_move_bother: Yup.number().when('limbs_urge_to_move_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  bodily_movements_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  bodily_movements_bother: Yup.number().when('bodily_movements_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  nasal_obstruction_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  nasal_obstruction_bother: Yup.number().when('nasal_obstruction_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  own_snoring_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  own_snoring_bother: Yup.number().when('own_snoring_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  urge_to_urinate_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  urge_to_urinate_bother: Yup.number().when('urge_to_urinate_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  urge_to_urinate_count: Yup.number().when('urge_to_urinate_frequency', {
    is: val => val >= 3,
    then: Yup.number().min(0, 'Invalid').max(maxNum, numErr).required(reqErr),
  }),
  bedwetting_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  bedwetting_bother: Yup.number().when('bedwetting_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  urge_to_eat_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  urge_to_eat_bother: Yup.number().when('urge_to_eat_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  worrying_thoughts_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  worrying_thoughts_bother: Yup.number().when('worrying_thoughts_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  nightmares_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  nightmares_bother: Yup.number().when('nightmares_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  other_fa_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  other_fa_bother: Yup.number().when('other_fa_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  other_fa_info: Yup.string().when('other_fa_frequency', {
    is: val => val && val !== 0,
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),

  breathlessness_or_choking_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  breathlessness_or_chokings_bother: Yup.number().when('breathlessness_or_choking_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  inability_to_move_feeling_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  inability_to_move_feelings_bother: Yup.number().when('inability_to_move_feeling_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  panic_feeling_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  panic_feelings_bother: Yup.number().when('panic_feeling_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  headache_pain_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  headache_pains_bother: Yup.number().when('headache_pain_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  dry_mouth_feeling_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  dry_mouth_feelings_bother: Yup.number().when('dry_mouth_feeling_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  sore_throat_pain_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  sore_throat_pains_bother: Yup.number().when('sore_throat_pain_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  jaw_pain_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  jaw_pains_bother: Yup.number().when('jaw_pain_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  heartburn_feeling_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  heartburn_feelings_bother: Yup.number().when('heartburn_feeling_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  palpitations_feeling_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  palpitations_feelings_bother: Yup.number().when('palpitations_feeling_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  excessive_sweating_feeling_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  excessive_sweating_feelings_bother: Yup.number().when('excessive_sweating_feeling_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  hot_flushes_feeling_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  hot_flushes_feelings_bother: Yup.number().when('hot_flushes_feeling_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  other_ds_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  other_ds_bother: Yup.number().when('other_ds_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  other_ds_info: Yup.string().when('other_ds_frequency', {
    is: val => val && val !== 0,
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),

  snoring_bedpartner_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  snoring_bedpartners_bother: Yup.number().when('snoring_bedpartner_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  movements_bedpartner_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  movements_bedpartners_bother: Yup.number().when('movements_bedpartner_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  pet_activity_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  pet_activitys_bother: Yup.number().when('pet_activity_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  caretaking_family_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  caretaking_familys_bother: Yup.number().when('caretaking_family_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  sleeping_apart_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  sleeping_aparts_bother: Yup.number().when('sleeping_apart_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  environmental_noise_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  environmental_noises_bother: Yup.number().when('environmental_noise_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  smartphone_messages_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  smartphone_messagess_bother: Yup.number().when('smartphone_messages_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  bedroom_inconveniences_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  bedroom_inconveniencess_bother: Yup.number().when('bedroom_inconveniences_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  other_o_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  other_o_bother: Yup.number().when('other_o_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  other_o_info: Yup.string().when('other_o_frequency', {
    is: val => val && val !== 0,
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),

  // Footnote in 'My sleep affecting others'
  self_snoring_frequency: Yup.number().when('living_arrangement_together', {
    is: lat => lat.includes(0),
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_snoring_bother: Yup.number().when('self_snoring_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_breathing_stops_frequency: Yup.number().when('living_arrangement_together', {
    is: lat => lat.includes(0),
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_breathing_stops_bother: Yup.number().when('self_breathing_stops_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_limb_movements_frequency: Yup.number().when('living_arrangement_together', {
    is: lat => lat.includes(0),
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_limb_movements_bother: Yup.number().when('self_limb_movements_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_tossing_turning_frequency: Yup.number().when('living_arrangement_together', {
    is: lat => lat.includes(0),
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_tossing_turning_bother: Yup.number().when('self_tossing_turning_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_kicking_punching_frequency: Yup.number().when('living_arrangement_together', {
    is: lat => lat.includes(0),
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_kicking_punching_bother: Yup.number().when('self_kicking_punching_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_talking_screaming_frequency: Yup.number().when('living_arrangement_together', {
    is: lat => lat.includes(0),
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_talking_screaming_bother: Yup.number().when('self_talking_screaming_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_teeth_grinding_frequency: Yup.number().when('living_arrangement_together', {
    is: lat => lat.includes(0),
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_teeth_grinding_bother: Yup.number().when('self_teeth_grinding_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_sleepwalking_frequency: Yup.number().when('living_arrangement_together', {
    is: lat => lat.includes(0),
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  self_sleepwalking_bother: Yup.number().when('self_sleepwalking_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  other_os_frequency: Yup.number().when('living_arrangement_together', {
    is: lat => lat.includes(0),
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  other_os_bother: Yup.number().when('other_os_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  other_os_info: Yup.string().when('other_os_frequency', {
    is: val => val && val !== 0,
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),

  fall_asleep_30_to_60: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  fall_asleep_60_to_90: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  fall_asleep_over_90: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  fall_asleep_bother: Yup.number().when(
    ['fall_asleep_30_to_60', 'fall_asleep_60_to_90', 'fall_asleep_over_90'],
    {
      is: (a, b, c) => (a && a > 0) || (b && b > 0) || (c && c > 0),
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),

  woke_up_1_to_2: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  woke_up_2_to_4: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  woke_up_over_4: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  woke_up_bother: Yup.number().when(['woke_up_1_to_2', 'woke_up_2_to_4', 'woke_up_over_4'], {
    is: (a, b, c) => (a && a > 0) || (b && b > 0) || (c && c > 0),
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),

  fall_asleep_after_awake_30_to_60: Yup.number().when(
    ['woke_up_1_to_2', 'woke_up_2_to_4', 'woke_up_over_4'],
    {
      is: (a, b, c) => (a && a > 0) || (b && b > 0) || (c && c > 0),
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),
  fall_asleep_after_awake_60_to_90: Yup.number().when(
    ['woke_up_1_to_2', 'woke_up_2_to_4', 'woke_up_over_4'],
    {
      is: (a, b, c) => (a && a > 0) || (b && b > 0) || (c && c > 0),
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),
  fall_asleep_after_awake_over_90: Yup.number().when(
    ['woke_up_1_to_2', 'woke_up_2_to_4', 'woke_up_over_4'],
    {
      is: (a, b, c) => (a && a > 0) || (b && b > 0) || (c && c > 0),
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),
  fall_asleep_after_awake_bother: Yup.number().when(
    [
      'fall_asleep_after_awake_30_to_60',
      'fall_asleep_after_awake_60_to_90',
      'fall_asleep_after_awake_over_90',
    ],
    {
      is: (a, b, c) => (a && a > 0) || (b && b > 0) || (c && c > 0),
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),

  woke_up_early_30_to_60: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  woke_up_early_60_to_90: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  woke_up_early_over_90: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  woke_up_early_bother: Yup.number().when(
    ['woke_up_early_30_to_60', 'woke_up_early_60_to_90', 'woke_up_early_over_90'],
    {
      is: (a, b, c) => (a && a > 0) || (b && b > 0) || (c && c > 0),
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),

  felt_unrefreshed_30_to_60: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  felt_unrefreshed_1_to_4: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  felt_unrefreshed_over_4: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  felt_unrefreshed_bother: Yup.number().when(
    ['felt_unrefreshed_30_to_60', 'felt_unrefreshed_1_to_4', 'felt_unrefreshed_over_4'],
    {
      is: (a, b, c) => (a && a > 0) || (b && b > 0) || (c && c > 0),
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),

  fell_unintentionally_asleep_passive: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  fell_unintentionally_asleep_moderate: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  fell_unintentionally_asleep_strong: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  fell_unintentionally_asleep_bother: Yup.number().when(
    [
      'fell_unintentionally_asleep_passive',
      'fell_unintentionally_asleep_moderate',
      'fell_unintentionally_asleep_strong',
    ],
    {
      is: (a, b, c) => (a && a > 0) || (b && b > 0) || (c && c > 0),
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),

  effort_to_stay_awake: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  special_measures_to_stay_awake: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  activities_without_awareness: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  fight_sleepy_bother: Yup.number().when(
    ['effort_to_stay_awake', 'special_measures_to_stay_awake', 'activities_without_awareness'],
    {
      is: (a, b, c) => (a && a > 0) || (b && b > 0) || (c && c > 0),
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),

  overslept_with_alarm: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  overslept_with_alarm_bother: Yup.number().when('overslept_with_alarm', {
    is: a => a && a > 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),

  lack_of_energy_leisure_start: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  lack_of_energy_duty_start: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  lack_of_energy_any_start: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  lack_of_energy_bother: Yup.number().when(
    ['lack_of_energy_leisure_start', 'lack_of_energy_duty_start', 'lack_of_energy_any_start'],
    {
      is: (a, b, c) => (a && a > 0) || (b && b > 0) || (c && c > 0),
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),

  exhausted_activity_strong: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  exhausted_activity_moderate: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  exhausted_activity_passive: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  exhausted_activity_bother: Yup.number().when(
    ['exhausted_activity_strong', 'exhausted_activity_moderate', 'exhausted_activity_passive'],
    {
      is: (a, b, c) => (a && a > 0) || (b && b > 0) || (c && c > 0),
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),

  exhausted_physical_activity_vigorous: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  exhausted_physical_activity_moderate: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  exhausted_physical_activity_light: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  exhausted_physical_activity_bother: Yup.number().when(
    [
      'exhausted_physical_activity_vigorous',
      'exhausted_physical_activity_moderate',
      'exhausted_physical_activity_light',
    ],
    {
      is: (a, b, c) => (a && a > 0) || (b && b > 0) || (c && c > 0),
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),

  difficulty_remembering_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  difficulty_remembering_bother: Yup.number().when('difficulty_remembering_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  difficulty_concentrating_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  difficulty_concentrating_bother: Yup.number().when('difficulty_concentrating_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  lapses_of_attention_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  lapses_of_attention_bother: Yup.number().when('lapses_of_attention_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  anxiety_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  anxiety_bother: Yup.number().when('anxiety_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  depression_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  depression_bother: Yup.number().when('depression_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  losing_temper_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  losing_temper_bother: Yup.number().when('losing_temper_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  problems_with_relationships_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  problems_with_relationships_bother: Yup.number().when('problems_with_relationships_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  problems_with_social_interaction_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  problems_with_social_interaction_bother: Yup.number().when(
    'problems_with_social_interaction_frequency',
    {
      is: val => val && val !== 0,
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),
  problems_with_sexual_desire_activity_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  problems_with_sexual_desire_activity_bother: Yup.number().when(
    'problems_with_sexual_desire_activity_frequency',
    {
      is: val => val && val !== 0,
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),
  problems_with_daily_activities_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  problems_with_daily_activities_bother: Yup.number().when(
    'problems_with_daily_activities_frequency',
    {
      is: val => val && val !== 0,
      then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
    }
  ),
  sudden_muscle_weakness_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  sudden_muscle_weakness_bother: Yup.number().when('sudden_muscle_weakness_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  safety_concerns_frequency: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  safety_concerns_bother: Yup.number().when('safety_concerns_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  concerns_with_health_frequency: Yup.number()
    .min(minNum, numErr)
    .max(maxNum, numErr)
    .required(reqErr),
  concerns_with_health_bother: Yup.number().when('concerns_with_health_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  near_accident: Yup.number().when('safety_concerns_frequency', {
    is: val => val && val !== 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),

  receive_help: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  receive_help_from: Yup.number().when('receive_help', {
    is: 1,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  nocturnal_sleep_rating: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  daytime_functioning_rating: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  final_remarks: Yup.string().max(maxChars, tooLongErr),

  nocturnal_sleep_change: Yup.number().when('__isSecondRound__', {
    is: true,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  daytime_functioning_change: Yup.number().when('__isSecondRound__', {
    is: true,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  which_treatment: Yup.string().when('__isSecondRound__', {
    is: true,
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),
  followed_treatment: Yup.number().when('__isSecondRound__', {
    is: true,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  did_follow_treatment_status: Yup.number().when(['__isSecondRound__', 'followed_treatment'], {
    is: (a, b) => a === true && b === 1,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  did_follow_treatment_comment: Yup.string().max(maxChars, tooLongErr),
  did_not_follow_treatment_status: Yup.number().when(['__isSecondRound__', 'followed_treatment'], {
    is: (a, b) => a === true && b === 0,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  did_not_follow_treatment_comment: Yup.string().max(maxChars, tooLongErr),
  side_effects_extent: Yup.number().when('__isSecondRound__', {
    is: true,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  side_effects_description: Yup.string().when(['__isSecondRound__', 'side_effects_extent'], {
    is: (a, b) => a === true && b > 0,
    then: Yup.string().max(maxChars, tooLongErr).required(reqErr),
  }),
  treatment_helpful_extent: Yup.number().when('__isSecondRound__', {
    is: true,
    then: Yup.number().min(minNum, numErr).max(maxNum, numErr).required(reqErr),
  }),
  treatment_not_helpful_explanation: Yup.string().max(maxChars, tooLongErr),
  treatment_was_helpful_explanation: Yup.string().max(maxChars, tooLongErr),
})

export default FormikValidationSchema
