/* eslint-disable camelcase */

import React, { useContext, useEffect } from 'react'
import Comment from '../../../comps/Comment'
import ConnectedFields from '../../../comps/ConnectedFields'
import FormikMuiInput from '../../../comps/FormikMuiInput'
import FormikMuiRadio from '../../../comps/FormikMuiRadio'
import { LangContext } from '../../../const/Contexts'
import { occurrenceFrequencyKeys, occurrenceBotherKeys } from '../../../const/ESQOptions'

const FAMSFallingAsleep = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  const { values, setFieldValue } = formikProps

  const {
    pain_frequency,
    pain_bother,
    itch_frequency,
    itch_bother,
    leg_cramps_frequency,
    leg_cramps_bother,
    limbs_urge_to_move_frequency,
    limbs_urge_to_move_bother,
    bodily_movements_frequency,
    bodily_movements_bother,
    nasal_obstruction_frequency,
    nasal_obstruction_bother,
    own_snoring_frequency,
    own_snoring_bother,
    urge_to_urinate_frequency,
    urge_to_urinate_bother,
    urge_to_urinate_count,
    bedwetting_frequency,
    bedwetting_bother,
    urge_to_eat_frequency,
    urge_to_eat_bother,
    worrying_thoughts_frequency,
    worrying_thoughts_bother,
    nightmares_frequency,
    nightmares_bother,
    other_fa_frequency,
    other_fa_bother,
    other_fa_info,
  } = values

  // Resetting 'Extra' fields when they switch back (ex. 'Other' boxes)
  useEffect(() => {
    if (pain_frequency === 0 && pain_bother !== '') {
      setFieldValue('pain_bother', '')
    }
  }, [pain_frequency])
  useEffect(() => {
    if (itch_frequency === 0 && itch_bother !== '') {
      setFieldValue('itch_bother', '')
    }
  }, [itch_frequency])
  useEffect(() => {
    if (leg_cramps_frequency === 0 && leg_cramps_bother !== '') {
      setFieldValue('leg_cramps_bother', '')
    }
  }, [leg_cramps_frequency])
  useEffect(() => {
    if (limbs_urge_to_move_frequency === 0 && limbs_urge_to_move_bother !== '') {
      setFieldValue('limbs_urge_to_move_bother', '')
    }
  }, [limbs_urge_to_move_frequency])
  useEffect(() => {
    if (bodily_movements_frequency === 0 && bodily_movements_bother !== '') {
      setFieldValue('bodily_movements_bother', '')
    }
  }, [bodily_movements_frequency])
  useEffect(() => {
    if (nasal_obstruction_frequency === 0 && nasal_obstruction_bother !== '') {
      setFieldValue('nasal_obstruction_bother', '')
    }
  }, [nasal_obstruction_frequency])
  useEffect(() => {
    if (own_snoring_frequency === 0 && own_snoring_bother !== '') {
      setFieldValue('own_snoring_bother', '')
    }
  }, [own_snoring_frequency])
  useEffect(() => {
    if (urge_to_urinate_frequency === 0) {
      if (urge_to_urinate_bother !== '') {
        setFieldValue('urge_to_urinate_bother', '')
      }
      if (urge_to_urinate_count !== '') {
        setFieldValue('urge_to_urinate_count', '')
      }
    }
  }, [urge_to_urinate_frequency])
  useEffect(() => {
    if (bedwetting_frequency === 0 && bedwetting_bother !== '') {
      setFieldValue('bedwetting_bother', '')
    }
  }, [bedwetting_frequency])
  useEffect(() => {
    if (urge_to_eat_frequency === 0 && urge_to_eat_bother !== '') {
      setFieldValue('urge_to_eat_bother', '')
    }
  }, [urge_to_eat_frequency])
  useEffect(() => {
    if (worrying_thoughts_frequency === 0 && worrying_thoughts_bother !== '') {
      setFieldValue('worrying_thoughts_bother', '')
    }
  }, [worrying_thoughts_frequency])
  useEffect(() => {
    if (nightmares_frequency === 0 && nightmares_bother !== '') {
      setFieldValue('nightmares_bother', '')
    }
  }, [nightmares_frequency])
  useEffect(() => {
    if (other_fa_frequency === 0) {
      if (other_fa_bother !== '') {
        setFieldValue('other_fa_bother', '')
      }
      if (other_fa_info !== '') {
        setFieldValue('other_fa_info', '')
      }
    }
  }, [other_fa_frequency])

  return (
    <>
      <ConnectedFields>
        <Comment text={t('Duringthepastmonth')} bold />
        <Comment text={t('columnName1FactorsAffectingMySleep2')} />
        <FormikMuiRadio
          column
          label={t('pain')}
          name="pain_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('itch')}
          name="itch_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('legcramps')}
          name="leg_cramps_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('movelimbs')}
          name="limbs_urge_to_move_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('bodilymovements')}
          name="bodily_movements_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('nasalobstruction')}
          name="nasal_obstruction_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('ownsnoring')}
          name="own_snoring_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('urinate')}
          name="urge_to_urinate_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('bedwetting')}
          name="bedwetting_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('eat')}
          name="urge_to_eat_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('thoughts')}
          name="worrying_thoughts_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('nightmares')}
          name="nightmares_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        <FormikMuiRadio
          column
          label={t('otherIfPresent')}
          name="other_fa_frequency"
          options={occurrenceFrequencyKeys.map(val => t(val))}
        />
        {other_fa_frequency > 0 && (
          <FormikMuiInput label={t('otherIfPresent')} name="other_fa_info" type="text" />
        )}
        {urge_to_urinate_frequency >= 3 && (
          <>
            <Comment text={t('urinateTimes')} />
            <FormikMuiInput label={t('urinateTimes')} name="urge_to_urinate_count" type="number" />
          </>
        )}
      </ConnectedFields>
      <ConnectedFields>
        {(pain_frequency > 0 ||
          itch_frequency > 0 ||
          leg_cramps_frequency > 0 ||
          limbs_urge_to_move_frequency > 0 ||
          bodily_movements_frequency > 0 ||
          nasal_obstruction_frequency > 0 ||
          own_snoring_frequency > 0 ||
          urge_to_urinate_frequency > 0 ||
          bedwetting_frequency > 0 ||
          urge_to_eat_frequency > 0 ||
          worrying_thoughts_frequency > 0 ||
          nightmares_frequency > 0 ||
          other_fa_frequency > 0) && <Comment text={t('columnName2FactorsAffectingMySleep2')} />}
        {pain_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('pain')}
            name="pain_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {itch_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('itch')}
            name="itch_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {leg_cramps_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('legcramps')}
            name="leg_cramps_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {limbs_urge_to_move_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('movelimbs')}
            name="limbs_urge_to_move_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {bodily_movements_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('bodilymovements')}
            name="bodily_movements_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {nasal_obstruction_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('nasalobstruction')}
            name="nasal_obstruction_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {own_snoring_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('ownsnoring')}
            name="own_snoring_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {urge_to_urinate_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('urinate')}
            name="urge_to_urinate_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {bedwetting_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('bedwetting')}
            name="bedwetting_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {urge_to_eat_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('eat')}
            name="urge_to_eat_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {worrying_thoughts_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('thoughts')}
            name="worrying_thoughts_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {nightmares_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('nightmares')}
            name="nightmares_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
        {other_fa_frequency > 0 && (
          <FormikMuiRadio
            column
            label={t('Other')}
            name="other_fa_bother"
            options={occurrenceBotherKeys.map(val => t(val))}
          />
        )}
      </ConnectedFields>
    </>
  )
}

export default FAMSFallingAsleep
