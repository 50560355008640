import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Text } from '../atoms'
import { DropDown } from '../molecules'

const getLang = () => {
  const language = JSON.parse(localStorage.getItem('lang'))
  if (language != null) {
    return language
  }
  return 'en'
}

const LanguageDropdown = ({ width, mb }) => {
  const [lang, setLang] = useState(getLang())
  const [langText, setLangText] = useState('')
  const { i18n } = useTranslation(['common'])

  const languageOptions = [
    {
      text: 'English',
      value: 'en',
      onPress: () => {
        setLang('en')
      },
      icon: '../../assets/flags/gb.svg',
    },
    {
      text: 'Íslenska',
      value: 'is',
      onPress: () => {
        setLang('is')
      },
      icon: '../../assets/flags/is.svg',
    },
    {
      text: 'Čeština',
      value: 'cs',
      onPress: () => {
        setLang('cs')
      },
      icon: '../../assets/flags/cz.svg',
    },
    {
      text: 'Deutsch',
      value: 'de',
      onPress: () => {
        setLang('de')
      },
      icon: '../../assets/flags/de.svg',
    },
    {
      text: 'Ελληνικά',
      value: 'gr',
      onPress: () => {
        setLang('gr')
      },
      icon: '../../assets/flags/gr.svg',
    },
    {
      text: 'Eesti keel',
      value: 'et',
      onPress: () => {
        setLang('et')
      },
      icon: '../../assets/flags/ee.svg',
    },
    {
      text: 'Finnish',
      value: 'fi',
      onPress: () => {
        setLang('fi')
      },
      icon: '../../assets/flags/fi.svg',
    },
    {
      text: 'Français',
      value: 'fr',
      onPress: () => {
        setLang('fr')
      },
      icon: '../../assets/flags/fr.svg',
    },
    {
      text: 'Hrvatski',
      value: 'hr',
      onPress: () => {
        setLang('hr')
      },
      icon: '../../assets/flags/hr.svg',
    },
    {
      text: 'Italiano',
      value: 'it',
      onPress: () => {
        setLang('it')
      },
      icon: '../../assets/flags/it.svg',
    },
    {
      text: 'Norsk',
      value: 'no',
      onPress: () => {
        setLang('no')
      },
      icon: '../../assets/flags/no.svg',
    },
    {
      text: 'Nederlands',
      value: 'nl',
      onPress: () => {
        setLang('nl')
      },
      icon: '../../assets/flags/nl.svg',
    },
    {
      text: 'Polish',
      value: 'pl',
      onPress: () => {
        setLang('pl')
      },
      icon: '../../assets/flags/pl.svg',
    },
    {
      text: 'Português',
      value: 'pt',
      onPress: () => {
        setLang('pt')
      },
      icon: '../../assets/flags/pt.svg',
    },
    {
      text: 'Română',
      value: 'ro',
      onPress: () => {
        setLang('ro')
      },
      icon: '../../assets/flags/ro.svg',
    },
    {
      text: 'Svenska',
      value: 'sv',
      onPress: () => {
        setLang('sv')
      },
      icon: '../../assets/flags/se.svg',
    },
    {
      text: 'Türkçe',
      value: 'tr',
      onPress: () => {
        setLang('tr')
      },
      icon: '../../assets/flags/tr.svg',
    },
  ]

  useEffect(() => {
    localStorage.setItem('lang', JSON.stringify(lang))
    i18n.changeLanguage(lang)
    setLangText(languageOptions.find(x => x.value === lang).text)
    console.log('end use effect')
  }, [lang])

  return (
    <Box width={width || '3rem'} mb={mb || '0px'}>
      <DropDown
        header={langText}
        options={languageOptions}
        icon={languageOptions.find(x => x.value === lang).icon}
      />
    </Box>
  )
}
export default LanguageDropdown
