import React, { useMemo, useState } from 'react'
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import moment from 'moment'
import colors from 'utils/colors'
import { Box, Text, Button } from '..'
import {
  BoxSection,
  ESQVMiscDG,
  ESQVLC,
  Loading,
  ESQVRC,
  ESQVFreqSevDG,
  ESQVCodeLegend,
} from './wrappers'

const sectionTitles = {
  participant: 'Participant',
  personalInformation: 'Personal information',
  myHelpRequest: 'My help request',
  mySleepProfile: 'My sleep profile',
  factorsAffectingMySleep: 'Factors affecting my sleep',
  mySleepAffectingOthers: 'My sleep affecting others',
  myNocturnalSleep: 'My nocturnal sleep',
  myDaytimeFunctioning: 'My daytime functioning',
  finalComments: 'Final comments',
  followUpModule: 'Follow-up module',
  legend: 'Legend: explanation of codes',
}

// for selection (SO) patterns of "[No, Yes]" or "[option1, option2, neither of these, don't know]"
const filterInteresting = (shouldFilterInteresting, entryResults) => {
  if (!shouldFilterInteresting) {
    return entryResults
  }

  let shouldExit = false
  let selection
  entryResults.forEach(er => {
    if (!shouldExit && er !== undefined) {
      if (!(er?.base?.entryId?.entryType === 'SO' || er?.followUp?.entryId?.entryType === 'SO')) {
        console.error('filterInteresting: not all entries are selection type.')
        shouldExit = true
      } else {
        const currSelection = er?.base?.entryId?.selection ?? er?.followUp?.entryId?.selection
        if (
          !(
            selection?.endsWith('"neither of these", "don\'t know"]') &&
            currSelection?.endsWith('"neither of these", "don\'t know"]')
          ) &&
          selection !== undefined &&
          currSelection !== undefined &&
          selection !== currSelection
        ) {
          console.error('filterInteresting: not all entries have the same selection options.')
          shouldExit = true
        }
        selection = currSelection ?? selection
      }
    }
  })

  if (shouldExit || selection === undefined) {
    return entryResults
  }

  if (selection === '["No", "Yes"]') {
    const arr = []
    const interestingIdx = 1
    entryResults.forEach(er => {
      if (
        er?.base?.selectAnswer === interestingIdx ||
        er?.followUp?.selectAnswer === interestingIdx
      ) {
        arr.push(er)
      }
    })
    return arr
  }
  if (selection.endsWith('"neither of these", "don\'t know"]')) {
    const arr = []
    const interestingIdx = [0, 1]
    entryResults.forEach(er => {
      if (
        interestingIdx.includes(er?.base?.selectAnswer) ||
        interestingIdx.includes(er?.followUp?.selectAnswer)
      ) {
        arr.push(er)
      }
    })
    return arr
  }

  return entryResults
}

const checkBotherIsInteresting = (shouldFilterInteresting, botherEntry, entry) => {
  if (!shouldFilterInteresting) {
    return entry
  }
  if (botherEntry?.base?.selectAnswer >= 2 || botherEntry?.followUp?.selectAnswer >= 2) {
    return entry
  }
  return undefined
}

const EsqVisManager = ({ metaData, baseData, followUpData, sri, comparison }) => {
  const [activeSection, setActiveSection] = useState(sectionTitles.participant)
  const [shouldFilterInteresting, setShouldFilterInteresting] = useState(true)

  const dates = useMemo(() => {
    // console.log(metaData)
    const obj = {}
    const baseDate =
      metaData?.base || metaData?.entryResults?.find(er => er.title === 'base_finished_at')?.value
    obj.base = baseDate ? moment(baseDate).format('YYYY-MM-DD') : ''
    const followUpDate =
      metaData?.followUp ||
      metaData?.entryResults?.find(er => er.title === 'follow_up_finished_at')?.value
    obj.followUp = followUpDate ? moment(followUpDate).format('YYYY-MM-DD') : ''
    return obj
  }, [metaData])

  // storing data in a more structured object
  const data = useMemo(() => {
    if (baseData && followUpData && metaData) {
      const obj = {}
      // console.log(baseData)
      // console.log(followUpData)

      baseData.forEach(({ entryResults }) => {
        entryResults.forEach(er => {
          obj[er.entryId.title] = { base: er }
        })
      })
      followUpData.forEach(({ entryResults }) => {
        entryResults.forEach(er => {
          obj[er.entryId.title] = obj[er.entryId.title]
            ? { ...obj[er.entryId.title], followUp: er }
            : { followUp: er }
        })
      })
      return obj
    }
    return null
  }, [baseData, followUpData])

  const sections = useMemo(() => {
    const arr = [
      sectionTitles.participant,
      sectionTitles.personalInformation,
      sectionTitles.myHelpRequest,
      sectionTitles.mySleepProfile,
      sectionTitles.factorsAffectingMySleep,
    ]
    const conditionalSection = sectionTitles.mySleepAffectingOthers
    const arr2 = [
      sectionTitles.myNocturnalSleep,
      sectionTitles.myDaytimeFunctioning,
      sectionTitles.finalComments,
      sectionTitles.followUpModule,
      sectionTitles.legend,
    ]
    if (data.living_arrangement_with_partner) {
      return [...arr, conditionalSection, ...arr2]
    }
    return [...arr, ...arr2]
  }, [data])

  if (!sri || !data || !dates || !(sections && sections.length > 0)) {
    return <Loading />
  }

  return (
    <Box block>
      <Box row flexWrap mb="30px">
        {sections.map(title => (
          <Box key={title} m="3px" flexGrow>
            <Button
              color1={activeSection === title ? colors.mood.left : colors.button.right}
              color2={activeSection === title ? colors.reactionTask.right : colors.button.right}
              maxWidth="100%"
              onPress={() => setActiveSection(title)}
            >
              <Box mh="5px">
                <Text center>{title}</Text>
              </Box>
            </Button>
          </Box>
        ))}
      </Box>
      <Box cc mb="30px">
        <FormGroup>
          <FormControlLabel
            label={<Text>Only show interesting data</Text>}
            control={
              <Checkbox
                checked={shouldFilterInteresting}
                onChange={e => setShouldFilterInteresting(e.target.checked)}
              />
            }
          />
        </FormGroup>
      </Box>
      {activeSection === sectionTitles.participant && (
        <BoxSection title={activeSection} subtitle={`${sri} (${dates.base} / ${dates.followUp})`}>
          <ESQVMiscDG
            showToolbar
            entries={[
              data.height,
              data.weight,
              data.gender,
              data.gender_other,
              ...(shouldFilterInteresting
                ? []
                : [data.country_birth, data.country_residence, data.native_lang, data.ethnicity]),
            ]}
          />
        </BoxSection>
      )}
      {activeSection === sectionTitles.personalInformation && (
        <BoxSection title={activeSection}>
          <ESQVMiscDG
            showToolbar
            entries={[
              ...filterInteresting(shouldFilterInteresting, [
                data.occupation_employed,
                data.occupation_self_employed,
                data.occupation_student,
                data.occupation_volunteering,
                data.occupation_caretaker,
                data.occupation_retired,
                data.occupation_sick_leave,
                data.occupation_disability,
              ]),
              data.work_schedule,
              data.work_schedule_other,
              data.education_highest,
              ...filterInteresting(shouldFilterInteresting, [
                data.living_arrangement_alone,
                data.living_arrangement_with_partner,
                data.living_arrangement_with_children,
                data.living_arrangement_with_family,
                data.living_arrangement_with_others,
                data.living_arrangement_with_pet,
              ]),
              data.sleeping_arrangement,
              data.driving_license,
              data.driving_license_category_1,
              data.driving_license_category_2,
              data.driving_km,
              data.does_smoke,
              data.smoking_year_started,
              ...filterInteresting(shouldFilterInteresting, [
                data.smoking_category_cigarettes,
                data.smoking_category_cigars,
                data.smoking_category_chewing_tobacco,
                data.smoking_category_pipe,
                data.smoking_category_vape,
              ]),
              data.smoking_average_day,
              data.alcohol_intake,
              data.alcohol_intake_days,
              data.alcohol_intake_units,
              data.alcohol_sleep_aid,
              data.caffeine_intake,
              ...filterInteresting(shouldFilterInteresting, [
                data.caffeine_category_coffee,
                data.caffeine_category_tea,
                data.caffeine_category_energy_drinks,
                data.caffeine_category_other,
              ]),
              data.caffeine_category_other_info,
              data.caffeine_average_units,
              data.caffeine_last_drink,
              data.substance_intake,
              data.substance_intake_info,
              data.substance_intake_days,
              data.substance_intake_units,
              data.exercise,
              data.exercise_count,
              data.exercise_count_type,
              data.exercise_hours,
              data.exercise_hours_type,
              data.leisure_recreational_activities,
              data.uses_contraception_therapy,
              data.is_pregnant,
              data.menopausal_state,
              data.uses_hormone_replacement,
              data.self_reported_health,
              ...filterInteresting(shouldFilterInteresting, [
                data.stress_none,
                data.stress_health,
                data.stress_relationship_family,
                data.stress_job_school,
                data.stress_financial,
                data.stress_other,
              ]),
              data.stress_other_info,
              ...filterInteresting(shouldFilterInteresting, [data.stress_additional_comments]),
              data.stress_additional_comments_info,
            ]}
          />
        </BoxSection>
      )}
      {activeSection === sectionTitles.myHelpRequest && (
        <BoxSection title={activeSection}>
          <ESQVMiscDG
            showToolbar
            entries={[
              data.sleep_daytime_problem_description,
              data.sleep_daytime_problem_duration,
              data.sleep_daytime_problem_worsen,
              data.sleep_daytime_problem_worsen_info,
              data.sleep_daytime_problem_causation,
              data.sleep_problem_affects_daytime,
              data.sleep_problem_affects_daytime_info,
              data.diagnostic_investigation_expectation,
              data.previous_sleep_problem_treatment,
              data.prescribed_treatments_for_sleep,
              data.treated_by_family_doctor,
              data.treated_by_specialist_medical_doctor,
              data.treated_by_non_medical_practitioner,
              data.previous_treatment_other_sleep_problems,
              data.previous_treatment_other_sleep_problems_info,
            ]}
          />
        </BoxSection>
      )}
      {activeSection === sectionTitles.mySleepProfile && (
        <BoxSection title={activeSection}>
          <ESQVMiscDG
            showToolbar
            entries={[
              ...filterInteresting(shouldFilterInteresting, [
                data.sleep_duration,
                data.bedtime,
                data.wake_up_time,
                data.sleep_schedule,
                data.sleep_depth,
                data.sleep_quantity,
                data.sleep_offset,
              ]),
              data.sleep_time_preferred,
              data.out_of_bed_preferred,
              data.sleep_amount_preferred,
              data.sleep_amount_business_days,
              data.sleep_amount_free_days,
              data.sleep_into_bed_business_days,
              data.sleep_into_bed_free_days,
              data.sleep_out_of_bed_business_days,
              data.sleep_out_of_bed_free_days,
              ...filterInteresting(shouldFilterInteresting, [
                data.activities_before_sleep_none,
                data.activities_before_sleep_reading,
                data.activities_before_sleep_listening,
                data.activities_before_sleep_meditating,
                data.activities_before_sleep_watching_tv,
                data.activities_before_sleep_using_device,
                data.activities_before_sleep_other,
              ]),
              data.activities_before_sleep_other_info,
              data.planned_nap,
              data.nap_frequency_per_day,
              data.nap_day_count,
              data.nap_per_week_or_month,
              data.nap_average_duration,
              data.nap_feels_refreshed,
            ]}
          />
        </BoxSection>
      )}
      {activeSection === sectionTitles.factorsAffectingMySleep && (
        <BoxSection
          title={activeSection}
          subtitle="Factors affecting falling asleep and/or sleeping"
        >
          <ESQVLC
            comparison={comparison}
            shouldFilterInteresting={shouldFilterInteresting}
            freqBotherEntries={[
              [data.pain_frequency, data.pain_bother],
              [data.itch_frequency, data.itch_bother],
              [data.leg_cramps_frequency, data.leg_cramps_bother],
              [data.limbs_urge_to_move_frequency, data.limbs_urge_to_move_bother],
              [data.bodily_movements_frequency, data.bodily_movements_bother],
              [data.nasal_obstruction_frequency, data.nasal_obstruction_bother],
              [data.own_snoring_frequency, data.own_snoring_bother],
              [data.urge_to_urinate_frequency, data.urge_to_urinate_bother],
              [data.bedwetting_frequency, data.bedwetting_bother],
              [data.urge_to_eat_frequency, data.urge_to_eat_bother],
              [data.worrying_thoughts_frequency, data.worrying_thoughts_bother],
              [data.nightmares_frequency, data.nightmares_bother],
              [data.other_fa_frequency, data.other_fa_bother],
            ]}
          />
          <ESQVMiscDG
            entries={[
              checkBotherIsInteresting(
                shouldFilterInteresting,
                data.urge_to_urinate_bother,
                data.urge_to_urinate_count
              ),
              checkBotherIsInteresting(
                shouldFilterInteresting,
                data.other_fa_bother,
                data.other_fa_info
              ),
            ]}
          />
        </BoxSection>
      )}
      {activeSection === sectionTitles.factorsAffectingMySleep && (
        <BoxSection
          title={activeSection}
          subtitle="Feelings associated with your awakenings during sleep and/or final awakening"
        >
          <ESQVLC
            comparison={comparison}
            shouldFilterInteresting={shouldFilterInteresting}
            freqBotherEntries={[
              [data.breathlessness_or_choking_frequency, data.breathlessness_or_chokings_bother],
              [data.inability_to_move_feeling_frequency, data.inability_to_move_feelings_bother],
              [data.panic_feeling_frequency, data.panic_feelings_bother],
              [data.headache_pain_frequency, data.headache_pains_bother],
              [data.dry_mouth_feeling_frequency, data.dry_mouth_feelings_bother],
              [data.sore_throat_pain_frequency, data.sore_throat_pains_bother],
              [data.jaw_pain_frequency, data.jaw_pains_bother],
              [data.heartburn_feeling_frequency, data.heartburn_feelings_bother],
              [data.palpitations_feeling_frequency, data.palpitations_feelings_bother],
              [data.excessive_sweating_feeling_frequency, data.excessive_sweating_feelings_bother],
              [data.hot_flushes_feeling_frequency, data.hot_flushes_feelings_bother],
              [data.other_ds_frequency, data.other_ds_bother],
            ]}
          />
          <ESQVMiscDG
            entries={[
              checkBotherIsInteresting(
                shouldFilterInteresting,
                data.other_ds_bother,
                data.other_ds_info
              ),
            ]}
          />
        </BoxSection>
      )}
      {activeSection === sectionTitles.factorsAffectingMySleep && (
        <BoxSection title={activeSection} subtitle="External factors affecting your sleep">
          <ESQVLC
            comparison={comparison}
            shouldFilterInteresting={shouldFilterInteresting}
            freqBotherEntries={[
              [data.snoring_bedpartner_frequency, data.snoring_bedpartners_bother],
              [data.movements_bedpartner_frequency, data.movements_bedpartners_bother],
              [data.pet_activity_frequency, data.pet_activitys_bother],
              [data.caretaking_family_frequency, data.caretaking_familys_bother],
              [data.sleeping_apart_frequency, data.sleeping_aparts_bother],
              [data.environmental_noise_frequency, data.environmental_noises_bother],
              [data.smartphone_messages_frequency, data.smartphone_messagess_bother],
              [data.bedroom_inconveniences_frequency, data.bedroom_inconveniencess_bother],
              [data.other_o_frequency, data.other_o_bother],
            ]}
          />
          <ESQVMiscDG
            entries={[
              checkBotherIsInteresting(
                shouldFilterInteresting,
                data.other_o_bother,
                data.other_o_info
              ),
            ]}
          />
        </BoxSection>
      )}
      {activeSection === sectionTitles.mySleepAffectingOthers && (
        <BoxSection title={activeSection}>
          <ESQVLC
            comparison={comparison}
            shouldFilterInteresting={shouldFilterInteresting}
            freqBotherEntries={[
              [data.self_snoring_frequency, data.self_snoring_bother],
              [data.self_breathing_stops_frequency, data.self_breathing_stops_bother],
              [data.self_limb_movements_frequency, data.self_limb_movements_bother],
              [data.self_tossing_turning_frequency, data.self_tossing_turning_bother],
              [data.self_kicking_punching_frequency, data.self_kicking_punching_bother],
              [data.self_talking_screaming_frequency, data.self_talking_screaming_bother],
              [data.self_teeth_grinding_frequency, data.self_teeth_grinding_bother],
              [data.self_sleepwalking_frequency, data.self_sleepwalking_bother],
              [data.other_os_frequency, data.other_os_bother],
            ]}
          />
          <ESQVMiscDG
            entries={[
              checkBotherIsInteresting(
                shouldFilterInteresting,
                data.other_os_bother,
                data.other_os_info
              ),
            ]}
          />
        </BoxSection>
      )}
      {activeSection === sectionTitles.myNocturnalSleep && (
        <BoxSection title={activeSection}>
          <ESQVFreqSevDG
            entries={[
              {
                title: 'Fall asleep',
                severity1: data.fall_asleep_30_to_60,
                severity2: data.fall_asleep_60_to_90,
                severity3: data.fall_asleep_over_90,
                bother: data.fall_asleep_bother,
              },
              {
                title: 'Woke up',
                severity1: data.woke_up_1_to_2,
                severity2: data.woke_up_2_to_4,
                severity3: data.woke_up_over_4,
                bother: data.woke_up_bother,
              },
              {
                title: 'Fall asleep after awake',
                severity1: data.fall_asleep_after_awake_30_to_60,
                severity2: data.fall_asleep_after_awake_60_to_90,
                severity3: data.fall_asleep_after_awake_over_90,
                bother: data.fall_asleep_after_awake_bother,
              },
              {
                title: 'Woke up early',
                severity1: data.woke_up_early_30_to_60,
                severity2: data.woke_up_early_60_to_90,
                severity3: data.woke_up_early_over_90,
                bother: data.woke_up_early_bother,
              },
              {
                title: 'Felt unrefreshed',
                severity1: data.felt_unrefreshed_30_to_60,
                severity2: data.felt_unrefreshed_1_to_4,
                severity3: data.felt_unrefreshed_over_4,
                bother: data.felt_unrefreshed_bother,
              },
            ]}
          />
          <ESQVRC
            botherEntries={[
              data.fall_asleep_bother,
              data.woke_up_bother,
              data.fall_asleep_after_awake_bother,
              data.woke_up_early_bother,
              data.felt_unrefreshed_bother,
            ]}
          />
        </BoxSection>
      )}
      {activeSection === sectionTitles.myDaytimeFunctioning && (
        <BoxSection title={activeSection}>
          <ESQVFreqSevDG
            entries={[
              {
                title: 'Fell unintentionally asleep',
                severity1: data.fell_unintentionally_asleep_passive,
                severity2: data.fell_unintentionally_asleep_moderate,
                severity3: data.fell_unintentionally_asleep_strong,
                bother: data.fell_unintentionally_asleep_bother,
              },
              {
                title: 'Fight sleepy',
                severity1: data.effort_to_stay_awake,
                severity2: data.special_measures_to_stay_awake,
                severity3: data.activities_without_awareness,
                bother: data.fight_sleepy_bother,
              },
              {
                title: 'Overslept with alarm',
                severity1: data.overslept_with_alarm,
                bother: data.overslept_with_alarm_bother,
              },
              {
                title: 'Lack of energy',
                severity1: data.lack_of_energy_leisure_start,
                severity2: data.lack_of_energy_duty_start,
                severity3: data.lack_of_energy_any_start,
                bother: data.lack_of_energy_bother,
              },
              {
                title: 'Exhausted activity',
                severity1: data.exhausted_activity_strong,
                severity2: data.exhausted_activity_moderate,
                severity3: data.exhausted_activity_passive,
                bother: data.exhausted_activity_bother,
              },
              {
                title: 'Exhausted physical activity',
                severity1: data.exhausted_physical_activity_vigorous,
                severity2: data.exhausted_physical_activity_moderate,
                severity3: data.exhausted_physical_activity_light,
                bother: data.exhausted_physical_activity_bother,
              },
            ]}
          />
          <ESQVRC
            botherEntries={[
              data.fell_unintentionally_asleep_bother,
              data.fight_sleepy_bother,
              data.overslept_with_alarm_bother,
              data.lack_of_energy_bother,
              data.exhausted_activity_bother,
              data.exhausted_physical_activity_bother,
            ]}
          />
        </BoxSection>
      )}
      {activeSection === sectionTitles.myDaytimeFunctioning && (
        <BoxSection
          title={activeSection}
          subtitle="Miscellaneous problems with daytime functioning"
        >
          <ESQVLC
            comparison={comparison}
            shouldFilterInteresting={shouldFilterInteresting}
            freqBotherEntries={[
              [data.difficulty_remembering_frequency, data.difficulty_remembering_bother],
              [data.difficulty_concentrating_frequency, data.difficulty_concentrating_bother],
              [data.lapses_of_attention_frequency, data.lapses_of_attention_bother],
              [data.anxiety_frequency, data.anxiety_bother],
              [data.depression_frequency, data.depression_bother],
              [data.losing_temper_frequency, data.losing_temper_bother],
              [data.problems_with_relationships_frequency, data.problems_with_relationships_bother],
              [
                data.problems_with_social_interaction_frequency,
                data.problems_with_social_interaction_bother,
              ],
              [
                data.problems_with_sexual_desire_activity_frequency,
                data.problems_with_sexual_desire_activity_bother,
              ],
              [
                data.problems_with_daily_activities_frequency,
                data.problems_with_daily_activities_bother,
              ],
              [data.sudden_muscle_weakness_frequency, data.sudden_muscle_weakness_bother],
              [data.safety_concerns_frequency, data.safety_concerns_bother],
              [data.concerns_with_health_frequency, data.concerns_with_health_bother],
            ]}
          />
          {/* Only interested in things with bother > 1 */}
          <ESQVMiscDG
            entries={[
              checkBotherIsInteresting(
                shouldFilterInteresting,
                data.safety_concerns_bother,
                data.near_accident
              ),
            ]}
          />
        </BoxSection>
      )}
      {activeSection === sectionTitles.finalComments && (
        <BoxSection title={activeSection}>
          <ESQVMiscDG
            showToolbar
            entries={[
              data.receive_help,
              data.receive_help_from,
              data.nocturnal_sleep_rating,
              data.daytime_functioning_rating,
              data.final_remarks,
            ]}
          />
        </BoxSection>
      )}
      {activeSection === sectionTitles.followUpModule && (
        <BoxSection title={activeSection}>
          <ESQVMiscDG
            showToolbar
            entries={[
              data.sleep_daytime_problem_description,
              data.nocturnal_sleep_change,
              data.daytime_functioning_change,
              data.which_treatment,
              data.followed_treatment,
              data.did_follow_treatment_status,
              data.did_follow_treatment_comment,
              data.did_not_follow_treatment_status,
              data.did_not_follow_treatment_comment,
              data.side_effects_extent,
              data.side_effects_description,
              data.treatment_helpful_extent,
              data.treatment_not_helpful_explanation,
              data.treatment_was_helpful_explanation,
            ]}
          />
        </BoxSection>
      )}
      {activeSection === sectionTitles.legend && (
        <BoxSection title={activeSection}>
          <ESQVCodeLegend />
        </BoxSection>
      )}
    </Box>
  )
}

export default EsqVisManager
