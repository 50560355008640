import Introduction from '../sections/0_Introduction/Introduction'
import GeneralInformation, {
  GIFieldNames,
} from '../sections/1_GeneralInformation/GeneralInformation'
import MyHelpRequest, { MHRFieldNames } from '../sections/2_MyHelpRequest/MyHelpRequest'
import MySleepProfile, { MSPFieldNames } from '../sections/3_MySleepProfile/MySleepProfile'
import FactorsAffectingMySleep, {
  FAMSFieldNames,
} from '../sections/4_FactorsAffectingMySleep/FactorsAffectingMySleep'
import MySleepAffectingOthers, {
  MSAOFieldNames,
} from '../sections/5_MySleepAffectingOthers/MySleepAffectingOthers'
import NocturnalSleep, { NSFieldNames } from '../sections/6_NocturnalSleep/NocturnalSleep'
import DaytimeFunctioning, {
  DFFieldNames,
} from '../sections/7_DaytimeFunctioning/DaytimeFunctioning'
import FollowUp, { FUFieldNames } from '../sections/8_FollowUp/FollowUp'

const formSections = [
  // {
  //   key: 'I0',
  //   translationKey: 'Instructions',
  //   component: props => <Introduction formikProps={props} />,
  //   fieldNames: [],
  // },
  {
    key: 'GI0',
    translationKey: 'GeneralInformation',
    component: props => <GeneralInformation formikProps={props} />,
    fieldNames: GIFieldNames,
    formTitle: 'ESQ General Information',
    dependentSectionKeys: ['MSAO0'],
  },
  {
    key: 'MHR0',
    translationKey: 'MyHelpRequest',
    component: props => <MyHelpRequest formikProps={props} />,
    fieldNames: MHRFieldNames,
    formTitle: 'ESQ My Help Request',
  },
  {
    key: 'MSP0',
    translationKey: 'MySleepProfile',
    component: props => <MySleepProfile formikProps={props} />,
    fieldNames: MSPFieldNames,
    formTitle: 'ESQ My Sleep Profile',
  },
  {
    key: 'FAMS0',
    translationKey: 'FactorsAffectingMySleep',
    component: props => <FactorsAffectingMySleep formikProps={props} />,
    fieldNames: FAMSFieldNames,
    formTitle: 'ESQ Factors Affecting My Sleep',
  },
  {
    key: 'MSAO0',
    translationKey: 'Mysleepaffectingothers',
    component: props => <MySleepAffectingOthers formikProps={props} />,
    fieldNames: MSAOFieldNames,
    formTitle: 'ESQ My Sleep Affecting Others',
  },
  {
    key: 'NS0',
    translationKey: 'NocturalSleep',
    component: props => <NocturnalSleep formikProps={props} />,
    fieldNames: NSFieldNames,
    formTitle: 'ESQ Nocturnal Sleep',
  },
  {
    key: 'DF0',
    translationKey: 'DaytimeFunctioning',
    component: props => <DaytimeFunctioning formikProps={props} />,
    fieldNames: DFFieldNames,
    formTitle: 'ESQ Daytime Functioning',
  },
  {
    key: 'FU0',
    translationKey: props => (props.__isSecondRound__ ? 'FollowUp' : 'FinalComments'),
    component: props => <FollowUp formikProps={props} />,
    fieldNames: FUFieldNames,
    formTitle: 'ESQ Follow-Up',
  },
]

export const getDependentSections = sections => {
  const keyIdxMap = {}
  formSections.forEach((s, idx) => {
    keyIdxMap[s.key] = idx
  })
  const uncheckedKeys = sections.map(s => s.key)
  const currentKeys = []
  while (uncheckedKeys.length > 0) {
    const key = uncheckedKeys.pop()
    if (!currentKeys.includes(key)) {
      currentKeys.push(key)
      const { dependentSectionKeys } = formSections[keyIdxMap[key]]
      if (dependentSectionKeys) {
        uncheckedKeys.push(...dependentSectionKeys)
      }
    }
  }

  return currentKeys.map(key => formSections[keyIdxMap[key]])
}

export default formSections
