import React, { useEffect, useState } from 'react'
import Chip from '@mui/material/Chip'
import Popover from '@mui/material/Popover'
import moment from 'moment'
import theme from 'utils/theme'
import Box from './Base/Box'
import Clickable from './Base/Clickable'
import Text from './Base/Text'
import Filter from './Filter'

const FilterChip = ({ label, valType, selection, updateFilterChipItem }) => {
  const [open, setOpen] = useState(false)
  const [val, setVal] = useState('')
  const [min, setMin] = useState('')
  const [max, setMax] = useState('')
  const [values, setValues] = useState([])
  const [valueIndices, setValueIndices] = useState([])
  const [anchorEl, setAnchorEl] = React.useState(null)

  useEffect(() => {
    if (label) {
      updateFilterChipItem({
        label,
        valType,
        selection,
        val,
        min,
        max,
        valueIndices,
      })
    }
  }, [val, min, max, values, valueIndices])

  const filter = () => {
    if (valType === 'number' && (min !== '' || max !== '')) {
      if (min !== '' && min === max) {
        return (
          <Box row>
            {label}: {min}
          </Box>
        )
      }

      const minStr =
        min !== '' ? (
          min
        ) : (
          <Box mt="-6px" ml="3px" mr="3px">
            <Text bold medium color={theme.colors.white}>
              ∞
            </Text>
          </Box>
        )
      const maxStr =
        max !== '' ? (
          max
        ) : (
          <Box mt="-8px" ml="3px">
            <Text bold big color={theme.colors.white}>
              ∞
            </Text>
          </Box>
        )
      return (
        <Box row mt={min !== '' && max !== '' ? '0' : '12px'}>
          {label}: {minStr} - {maxStr}
        </Box>
      )
    }

    if (valType === 'date' && (min !== '' || max !== '')) {
      const minStr =
        min !== '' ? (
          `${moment(min).format('MM/DD/YYYY')}`
        ) : (
          <Box mt="-8px" ml="3px" mr="3px">
            <Text bold medium color={theme.colors.white}>
              ∞
            </Text>
          </Box>
        )
      const maxStr =
        max !== '' ? (
          `${moment(max).format('MM/DD/YYYY')}`
        ) : (
          <Box mt="-8px" ml="3px">
            <Text bold big color={theme.colors.white}>
              ∞
            </Text>
          </Box>
        )
      return (
        <Box row mb={min !== '' && max !== '' ? '0' : '-14px'}>
          {minStr} - {maxStr}
        </Box>
      )
    }

    if (values.length > 0) return values.join(', ')

    if (val !== '') {
      if (typeof val === 'boolean') {
        return val ? label : <Box style={{ textDecoration: 'line-through' }}>{label}</Box>
      }
      return val
    }

    return null
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const clearFilter = () => {
    setVal('')
    setValues([])
    setMin('')
    setMax('')
    setValueIndices([])
  }

  return (
    <Box>
      <Clickable onPress={handleClick}>
        <Chip
          style={{
            backgroundColor: filter() ? theme.colors.bluepurple : theme.colors.greyLightest,
            color: filter() ? theme.colors.white : theme.colors.black,
            fontWeight: filter() ? 600 : 400,
            fontSize: 14,
          }}
          label={filter() || label}
          onDelete={filter() ? () => clearFilter() : null}
        />
      </Clickable>

      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Filter
          label={label}
          valType={valType}
          selection={selection}
          val={val}
          setVal={setVal}
          min={min}
          setMin={setMin}
          max={max}
          setMax={setMax}
          values={values}
          setValues={setValues}
          setValueIndices={setValueIndices}
        />
      </Popover>
    </Box>
  )
}

export default FilterChip
