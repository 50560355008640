import React from 'react'
import PropTypes from 'prop-types'
import theme from 'utils/theme'
import { Box, Clickable } from '../../atoms'

const Line = ({
  onPress,
  top,
  left,
  height,
  width,
  rotate,
  disabled,
  hover,
  position,
  children,
  rounded,
  minWidth,
  minHeight,
  maxWidth,
}) => (
  <Clickable onPress={onPress} disabled={disabled} position={position}>
    <Box
      top={top}
      left={left}
      rotate={rotate}
      height={height}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      minHeight={minHeight}
      position="absolute"
      bg={theme.colors.greySoft}
      hover={hover}
      rounded={rounded}
    >
      {children}
    </Box>
  </Clickable>
)

Line.propTypes = {
  onPress: PropTypes.func.isRequired,
  top: PropTypes.string.isRequired,
  left: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  rotate: PropTypes.string,
  disabled: PropTypes.bool,
  hover: PropTypes.string,
  position: PropTypes.string,
  children: PropTypes.node.isRequired,
  rounded: PropTypes.bool,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  maxWidth: PropTypes.string,
}

Line.defaultProps = {
  rotate: '0deg',
  disabled: false,
  hover: false,
  position: 'static',
  rounded: false,
  minWidth: 'auto',
  maxWidth: 'auto',
  minHeight: 'auto',
}

export default Line
