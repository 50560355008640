import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { BubbleDate } from '../atoms'
import CustomCalendar from './CustomCalendar'
import CustomWeeklyCalendar from './CustomWeeklyCalendar'

/**
 * A component that displays the given month in the given year as a calendar
 * overview. The year is expected to be a postive number and month is expected
 * to be a number between 1 and 12 inclusive.
 */
const MORNING_DIARY = 'morningDiaryResults'
const EVENING_DIARY = 'eveningDiaryResults'
const MEMORY_GAME = 'memoryGameResults'
const PERCEPTION_GAME = 'perceptionGameResults'
const REACTION_GAME = 'reactionGameResults'
const FLEXIBILITY_GAME = 'flexibilityGameResults'
const COGNITIVE_BATTERY = 'cognitiveBatteryResults'
const MYO_MORNING = 'myoMorningResults'
const MYO_MIDDAY = 'myoMiddayResults'
const MYO_EVENING = 'myoEveningResults'
const ESQ_INTAKE = 'esqIntake'
const ESQ_FOLLOW_UP = 'esqFollowUp'

const cleanData = data => {
  const newData = {}
  newData[MORNING_DIARY] = new Set(
    data.morningDiaryResults?.map(o => moment(o.answeredAt).format('L'))
  )
  newData[EVENING_DIARY] = new Set(
    data.eveningDiaryResults?.map(o => moment(o.answeredAt).format('L'))
  )
  newData[FLEXIBILITY_GAME] = new Set(
    data.flexibilityGameResults?.map(o => moment(o.answeredAt).format('L'))
  )
  newData[MEMORY_GAME] = new Set(data.memoryGameResults?.map(o => moment(o.answeredAt).format('L')))
  newData[PERCEPTION_GAME] = new Set(
    data.perceptionGameResults?.map(o => moment(o.answeredAt).format('L'))
  )
  newData[REACTION_GAME] = new Set(
    data.reactionGameResults?.map(o => moment(o.answeredAt).format('L'))
  )

  newData[COGNITIVE_BATTERY] = new Set(
    data.cognitiveBatteryResults?.map(o => moment(o.answeredAt).format('L'))
  )

  newData[MYO_MORNING] = new Set(data.morningMyoResults?.map(o => moment(o.answeredAt).format('L')))

  newData[MYO_MIDDAY] = new Set(data.middayMyoResults?.map(o => moment(o.answeredAt).format('L')))
  newData[MYO_EVENING] = new Set(data.eveningMyoResults?.map(o => moment(o.answeredAt).format('L')))

  const esqIntakeDate = data.esqMetadata
    ?.at(0)
    ?.entryResults?.find(er => er.entryId.title === 'base_finished_at')?.datetimefieldAnswer
  newData[ESQ_INTAKE] = new Set(esqIntakeDate ? [moment(esqIntakeDate)?.format('L')] : [])

  const esqFollowUpDate = data.esqMetadata
    ?.at(0)
    ?.entryResults?.find(er => er.entryId.title === 'follow_up_finished_at')?.datetimefieldAnswer
  newData[ESQ_FOLLOW_UP] = new Set(esqFollowUpDate ? [moment(esqFollowUpDate)?.format('L')] : [])

  // console.log(data.esqMetadata[0]?.entryResults)
  return newData
}

const BubbleCalendar = ({ year, month, startDate, weekly, itemData, activeBadges, weekdays }) => {
  const [data, setData] = useState({})

  useEffect(() => {
    if (itemData) {
      setData(cleanData(itemData))
    }
  }, [itemData])

  const createDate = (date, isOut, isFuture, key) => (
    <BubbleDate
      key={key}
      date={date}
      isOut={isOut}
      isFuture={isFuture}
      activeBadges={activeBadges}
      hasMorningDiary={data[MORNING_DIARY]?.has(date.format('L'))}
      hasEveningDiary={data[EVENING_DIARY]?.has(date.format('L'))}
      hasFlexibilityGame={data[FLEXIBILITY_GAME]?.has(date.format('L'))}
      hasMemoryGame={data[MEMORY_GAME]?.has(date.format('L'))}
      hasPerceptionGame={data[PERCEPTION_GAME]?.has(date.format('L'))}
      hasReactionGame={data[REACTION_GAME]?.has(date.format('L'))}
      hasCognitiveBattery={data[COGNITIVE_BATTERY]?.has(date.format('L'))}
      hasMyoMorning={data[MYO_MORNING]?.has(date.format('L'))}
      hasMyoMidday={data[MYO_MIDDAY]?.has(date.format('L'))}
      hasMyoEvening={data[MYO_EVENING]?.has(date.format('L'))}
      hasEsqIntake={data[ESQ_INTAKE]?.has(date.format('L'))}
      hasEsqFollowUp={data[ESQ_FOLLOW_UP]?.has(date.format('L'))}
    />
  )

  if (weekly) {
    return (
      <CustomWeeklyCalendar
        hSpacing="0.8rem"
        vSpacing="0px"
        startDate={startDate}
        createDate={createDate}
        weekdays={weekdays}
      />
    )
  }

  return (
    // calendar
    <CustomCalendar
      hSpacing="0.8rem"
      vSpacing="0.8rem"
      month={month}
      year={year}
      createDate={createDate}
      weekdays={weekdays}
    />

    // <Box flexDirection="column">
    //   {/* calendar rows */}
    //   {weeks.map((week, i) => (
    //     <Box key={i.toString()} flexDirection="row" flex={1}>
    //       {week.map(date => (
    //         <BubbleDate
    //           key={date.toISOString()}
    //           date={date}
    //           isGrey={date.month() + 1 !== month}
    //           hasMorningDiary={data[MORNING_DIARY]?.has(date.format('L'))}
    //           hasEveningDiary={data[EVENING_DIARY]?.has(date.format('L'))}
    //           hasFlexibilityGame={data[FLEXIBILITY_GAME]?.has(date.format('L'))}
    //           hasMemoryGame={data[MEMORY_GAME]?.has(date.format('L'))}
    //           hasPerceptionGame={data[PERCEPTION_GAME]?.has(date.format('L'))}
    //           hasReactionGame={data[REACTION_GAME]?.has(date.format('L'))}
    //         />
    //       ))}
    //     </Box>
    //   ))}
    // </Box>
  )
}

BubbleCalendar.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  participationId: PropTypes.number,
  weekly: PropTypes.bool,
  startDate: PropTypes.string,
}

BubbleCalendar.defaultProps = {
  weekly: false,
  startDate: moment().format(),
  participationId: null,
}

export default BubbleCalendar
