import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import useSound from 'use-sound'
import colors from 'utils/colors'
import theme from 'utils/theme'
import wordIs4 from '../assets/audio/auditoryVerbalLearning/rabbit.mp3'
import { PlayIcon } from '../assets/icons'
import { Box, Text, Button, Clickable } from '../components'
import Battery0 from '../models/battery1.json'
import Battery1 from '../models/battery2.json'
import Battery2 from '../models/battery3.json'

const batteries = [Battery0, Battery1, Battery2]

const CognitiveBattery = () => {
  const [navigateToTask, setNavigateToTask] = useState(false)
  const [batteryNo, setBatteryNo] = useState(null)
  const [firstTask, setFirstTask] = useState(null)
  const enterFullscreen = () => {
    const element = document.documentElement
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullScreen()
    }
  }
  const [play] = useSound(wordIs4)

  useEffect(() => {
    const now = Date.now()
    const midTest = new Date('2022-04-04')
    if (now < midTest) {
      setBatteryNo(0)
    } else if (now > midTest) {
      setBatteryNo(2)
    } else {
      setBatteryNo(1)
    }
  }, [])

  const startBattery = () => {
    enterFullscreen()
    const battery = Battery0
    setFirstTask(battery.shift())
  }

  useEffect(() => {
    if (!firstTask) return

    setNavigateToTask(true)
  }, [firstTask])

  if (navigateToTask) {
    return (
      <Navigate
        to={`/${firstTask.title}`}
        state={{
          taskSetting: firstTask,
          battery: Battery0,
        }}
      />
    )
  }

  return (
    <Box bg={colors.greyDark} fullscreen cc p={theme.spacing.huge}>
      <Box width="600px">
        <Text bold pv="12" center color="white">
          Cognitive Games
        </Text>
        <Box mt="32px">
          <Text sub color="white">
            Please note that as soon as you press 'start', the application will enter fullscreen
            mode and it will exit only when the cognitive games are finished.
          </Text>
          <Text sub color="white">
            Please put your smartphone or any other potential sources of distraction aside. Since
            the data will be used for scientific purposes it is important that you pay close
            attention during the time of the experiment.
          </Text>
          <Text sub color="white">
            Some of the cognitive games include audio data that you have to listen to. Please set up
            your headphones and make sure that the audio is set to a sufficient volume. If you can
            hear the test audio well, you are ready to start.
          </Text>

          <Clickable onPress={play}>
            <Box cc p="16px" row>
              <PlayIcon fill={theme.colors.button.left} width={32} height={30} />
              <Box ml="8px">
                <Text sub color="white">
                  Test audio
                </Text>
              </Box>
            </Box>
          </Clickable>
        </Box>

        {batteryNo !== null && (
          <Box height="40px" mt="16px">
            <Button onPress={startBattery} width="100">
              <Text bold center color="white">
                Start
              </Text>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}
export default CognitiveBattery
