/* eslint-disable camelcase */

import React, { useContext, useEffect } from 'react'
import Comment from '../../comps/Comment'
import ConnectedFields from '../../comps/ConnectedFields'
import FormikMuiInput from '../../comps/FormikMuiInput'
import FormikMuiRadio from '../../comps/FormikMuiRadio'
import Info from '../../comps/Info'
import Section from '../../comps/Section'
import { LangContext } from '../../const/Contexts'
import { occurrenceFrequencyKeys, occurrenceBotherKeys } from '../../const/ESQOptions'

export const MSAOFieldNames = [
  'self_snoring_frequency',
  'self_snoring_bother',
  'self_breathing_stops_frequency',
  'self_breathing_stops_bother',
  'self_limb_movements_frequency',
  'self_limb_movements_bother',
  'self_tossing_turning_frequency',
  'self_tossing_turning_bother',
  'self_kicking_punching_frequency',
  'self_kicking_punching_bother',
  'self_talking_screaming_frequency',
  'self_talking_screaming_bother',
  'self_teeth_grinding_frequency',
  'self_teeth_grinding_bother',
  'self_sleepwalking_frequency',
  'self_sleepwalking_bother',
  'other_os_frequency',
  'other_os_bother',
  'other_os_info',
]

const MySleepAffectingOthers = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  const { values, setFieldValue } = formikProps

  const {
    living_arrangement_together,
    self_snoring_frequency,
    self_snoring_bother,
    self_breathing_stops_frequency,
    self_breathing_stops_bother,
    self_limb_movements_frequency,
    self_limb_movements_bother,
    self_tossing_turning_frequency,
    self_tossing_turning_bother,
    self_kicking_punching_frequency,
    self_kicking_punching_bother,
    self_talking_screaming_frequency,
    self_talking_screaming_bother,
    self_teeth_grinding_frequency,
    self_teeth_grinding_bother,
    self_sleepwalking_frequency,
    self_sleepwalking_bother,
    other_os_frequency,
    other_os_bother,
    other_os_info,
  } = values

  // Resetting 'Extra' fields when they switch back (ex. 'Other' boxes)
  useEffect(() => {
    if (self_snoring_frequency === 0 && self_snoring_bother !== '') {
      setFieldValue('self_snoring_bother', '')
    }
  }, [self_snoring_frequency])
  useEffect(() => {
    if (self_breathing_stops_frequency === 0 && self_breathing_stops_bother !== '') {
      setFieldValue('self_breathing_stops_bother', '')
    }
  }, [self_breathing_stops_frequency])
  useEffect(() => {
    if (self_limb_movements_frequency === 0 && self_limb_movements_bother !== '') {
      setFieldValue('self_limb_movements_bother', '')
    }
  }, [self_limb_movements_frequency])
  useEffect(() => {
    if (self_tossing_turning_frequency === 0 && self_tossing_turning_bother !== '') {
      setFieldValue('self_tossing_turning_bother', '')
    }
  }, [self_tossing_turning_frequency])
  useEffect(() => {
    if (self_kicking_punching_frequency === 0 && self_kicking_punching_bother !== '') {
      setFieldValue('self_kicking_punching_bother', '')
    }
  }, [self_kicking_punching_frequency])
  useEffect(() => {
    if (self_talking_screaming_frequency === 0 && self_talking_screaming_bother !== '') {
      setFieldValue('self_talking_screaming_bother', '')
    }
  }, [self_talking_screaming_frequency])
  useEffect(() => {
    if (self_teeth_grinding_frequency === 0 && self_teeth_grinding_bother !== '') {
      setFieldValue('self_teeth_grinding_bother', '')
    }
  }, [self_teeth_grinding_frequency])
  useEffect(() => {
    if (self_sleepwalking_frequency === 0 && self_sleepwalking_bother !== '') {
      setFieldValue('self_sleepwalking_bother', '')
    }
  }, [self_sleepwalking_frequency])
  useEffect(() => {
    if (other_os_frequency === 0) {
      if (other_os_bother !== '') {
        setFieldValue('other_os_bother', '')
      }
      if (other_os_info !== '') {
        setFieldValue('other_os_info', '')
      }
    }
  }, [other_os_frequency])

  return (
    <>
      {!living_arrangement_together.includes(0) ? ( // Footnote in 'My sleep affecting others'
        <Comment text={t('MysleepaffectingothersDisabled')} />
      ) : (
        <Section>
          <Info text={t('MySleepAffectingOthersFootnote')}>
            <Comment text={t('MysleepaffectingothersInfo')} />
          </Info>
          <ConnectedFields>
            <Comment text={t('Duringthepastmonth')} bold />
            <Comment text={t('columnName1Mysleepaffectingothers1')} />
            <FormikMuiRadio
              column
              label={t('snoring')}
              name="self_snoring_frequency"
              options={occurrenceFrequencyKeys.map(val => t(val))}
            />
            <FormikMuiRadio
              column
              label={t('breathingstops')}
              name="self_breathing_stops_frequency"
              options={occurrenceFrequencyKeys.map(val => t(val))}
            />
            <FormikMuiRadio
              column
              label={t('limbmovements')}
              name="self_limb_movements_frequency"
              options={occurrenceFrequencyKeys.map(val => t(val))}
            />
            <FormikMuiRadio
              column
              label={t('tossingturning')}
              name="self_tossing_turning_frequency"
              options={occurrenceFrequencyKeys.map(val => t(val))}
            />
            <FormikMuiRadio
              column
              label={t('kickingpunching')}
              name="self_kicking_punching_frequency"
              options={occurrenceFrequencyKeys.map(val => t(val))}
            />
            <FormikMuiRadio
              column
              label={t('talkingscreaming')}
              name="self_talking_screaming_frequency"
              options={occurrenceFrequencyKeys.map(val => t(val))}
            />
            <FormikMuiRadio
              column
              label={t('teethgrinding')}
              name="self_teeth_grinding_frequency"
              options={occurrenceFrequencyKeys.map(val => t(val))}
            />
            <Info text={t('sleepwalkingInfo')}>
              <FormikMuiRadio
                column
                label={t('sleepwalking')}
                name="self_sleepwalking_frequency"
                options={occurrenceFrequencyKeys.map(val => t(val))}
              />
            </Info>
            <FormikMuiRadio
              column
              label={t('otherIfPresent')}
              name="other_os_frequency"
              options={occurrenceFrequencyKeys.map(val => t(val))}
            />
            {other_os_frequency > 0 && (
              <FormikMuiInput label={t('otherIfPresent')} name="other_os_info" type="text" />
            )}
          </ConnectedFields>
          <ConnectedFields>
            {(self_snoring_frequency > 0 ||
              self_breathing_stops_frequency > 0 ||
              self_limb_movements_frequency > 0 ||
              self_tossing_turning_frequency > 0 ||
              self_kicking_punching_frequency > 0 ||
              self_talking_screaming_frequency > 0 ||
              self_teeth_grinding_frequency > 0 ||
              self_sleepwalking_frequency > 0 ||
              other_os_frequency > 0) && <Comment text={t('columnName2Mysleepaffectingothers1')} />}
            {self_snoring_frequency > 0 && (
              <FormikMuiRadio
                column
                label={t('snoring')}
                name="self_snoring_bother"
                options={occurrenceBotherKeys.map(val => t(val))}
              />
            )}
            {self_breathing_stops_frequency > 0 && (
              <FormikMuiRadio
                column
                label={t('breathingstops')}
                name="self_breathing_stops_bother"
                options={occurrenceBotherKeys.map(val => t(val))}
              />
            )}
            {self_limb_movements_frequency > 0 && (
              <FormikMuiRadio
                column
                label={t('limbmovements')}
                name="self_limb_movements_bother"
                options={occurrenceBotherKeys.map(val => t(val))}
              />
            )}
            {self_tossing_turning_frequency > 0 && (
              <FormikMuiRadio
                column
                label={t('tossingturning')}
                name="self_tossing_turning_bother"
                options={occurrenceBotherKeys.map(val => t(val))}
              />
            )}
            {self_kicking_punching_frequency > 0 && (
              <FormikMuiRadio
                column
                label={t('kickingpunching')}
                name="self_kicking_punching_bother"
                options={occurrenceBotherKeys.map(val => t(val))}
              />
            )}
            {self_talking_screaming_frequency > 0 && (
              <FormikMuiRadio
                column
                label={t('talkingscreaming')}
                name="self_talking_screaming_bother"
                options={occurrenceBotherKeys.map(val => t(val))}
              />
            )}
            {self_teeth_grinding_frequency > 0 && (
              <FormikMuiRadio
                column
                label={t('teethgrinding')}
                name="self_teeth_grinding_bother"
                options={occurrenceBotherKeys.map(val => t(val))}
              />
            )}
            {self_sleepwalking_frequency > 0 && (
              <Info text={t('sleepwalkingInfo')}>
                <FormikMuiRadio
                  column
                  label={t('sleepwalking')}
                  name="self_sleepwalking_bother"
                  options={occurrenceBotherKeys.map(val => t(val))}
                />
              </Info>
            )}
            {other_os_frequency > 0 && (
              <FormikMuiRadio
                column
                label={t('Other')}
                name="other_os_bother"
                options={occurrenceBotherKeys.map(val => t(val))}
              />
            )}
          </ConnectedFields>
        </Section>
      )}
    </>
  )
}

export default MySleepAffectingOthers
