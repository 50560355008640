import React, { useState } from 'react'
import colors from '../../../utils/colors'
import Box from '../Base/Box'
import ChatWindowBox from './ChatWindowBox'
import ChatWindowIcon from './ChatWindowIcon'

const ChatWindow = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box style={chatWindowStyle}>
      {isOpen && <ChatWindowBox />}
      <ChatWindowIcon setIsOpen={setIsOpen} isOpen={isOpen} />
    </Box>
  )
}

const chatWindowStyle = {
  position: 'fixed',
  bottom: '0px',
  right: '0px',
  zIndex: 110,
}

export default ChatWindow
