import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LineOrientationIcon } from '../../../assets/icons'
import colors from '../../../utils/colors'
import { Box } from '../../atoms'
import { ActionButton } from '../../molecules'

const LineOrientationTask = () => {
  const { t } = useTranslation(['lineOrientationTask'])
  const navigate = useNavigate()

  return (
    <Box width="380px">
      <ActionButton
        Icon={LineOrientationIcon}
        header={t('header')}
        description={t('description')}
        actionText={t('actionText')}
        color1={colors.eveningSleepDiary.left}
        color2={colors.eveningSleepDiary.right}
        onPress={() => navigate('/lineorientationtask')}
      />
    </Box>
  )
}

export default LineOrientationTask
