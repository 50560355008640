import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { gql, useLazyQuery, useMutation } from '@apollo/client'

const ME_QUERY = gql`
  query {
    me {
      username
      firstName
      lastName
    }
    myAppPermissions {
      isAllowedToUseChat
      isAllowedToDoBrainGames
      isAllowedToDoMyofunctionalExercises
      isStaff
    }
  }
`

const UserContext = React.createContext()

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  const [getMe] = useLazyQuery(ME_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: meData => {
      if (meData?.me) {
        setUser({
          username: meData.me.username,
          firstname: meData.me.firstName,
          lastname: meData.me.lastName,
          role: meData?.myAppPermissions?.isStaff ? 'staff' : null,
        })
      } else {
        setUser({})
      }
    },
    onError: error => {
      setUser({})
    },
  })

  useEffect(() => {
    getMe()
  }, [])

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>
}

export default UserContext
export { UserContextProvider }
