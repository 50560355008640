import React from 'react'
import { chartSettings } from '../const/chartSettings'

const RCSeverityDot = ({ cx, cy, stroke, strokeWidth, value, payload, hideNull }) => {
  if (value === undefined && hideNull) return null
  const val = value ?? payload.value ?? null
  const dotColor = chartSettings.severityColors[val] ?? chartSettings.textColor
  return val !== undefined && dotColor ? (
    <circle cx={cx} cy={cy} r={8} fill={dotColor} stroke={stroke} strokeWidth={strokeWidth} />
  ) : null
}

export default RCSeverityDot
