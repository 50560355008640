import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'
import { useTimer } from 'react-timer-hook'
import PropTypes from 'prop-types'
import symbol1 from 'assets/images/symbols/Symbol1.svg'
import symbol2 from 'assets/images/symbols/Symbol2.svg'
import symbol3 from 'assets/images/symbols/Symbol3.svg'
import symbol4 from 'assets/images/symbols/Symbol4.svg'
import symbol5 from 'assets/images/symbols/Symbol5.svg'
import symbol6 from 'assets/images/symbols/Symbol6.svg'
import symbol7 from 'assets/images/symbols/Symbol7.svg'
import symbol8 from 'assets/images/symbols/Symbol8.svg'
import symbol9 from 'assets/images/symbols/Symbol9.svg'
import SymbolKey from 'assets/images/symbols/symbol_key.svg'
import { Box, Instructions, Modal, Text, Button, ProgressBar } from 'components'
import { times } from 'lodash'
import moment from 'moment'
import theme from 'utils/theme'
import DigitSymbol from '../../components/molecules/CognitiveTasks/DigitSymbol'

const DigitSymbolCoding = ({ onFinish }) => {
  const location = useLocation()
  const { t, i18n } = useTranslation(['digitSymbolCoding'])

  const timelimitInMin = location?.state?.taskSetting?.timelimitInMin || 2
  const rowsPerPage = 8
  const digitsPerRow = 18
  const digitsPerPage = rowsPerPage * digitsPerRow

  const [results, setResults] = useState([{ testName: t('header') }])
  const [symbols, setSymbols] = useState([
    symbol1,
    symbol2,
    symbol3,
    symbol4,
    symbol5,
    symbol6,
    symbol7,
    symbol8,
    symbol9,
  ])
  const [practiceShown, setPracticeShown] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9])
  const [symbolsShown, setSymbolsShown] = useState([])
  const [finished, setFinished] = useState(false)
  const [typing, setTyping] = useState(false)
  const [started, setStarted] = useState(false)
  const [finalInstructions, setFinalInstructions] = useState(false)
  const [practice, setPractice] = useState(false)
  const [wrongAnswer, setWrongAnswer] = useState(false)
  const [theWrongAnswer, setTheWrongAnswer] = useState()
  const [answers, setAnswers] = useState([])
  const [correctAnswers, setCorrectAnswers] = useState([])
  const [pageAnswers, setPageAnswers] = useState([])
  const [practiceAnswers, setPracticeAnswers] = useState([])
  const [startedAt, setStartedAt] = useState(null)
  const [symbolReachedAt, setSymbolReachedAt] = useState(null)
  const [reactedAt, setReactedAt] = useState(null)
  const [reactSeconds, setReactSeconds] = useState([])

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('lang')))
  }, [started])

  const taskTimer = useTimer({
    expiryTimestamp: moment().add(timelimitInMin, 'minutes').toDate(),
    autoStart: false,
    onExpire: () => setFinished(true),
  })

  const getNewSymbolsShown = () => {
    const newSymbolsShown = []
    let lastSymbol = 0
    for (let i = 0; i < digitsPerPage; i += 1) {
      let nextSymbol = Math.floor(Math.random() * 9) + 1
      while (nextSymbol === lastSymbol) {
        nextSymbol = Math.floor(Math.random() * 9) + 1 // Randomize new so the same symbol will not be twice in a row
      }
      newSymbolsShown.push(nextSymbol)
      lastSymbol = nextSymbol
    }
    return newSymbolsShown
  }

  const randomizeSymbolKey = () => {
    const newSymbols = symbols.sort(() => Math.random() - 0.5) // Shuffle the array.
    newSymbols.unshift(SymbolKey)
    setSymbols(newSymbols)
  }

  const randomizePracticeSymbols = () => {
    setPracticeShown(practiceShown.sort(() => Math.random() - 0.5))
    setPracticeAnswers(practiceShown.slice(0, 3))
  }

  const randomizeTestSymbols = () => {
    const newSymbolsShown = getNewSymbolsShown()
    setCorrectAnswers(newSymbolsShown)
    setSymbolsShown(newSymbolsShown)
    setPageAnswers(newSymbolsShown.slice(0, 3))
  }

  useEffect(() => {
    randomizeSymbolKey()
    randomizePracticeSymbols()
    randomizeTestSymbols()
  }, [])

  useEffect(() => {
    if (started) {
      taskTimer.start()
      setStartedAt(moment())
      setSymbolReachedAt(moment())
    }
  }, [started])

  useEffect(() => {
    if (finished) {
      answers.push(...pageAnswers)
      setAnswers(answers)
      setPageAnswers([])
      processResults()
    }
  }, [finished])

  const processResults = () => {
    let correct = 0
    let incorrect = 0
    const secondsCorrectAnswers = []
    const secondsIncorrectAnswers = []
    for (let i = 3; i < answers.length; i += 1) {
      // First 3 answers are given
      if (answers[i] === correctAnswers[i]) {
        correct += 1
        secondsCorrectAnswers.push(reactSeconds[i - 3])
      } else {
        incorrect += 1
        secondsIncorrectAnswers.push(reactSeconds[i - 3])
      }
    }
    const averageResponseTime = (reactSeconds.reduce((a, b) => a + b, 0) / reactSeconds.length)
      .toFixed(2)
      .concat(t('seconds'))

    const averageCorrectReponseTime = (
      secondsCorrectAnswers.reduce((a, b) => a + b, 0) / secondsCorrectAnswers.length
    )
      .toFixed(2)
      .concat(t('seconds'))

    const averageIncorrectReponseTime = (
      secondsIncorrectAnswers.reduce((a, b) => a + b, 0) / secondsIncorrectAnswers.length
    )
      .toFixed(2)
      .concat(t('seconds'))

    let percentCorrect
    if (answers.length === 3) {
      percentCorrect = '-'
    } else {
      percentCorrect = ((correct / (correct + incorrect)) * 100).toFixed(1).toString().concat('%')
    }
    setResults([
      ...results,
      {
        numCorrect: correct,
        numIncorrect: incorrect,
        percentCorrect,
        averageResponseTime,
        averageCorrectReponseTime,
        averageIncorrectReponseTime,
      },
    ])
  }

  useEffect(() => {
    if (reactedAt !== null) {
      const seconds = reactedAt.diff(symbolReachedAt, 'seconds')
      setReactSeconds([...reactSeconds, seconds])
    }
  }, [reactedAt])

  useEffect(() => {
    async function onKeyPress(e) {
      if (!typing) {
        setTyping(true)
        const number = Number(e.key)
        if (!Number.isNaN(e.key) && number > 0 && number < 10) {
          // Practice input
          if (practice && practiceAnswers.length !== practiceShown.length) {
            if (number === practiceShown[practiceAnswers.length]) {
              setWrongAnswer(false)
              setPracticeAnswers([...practiceAnswers, number])
            } else {
              setWrongAnswer(true)
              setTheWrongAnswer(number)
            }
          }
          if (started) {
            setReactedAt(moment())
            if (pageAnswers.length === symbolsShown.length - 1) {
              answers.push(...pageAnswers)
              setAnswers([...answers, number])
              setPageAnswers([])
              const newSymbolsShown = getNewSymbolsShown()
              correctAnswers.push(...newSymbolsShown)
              setCorrectAnswers(correctAnswers)
              setSymbolsShown(newSymbolsShown)
            } else {
              setPageAnswers([...pageAnswers, number])
            }
            setSymbolReachedAt(moment())
          }
        }

        setTyping(false)
      }
    }
    window.addEventListener('keydown', onKeyPress)
    return () => window.removeEventListener('keydown', onKeyPress)
  }, [pageAnswers, answers, practice, started, practiceAnswers, typing])

  const conditionalRender = index => (
    <DigitSymbol
      image={symbols[symbolsShown[index]]}
      color={index === pageAnswers.length && 'purple'}
    >
      {pageAnswers[index]}
    </DigitSymbol>
  )

  const practiceDisplaySymbol = index => {
    if (wrongAnswer && index === practiceAnswers.length) {
      return (
        <DigitSymbol image={symbols[practiceShown[index]]} color="red">
          {theWrongAnswer}
        </DigitSymbol>
      )
    }
    return (
      <DigitSymbol
        image={symbols[practiceShown[index]]}
        color={index === practiceAnswers.length && 'purple'}
      >
        {practiceAnswers[index]}
      </DigitSymbol>
    )
  }

  const practiceRender = () => (
    <Box>
      <Box row center>
        {times(9, i => practiceDisplaySymbol(i))}
      </Box>
      {wrongAnswer && (
        <Text center color="red" bigger>
          {t('wrongAnswerPractice')}
        </Text>
      )}
      {practiceAnswers.length === practiceShown.length && (
        <Box center width="100%" cc mt="30px">
          <Button onPress={() => setFinalInstructions(true)} block>
            <Text semibold center color="white">
              {t('continueButtonText')}
            </Text>
          </Button>
        </Box>
      )}
    </Box>
  )
  const symbolKeyRender = () => (
    <Box>
      <Box row center>
        {times(9, j => (
          <DigitSymbol image={symbols[j + 1]}>{j + 1}</DigitSymbol>
        ))}
      </Box>
    </Box>
  )

  const testRender = () => (
    <Box>
      {times(rowsPerPage, i => (
        <Box row center>
          {pageAnswers.length > digitsPerPage - 1
            ? times(digitsPerRow, j => conditionalRender((i + rowsPerPage) * digitsPerRow + j))
            : times(digitsPerRow, j => conditionalRender(i * digitsPerRow + j))}
        </Box>
      ))}
    </Box>
  )

  if (!finished) {
    if (finalInstructions) {
      return (
        <Box fullscreen bg={theme.colors.greyDark}>
          <Modal
            isOpen={!started}
            close
            closeText={t('startTest')}
            onClose={() => setStarted(true)}
          >
            <Text big color="white">
              {t('header')} - {t('instructionsHeader')}
            </Text>
            <Instructions body={t('finalInstructions')} />
            <Box cc>{symbolKeyRender()}</Box>
          </Modal>
          <Box center pb="28px" mt="40px">
            {symbolKeyRender()}
          </Box>
          {testRender()}
          {/* <Box width="100%" bottom="0px" position="fixed">
            <ProgressBar
              width="100"
              progressPercentage={(moment().diff(startedAt, 'seconds') / 120) * 100}
            />
          </Box> */}
        </Box>
      )
    }
    return (
      // practice
      <Box fullscreen bg={theme.colors.greyDark}>
        <Modal
          isOpen={!practice}
          onClose={() => setPractice(true)}
          close
          closeText={t('startPractice')}
        >
          <Text big color="white">
            {t('header')} - {t('instructionsHeader')}
          </Text>
          <Instructions body={t('instructions')} />
          <Box cc>{symbolKeyRender()}</Box>
        </Modal>
        <Box center pb="28px" mt="40px">
          {symbolKeyRender()}
        </Box>
        <Box>{practiceRender()}</Box>
        <Box width="120px" position="fixed" right="20px" bottom="20px">
          <Button onPress={() => setFinalInstructions(true)} block>
            <Text semibold center color="white">
              {t('skipPractice')}
            </Text>
          </Button>
        </Box>
      </Box>
    )
  }

  if (results.length > 1) {
    return (
      <Navigate
        to="/results"
        state={{
          results,
          battery: location?.state?.battery,
          formTitle: location?.state?.taskSetting?.title || 'digitsymbolcoding',
        }}
      />
    )
  }

  return <Box fullscreen bg={theme.colors.greyDark} />
}

DigitSymbolCoding.propTypes = {
  onFinish: PropTypes.func,
}

DigitSymbolCoding.defaultProps = {
  onFinish: () => {},
}

export default DigitSymbolCoding
