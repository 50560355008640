/* eslint-disable camelcase */

import React, { useContext } from 'react'
import Comment from '../../comps/Comment'
import Section from '../../comps/Section'
import { LangContext } from '../../const/Contexts'
import FAMSAwakenings from './Subsections/FAMSAwakenings'
import FAMSFallingAsleep from './Subsections/FAMSFallingAsleep'
import FAMSSleep from './Subsections/FAMSSleep'

export const FAMSFieldNames = [
  'pain_frequency',
  'pain_bother',
  'itch_frequency',
  'itch_bother',
  'leg_cramps_frequency',
  'leg_cramps_bother',
  'limbs_urge_to_move_frequency',
  'limbs_urge_to_move_bother',
  'bodily_movements_frequency',
  'bodily_movements_bother',
  'nasal_obstruction_frequency',
  'nasal_obstruction_bother',
  'own_snoring_frequency',
  'own_snoring_bother',
  'urge_to_urinate_frequency',
  'urge_to_urinate_bother',
  'urge_to_urinate_count',
  'bedwetting_frequency',
  'bedwetting_bother',
  'urge_to_eat_frequency',
  'urge_to_eat_bother',
  'worrying_thoughts_frequency',
  'worrying_thoughts_bother',
  'nightmares_frequency',
  'nightmares_bother',
  'other_fa_frequency',
  'other_fa_bother',
  'other_fa_info',
  'breathlessness_or_choking_frequency',
  'breathlessness_or_chokings_bother',
  'inability_to_move_feeling_frequency',
  'inability_to_move_feelings_bother',
  'panic_feeling_frequency',
  'panic_feelings_bother',
  'headache_pain_frequency',
  'headache_pains_bother',
  'dry_mouth_feeling_frequency',
  'dry_mouth_feelings_bother',
  'sore_throat_pain_frequency',
  'sore_throat_pains_bother',
  'jaw_pain_frequency',
  'jaw_pains_bother',
  'heartburn_feeling_frequency',
  'heartburn_feelings_bother',
  'palpitations_feeling_frequency',
  'palpitations_feelings_bother',
  'excessive_sweating_feeling_frequency',
  'excessive_sweating_feelings_bother',
  'hot_flushes_feeling_frequency',
  'hot_flushes_feelings_bother',
  'other_ds_frequency',
  'other_ds_bother',
  'other_ds_info',
  'snoring_bedpartner_frequency',
  'snoring_bedpartners_bother',
  'movements_bedpartner_frequency',
  'movements_bedpartners_bother',
  'pet_activity_frequency',
  'pet_activitys_bother',
  'caretaking_family_frequency',
  'caretaking_familys_bother',
  'sleeping_apart_frequency',
  'sleeping_aparts_bother',
  'environmental_noise_frequency',
  'environmental_noises_bother',
  'smartphone_messages_frequency',
  'smartphone_messagess_bother',
  'bedroom_inconveniences_frequency',
  'bedroom_inconveniencess_bother',
  'other_o_frequency',
  'other_o_bother',
  'other_o_info',
]

const FactorsAffectingMySleep = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  return (
    <Section>
      <Comment text={t('FactorsAffectingMySleepInfo')} />
      <FAMSFallingAsleep formikProps={formikProps} />
      <FAMSAwakenings formikProps={formikProps} />
      <FAMSSleep formikProps={formikProps} />
    </Section>
  )
}

export default FactorsAffectingMySleep
