import React, { useRef, useState, useCallback, useEffect } from 'react'
import 'react-chat-elements/dist/main.css'
import { ChatList, MessageList, Input, Button } from 'react-chat-elements'
import { gql, useMutation, useQuery, useSubscription } from '@apollo/client'
import { Conversation } from 'components'
import SRLogo from '../../assets/images/SRLogo.png'
import transparent from '../../assets/images/transparent.png'
import { Text, Box } from '../atoms'

const GET_CONVERSATIONS = gql`
  query GetConversations($studyTitle: String) {
    conversations(studyTitle: $studyTitle) {
      title
      subtitle
      date
      unread
      username
      group
    }
  }
`

// const CHAT_SUBSCRIPTION = gql`
//   subscription ($token: String!) {
//     onNewChatMessage(chatroom: "unreadstaff", token: $token) {
//       id
//       sender
//       text
//       createdAt
//     }
//   }
// `

const ChatComponent = ({ studyTitle, hideGroups }) => {
  const [tab, setTab] = useState(0)
  const [conversations, setConversations] = useState([])
  const [studies, setStudies] = useState([])
  const [group, setGroup] = useState('123')
  const [conversationUsername, setConversationUsername] = useState(null)
  const [conversationName, setConversationName] = useState(null)
  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  useEffect(() => {
    if (hideGroups) {
      setConversations(
        conversations.map((a, i) => ({
          ...a,
          avatar: i === 0 ? SRLogo : transparent,
          title: a.title.split(' - ')[0],
        }))
      )
    } else {
      setConversations(
        conversations.map((a, i) => ({
          ...a,
          title: a.group ? `${a.title} - ${a.group}` : a.title,
          avatar: i === 0 ? SRLogo : transparent,
        }))
      )
    }
  }, [hideGroups])

  useQuery(GET_CONVERSATIONS, {
    pollInterval: 10000,
    variables: {
      studyTitle,
    },
    onCompleted: data => {
      if (data?.conversations) {
        setConversations(
          data.conversations.map((a, i) => ({
            ...a,
            title: hideGroups || !a.group ? a.title : `${a.title} - ${a.group}`,
            avatar: i === 0 ? SRLogo : transparent,
          }))
        )
        setConversationUsername(data.conversations?.[0]?.username)
      }
    },
  })

  //   useSubscription(CHAT_SUBSCRIPTION, {
  //     variables: {
  //       token: getToken(),
  //     },
  //     onSubscriptionData: ({ subscriptionData }) => {
  //       if (subscriptionData?.data?.onNewChatMessage) {
  //         setConversations(
  //           conversations.map(con =>
  //             con.username === subscriptionData?.data?.onNewChatMessage.sender
  //               ? {
  //                   ...con,
  //                   unread:
  //                     subscriptionData?.data?.onNewChatMessage.sender !== conversationUsername
  //                       ? con.unread + 1
  //                       : con.unread,
  //                   subtitle: subscriptionData?.data?.onNewChatMessage.text,
  //                 }
  //               : con
  //           )
  //         )
  //       }
  //     },
  //   })

  return (
    <Box>
      <Box row spaceBetween height="75vh" pb="80px">
        <Box styles={{ overflowY: 'scroll' }} width="400px">
          <ChatList
            onClick={c => {
              setConversationUsername(c.username)
              setConversations(
                conversations.map(con =>
                  con.username !== c.username
                    ? { ...con, avatar: transparent }
                    : { ...con, unread: 0, avatar: SRLogo }
                )
              )
            }}
            className="chat-list"
            dataSource={conversations}
          />
        </Box>
        {conversationUsername && (
          <Conversation key={conversationUsername} username={conversationUsername} isStaff />
        )}
      </Box>
    </Box>
  )
}
export default ChatComponent
