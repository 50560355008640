import React from 'react'
import colors from 'utils/colors'
import { Box, Text } from '../..'

const BoxSection = ({ title, subtitle, children }) => (
  <Box
    bg={colors.greyDarkestTransparent}
    width="100%"
    center
    round
    p="2rem"
    mb="20px"
    style={{ boxSizing: 'border-box' }}
  >
    <Box mb="8px" row>
      <Box block cc>
        <Text bigger>{title}</Text>
        {subtitle && <Text>{subtitle}</Text>}
      </Box>
    </Box>
    <Box mt="32px">{children}</Box>
  </Box>
)

export default BoxSection
