import React, { useMemo } from 'react'
import { Tooltip } from '@mui/material'
import { Text } from 'components/atoms'
import DGTooltipCell from '../comps/DGTooltipCell'
import { getAnswerReadable } from '../utils/ESQVUtils'
import ESQVDG from './ESQVDG'

// better readability of entry titles if truncated on left side instead of right
// const RTLCell = ({ value }) => (
//   <div title={value} style={{ direction: 'rtl', overflow: 'hidden', textOverflow: 'ellipsis' }}>
//     {value}
//   </div>
// )

const BoldHeader = ({ value }) => (
  <strong>
    <span>{value}</span>
  </strong>
)

const commonColumnProps = {
  disableColumnMenu: true,
  headerAlign: 'center',
  editable: false,
  renderCell: params => <DGTooltipCell value={params.value} />,
  renderHeader: params => <BoldHeader value={params.colDef.headerName} />,
}

const columns = [
  // {
  //   field: 'id',
  //   headerName: '#',
  //   flex: 0.1,
  //   minWidth: 30,
  //   maxWidth: 40,
  //   editable: false,
  //   align: 'center',
  //   ...commonColumnProps,
  // },
  {
    field: 'entryTitle',
    headerName: 'Entry Title',
    flex: 0.2,
    ...commonColumnProps,
    renderCell: params => <DGTooltipCell value={params.value} format />,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 0.2,
    ...commonColumnProps,
  },
  {
    field: 'baseAnswer',
    headerName: 'Baseline',
    flex: 0.2,
    align: 'center',
    ...commonColumnProps,
  },
  {
    field: 'followUpAnswer',
    headerName: 'Follow-up',
    flex: 0.2,
    align: 'center',
    ...commonColumnProps,
  },
  {
    field: 'changed',
    headerName: 'Changed',
    flex: 0.1,
    maxWidth: 90,
    align: 'center',
    sortingOrder: ['desc', 'asc', null],
    ...commonColumnProps,
    valueGetter: params =>
      params.row.baseAnswer !== 'N/A' &&
      params.row.followUpAnswer !== 'N/A' &&
      params.row.baseAnswer !== params.row.followUpAnswer
        ? 'Yes'
        : 'No',
  },
]

// ex. entries = [{ base: <entry>, followUp: <entry> }, { base: <entry>, followUp: <entry> }, ... ]
const ESQVMiscDG = ({ entries, ...props }) => {
  // console.log(entries)
  const rows = useMemo(() => {
    const arr = []
    entries.forEach((entry, index) => {
      if (entry) {
        arr.push({
          id: index,
          entryTitle:
            entry?.base?.entryId?.title ?? entry?.followUp?.entryId?.title ?? '<Title missing>',
          baseAnswer: entry?.base ? getAnswerReadable(entry.base) : 'N/A',
          followUpAnswer: entry?.followUp ? getAnswerReadable(entry.followUp) : 'N/A',
          description: entry?.base?.entryId?.description ?? '',
        })
      }
    })
    return arr
  }, [entries])

  if (!rows || (rows && rows.length === 0)) {
    return null
  }

  return <ESQVDG rows={rows} columns={columns} {...props} />
}

export default ESQVMiscDG
