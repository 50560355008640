import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Box,
  Modal,
  Text,
  Button,
  AVLAudioPlayer,
  AVLRecallInstructions,
  AVLPracticeInstructions,
  AVLTaskInstructions,
  AVLWordListForm,
} from 'components'
import moment from 'moment'
import getSounds from 'utils/AuditoryVerbalLearningHelper'
import { parseInput, intersection } from 'utils/taskHelper'
import theme from 'utils/theme'

const AuditoryVerbalLearning = ({ onFinish }) => {
  const location = useLocation()

  const { t, i18n } = useTranslation(['auditoryVerbalLearning'])

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('lang')))
  }, [])

  const [results, setResults] = useState([])
  const sounds = getSounds(t('language'))
  const words = sounds.map(item => item.word)

  const numOfWords = location?.state?.taskSetting?.words?.length || 15
  const numOfRounds = location?.state?.taskSetting?.rounds || 5
  const [roundNumber, setRoundNumber] = useState(0)

  const [totalCorrect, setTotalCorrect] = useState(0)

  const [finished, setFinished] = useState(false)
  const [startedAt, setStartedAt] = useState(null)
  const [finishedAt, setFinishedAt] = useState(null)

  // For Practice
  const [isInPractice, setIsInPractice] = useState(!location?.state?.taskSetting?.withoutRepitition)
  const [showPracticeInstructions, setShowPracticeInstructions] = useState(
    !location?.state?.taskSetting?.withoutRepitition
  )
  const [showTaskInstructions, setShowTaskInstructions] = useState(false)
  const [showRecallInstructions, setShowRecallInstructions] = useState(
    location?.state?.taskSetting?.withoutRepitition || false
  )
  const [playPracticeAudio, setPlayPracticeAudio] = useState(false)
  const [playAudio, setPlayAudio] = useState(false)
  const [showSubmitForm, setShowSubmitForm] = useState(false)

  const skipPractice = () => {
    setShowSubmitForm(false)
    setIsInPractice(false)
    setShowTaskInstructions(true)
    setShowPracticeInstructions(false)
    setPlayPracticeAudio(false)
  }

  const processResults = inputWords => {
    console.log('processresults')
    const wordList = parseInput(inputWords)
    let correct = intersection(wordList, words)
    if (!isInPractice) {
      console.log('not in practice')
      correct = correct.map(correctWord => correctWord.concat(' '))
      setTotalCorrect(totalCorrect + correct.length)
      const correctPercent = ((correct.length / numOfWords) * 100).toFixed(1) // Round with one fractional digit
      const correctPercentString = correctPercent.toString().concat('%')
      const seconds = moment().diff(startedAt, 'seconds')
      setResults([
        ...results,
        {
          roundNumber,
          numCorrectWords: correct.length,
          numShownWords: numOfWords,
          correctRate: correctPercentString,
          correctWords: correct,
          typingTimer: seconds.toString().concat(t('seconds')),
        },
      ])
      // else {
      //   const totalCorrectPercent = (
      //     ((totalCorrect + correct.length) / (numOfWords * numOfRounds)) *
      //     100
      //   ).toFixed(1)
      //   const totalCorrectPercentString = totalCorrectPercent.toString().concat('%')
      //   setResults([
      //     { testName: 'Auditory Verbal Learning' },
      //     {
      //       numOfRounds,
      //       numCorrectWords: totalCorrect + correct.length,
      //       numShownWords: numOfWords * numOfRounds,
      //       totalCorrectRate: totalCorrectPercentString,
      //     },
      //     ...results,
      //     {
      //       roundNumber,
      //       numCorrectWords: correct.length,
      //       numShownWords: numOfWords,
      //       correctRate: correctPercentString,
      //       correctWords: correct,
      //       typingTimer: seconds.toString().concat(t('seconds')),
      //     },
      //   ])
      // }
    } else {
      skipPractice()
    }
  }

  useEffect(() => {
    if (results?.length <= 0) return

    if (isInPractice) {
      setIsInPractice(false)
      setShowTaskInstructions(true)
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!location?.state?.taskSetting?.withoutRepitition && roundNumber + 1 < numOfRounds) {
        setRoundNumber(roundNumber + 1)
        setPlayAudio(true)
      } else {
        setFinished(true)
      }
    }
    setShowSubmitForm(false)
  }, [results])

  // const getProgressPercentage = () => {
  //   const totalNumberOfWords = numOfRounds * numOfWords
  //   return (numOfPlayedWords / totalNumberOfWords) * 100
  // }

  if (finished) {
    return (
      <Navigate
        to="/results"
        state={{
          results,
          battery: location?.state?.battery,
          formTitle: location?.state?.taskSetting?.title || 'auditoryverballearning',
        }}
      />
    )
  }

  return (
    <Box fullscreen bg={theme.colors.greyDark}>
      <Modal
        isOpen={showTaskInstructions}
        onClose={() => {
          setPlayAudio(true)
          setShowTaskInstructions(false)
        }}
        close
        closeText={t('startTest')}
      >
        <AVLTaskInstructions />
      </Modal>

      <Modal
        isOpen={showPracticeInstructions}
        onClose={() => {
          setShowPracticeInstructions(false)
          setPlayPracticeAudio(true)
        }}
        close
        closeText={t('startPractice')}
      >
        <AVLPracticeInstructions />
      </Modal>

      <Modal
        isOpen={showRecallInstructions}
        onClose={() => {
          setStartedAt(moment())
          setShowRecallInstructions(false)
          setShowSubmitForm(true)
        }}
        close
        closeText={t('startTest')}
      >
        <AVLRecallInstructions />
      </Modal>

      {playPracticeAudio && (
        <Box width="120px" position="fixed" right="20px" bottom="20px">
          <Button onPress={() => skipPractice()} block>
            <Text semibold center color="white">
              {t('skipPractice')}
            </Text>
          </Button>
        </Box>
      )}

      {playAudio && (
        <AVLAudioPlayer
          key="player"
          sounds={sounds}
          onFinish={() => {
            setShowSubmitForm(true)
            setStartedAt(moment())
            setPlayAudio(false)
          }}
        />
      )}

      {playPracticeAudio && (
        <AVLAudioPlayer
          key="practiceplayer"
          sounds={sounds}
          practice
          onFinish={() => {
            setShowSubmitForm(true)
            setPlayPracticeAudio(false)
          }}
        />
      )}

      {showSubmitForm && <AVLWordListForm results={results} processResults={processResults} />}
    </Box>
  )
}

AuditoryVerbalLearning.propTypes = {
  onFinish: PropTypes.func,
}

AuditoryVerbalLearning.defaultProps = {
  onFinish: () => {},
}

export default AuditoryVerbalLearning
