import React, { useState } from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Box from './Base/Box'
import Clickable from './Base/Clickable'

const boolSelection = ['yes', 'no']

const Filter = ({
  label,
  valType,
  selection,
  values,
  setValues,
  setValueIndices,
  val,
  setVal,
  min,
  setMin,
  max,
  setMax,
}) => {
  const handleChange = event => {
    const {
      target: { value },
    } = event
    setValues(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
    const indices = value.map(v => selection.indexOf(v))
    setValueIndices(indices)
  }

  return (
    <Box ph="32px" pt="16px" pb="16px" style={{ minWidth: 220 }}>
      {valType === 'string' && (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            multiple
            label={label}
            value={values}
            onChange={handleChange}
            // input={<OutlinedInput label="Tag" />}
            renderValue={selected => selected.join(', ')}
          >
            {selection.map(item => (
              <MenuItem value={item}>
                <Checkbox checked={values.indexOf(item) > -1} />
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {valType === 'number' && (
        <Box row style={{ gap: '16px' }}>
          <TextField
            id="input-number-min"
            label="Min"
            type="number"
            value={min}
            InputProps={{ inputProps: { max } }}
            onChange={e =>
              setMin(e.target.value && max !== '' && e.target.value > max ? max : e.target.value)
            }
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="input-number-max"
            label="Max"
            type="number"
            InputProps={{ inputProps: { min } }}
            onChange={e => setMax(e.target.value && e.target.value < min ? min : e.target.value)}
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      )}
      {valType === 'boolean' && (
        <Clickable onPress={() => setVal(!val)}>
          <MenuItem value={label}>
            <Checkbox checked={val} />
            {label}
          </MenuItem>
        </Clickable>
      )}
      {valType === 'date' && (
        <Box row style={{ gap: '16px' }} ph="12px">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start date"
              value={min}
              maxDate={max || new Date()}
              onChange={newValue => {
                setMin(newValue)
              }}
              renderInput={params => <TextField variant="standard" {...params} />}
            />
            <DatePicker
              label="End date"
              value={max}
              minDate={min}
              maxDate={new Date()}
              onChange={newValue => {
                setMax(newValue)
              }}
              renderInput={params => <TextField variant="standard" {...params} />}
            />
          </LocalizationProvider>
        </Box>
      )}
    </Box>
  )
}

export default Filter
