/* eslint-disable camelcase */

import React, { useContext, useEffect } from 'react'
import { Text } from 'components'
import Comment from '../../../comps/Comment'
import ConnectedFields from '../../../comps/ConnectedFields'
import FormikMuiInput from '../../../comps/FormikMuiInput'
import FormikMuiSelect from '../../../comps/FormikMuiSelect'
import { fontSizes } from '../../../const/Const'
import { LangContext } from '../../../const/Contexts'
import { noYesKeys, selfReportedHealthKeys, stressorsKeys } from '../../../const/ESQOptions'

export const GHFieldNames = [
  'self_reported_health',
  'stress',
  'stress_other_info',
  'stress_additional_comments',
  'stress_additional_comments_info',
]

const GeneralHealth = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  const { values, setFieldValue } = formikProps

  const { stress, stress_other_info, stress_additional_comments, stress_additional_comments_info } =
    values

  // Resetting 'Extra' fields when they switch back (ex. 'Other' boxes)
  useEffect(() => {
    if (stress.includes(0) && stress.length > 1) {
      setFieldValue('stress', [0])
    } else if (!stress.includes(5) && stress_other_info !== '') {
      setFieldValue('stress_other_info', '')
    }
  }, [stress])
  useEffect(() => {
    if (stress_additional_comments !== 1 && stress_additional_comments !== '') {
      setFieldValue('stress_additional_comments_info', '')
    }
  }, [stress_additional_comments])

  return (
    <>
      <Comment center bold text={t('GeneralHealth')} />
      <ConnectedFields>
        <Comment text={t('HowWouldYouRateYourHealth?')} />
        <FormikMuiSelect
          label={t('MyHealth')}
          name="self_reported_health"
          options={selfReportedHealthKeys.map(val => t(val))}
        />
      </ConnectedFields>
      <ConnectedFields>
        <Comment text={`${t('Stressors')}:`} />
        <FormikMuiSelect
          label={t('CurrentStressors')}
          name="stress"
          options={stressorsKeys.map(val => t(val))}
          multiple
        />
        {stress.includes(5) && (
          <FormikMuiInput label={t('Other')} name="stress_other_info" type="text" />
        )}
      </ConnectedFields>
      <ConnectedFields>
        <FormikMuiSelect
          label={`${t('AdditionalComments')}?`}
          name="stress_additional_comments"
          options={noYesKeys.map(val => t(val))}
        />
        {stress_additional_comments === 1 && (
          <FormikMuiInput
            label={t('AdditionalComments')}
            name="stress_additional_comments_info"
            type="text"
            multiline
          />
        )}
      </ConnectedFields>
    </>
  )
}

export default GeneralHealth
