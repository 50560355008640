import React from 'react'
import { Box, Text, Footer, Header } from 'components'

const AccountDeletion = () => (
  <Box style={{ height: '100vh' }}>
    <Header publicRouter />
    <Box
      pv="2rem"
      ph="4rem"
      mb="140px"
      mt="80px"
      maxWidth="760px"
      style={{
        display: 'block',
        overflow: 'scroll',
      }}
    >
      <Box mv="4px">
        <Text huge color="black">
          Data Management and Account Deletion
        </Text>
      </Box>
      <Box mv="4px">
        <Text color="black" medium bold>
          <br />
          Data Removal Instructions
        </Text>
      </Box>
      <Box mv="4px">
        <Text color="black">
          Thank you for choosing SleepRevolution! We respect your privacy and provide the following
          process for managing your data:
        </Text>
      </Box>
      <Box mv="4px">
        <Text color="black" medium bold>
          <br />
          Request Account Deletion or Data Removal via Email
        </Text>
      </Box>
      <Box mv="4px">
        <Text color="black">
          You can request account deletion or data removal by sending an email to
          sleeprevolution@ru.is. Please include the email associated with your account, and our
          support team will process your request promptly.
        </Text>
      </Box>
      <Box mv="4px">
        <Text color="black">
          Upon request, all associated data is permanently and immediately removed. We do not retain
          any data after the data removal process.
        </Text>
      </Box>
      <Box mv="4px">
        <Text color="black">
          For any assistance or clarification, please contact our support team at
          sleeprevolution@ru.is.
        </Text>
      </Box>
      <Box mv="4px">
        <Text color="black">
          <br />
          <br />
          The SleepRevolution Team
        </Text>
      </Box>
    </Box>
    <Box style={{ bottom: 0, position: 'absolute' }}>
      <Footer />
    </Box>
  </Box>
)

export default AccountDeletion
