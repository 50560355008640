import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import theme from 'utils/theme'

const StyledTextArea = styled.textarea(
  ({ color, fontSize, width, rows }) => css`
    border: none;
    margin: 0px 0px 15px 0px;
    padding: 0px;
    width: ${width}px;
    overflow: visible;

    font-size: ${fontSize}px;
    border-radius: 7px;

    background: ${color || theme.colors.greyDark};
    color: white;
    rows: ${rows};
  `
)

const TextArea = ({ children, color, fontSize, placeholder, onChange, width, rows }) => (
  <StyledTextArea
    color={color}
    fontSize={fontSize}
    placeholder={placeholder}
    onChange={event => onChange(event.target.value)}
    width={width}
    rows={rows}
  >
    {children}
  </StyledTextArea>
)

TextArea.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  fontSize: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.string,
  rows: PropTypes.string,
}

TextArea.defaultProps = {
  children: PropTypes.node,
  color: theme.colors.greyDark,
  fontSize: 18,
  placeholder: 'Input text here...',
  onChange: PropTypes.func,
  width: 'auto',
  rows: 10,
}

export default TextArea
