import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { flatten } from 'lodash'
import moment from 'moment'
import colors from 'utils/colors'
import { Box, Text } from '../atoms'

/**
 * A component that generates that handles the infrastructure around creating a
 * calendar. You supply your own function (`createDate`), that tells this
 * component how to render each date in the calendar, given some information
 * about each date that is supplied to you by this component.
 */

const formatStr = 'YYYY/M/D'

// helper functions
const getKey = d => d.toISOString()
const getIsOut = (d, m) => d.month() + 1 !== m // is the date outside this month?
const getIsFuture = d => d.isAfter(moment().endOf('day'))

const CustomCalendar = ({ year, month, createDate, hSpacing, vSpacing, weekdays }) => {
  const dateStr = useMemo(() => `${year}/${month}/1`, [year, month])
  const startOfMonth = useMemo(() => moment(dateStr, formatStr, true), [dateStr])

  // collect all of the dates that should appear in the calendar
  const dates = useMemo(() => {
    const arr = []
    const currDate = startOfMonth.clone().startOf('week')
    const finalDate = startOfMonth.clone().endOf('month').endOf('week')
    while (currDate.isSameOrBefore(finalDate)) {
      arr.push(currDate.clone())
      currDate.add(1, 'day')
    }
    return arr
  }, [startOfMonth])

  // group the dates up into weeks (i.e. rows in the calendar)
  const weeks = useMemo(() => {
    const arr = []
    for (let i = 0; i < dates.length; i += 7) {
      arr.push(dates.slice(i, i + 7))
    }
    return arr
  }, [dates])

  // return each week as a calendar row
  return weeks?.map((week, i) => (
    <div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          padding: '0 3rem 0 3rem',
          marginBottom: '0.5rem',
          boxSizing: 'border-box',
        }}
      >
        {weekdays &&
          weekdays.map(wd => (
            <div key={wd} style={{ width: '2rem', textAlign: 'center' }}>
              <Text small semibold center color={colors.greyDarker}>
                {wd}
              </Text>
            </div>
          ))}
      </div>
      <div
        key={`cal-row-${i + 1}`}
        style={{
          overflow: 'visible',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: vSpacing,
        }}
      >
        {week.flatMap((date, dateIndex) => {
          const isFinalDate = dateIndex === week.length - 1

          return [
            createDate(date, getIsOut(date, month), getIsFuture(date), getKey(date)),
            isFinalDate ? null : (
              <Box key={`divider-${getKey(date)}`} height="1px" width={hSpacing} />
            ),
          ]
        })}
      </div>
    </div>
  ))
}

CustomCalendar.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  hSpacing: PropTypes.string,
  vSpacing: PropTypes.string,
  startDate: PropTypes.string,
  weekdays: PropTypes.array,
}

CustomCalendar.defaultProps = {
  hSpacing: '1rem',
  vSpacing: '1rem',
  startDate: moment().format(),
}

export default CustomCalendar
