const env = process.env.REACT_APP_ENV || 'development'

const localhost = '127.0.0.1:8000'

const appConfig = {
  graphqlConfig: {
    url: {
      development: 'https://api.sleepwell.sleeprevolution.eu/graphql/', // `http://${localhost}/graphql/`, //
      staging: 'http://apistage.sleeprevolution.eu/graphql/',
      production: 'https://api.sleepwell.sleeprevolution.eu/graphql/',
    }[env],
    ws: {
      development: `ws://${localhost}/graphql/`,
      staging: 'http://apistage.sleeprevolution.eu/graphql/',
      production: 'ws://api.sleepwell.sleeprevolution.eu/graphql/',
    }[env],
    baseUrl: {
      development: `http://${localhost}`,
      staging: 'http://apistage.sleeprevolution.eu',
      production: 'https://api.sleepwell.sleeprevolution.eu',
    }[env],
  },
}

export default appConfig
