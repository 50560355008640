import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import { Text } from 'components'

function SmallInput({ label, value, setvalue, errorMessage, error }) {
  return (
    <div style={{ marginBottom: 10 }}>
      <div style={{ paddingTop: 5, marginRight: 10, marginBottom: 3 }}>
        <Text fontSize="17px"> {label} </Text>
      </div>
      <TextField
        style={{ backgroundColor: 'white', borderRadius: 5 }}
        hiddenLabel
        id="filled-hidden-label-small"
        defaultValue={value}
        variant="filled"
        size="small"
        onChange={o => setvalue(o)}
      />
      {error ? (
        <div style={{ backgroundColor: '#f7b2b2', width: 265, paddingLeft: 5 }}>
          <Text color="red" fontSize="17px">
            {errorMessage}
          </Text>
        </div>
      ) : null}
    </div>
  )
}

SmallInput.propTypes = {
  setvalue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
}

SmallInput.defaultProps = {
  errorMessage: PropTypes.string.errorMessage,
  error: PropTypes.bool.error,
}
export default SmallInput
