import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { getUser, setUser } from 'utils/helper'
import backgroundImage from '../../assets/images/login_background_image.jpg'
import { Text, LoginForm, Box, Footer } from '../../components'

const Login = () => (
  <Footer>
    <Box>
      <Box
        flex={1}
        cc
        justifyContent="center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        <Box mt="-140px">
          <LoginForm />
        </Box>
      </Box>
      {/* <Box style={{ bottom: 0, position: 'absolute' }}>
      <Footer />
    </Box> */}
    </Box>
  </Footer>
)

export default Login
