import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ChatIcon, XIcon } from '../../../assets/icons'
import colors from '../../../utils/colors'
import Box from '../Base/Box'
import Button from '../Base/Button'

const ICON_WIDTH = '26px'
const CLOSE_ICON_WIDTH = '22px'

const ChatWindowIcon = ({ isOpen, setIsOpen }) => (
  <Box style={chatWindowIconStyle}>
    <Button
      circle
      color1={colors.button.left}
      color2={colors.button.right}
      center
      onPress={() => {
        console.log('onpress')
        console.log(isOpen)
        setIsOpen(!isOpen)
      }}
    >
      <Box cc>
        {isOpen ? (
          <XIcon fill="white" width={CLOSE_ICON_WIDTH} />
        ) : (
          <ChatIcon fill="white" width={ICON_WIDTH} />
        )}
      </Box>
    </Button>
  </Box>
)

const chatWindowIconStyle = {
  position: 'fixed',
  bottom: '20px',
  right: '20px',
}

ChatWindowIcon.propTypes = {
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
}

ChatWindowIcon.defaultProps = {
  setIsOpen: () => {},
  isOpen: false,
}

export default ChatWindowIcon
