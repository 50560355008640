import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery, useTheme } from '@mui/material'
import XMark from 'assets/icons/xmark-solid.svg'
import EUFlag from 'assets/images/eu_flag.png'
import { drawerWidth } from 'components/organisms/Base/ResponsiveDrawer'
import colors from '../../utils/colors'
import Box from './Base/Box'
import Clickable from './Base/Clickable'
import Text from './Base/Text'

const footerHeights = {
  largeScreen: '120px',
  mediumScreen: '160px',
  smallScreen: '180px',
}

const Flag = ({ ml, mr }) => (
  <Box maxWidth="5rem" ml={ml} mr={mr}>
    <img src={EUFlag} alt="EU Flag" width="100%" />
  </Box>
)

const Footer = ({ inResponsiveDrawer, hasChatBubble, children }) => {
  const navigate = useNavigate()
  const theme = useTheme()

  const greaterThanMid = useMediaQuery(theme.breakpoints.up('md'))
  const smallToMid = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const footerHeight = useMemo(() => {
    if (greaterThanMid) return footerHeights.largeScreen
    if (smallToMid) return footerHeights.mediumScreen
    return footerHeights.smallScreen
  }, [greaterThanMid, smallToMid, lessThanSmall])

  const [showFooter, setShowFooter] = useState(true)

  return (
    <Box>
      <Box pb={showFooter ? footerHeight : '0px'}>{children}</Box>
      {showFooter && (
        <Box
          style={{
            bottom: 0,
            position: 'fixed',
            width: '100%',
            height: footerHeight,
            zIndex: 100,
          }}
        >
          <Box
            col
            width={inResponsiveDrawer && !lessThanSmall ? `calc(100vw - ${drawerWidth}px)` : '100%'}
            height="100%"
            bg={colors.lightBrown}
            cc
            justifyContent="space-between"
            pl="2rem"
            pr="calc(1rem + 12px)" // 12px in case of scrollbar
            style={{ boxSizing: 'border-box' }}
          >
            <Box row block cc jc="space-between" flexGrow mt="1rem">
              {(!hasChatBubble || (hasChatBubble && !lessThanSmall)) && <Flag mr="1rem" />}
              <Box minWidth="100px" mr={hasChatBubble && !lessThanSmall ? '50px' : '0px'}>
                <Text color={colors.black} small>
                  This project has received funding from the European Union’s Horizon 2020 research
                  and innovation programme under grant agreement no. 965417. This website reflects
                  only the author’s view and that the European Commission is not responsible for any
                  use that may be made of the information it contains.
                </Text>
              </Box>
              {hasChatBubble && lessThanSmall && <Flag ml="1rem" />}
              <Box col jc="flex-start" height="100%" minWidth="1rem" maxWidth="1rem">
                <Clickable onPress={() => setShowFooter(false)}>
                  <img src={XMark} alt="X" width="75%" />
                </Clickable>
              </Box>
            </Box>
            <Box
              block
              row
              jc="space-evenly"
              mb="1rem"
              mr={hasChatBubble && lessThanSmall ? '60px' : '0px'}
            >
              <Clickable onPress={() => navigate('/termsofuse')}>
                <Text color={colors.black} small bold>
                  Terms of Use
                </Text>
              </Clickable>
              <Clickable onPress={() => navigate('/privacypolicy')}>
                <Text color={colors.black} small bold>
                  Privacy Policy
                </Text>
              </Clickable>
              <Clickable onPress={() => navigate('/CookiesPolicy')}>
                <Text color={colors.black} small bold>
                  Cookies Policy
                </Text>
              </Clickable>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Footer
