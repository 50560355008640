import React, { useEffect, useState } from 'react'
import Chip from '@mui/material/Chip'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { DataTable, Filters, Overview, Footer } from 'components'
import BubbleCalendarRow from 'components/organisms/BubbleCalendarRow'
import theme from 'utils/theme'
import { Box } from '../../components/atoms'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box mt="32px">{children}</Box>}
    </div>
  )
}

const Home = () => {
  const [tab, setTab] = useState(0)
  const [filters, setFilters] = useState([])
  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const setNonNullFilters = newFilters => {
    const nonNullFilters = newFilters.filter(
      i =>
        (i.min && i.min !== '') ||
        (i.max && i.max !== '') ||
        (i.valueIndices && i.valueIndices.length > 0)
    )

    const formResults = nonNullFilters.map(
      i => {
        if (i.label === 'Studies') {
          // get all the array elements in i.selection with the index in i.valueIndices

          const selectedStudies = i.selection.filter((item, index) =>
            i.valueIndices.includes(index)
          )
          return { study: { title__in: selectedStudies } }
        }

        // const er = {
        //   entryResults: {
        //     entryId: { title: i.label },
        //   },
        // }

        // if (i.min && i.min !== '') er.entryResults.numberAnswer_Gte = i.min
        // if (i.max && i.max !== '') er.entryResults.numberAnswer_Lte = i.max
        // if (i?.valueIndices?.length > 0) er.entryResults.select_answer__in = i.valueIndices

        // return er
        return {}
      }
      // ,{ entryResults: { numberAnswer: 21, entryId: {title:"Age"} }}
    )

    // ??
    // if (filters.length === 0) {
    //   setFilters(formResults)
    // }
    setFilters(formResults)
  }

  return (
    <Box flex ph="2vw" pv={theme.spacing.largest} bg="white">
      <Box mb="32px">
        <Filters setFilters={setNonNullFilters} />
      </Box>
      <Box row jc="space-between" style={{ borderBottom: '1px solid rgb(212, 212, 212)' }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.colors.bluepurple,
            },
          }}
        >
          <Tab
            label="Table"
            style={{ color: tab === 0 ? theme.colors.bluepurple : theme.colors.black }}
          />
          <Tab
            label="Overview"
            style={{ color: tab === 1 ? theme.colors.bluepurple : theme.colors.black }}
          />

          {/* <Tab
            label="Charts"
            style={{ color: tab === 2 ? theme.colors.bluepurple : theme.colors.black }}
          /> */}
        </Tabs>
        <Box row cc>
          {/* <Box mr="4px">
            <Button
              color1={theme.colors.bluepurple}
              color2={theme.colors.bluepurple}
              onPress={() => {}}
            >
              <Text center color={theme.colors.white} bold>
                Save Data Group
              </Text>
            </Button>
          </Box>
          <Box>
            <Button
              color1={theme.colors.bluepurple}
              color2={theme.colors.bluepurple}
              onPress={() => {}}
            >
              <Text center color={theme.colors.white} bold>
                Export paths
              </Text>
            </Button>
          </Box> */}
        </Box>
      </Box>
      <TabPanel value={tab} index={0}>
        <DataTable key="data-table" filters={filters} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Box row block jc="center">
          <Box style={{ overflowX: 'auto' }}>
            <BubbleCalendarRow filters={filters} />
          </Box>
        </Box>
      </TabPanel>

      {/* <TabPanel value={tab} index={2}>
        <DataCharts filters={filters} />
      </TabPanel> */}
    </Box>
  )
}
export default Home
