// import wordEn13 from 'assets/audio/auditoryVerbalLearning/13-color.mp4'
// import wordEn1 from '../assets/audio/auditoryVerbalLearning/1-drum.mp4'
import wordIs1 from '../assets/audio/auditoryVerbalLearning/1-fidla.m4a'
// import wordEn10 from '../assets/audio/auditoryVerbalLearning/10-farmer.mp4'
import wordIs10 from '../assets/audio/auditoryVerbalLearning/10-nef.m4a'
// import wordEn11 from '../assets/audio/auditoryVerbalLearning/11-nose.mp4'
import wordIs11 from '../assets/audio/auditoryVerbalLearning/11-rjupa.m4a'
import wordIs12 from '../assets/audio/auditoryVerbalLearning/12-kollur.m4a'
// import wordEn12 from '../assets/audio/auditoryVerbalLearning/12-turkey.mp4'
import wordIs13 from '../assets/audio/auditoryVerbalLearning/13-skogur.m4a'
// import wordEn14 from '../assets/audio/auditoryVerbalLearning/14-house.mp4'
import wordIs14 from '../assets/audio/auditoryVerbalLearning/14-ljon.m4a'
import wordIs15 from '../assets/audio/auditoryVerbalLearning/15-foss.m4a'
// import wordEn15 from '../assets/audio/auditoryVerbalLearning/15-river.mp4'
// import wordEn2 from '../assets/audio/auditoryVerbalLearning/2-curtain.mp4'
import wordIs2 from '../assets/audio/auditoryVerbalLearning/2-tjald.m4a'
// import wordEn3 from '../assets/audio/auditoryVerbalLearning/3-bell.mp4'
import wordIs3 from '../assets/audio/auditoryVerbalLearning/3-bjalla.m4a'
// import wordEn4 from '../assets/audio/auditoryVerbalLearning/4-coffee.mp4'
import wordIs4 from '../assets/audio/auditoryVerbalLearning/4-kaffi.m4a'
import wordIs5 from '../assets/audio/auditoryVerbalLearning/5-loft.m4a'
// import wordEn5 from '../assets/audio/auditoryVerbalLearning/5-school.mp4'
// import wordEn6 from '../assets/audio/auditoryVerbalLearning/6-parent.mp4'
import wordIs6 from '../assets/audio/auditoryVerbalLearning/6-tungl.m4a'
import wordIs7 from '../assets/audio/auditoryVerbalLearning/7-gardur.m4a'
// import wordEn7 from '../assets/audio/auditoryVerbalLearning/7-moon.mp4'
// import wordEn8 from '../assets/audio/auditoryVerbalLearning/8-garden.mp4'
import wordIs8 from '../assets/audio/auditoryVerbalLearning/8-hufa.m4a'
import wordIs9 from '../assets/audio/auditoryVerbalLearning/9-bondi.m4a'
// import wordEn9 from '../assets/audio/auditoryVerbalLearning/9-hat.mp4'
import wordEn1 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording1.m4a'
import wordEn10 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording10.m4a'
import wordEn11 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording11.m4a'
import wordEn12 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording12.m4a'
import wordEn13 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording13.m4a'
import wordEn14 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording14.m4a'
import wordEn15 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording15.m4a'
import wordEn2 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording2.m4a'
import wordEn3 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording3.m4a'
import wordEn4 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording4.m4a'
import wordEn5 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording5.m4a'
import wordEn6 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording6.m4a'
import wordEn7 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording7.m4a'
import wordEn8 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording8.m4a'
import wordEn9 from '../assets/audio/auditoryVerbalLearning/wordlistd/NewRecording9.m4a'

const getSounds = language => {
  let sounds = []
  if (language === 'icelandic') {
    sounds = [
      { sound: wordIs1, word: 'fiðla' },
      { sound: wordIs2, word: 'tjald' },
      { sound: wordIs3, word: 'bjalla' },
      { sound: wordIs4, word: 'kaffi' },
      { sound: wordIs5, word: 'loft' },
      { sound: wordIs6, word: 'tungl' },
      { sound: wordIs7, word: 'garður' },
      { sound: wordIs8, word: 'húfa' }, //
      { sound: wordIs9, word: 'bóndi' }, //
      { sound: wordIs10, word: 'nef' },
      { sound: wordIs11, word: 'rjúpa' }, //
      { sound: wordIs12, word: 'kollur' },
      { sound: wordIs13, word: 'skógur' }, //
      { sound: wordIs14, word: 'ljón' }, //
      { sound: wordIs15, word: 'foss' },
    ]
  } else {
    sounds = [
      { sound: wordEn1, word: 'dish' }, // 'doll' }, // 'desk' }, // 'drum' },
      { sound: wordEn2, word: 'jester' }, // 'mirror' }, // 'ranger' }, // 'curtain' },
      { sound: wordEn3, word: 'hill' }, // 'nail' }, // 'bird' }, // 'bell' },
      { sound: wordEn4, word: 'coat' }, // 'sailor' }, // 'shoe' }, // 'coffee' },
      { sound: wordEn5, word: 'tool' }, // 'heart' }, // 'stove' }, // 'school' },
      { sound: wordEn6, word: 'forest' }, // 'desert' }, // 'mountain' }, // 'parent' },
      { sound: wordEn7, word: 'water' }, // 'face' }, // 'glasses' }, // 'moon' },
      { sound: wordEn8, word: 'ladder' }, // 'letter' }, // 'towel' }, // 'garden' },
      { sound: wordEn9, word: 'girl' }, // 'bed' }, // 'cloud' }, // 'hat' },
      { sound: wordEn10, word: 'foot' }, // 'machine' }, // 'boat' }, // 'farmer' },
      { sound: wordEn11, word: 'shield' }, // 'milk' }, // 'lamb' }, // 'nose' },
      { sound: wordEn12, word: 'pie' }, // 'helmet' }, // 'gun' }, // 'turkey' },
      { sound: wordEn13, word: 'insect' }, // 'music' }, // 'pencil' }, // 'color' },
      { sound: wordEn14, word: 'ball' }, // 'horse' }, // 'church' }, // 'house' },
      { sound: wordEn15, word: 'car' }, // 'road' }, // 'fish' }, // 'river' },
    ]
  }
  return sounds
}

export default getSounds
