/* eslint-disable camelcase */

import React, { useContext, useEffect } from 'react'
import Comment from '../../comps/Comment'
import ConnectedFields from '../../comps/ConnectedFields'
import FormikMuiInput from '../../comps/FormikMuiInput'
import FormikMuiSelect from '../../comps/FormikMuiSelect'
import InlineFields from '../../comps/InlineFields'
import Section from '../../comps/Section'
import TextBetween from '../../comps/TextBetween'
import { LangContext } from '../../const/Contexts'
import {
  sleepDurationKeys,
  bedtimeKeys,
  sleepScheduleKeys,
  sleepDepthKeys,
  sleepQuantityKeys,
  sleepOffsetKeys,
  activitiesBeforeSleepKeys,
  noYesKeys,
  weekOrMonthKeys,
} from '../../const/ESQOptions'

export const MSPFieldNames = [
  'sleep_duration',
  'bedtime',
  'wake_up_time',
  'sleep_schedule',
  'sleep_depth',
  'sleep_quantity',
  'sleep_offset',
  'sleep_time_preferred',
  'out_of_bed_preferred',
  'sleep_amount_preferred',
  'sleep_amount_business_days',
  'sleep_amount_free_days',
  'sleep_into_bed_business_days',
  'sleep_into_bed_free_days',
  'sleep_out_of_bed_business_days',
  'sleep_out_of_bed_free_days',
  'activities_before_sleep',
  'activities_before_sleep_other_info',
  'planned_nap',
  'nap_frequency_per_day',
  'nap_day_count',
  'nap_per_week_or_month',
  'nap_average_duration',
  'nap_feels_refreshed',
]

const MySleepProfile = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  const { values, setFieldValue } = formikProps

  const {
    activities_before_sleep,
    activities_before_sleep_other_info,
    planned_nap,
    nap_frequency_per_day,
    nap_day_count,
    nap_per_week_or_month,
    nap_average_duration,
    nap_feels_refreshed,
  } = values

  // Resetting 'Extra' fields when they switch back (ex. 'Other' boxes)
  useEffect(() => {
    if (activities_before_sleep.includes(0) && activities_before_sleep.length > 1) {
      setFieldValue('activities_before_sleep', [0])
    }
  }, [activities_before_sleep])
  useEffect(() => {
    if (!activities_before_sleep.includes(6) && activities_before_sleep_other_info !== '') {
      setFieldValue('activities_before_sleep_other_info', '')
    }
  }, [activities_before_sleep])
  useEffect(() => {
    if (planned_nap === 0) {
      if (nap_frequency_per_day !== '') {
        setFieldValue('nap_frequency_per_day', '')
      }
      if (nap_day_count !== '') {
        setFieldValue('nap_day_count', '')
      }
      if (nap_per_week_or_month !== '') {
        setFieldValue('nap_per_week_or_month', '')
      }
      if (nap_average_duration !== '') {
        setFieldValue('nap_average_duration', '')
      }
      if (nap_feels_refreshed !== '') {
        setFieldValue('nap_feels_refreshed', '')
      }
    }
  }, [planned_nap])

  return (
    <Section>
      <ConnectedFields>
        <Comment text={t('NaturalSleep')} />
        <FormikMuiSelect
          label={t('SleepDuration')}
          name="sleep_duration"
          options={sleepDurationKeys.map(val => t(val))}
        />
        <FormikMuiSelect
          label={t('SleepTiming')}
          name="bedtime"
          options={bedtimeKeys.map(val => t(val))}
        />
        <FormikMuiSelect
          label={t('WakeUp')}
          name="wake_up_time"
          options={bedtimeKeys.map(val => t(val))}
        />
        <FormikMuiSelect
          label={t('SleepSchedule')}
          name="sleep_schedule"
          options={sleepScheduleKeys.map(val => t(val))}
        />
        <FormikMuiSelect
          label={t('SleepDepth')}
          name="sleep_depth"
          options={sleepDepthKeys.map(val => t(val))}
        />
        <FormikMuiSelect
          label={t('SleepQuantity')}
          name="sleep_quantity"
          options={sleepQuantityKeys.map(val => t(val))}
        />
        <FormikMuiSelect
          label={t('SleepOffset')}
          name="sleep_offset"
          options={sleepOffsetKeys.map(val => t(val))}
        />
      </ConnectedFields>
      <ConnectedFields>
        <Comment text={t('SleepFreeToChoose')} />
        <FormikMuiInput label={t('SleepTime')} name="sleep_time_preferred" type="time" />
        <FormikMuiInput label={t('WakeupTime')} name="out_of_bed_preferred" type="time" />
        <FormikMuiInput label={t('SleepHours')} name="sleep_amount_preferred" type="time" />
      </ConnectedFields>
      <ConnectedFields>
        <Comment text={t('InCurrentSituation')} />
        <Comment text={t('SleepHourAverage')} />
        <FormikMuiInput label={t('OnWorkingDays')} name="sleep_amount_business_days" type="time" />
        <FormikMuiInput
          label={t('OnWeekdaysHollidays')}
          name="sleep_amount_free_days"
          type="time"
        />
        <Comment text={t('GetIntoBed')} />
        <FormikMuiInput
          label={t('OnWorkingDays')}
          name="sleep_into_bed_business_days"
          type="time"
        />
        <FormikMuiInput
          label={t('OnWeekdaysHollidays')}
          name="sleep_into_bed_free_days"
          type="time"
        />
        <Comment text={t('OutOfBed')} />
        <FormikMuiInput
          label={t('OnWorkingDays')}
          name="sleep_out_of_bed_business_days"
          type="time"
        />
        <FormikMuiInput
          label={t('OnWeekdaysHollidays')}
          name="sleep_out_of_bed_free_days"
          type="time"
        />
      </ConnectedFields>
      <ConnectedFields>
        <Comment text={t('ActivitiesBeforeBed')} />
        <FormikMuiSelect
          label=""
          name="activities_before_sleep"
          options={activitiesBeforeSleepKeys.map(val => t(val))}
          multiple
        />
        {activities_before_sleep.includes(6) && (
          <FormikMuiInput
            label={t('Other')}
            name="activities_before_sleep_other_info"
            type="text"
          />
        )}
      </ConnectedFields>
      <ConnectedFields>
        <Comment text={`${t('Napping')}:`} />
        <Comment text={t('NapSometimes')} />
        <FormikMuiSelect label="" name="planned_nap" options={noYesKeys.map(val => t(val))} />
        {planned_nap === 1 && (
          <>
            <Comment text={t('NappingFrequently')} />
            <FormikMuiInput label={t('TimesPerDay')} name="nap_frequency_per_day" type="number" />
            <InlineFields>
              <FormikMuiInput label="" name="nap_day_count" type="number" />
              <TextBetween text={t('DaysPer')} />
              <FormikMuiSelect
                label=""
                name="nap_per_week_or_month"
                options={weekOrMonthKeys.map(val => t(val))}
              />
            </InlineFields>
            <FormikMuiInput
              label={`${t('AverageDuration')}?`}
              name="nap_average_duration"
              type="number"
              unit={t('minutes')}
            />
            <FormikMuiSelect
              label={t('RefreshedAfterNapping')}
              name="nap_feels_refreshed"
              options={noYesKeys.map(val => t(val))}
            />
          </>
        )}
      </ConnectedFields>
    </Section>
  )
}

export default MySleepProfile
