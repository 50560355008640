import React from 'react'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Box } from '../atoms'
import AgeDistributionChartCard from './Charts/AgeDistributionChartCard'
import ApneaDistributionChartCard from './Charts/ApneaDistributionChartCard'
import CorrelationChartCard from './Charts/CorrelationChartCard'
import DevicesChartCard from './Charts/DevicesChartCard'
import GenderDistribution from './Charts/GenderDistribution'
import InfoCards from './Charts/InfoCards'
import StudiesChartCard from './Charts/StudiesChartCard'

const PARTICIPANTS_OVERVIEW_QUERY = gql`
  query ParticipantsOverview {
    allParticipations {
      overview {
        ageDistr {
          ltTwenty {
            male
            female
            notSpecified
          }
          twentyToFourty {
            male
            female
            notSpecified
          }
          fourtyToSixty {
            male
            female
            notSpecified
          }
          gteSixty {
            male
            female
            notSpecified
          }
        }
        genderDistr {
          male
          female
          notSpecified
        }
        ahiDistr {
          none
          mild
          moderate
          severe
        }
      }
    }
  }
`

const DataCharts = () => {
  const { loading, error, data } = useQuery(PARTICIPANTS_OVERVIEW_QUERY)

  return (
    <Box row pv="16px" style={{ gap: '16px' }}>
      {data && (
        <>
          <Box style={{ gap: '16px' }}>
            <GenderDistribution data={data?.allParticipations?.overview?.genderDistr} />
            {/* <DevicesChartCard /> */}
          </Box>
          <Box style={{ gap: '16px' }}>
            <AgeDistributionChartCard data={data?.allParticipations?.overview?.ageDistr} />
            {/* <CorrelationChartCard /> */}
          </Box>
          <Box style={{ gap: '16px' }}>
            {/* <InfoCards /> */}
            {/* <StudiesChartCard /> */}
            <ApneaDistributionChartCard data={data?.allParticipations?.overview?.ahiDistr} />
          </Box>
        </>
      )}
    </Box>
  )
}

export default DataCharts
