import React, { useState, useMemo, useEffect, useContext } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { KeyboardArrowLeft, KeyboardArrowRight } from 'assets/icons'
import ESQFollowUpBadge from 'components/atoms/Badges/ESQFollowUpBadge'
import ESQIntakeBadge from 'components/atoms/Badges/ESQIntakeBadge'
import EveningDiaryBadge from 'components/atoms/Badges/EveningDiaryBadge'
import FlexibilityGameBadge from 'components/atoms/Badges/FlexibilityGameBadge'
import MemoryGameBadge from 'components/atoms/Badges/MemoryGameBadge'
import MorningDiaryBadge from 'components/atoms/Badges/MorningDiaryBadge'
import PerceptionGameBadge from 'components/atoms/Badges/PerceptionGameBadge'
import ReactionGameBadge from 'components/atoms/Badges/ReactionGameBadge'
import moment from 'moment'
import Cookies from 'universal-cookie'
import colors from '../../utils/colors'
import { Box, Text, Clickable } from '../atoms'
import BubbleCalendar from '../molecules/BubbleCalendar'

const MORNING_DIARY = 'morningDiaryResults'
const EVENING_DIARY = 'eveningDiaryResults'
const MEMORY_GAME = 'memoryGameResults'
const PERCEPTION_GAME = 'perceptionGameResults'
const REACTION_GAME = 'reactionGameResults'
const FLEXIBILITY_GAME = 'flexibilityGameResults'
const COGNITIVE_BATTERY = 'cognitiveBatteryResults'

const MY_RESULTS_QUERY = gql`
   query GetParticipation ($startDate: Date!, $endDate: Date!) {
     ${MORNING_DIARY}: formResults(
       formTitle: "MorningSleepDiary"
       startDate: $startDate,
       endDate: $endDate,
     ) {
       answeredAt
     }
     ${EVENING_DIARY}: formResults(
       formTitle: "EveningSleepDiary"
       startDate: $startDate,
       endDate: $endDate,
     ) {
       answeredAt
     }
     ${MEMORY_GAME}: formResults(
       formTitle: "MemoryGame"
       startDate: $startDate,
       endDate: $endDate,
     ) {
       answeredAt
     }
     ${PERCEPTION_GAME}: formResults(
       formTitle: "PerceptionGame"
       startDate: $startDate,
       endDate: $endDate,
     ) {
       answeredAt
     }
     ${REACTION_GAME}: formResults(
       formTitle: "ReactionGame"
       startDate: $startDate,
       endDate: $endDate,
     ) {
       answeredAt
     }
     ${FLEXIBILITY_GAME}: formResults(
       formTitle: "FlexibilityGame"
       startDate: $startDate,
       endDate: $endDate,
     ) {
       answeredAt
     }
    # Not using the badge and resolver returns unauthorized
    #  ${COGNITIVE_BATTERY}: formResults(
    #   formTitle: "CognitiveBattery"
    #   startDate: $startDate,
    #   endDate: $endDate,
    # ) {
    #   answeredAt
    # }
   }
 `

const FINISHED_QUERY = gql`
   query GetParticipation($participationId: ID, $startDate: Date!, $endDate: Date!) {
     ${MORNING_DIARY}: formResults(
       formTitle: "MorningSleepDiary"
       participationId: $participationId
       startDate: $startDate,
       endDate: $endDate,
     ) {
       answeredAt
     }
     ${EVENING_DIARY}: formResults(
       formTitle: "EveningSleepDiary"
       participationId: $participationId
       startDate: $startDate,
       endDate: $endDate
     ) {
       answeredAt
     }
     ${MEMORY_GAME}: formResults(
       formTitle: "MemoryGame"
       participationId: $participationId
       startDate: $startDate,
       endDate: $endDate
     ) {
       answeredAt
     }
     ${PERCEPTION_GAME}: formResults(
       formTitle: "PerceptionGame"
       participationId: $participationId
       startDate: $startDate,
       endDate: $endDate
     ) {
       answeredAt
     }
     ${REACTION_GAME}: formResults(
       formTitle: "ReactionGame"
       participationId: $participationId
       startDate: $startDate,
       endDate: $endDate
     ) {
       answeredAt
     }
     ${FLEXIBILITY_GAME}: formResults(
       formTitle: "FlexibilityGame"
       participationId: $participationId
       startDate: $startDate,
       endDate: $endDate
     ) {
       answeredAt
     }
    # Not using the badge and resolver returns unauthorized
    #  ${COGNITIVE_BATTERY}: formResults(
    #   formTitle: "CognitiveBattery"
    #   startDate: $startDate,
    #   endDate: $endDate,
    #  ) {
    #   answeredAt
    #  }
   }
 `

const getLegendBadges = (onPress, activeBadges) => {
  // collect all of the different badges to use
  const badges = [
    <MorningDiaryBadge
      key="morning"
      hasLabel
      isActive={activeBadges.morning}
      onPress={() => {
        onPress('morning')
      }}
    />,
    <EveningDiaryBadge
      key="evening"
      hasLabel
      isActive={activeBadges.evening}
      onPress={() => {
        onPress('evening')
      }}
    />,
    <FlexibilityGameBadge
      key="flexibility"
      hasLabel
      isActive={activeBadges.flexibility}
      onPress={() => {
        onPress('flexibility')
      }}
    />,
    <ReactionGameBadge
      key="reaction"
      hasLabel
      isActive={activeBadges.reaction}
      onPress={() => {
        onPress('reaction')
      }}
    />,
    <MemoryGameBadge
      key="memory"
      hasLabel
      isActive={activeBadges.memory}
      onPress={() => {
        onPress('memory')
      }}
    />,
    <PerceptionGameBadge
      key="perception"
      hasLabel
      isActive={activeBadges.perception}
      onPress={() => {
        onPress('perception')
      }}
    />,
    <ESQIntakeBadge
      key="esqIntake"
      hasLabel
      isActive={activeBadges.esqIntake}
      onPress={() => {
        onPress('esqIntake')
      }}
    />,
    <ESQFollowUpBadge
      key="esqFollowUp"
      hasLabel
      isActive={activeBadges.esqFollowUp}
      onPress={() => {
        onPress('esqFollowUp')
      }}
    />,
  ]

  // separate them using boxes
  // const elements = [badges[0]]
  // for (let i = 1; i < badges.length; i += 1) {
  //   elements.push(<Box key={i.toString()} height="1px" width="20px" />)
  //   elements.push(badges[i])
  // }

  return (
    <Box block row flexWrap jc="center">
      {badges.map((badge, index) => (
        <Box key={index.toString()} mh="10px">
          {badge}
        </Box>
      ))}
    </Box>
  )
}

const weekdays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

const defaultActiveBadges = {
  morning: true,
  evening: true,
  flexibility: true,
  reaction: true,
  memory: true,
  perception: true,
  esqIntake: true,
  esqFollowUp: true,
}

const cookies = new Cookies()

const BubbleCalendarView = ({ participationId, esqBaseDate, esqFollowUpDate }) => {
  const [currDate, setCurrDate] = useState(moment().startOf('month'))
  const nextMonthAllowed = useMemo(
    () => !currDate.clone().startOf('month').add(1, 'month').isAfter(moment()),
    [currDate]
  )
  const [data, setData] = useState(null)

  const [loadResults, { data: queryData }] = useLazyQuery(
    participationId !== undefined ? FINISHED_QUERY : MY_RESULTS_QUERY
  )

  useEffect(() => {
    loadResults({
      variables: {
        participationId,
        startDate: currDate.clone().startOf('month').format('YYYY-MM-DD'),
        endDate: currDate.clone().endOf('month').format('YYYY-MM-DD'),
      },
    })
  }, [currDate, participationId, loadResults])

  useEffect(() => {
    if (queryData) {
      setData({
        ...queryData,
        esqMetadata: [
          {
            entryResults: [
              ...(esqBaseDate
                ? [
                    {
                      entryId: { title: 'base_finished_at' },
                      datetimefieldAnswer: esqBaseDate,
                    },
                  ]
                : []),
              ...(esqFollowUpDate
                ? [
                    {
                      entryId: { title: 'follow_up_finished_at' },
                      datetimefieldAnswer: esqFollowUpDate,
                    },
                  ]
                : []),
            ],
          },
        ],
      })
    }
  }, [queryData, esqBaseDate, esqFollowUpDate])

  const [activeBadges, setActiveBadges] = useState(defaultActiveBadges)
  const cookieActiveBadges = useMemo(() => cookies.get('activeBadges'), [])

  useEffect(() => {
    const relevantBadges = {}
    if (cookieActiveBadges) {
      // if there is a cookie, use it
      const cb =
        typeof cookieActiveBadges === 'string' || cookieActiveBadges instanceof String
          ? JSON.parse(cookieActiveBadges)
          : cookieActiveBadges
      // only get badges that are actually used in this component
      Object.keys(cb).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(defaultActiveBadges, key)) {
          relevantBadges[key] = cb[key]
        }
      })
    }

    if (Object.keys(relevantBadges).length !== 0) {
      setActiveBadges({ ...defaultActiveBadges, ...relevantBadges })
    } else {
      // if there is no cookie, use default
      setActiveBadges(defaultActiveBadges)
    }
  }, [])

  const onPressBadge = badge => {
    setActiveBadges(b => ({ ...b, [badge]: !b[badge] }))
  }

  useEffect(() => {
    // save active badges settings in cookies without deleting unrelevant badges
    const obj = { ...cookieActiveBadges, ...activeBadges }
    cookies.set('activeBadges', JSON.stringify(obj))
  }, [activeBadges])

  return (
    // overarching container
    <Box cc pv="2rem" width="65rem">
      {/* month & year & controls */}
      <Box flexDirection="row" spaceBetween>
        {/* "previous month" button */}
        <Clickable
          onPress={() => {
            const newDate = currDate.clone().subtract(1, 'month')
            setCurrDate(newDate)
          }}
        >
          <Box
            height="2rem"
            width="2rem"
            round
            justifyContent="center"
            cc
            style={{ border: '2px solid #000000' }}
          >
            <KeyboardArrowLeft style={{ marginLeft: '-2px' }} />
          </Box>
        </Clickable>

        {/* month & year */}
        <Box width="20rem" flexDirection="row" justifyContent="center">
          <Text huge bold color={colors.greyDarkest}>
            {currDate.format('MMMM YYYY')}
          </Text>
        </Box>

        {/* "next month" button */}
        <Clickable
          onPress={() => {
            const newDate = currDate.clone().add(1, 'month')
            setCurrDate(newDate)
          }}
          disabled={!nextMonthAllowed}
        >
          <Box
            height="2rem"
            width="2rem"
            round
            justifyContent="center"
            cc
            style={{ border: '2px solid #000000', opacity: `${Number(nextMonthAllowed)}` }}
          >
            <KeyboardArrowRight style={{ marginLeft: '2px' }} />
          </Box>
        </Clickable>
      </Box>

      {/* legend */}
      <Box block cc mb="25px" ph="2rem" pv="1rem">
        {getLegendBadges(onPressBadge, activeBadges)}
      </Box>

      {/* weekday headings */}
      {/* <Box flex row spaceBetween style={{ gap: '0.8rem' }}>
        {weekdays.map(wd => (
          <Box centerContent key={wd} width="5.6rem">
            <Text medium semibold center color={colors.greyDarker}>
              {wd}
            </Text>
          </Box>
        ))}
      </Box> */}

      {data && (
        <BubbleCalendar
          itemData={data}
          year={currDate.year()}
          month={currDate.month() + 1}
          weekdays={weekdays}
          activeBadges={activeBadges}
        />
      )}
    </Box>
  )
}

// BubbleCalendarView.propTypes = {
//   morningDiaryDates: PropTypes.arrayOf(momentPropTypes.momentObj),
//   eveningDiaryDates: PropTypes.arrayOf(momentPropTypes.momentObj),
//   flexibilityGameDates: PropTypes.arrayOf(momentPropTypes.momentObj),
//   memoryGameDates: PropTypes.arrayOf(momentPropTypes.momentObj),
//   perceptionGameDates: PropTypes.arrayOf(momentPropTypes.momentObj),
//   reactionGameDates: PropTypes.arrayOf(momentPropTypes.momentObj),
// }

// BubbleCalendarView.defaultProps = {
//   morningDiaryDates: [],
//   eveningDiaryDates: [],
//   flexibilityGameDates: [],
//   memoryGameDates: [],
//   perceptionGameDates: [],
//   reactionGameDates: [],
// }

export default BubbleCalendarView
