import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../../utils/colors'
import Badge from './Badge'

const MorningMyoBadge = ({ isActive, hasLabel, onPress }) => (
  <Badge
    bigLetter="Y1"
    color={colors.reactionTask.right}
    isActive={isActive}
    onPress={onPress}
    label={hasLabel ? 'Morning Myofunctional Exercises' : ''}
  />
)

MorningMyoBadge.propTypes = {
  isActive: PropTypes.bool.isRequired,
  hasLabel: PropTypes.bool.isRequired,
  onPress: PropTypes.func,
}

MorningMyoBadge.defaultProps = {
  onPress: () => {},
}

export default MorningMyoBadge
