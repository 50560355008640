import React from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { KeyboardArrowLeft } from 'assets/icons'
import colors from 'utils/colors'
import { Box, Text, BodyMeasurementPresenter, Clickable, Button } from '../components'
import BubbleCalendarView from '../components/organisms/BubbleCalendarView'

const calendarWidth = '65rem'

const ParticipationDetails = () => {
  const location = useLocation()
  const navigate = useNavigate()

  console.log(location.state)

  if (!location.state) {
    // navigate back to the participation list
    return <Navigate to="/Home" />
  }

  return (
    <Box ph="32px" pv="32px">
      <Box mb="16px" row>
        <Clickable
          onPress={() => {
            navigate('/Home')
          }}
        >
          <Box
            height="2rem"
            width="2rem"
            mr="1rem"
            round
            justifyContent="center"
            cc
            bg={colors.button.right}
          >
            <KeyboardArrowLeft fill="white" style={{ marginLeft: '-2px' }} />
          </Box>
        </Clickable>
        <Text huge bold color="black">
          Participant {location?.state?.sri}
        </Text>
      </Box>
      <Box jc="center" row flexWrap style={{ gap: '2rem' }}>
        <Box col flexGrow maxWidth={calendarWidth}>
          {/* {location?.state?.esqMetadata?.base && location?.state?.esqMetadata?.followUp && ( */}
          {location?.state?.esqMetadata?.base && (
            <Box mb="1rem">
              <Button
                block
                maxWidth="100%"
                onPress={() =>
                  navigate('/esqvisualization', {
                    state: { ...location.state },
                  })
                }
              >
                <Text bigger center bold>
                  ESQ Visualization
                </Text>
              </Button>
            </Box>
          )}
          <BodyMeasurementPresenter participationId={location.state.id} />
        </Box>
        <Box
          round
          bg={colors.white}
          style={{
            borderColor: colors.greyDark,
            borderWidth: '1px',
            borderStyle: 'solid',
            overflowX: 'auto',
          }}
        >
          <BubbleCalendarView
            participationId={location.state.id}
            esqBaseDate={location?.state?.esqMetadata?.base}
            esqFollowUpDate={location?.state?.esqMetadata?.followUp}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ParticipationDetails
