import React from 'react'
import { Typography } from '@mui/material'

const TextBetween = ({ text }) => (
  <div
    style={{
      flexShrink: 0,
      width: '80px',
      marginTop: '25px',
      textAlign: 'center',
    }}
  >
    <Typography>{text}</Typography>
  </div>
)

export default TextBetween
