/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import instructionImage from 'assets/images/tol/instructionImage.png'
import { Box, Instructions, Modal, Text, Button, ProgressBar } from 'components'
import moment from 'moment'
import theme from 'utils/theme'
import { levelImages, levels, startingBalls } from 'utils/towerOfLondonHelper'

const TowerOfLondon = () => {
  const location = useLocation()
  const { t, i18n } = useTranslation(['towerOfLondon'])

  const TIMEOUT = 480000 // 8 minutes in ms.

  const onNext = () => setModalIndex(modalIndex + 1)
  const onPrev = () => setModalIndex(modalIndex - 1)

  const [started, setStarted] = useState(false)
  const [finished, setFinished] = useState(false)
  const [practice, setPractice] = useState(false)
  const [balls, setBalls] = useState(JSON.parse(JSON.stringify(startingBalls)))
  const [won, setWon] = useState(false)
  const [outOfMoves, setOutOfMoves] = useState(false)
  const [levelIndex, setLevelIndex] = useState(0)
  const [dragging, setDragging] = useState('0')
  const [results, setResults] = useState([])
  const [startedTestAt, setStartedTestAt] = useState(null)
  const [startedAt, setStartedAt] = useState(null)
  const [finishedAt, setFinishedAt] = useState(null)
  const [moves, setMoves] = useState(0)
  const [completedProblems, setCompletedProblems] = useState([])
  const [solvedProblems, setSolvedProblems] = useState([])
  const [failedProblems, setFailedProblems] = useState([])
  const [allLevelsCompleted, setAllLevelsCompleted] = useState(false)
  const [modalIndex, setModalIndex] = useState(0)
  const [timesUp, setTimesUp] = useState(false)
  const [timersToSolve, setTimersToSolve] = useState([])
  const [timersToFail, setTimersToFail] = useState([])
  const [progressPercentage, setProgressPercentage] = useState(0)

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('lang')))
  }, [])

  const handleOnDragEnd = result => {
    setDragging('0')
    if (!result.destination) {
      // Dragging out of place not possible.
      return
    }
    if (
      // Dragging in the same place should not do anything.
      (['pos0', 'pos1', 'pos2'].includes(result.destination.droppableId) &&
        ['pos0', 'pos1', 'pos2'].includes(result.source.droppableId)) ||
      (['pos3', 'pos4'].includes(result.destination.droppableId) &&
        ['pos3', 'pos4'].includes(result.source.droppableId)) ||
      (['pos5'].includes(result.destination.droppableId) &&
        ['pos5'].includes(result.source.droppableId))
    ) {
      return
    }
    // Find the moving ball and update position.
    const ball = balls.find(elem => elem.id === result.draggableId)
    if (
      result.destination.droppableId === 'pos0' ||
      result.destination.droppableId === 'pos1' ||
      result.destination.droppableId === 'pos2'
    ) {
      if (balls.find(elem => elem.pos === 2) === undefined) {
        if (balls.find(elem => elem.pos === 1) === undefined) {
          if (balls.find(elem => elem.pos === 0) === undefined) {
            ball.pos = 0
          } else {
            ball.pos = 1
          }
        } else {
          ball.pos = 2
        }
      } else {
        return
      }
    } else if (
      result.destination.droppableId === 'pos3' ||
      result.destination.droppableId === 'pos4'
    ) {
      if (balls.find(elem => elem.pos === 4) === undefined) {
        if (balls.find(elem => elem.pos === 3) === undefined) {
          ball.pos = 3
        } else {
          ball.pos = 4
        }
      }
    } else if (result.destination.droppableId === 'pos5') {
      if (balls.find(elem => elem.pos === 5) === undefined) {
        ball.pos = 5
      } else {
        return
      }
    }
    setMoves(moves + 1)
    setBalls(balls)
  }

  const setUpNextLevel = () => {
    setWon(false)
    setBalls(JSON.parse(JSON.stringify(startingBalls)))
    setMoves(0)
    setLevelIndex(levelIndex + 1)
    setCompletedProblems([...completedProblems, levelIndex + 1])
    setStartedAt(moment())
  }

  const fail = () => {
    // Cannot fail if already won level.
    if (!won) {
      if (levelIndex !== 0) {
        const finishedAt = moment()
        const seconds = finishedAt.diff(startedAt, 'seconds')
        setTimersToFail([...timersToFail, seconds])
        const failedAfter = seconds.toString().concat(t('seconds'))
        setFailedProblems([...failedProblems, levelIndex])

        setResults([
          ...results,
          {
            problem: levelIndex,
            moves,
            failedAfter,
          },
        ])
        if (timesUp) {
          setAllLevelsCompleted(true) // End the test.
        } else if (levelIndex < levels.length - 1) {
          setUpNextLevel()
        } else {
          setAllLevelsCompleted(true)
        }
      } else {
        setModalIndex(modalIndex + 1)
      }
    }
  }

  const reset = () => {
    // Cannot reset if already won level.
    if (!won && !outOfMoves && moves) {
      setMoves(0)
      setBalls(JSON.parse(JSON.stringify(startingBalls)))
    }
  }

  const processResults = () => {
    const numCompleted = completedProblems.length
    const numSolved = solvedProblems.length
    const numFailed = failedProblems.length
    const percentCompleted = ((numCompleted / (levels.length - 1)) * 100).toFixed(1)
    const percentCompletedString = percentCompleted.toString().concat('%')
    const percentSolved = ((numSolved / numCompleted) * 100).toFixed(1)
    const percentSolvedString = percentSolved.toString().concat('%')
    const finishedTestAt = moment()
    const seconds = finishedTestAt.diff(startedTestAt, 'seconds')
    const completedProblemResult = numCompleted ? completedProblems.join(', ') : '-'
    const solvedProblemResult = numSolved ? solvedProblems.join(', ') : '-'
    const failedProblemResult = numFailed ? failedProblems.join(', ') : '-'

    let averageSolve = (timersToSolve.reduce((a, b) => a + b, 0) / timersToSolve.length).toFixed(2)
    averageSolve = Number.isNaN(Number(averageSolve)) ? '-' : averageSolve.concat(t('seconds'))

    let averageFail = (timersToFail.reduce((a, b) => a + b, 0) / timersToFail.length).toFixed(2)
    averageFail = Number.isNaN(Number(averageFail)) ? '-' : averageFail.concat(t('seconds'))

    setResults([
      { testName: t('header') },
      {
        numProblems: levels.length - 1,
        numCompletedProblems: numCompleted,
        numSolvedProblems: numSolved,
        numFailedProblems: numFailed,
        completedProblems: completedProblemResult,
        solvedProblems: solvedProblemResult,
        failedProblems: failedProblemResult,
        percentCompleted: percentCompletedString,
        percentSolved: percentSolvedString,
        averageTimeToSolveProblem: averageSolve,
        averageTimeToFailProblem: averageFail,
        testTime: seconds.toString().concat(t('seconds')),
      },
      ...results,
    ])

    setFinished(true)
  }

  const droppableRender = (id, left, top, towerPos) => (
    <Droppable droppableId={id}>
      {provided => (
        <Box
          position="absolute"
          left={left}
          top={top}
          width="110px"
          height="25%"
          {...provided.droppableProps}
          innerRef={provided.innerRef}
        >
          {draggableRender(towerPos)}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  )

  const draggableRender = towerPos => {
    const theBall = balls.find(elem => elem.pos === towerPos)
    let draggable = false
    if (!won && !outOfMoves) {
      if (theBall !== undefined) {
        if (theBall.pos === 2 || theBall.pos === 4 || theBall.pos === 5) {
          draggable = true
        } else if (theBall.pos === 3) {
          if (balls.find(elem => elem.pos === 4) === undefined) {
            draggable = true
          }
        } else if (theBall.pos === 1) {
          if (balls.find(elem => elem.pos === 2) === undefined) {
            draggable = true
          }
        } else if (theBall.pos === 0) {
          if (balls.find(elem => elem.pos === 1) === undefined) {
            draggable = true
          }
        }
      }
    }
    return (
      <Box>
        {theBall && (
          <Draggable
            key={theBall.id}
            draggableId={theBall.id}
            index={parseInt(theBall.id)}
            isDragDisabled={!draggable || (dragging !== '0' && dragging !== theBall.id)}
          >
            {provided => (
              <Box
                height="110px"
                width="110px"
                round
                backgroundColor={theBall.color}
                position="absolute"
                top="10%"
                innerRef={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                cc
              >
                <Text semibold ginormous color="white">
                  {theBall.letter}
                </Text>
              </Box>
            )}
          </Draggable>
        )}
      </Box>
    )
  }

  useEffect(() => {
    if (
      balls[0].pos === levels[levelIndex].finalPos[0] &&
      balls[1].pos === levels[levelIndex].finalPos[1] &&
      balls[2].pos === levels[levelIndex].finalPos[2]
    ) {
      if (moves > levels[levelIndex].moves) {
        setOutOfMoves(true)
        if (practice) {
          reset() // Resets in practice so user can get it right.
        }
      } else {
        setWon(true)
        setFinishedAt(moment())
      }
    }
  }, [moves])

  useEffect(() => {
    if (won) {
      if (levelIndex !== 0) {
        setSolvedProblems([...solvedProblems, levelIndex])
        const seconds = finishedAt.diff(startedAt, 'seconds')
        setTimersToSolve([...timersToSolve, seconds])
        const solvedAfter = seconds.toString().concat(t('seconds'))

        setResults([
          ...results,
          {
            problem: levelIndex,
            moves: levels[levelIndex].moves,
            problemTime: solvedAfter,
          },
        ])
      }
    }
  }, [won])

  useEffect(() => {
    if (allLevelsCompleted) {
      processResults()
    }
  }, [allLevelsCompleted])

  // For displaying message and disable buttons.
  useEffect(() => {
    const interval = setInterval(() => {
      if (practice && won) {
        setModalIndex(modalIndex + 1)
      }
      if (started && won) {
        if (timesUp) {
          setAllLevelsCompleted(true) // End the test.
        } else if (levelIndex > 0 && levelIndex < levels.length - 1) {
          setUpNextLevel()
        } else if (levelIndex >= levels.length - 1) {
          setAllLevelsCompleted(true)
        }
      } else {
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [started, won])

  useEffect(() => {
    const interval = setInterval(() => {
      if (started) {
        setTimesUp(true)
      }
    }, TIMEOUT)
    return () => clearInterval(interval)
  }, [started])

  // For displaying message and disable buttons.
  useEffect(() => {
    const interval = setInterval(() => {
      if (practice && outOfMoves) {
        setOutOfMoves(false)
      }
      if (started && outOfMoves) {
        fail()
        setOutOfMoves(false)
      } else {
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [started, outOfMoves])

  useEffect(() => {
    const interval = setInterval(() => {
      if (started) {
        setProgressPercentage((moment().diff(startedTestAt, 'seconds') / (TIMEOUT / 1000)) * 100)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [startedTestAt])

  useEffect(() => {
    if (started) {
      setPractice(false)
      setUpNextLevel()
      setStartedTestAt(moment())
    }
  }, [started])

  const mainScreenRender = () => (
    <Box flexDirection="row" flexWrap>
      <Box width="33%" height="170px" cc>
        <Box pt="90px">
          <Text huge semibold color="white">
            {t('problem')} {levelIndex}
          </Text>
        </Box>
      </Box>
      <Box width="33%" cc mt="30px">
        <img src={levelImages[levelIndex]} alt="level {levelIndex}" width="70%" />
      </Box>
      <Box width="33%" height="170px" cc>
        <Box pt="90px">
          <Text big color="white">
            {t('allowed')} {levels[levelIndex].moves}
          </Text>
          <Text big color="white">
            {t('performed')} {moves}
          </Text>
        </Box>
      </Box>
      <Box cc block height="35px">
        {won && (
          <Text bigger color="white">
            {t('completed')}
          </Text>
        )}
        {outOfMoves && (
          <Text bigger color="white">
            {t('tooMany')}
          </Text>
        )}
      </Box>
      <DragDropContext
        onDragEnd={handleOnDragEnd}
        onDragStart={result => setDragging(String(result.draggableId))}
      >
        <Box width="80%" height="500px" position="relative" flexWrap center row>
          <Box width="23%" height="80%" cc mh="5%">
            <Box
              top="5%"
              backgroundColor={theme.colors.greySoft}
              width="50px"
              height="75%"
              position="absolute"
            />
            {droppableRender('pos2', '', '5%', 2)}
            {droppableRender('pos1', '', '30%', 1)}
            {droppableRender('pos0', '', '55%', 0)}
          </Box>
          <Box width="23%" height="80%" cc mh="5%">
            <Box
              top="30%"
              backgroundColor={theme.colors.greySoft}
              width="50px"
              height="50%"
              position="absolute"
            />
            {droppableRender('pos4', '', '30%', 4)}
            {droppableRender('pos3', '', '55%', 3)}
          </Box>
          <Box width="23%" height="80%" cc mh="5%">
            <Box
              top="55%"
              backgroundColor={theme.colors.greySoft}
              width="50px"
              height="25%"
              position="absolute"
            />
            {droppableRender('pos5', '', '55%', 5)}
          </Box>
          <Box width="100%" height="20%" cc>
            <Box
              position="absolute"
              backgroundColor={theme.colors.greySoft}
              width="80%"
              height="50px"
            />
          </Box>
        </Box>
      </DragDropContext>
    </Box>
  )

  const modalList = [
    <Modal isOpen={!practice} next onNext={onNext}>
      <Text big color="white">
        {t('instructionHeader')}
      </Text>
      <Instructions body={t('instructions')} />
      <Box cc>
        <img src={instructionImage} alt="symbol key" width="80%" />
      </Box>
      <Instructions body={t('instructions2')} />
    </Modal>,
    <Modal isOpen={!practice} next onNext={onNext} previous onPrev={onPrev}>
      <Text big color="white">
        {t('instructionHeader')}
      </Text>
      <Instructions body={t('instructions3')} />
      <Instructions body={t('rules')} />
    </Modal>,
    <Modal
      isOpen={!practice}
      closeText={t('startPractice')}
      close
      onClose={() => {
        setPractice(true)
      }}
      previous
      onPrev={onPrev}
    >
      <Text big color="white">
        {t('instructionHeader')}
      </Text>
      <Instructions body={t('instructions4')} />
      <Instructions body={t('instructions5')} />
    </Modal>,
    <Modal isOpen={!started} close closeText={t('startTest')} onClose={() => setStarted(true)}>
      <Text big color="white">
        {t('instructionHeader')}
      </Text>
      <Instructions body={t('instructions6')} />
      <Instructions body={t('rules')} />
    </Modal>,
  ]

  if (finished) {
    return (
      <Navigate
        to="/results"
        state={{
          results,
          battery: location?.state?.battery,
          formTitle: location?.state?.taskSetting?.title || 'toweroflondon',
        }}
      />
    )
  }

  if (started) {
    return (
      <Box fullscreen bg={theme.colors.greyDark}>
        {modalList[modalIndex]}
        {mainScreenRender()}
        <Box cc block spaceEvenly row>
          <Button onPress={() => fail()} block>
            <Text semibold center color="white">
              {t('skip')}
            </Text>
          </Button>
        </Box>
        {/* <Box width="100%" position="fixed" bottom="0">
          <ProgressBar width="100" progressPercentage={progressPercentage} />
        </Box> */}
      </Box>
    )
  }
  return (
    <Box fullscreen bg={theme.colors.greyDark}>
      {modalList[modalIndex]}
      {mainScreenRender()}
      <Box width="120px" position="fixed" right="20px" bottom="20px">
        <Button onPress={() => fail()} block>
          <Text semibold center color="white">
            {t('skipPractice')}
          </Text>
        </Button>
      </Box>
    </Box>
  )
}

export default TowerOfLondon
